<template>
  <div>
    <h3 align='center'>Good Bye</h3>
  </div>
</template>

<script>
export default {
  created: function(){
    let self = this
    setTimeout(() => {
      self.$router.push({name:'Home'})
    }, 2000);
  }
}
</script>

<style>

</style>