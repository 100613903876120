<template>
<div>
    
    <!-- DEBUG PANEL: to activate debug tail &debugView=show param to tool url
    http://localhost:8080/hcp/practice/clinical_tools?calc=perioperativeAnticoagulantAlgorithm&debugView=show
    -->
    <div style="position: fixed; top: 0; right: 0; z-index: 200" v-if="debug===true">
        <v-card>
            <v-card-title>PreopAlgo route options</v-card-title>
            <v-card-text>QID-1:  <span class="red--text font-weight-bold">{{getManuscriptOption('q1')}}</span></v-card-text>
            <v-card-text>QID-2:  <span class="red--text font-weight-bold">{{getManuscriptOption('q2')}}</span></v-card-text>
            <v-card-text>QID-3:  <span class="red--text font-weight-bold">{{getManuscriptOption('q3')}}</span></v-card-text>
            <v-card-text>QID-4:  <span class="red--text font-weight-bold">{{preop_selected.q4}}</span></v-card-text>
            <v-card-text>QID-5:  <span class="red--text font-weight-bold">{{preop_selected.q5}}</span></v-card-text>
            <v-card-text>QID-6:  <span class="red--text font-weight-bold">{{preop_selected.q6}}</span></v-card-text>
            <v-card-text>QID-7:  <span class="red--text font-weight-bold">{{preop_selected.q7}}</span></v-card-text>
            <v-card-text>QID-8:  <span class="red--text font-weight-bold">{{preop_selected.q8}}</span></v-card-text>
            <v-card-text>QID-9:  <span class="red--text font-weight-bold">{{preop_selected.q9}}</span></v-card-text>
            <v-card-text>QID-10:  <span class="red--text font-weight-bold">{{preop_selected.q10}}</span></v-card-text>
            <v-card-text>QID-11:  <span class="red--text font-weight-bold">{{preop_selected.q11}}</span></v-card-text>
            <v-card-text>QID-12:  <span class="red--text font-weight-bold">{{preop_selected.q12}}</span></v-card-text>
        </v-card>
    </div>
    
	<form id="calcWrapper" onsubmit="return false;" ref="calcWrapper">
		<div id="calcItems">
			<vueCalculators :language="language"/>

			<!-- Antothrombotic Algo -->
                        <template v-if="language==='fr-ca'">
                            <div class="calcDescription antithromboticAlgorithm">
                                    <h2>Posologie des anticoagulants en cas de fibrillation auriculaire</h2>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label class="withNumber">Âge (ans) <span class="validationMessage" style='color:red;'>Requis</span>
                                            <input value="" :required="activeCalc==='antithromboticAlgorithm'" type="number" onchange="calc.antithromboticAlgorithm('fr-ca')" onkeyup="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgAge">
                                    </label>

                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label class="withNumber">Poids (kg) <span class="validationMessage" style='color:red;'>Requis</span>
                                            <input value="" :required="activeCalc==='antithromboticAlgorithm'" type="number" onchange="calc.antithromboticAlgorithm('fr-ca')" onkeyup="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgWeight">
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label class="withNumber">Créatinine sérique <span class="calcUnit">(µmol/L)</span> <span class="validationMessage" style='color:red;'>Requis</span>
                                            <input value="" :required="activeCalc==='antithromboticAlgorithm'" type="number" onchange="calc.antithromboticAlgorithm('fr-ca')" onkeyup="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgSerumCreatinine">
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgHeart">
                                            Antécédents d’insuffisance cardiaque congestive
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgHyper">
                                            Antécédents d’hypertension artérielle
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgDiabetes">
                                            Antécédents de diabète
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgStroke">
                                            Antécédents d’AVC ou d’AIT
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgVascular">
                                            Antécédents de maladie macrovasculaire (coronaire, aortique ou périphérique)
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgIndicateWarfarin">
                                            Le patient présente une autre indication du traitement par la warfarine (p. ex., valve cardiaque mécanique, thrombus ventriculaire gauche, valvulopathie rhumatismale)
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgFemale">
                                            Sexe féminin
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgConcomitantInhibitors">
                                            Utilisation concomitante d’inhibiteurs de la P-gp (sauf l’amiodarone et le vérapamil)
                                            <a href="javascript:calcToggleExamples('#concomitantInhibitorsExamples');" class="toggleExamplesWrapper">?</a>
                                            <div id="concomitantInhibitorsExamples" class="examples" style='font-style:italic;color:#666;display: none;'>
                                                    Y compris, entre autres :<br>
                                                    Carvédilol, clarithromycine, cyclosporine, érythromycine, itraconazole, kétoconazole, dronédarone, lapatinib, lopinavir, propafénone, quinidine, ranolazine, ritonavir, saquinavir, télaprévir, tipranavir. En cas d’utilisation concomitante de vérapamil ou d’amiodarone, la réduction de la dose d’edoxaban n’est pas requise.
                                            </div>
                                    </label>
                            </div>                            
                        </template>
                        <template v-else>
                            <div class="calcDescription antithromboticAlgorithm">
                                    <h2>Anticoagulant Dosing In Atrial Fibrillation</h2>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label class="withNumber">Age (years) <span class="validationMessage" style='color:red;'>required</span>
                                            <input value="" :required="activeCalc==='antithromboticAlgorithm'" type="number" onchange="calc.antithromboticAlgorithm()" onkeyup="calc.antithromboticAlgorithm()" name="antithromboAlgAge">
                                    </label>

                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label class="withNumber">Weight (kg) <span class="validationMessage" style='color:red;'>required</span>
                                            <input value="" :required="activeCalc==='antithromboticAlgorithm'" type="number" onchange="calc.antithromboticAlgorithm()" onkeyup="calc.antithromboticAlgorithm()" name="antithromboAlgWeight">
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label class="withNumber">Serum Creatinine <span class="calcUnit">(µmol/L)</span> <span class="validationMessage" style='color:red;'>required</span>
                                            <input value="" :required="activeCalc==='antithromboticAlgorithm'" type="number" onchange="calc.antithromboticAlgorithm()" onkeyup="calc.antithromboticAlgorithm()" name="antithromboAlgSerumCreatinine">
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm()" name="antithromboAlgHeart">
                                            Congestive Heart Failure History
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm()" name="antithromboAlgHyper">
                                            Hypertension History
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm()" name="antithromboAlgDiabetes">
                                            Diabetes Mellitus History
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm()" name="antithromboAlgStroke">
                                            Previous stroke or TIA
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm()" name="antithromboAlgVascular">
                                            History of macrovascular disease (coronary, aortic or peripheral)
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm()" name="antithromboAlgIndicateWarfarin">
                                            Patient has another indication for warfarin therapy (for example, mechanical heart valve, LV thrombus, rheumatic valvular heart disease)
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm()" name="antithromboAlgFemale">
                                            Female Patient
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm()" name="antithromboAlgConcomitantInhibitors">
                                            Concomitant use of P-gp inhibitors (except amiodarone and verapamil)
                                            <a href="javascript:calcToggleExamples('#concomitantInhibitorsExamples');" class="toggleExamplesWrapper">?</a>
                                            <div id="concomitantInhibitorsExamples" class="examples" style='font-style:italic;color:#666;display: none;'>
                                                    Including but not limited to:<br>
                                                    Carvedilol, clarithromycin, cyclosporin, erythromycin,itraconazole, ketoconazole, dronedarone, lapatinib, lopinavir, propafenone, quinidine, ranolazine, ritonavir, saquinavir, telaprevir, tipranavir. Dose reduction of edoxaban is not required with concomitant use of verapamil or amiodarone
                                            </div>
                                    </label>
                            </div>
                        </template>

<PreopAlgorithm :language='language' :preop_selected='preop_selected' :preopOptionValueMap='preopOptionValueMap' :debug='debug' :syncDoacEffectLevel='syncDoacEffectLevel' :display_modal='display_modal'/>
<!--  Preoperative Anticoagulant Mangement Algo  -->
<!--  End of Preoperative Anticoagulant Mangement Algo  -->
                    
			<!--  Vivomap  -->
			<div class="calcDescription vivomap261">
                            <template v-if="language==='fr-ca'">
				<h2>Embolie pulmonaire</h2>
                                Algorithme interactif sur l’embolie pulmonaire (EP)
                            </template>
                            <h2 v-else>Pulmonary Embolism Management</h2>
			</div>
			<div class="calcItem vivomap261">
				<div id="vivomap261" class="vivomap_styles" style="width:100%;height:100%;overflow:hidden;"></div>
				<span style="text-align: center;display:block;">powered by Vivomap<sup>&reg;</sup></span>
			</div>
			<div class="calcDescription vivomap262">
				<h2>Atrial Fibrillation</h2>
			</div>
			<div class="calcItem vivomap262">
				<div id="vivomap262" class="vivomap_styles" style="width:100%;height:100%;overflow:hidden;"></div>
				<span style="text-align: center;display:block;">powered by Vivomap<sup>&reg;</sup></span>
			</div>
			<div class="calcDescription vivomap271">
				<h2>Bleed Management</h2>
			</div>
			<div class="calcItem vivomap271">
				<div id="vivomap271" class="vivomap_styles" style="width:100%;height:100%;overflow:hidden;"></div>
				<span style="text-align: center;display:block;">powered by Vivomap<sup>&reg;</sup></span>
			</div>
			<div class="calcDescription vivomap263">
                            <template v-if="language==='fr-ca'">
				<h2>Thrombose veineuse profonde (TVP)</h2>
                                Algorithme interactif sur la thrombose veineuse profonde (TVP)
                            </template>
                            <h2 v-else>Deep Vein Thrombosis</h2>
                        </div>
			<div class="calcItem vivomap263">
				<div id="vivomap263" class="vivomap_styles" style="width:100%;height:100%;overflow:hidden;"></div>
				<span style="text-align: center;display:block;">powered by Vivomap<sup>&reg;</sup></span>
			</div>
			<div class="calcDescription vivomap329">
                                <h2  v-if="language==='fr-ca'">Suivi du traitement anticoagulant oral direct (AOD)</h2>
                                <h2 v-else>DOAC Follow-up</h2>                                
			</div>
			<div class="calcItem vivomap329">
				<div id="vivomap329" class="vivomap_styles" style="width:100%;height:100%;overflow:hidden;"></div>
				<span style="text-align: center;display:block;">powered by Vivomap<sup>&reg;</sup></span>
			</div>



			<!--  CHADS2   -->
                        <template v-if="language==='fr-ca'">
                            <div class="calcDescription chads2">
                                    <h2>Score CHADS2 pour le risque d’AVC lié à la fibrillation auriculaire non valvulaire</h2>
                                    Estimation du risque d’AVC lié à la fibrillation auriculaire non valvulaire à l’aide des critères CHADS2.
                            </div>
                            <div class="calcItem chads2 congestiveHeardFailure">
                                    <label>
                                            <input onclick="calc.chads2('fr-ca');" type="checkbox" name="congestiveHeardFailure">
                                            Antécédent d’insuffisance cardiaque congestive?
                                    </label>
                            </div>
                            <div class="calcItem chads2">
                                    <label>
                                            <input onclick="calc.chads2('fr-ca');" type="checkbox" name="hypertension">
                                            Antécédent d’hypertension?
                                    </label>
                            </div>
                            <div class="calcItem chads2 ageOver75">
                                    <label>
                                            <input onclick="calc.chads2('fr-ca');" type="checkbox" name="ageOver75">
                                            Âge &ge; 75 ans?
                                    </label>
                            </div>
                            <div class="calcItem chads2 diabetese">
                                    <label>
                                            <input onclick="calc.chads2('fr-ca');" type="checkbox" name="diabetese">
                                            Antécédent de diabète?
                                    </label>
                            </div>
                            <div class="calcItem chads2 strokeOrTIA">
                                    <label>
                                            <input onclick="calc.chads2('fr-ca');" type="checkbox" name="strokeOrTIA">
                                            Antécédent d’AVC ou d’AIT?
                                    </label>
                            </div>
                        </template>
                        <template v-else>
                            <div class="calcDescription chads2">
                                    <h2>CHADS2 Score for Atrial Fibrillation Stroke Risk</h2>
                                    Estimates stroke risk in patients with atrial fibrillation by the CHADS2 criteria.
                            </div>
                            <div class="calcItem chads2 congestiveHeardFailure">
                                    <label>
                                            <input onclick="calc.chads2();" type="checkbox" name="congestiveHeardFailure">
                                            Congestive Heart Failure history?
                                    </label>
                            </div>
                            <div class="calcItem chads2">
                                    <label>
                                            <input onclick="calc.chads2();" type="checkbox" name="hypertension">
                                            Hypertension history?
                                    </label>
                            </div>
                            <div class="calcItem chads2 ageOver75">
                                    <label>
                                            <input onclick="calc.chads2();" type="checkbox" name="ageOver75">
                                            Age &ge; 75?
                                    </label>
                            </div>
                            <div class="calcItem chads2 diabetese">
                                    <label>
                                            <input onclick="calc.chads2();" type="checkbox" name="diabetese">
                                            Diabetes Mellitus history?
                                    </label>
                            </div>
                            <div class="calcItem chads2 strokeOrTIA">
                                    <label>
                                            <input onclick="calc.chads2();" type="checkbox" name="strokeOrTIA">
                                            Previous stroke or TIA?
                                    </label>
                            </div>
                        </template>



			<!--  CHADS2-VASC  -->
                        <template v-if="language==='fr-ca'">
                            <div class="calcDescription chads2vasc">
                                    <h2>Score CHA2DS2-VASc pour le risque d’AVC lié à la fibrillation auriculaire</h2>
                                    Permet de calculer le risque d’AVC des patients atteints de fibrillation auriculaire, éventuellement mieux que le score CHA2DS2-VASC.
                            </div>
                            <div class="calcItem chads2vasc">
                                    Âge<br>
                                    <div class="radiogroup">
                                            <label>
                                                    <input type="radio" onclick="calc.chads2Vasc('fr-ca');" name="chads2vascAge" value="0">
                                                    &lt; 65 ans <span class="calcItemValue">(+0)</span>
                                            </label>
                                            <label>
                                                    <input type="radio" onclick="calc.chads2Vasc('fr-ca');" name="chads2vascAge" value="1">
                                                    65-74 ans <span class="calcItemValue">(+1)</span>
                                            </label>
                                            <label>
                                                    <input type="radio" onclick="calc.chads2Vasc('fr-ca');" name="chads2vascAge" value="2">
                                                    &ge; 75 ans <span class="calcItemValue">(+2)</span>
                                            </label>
                                    </div>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('fr-ca')" class="chads2vasc" name="chads2vascHeart">
                                            Antécédents d’insuffisance cardiaque congestive <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('fr-ca')" class="chads2vasc" name="chads2vascHyper">
                                            Antécédents d’hypertension <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('fr-ca')" class="chads2vasc" name="chads2vascStroke">
                                            Antécédents d’AVC, d’AIT ou de thromboembolie <span class="calcItemValue">(+2)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('fr-ca')" class="chads2vasc" name="chads2vascVascHist">
                                            Antécédents de pathologie vasculaire (antécédents d’infarctus du myocarde, de maladie artérielle périphérique ou de plaque aortique)? <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('fr-ca')" class="chads2vasc" name="chads2vascDiabetes">
                                            Diabète sucré <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('fr-ca')" class="chads2vasc" name="chads2vascFemale">
                                            Sexe féminin <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>
                        </template>
                        <template v-else>
                            <div class="calcDescription chads2vasc">
                                    <h2>CHA2DS2-VASc Score for Atrial Fibrillation Stroke Risk</h2>
                                    Calculates stroke risk for patients with atrial fibrillation, possibly better than the CHADS2 score.
                            </div>
                            <div class="calcItem chads2vasc">
                                    Age<br>
                                    <div class="radiogroup">
                                            <label>
                                                    <input type="radio" onclick="calc.chads2Vasc('en-ca');" name="chads2vascAge" value="0">
                                                    &lt; 65 <span class="calcItemValue">(+0)</span>
                                            </label>
                                            <label>
                                                    <input type="radio" onclick="calc.chads2Vasc('en-ca');" name="chads2vascAge" value="1">
                                                    65-74 <span class="calcItemValue">(+1)</span>
                                            </label>
                                            <label>
                                                    <input type="radio" onclick="calc.chads2Vasc('en-ca');" name="chads2vascAge" value="2">
                                                    &ge; 75 <span class="calcItemValue">(+2)</span>
                                            </label>
                                    </div>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('en-ca')" class="chads2vasc" name="chads2vascHeart">
                                            Congestive Heart Failure History <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('en-ca')" class="chads2vasc" name="chads2vascHyper">
                                            Hypertension History <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('en-ca')" class="chads2vasc" name="chads2vascStroke">
                                            Stroke/TIA/Thromboembolism History <span class="calcItemValue">(+2)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('en-ca')" class="chads2vasc" name="chads2vascVascHist">
                                            Vascular Disease History? (previous MI, peripheral arterial disease or aortic plaque) <span class="calcItemValue">(+1
                                            )</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('en-ca')" class="chads2vasc" name="chads2vascDiabetes">
                                            Diabetes Mellitus <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('en-ca')" class="chads2vasc" name="chads2vascFemale">
                                            Female Patient <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>                            
                        </template>


			<!--  Creatinine Clearance (Cockcroft-Gault Equation)  -->
                        <template v-if="language==='fr-ca'">
                            <div class="calcDescription cockcroft">
                                    <h2>Clairance de la créatinine (équation de Cockcroft-Gault)</h2>
                                    Permet de calculer la clairance de la créatinine à l’aide de l’équation de Cockgroft-Gault.
                            </div>
                            <div class="calcItem cockcroft">
                                    <label>
                                            <input type="checkbox" name="isFemale" onclick="calc.creatinineClearance('fr-ca')">
                                            Sexe féminin
                                    </label>
                            </div>
                            <div class="calcItem cockcroft age">
                                    <label class="withNumber">
                                            Âge (ans)
                                            <input :required="activeCalc==='cockcroft'" type="number" oninvalid="this.setCustomValidity('Veuillez remplir ce champ')" onchange="calc.creatinineClearance('fr-ca')" onkeyup="calc.creatinineClearance('fr-ca')" name="cockcroftAge">
                                            <div class="validationMessage">!</div>
                                    </label>
                            </div>
                            <div class="calcItem cockcroft">
                                    <label class="withNumber">
                                            Poids (kg)
                                            <input :required="activeCalc==='cockcroft'" type="number" oninvalid="this.setCustomValidity('Veuillez remplir ce champ')" onchange="calc.creatinineClearance('fr-ca')" onkeyup="calc.creatinineClearance('fr-ca')" name="weight">
                                            <div class="validationMessage">!</div>
                                    </label>
                            </div>
                            <div class="calcItem cockcroft">
                                    <label class="withNumber">
                                            Créatinine sérique <span class="calcUnit">(µmol/L)</span>
                                            <input :required="activeCalc==='cockcroft'" type="number" oninvalid="this.setCustomValidity('Veuillez remplir ce champ')" onchange="calc.creatinineClearance('fr-ca')" onkeyup="calc.creatinineClearance('fr-ca')" name="serumCreatinine">
                                            <div class="validationMessage">!</div>
                                    </label>
                            </div>
                        </template>
                        <template v-else>
                            <div class="calcDescription cockcroft">
                                    <h2>Creatinine Clearance (Cockcroft-Gault Equation)</h2>
                                    Calculates creatinine clearance according to the Cockgroft-Gault equation.
                            </div>
                            <div class="calcItem cockcroft">
                                    <label>
                                            <input type="checkbox" name="isFemale" onclick="calc.creatinineClearance()">
                                            Female patient
                                    </label>
                            </div>
                            <div class="calcItem cockcroft age">
                                    <label class="withNumber">
                                            Age (years)
                                            <input :required="activeCalc==='cockcroft'" type="number" onchange="calc.creatinineClearance()" onkeyup="calc.creatinineClearance()" name="cockcroftAge">
                                            <div class="validationMessage">!</div>
                                    </label>
                            </div>
                            <div class="calcItem cockcroft">
                                    <label class="withNumber">
                                            Weight (kg)
                                            <input :required="activeCalc==='cockcroft'" type="number" onchange="calc.creatinineClearance()" onkeyup="calc.creatinineClearance()" name="weight">
                                            <div class="validationMessage">!</div>
                                    </label>
                            </div>
                            <div class="calcItem cockcroft">
                                    <label class="withNumber">
                                            Serum Creatinine <span class="calcUnit">(µmol/L)</span>
                                            <input :required="activeCalc==='cockcroft'" type="number" onchange="calc.creatinineClearance()" onkeyup="calc.creatinineClearance()" name="serumCreatinine">
                                            <div class="validationMessage">!</div>
                                    </label>
                            </div>
                        </template>




			<!--  HAS-BLED  -->
                        <template v-if="language==='fr-ca'">
                            <div class="calcDescription has-bled">
                                    <h2>Score HAS-BLED du risque d’hémorragie grave</h2>
                                    Estimation du risque d’hémorragie grave des patients sous anticoagulothérapie, permettant de déterminer le rapport risques/avantages du traitement de la fibrillation auriculaire
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="hasBledHypertension">
                                            Antécédents d’hypertension (non maîtrisée, TA systolique > 160 mm Hg)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="hasBledRenal">
                                            Néphropathie (dialyse, greffe, Cr > 2,6 mg/dL ou > 200 µmol/L)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="hasBledLiver">
                                            Maladie du foie (cirrhose, taux de bilirubine > 2 fois la normale, AST/ALT/AP > 3 fois la normale)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="hasBledStroke">
                                            Antécédents d’AVC
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="hasBledBleeding">
                                            Antécédents d’hémorragie grave ou prédisposition aux saignements 
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="labile">
                                            Fluctuations du RIN (RIN instables/élevés)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="hasBledageOver65">
                                            Âge &ge; 65 ans
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="hasBledMedBleeding">
                                            Prise de médicaments prédisposant aux hémorragies (antiplaquettaires, AINS)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="hasBledAlcohol">
                                            Antécédents de consommation d’alcool
                                    </label>
                            </div>
                        </template>
                        <template v-else>
                            <div class="calcDescription has-bled">
                                    <h2>HAS-BLED Score for Major Bleeding Risk</h2>
                                    Estimates risk of major bleeding for patients on anticoagulation to help determine risk-benefit in atrial fibrillation care.
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="hasBledHypertension">
                                            Hypertension History (uncontrolled, >160 mmHg systolic)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="hasBledRenal">
                                            Renal Disease (Dialysis, transplant, Cr > 2.6 mg/dl or > 200 &micro;mol/L)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="hasBledLiver">
                                            Liver Disease (Cirrhosis, Bilirubin > 2x Normal, AST/ALT/AP > 3x Normal)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="hasBledStroke">
                                            Stroke History
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="hasBledBleeding">
                                            Prior Major Bleeding or Predisposition to Bleeding
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="labile">
                                            Labile INR (Unstable/high INRs)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="hasBledageOver65">
                                            Age &ge; 65
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="hasBledMedBleeding">
                                            Medication Usage Predisposing to Bleeding (Antiplatelet agents, NSAIDS)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="hasBledAlcohol">
                                            Alcohol Usage History
                                    </label>
                            </div>                            
                        </template>


			<!--  ABCD2  -->
			<div class="calcDescription abcd2">
				<h2>ABCD2 Score for TIA</h2>
				Estimates risk of stroke after a TIA, according to patient risk factors.
			</div>
			<div class="calcItem abcd2">
				<label>
					<input onclick="calc.abcd2()" type="checkbox" name="abcdAgeOver60">
					Age &ge; 60
				</label>
			</div>
			<div class="calcItem abcd2">
				<label>
					<input onclick="calc.abcd2()" type="checkbox" name="abcdBP">
					BP &ge; 140/90 mmHg at initial evaluation
				</label>
			</div>
			<div class="calcItem abcd2">
				Clinical Features of the TIA
				<div class="radiogroup">
					<label>
						<input onclick="calc.abcd2()" type="radio" name="abcdFeat" value="2">
						Unilateral Weakness
					</label>
					<label>
						<input onclick="calc.abcd2()" type="radio" name="abcdFeat" value="1">
						Speech Disturbance Without Weakness
					</label>
					<label>
						<input onclick="calc.abcd2()" type="radio" name="abcdFeat" value="0">
						Other Symptom
					</label>
				</div>
			</div>
			<div class="calcItem abcd2">
				Duration of Symptoms
				<div class="radiogroup">
					<label>
						<input onclick="calc.abcd2()" type="radio" name="abcdDur" value="0">
						&lt; 10 minutes
					</label>
					<label>
						<input onclick="calc.abcd2()" type="radio" name="abcdDur" value="1">
						10-59 minutes
					</label>
					<label>
						<input onclick="calc.abcd2()" type="radio" name="abcdDur" value="2">
						&ge; 60 minutes
					</label>
				</div>
			</div>
			<div class="calcItem abcd2">
				<label>
					<input onclick="calc.abcd2()" type="checkbox" name="abcdDiab">
					Diabetes Mellitus in Patient's History
				</label>
			</div>





			<!--  Wells DVT  -->
                        <template v-if="language==='fr-ca'">
                            <div class="calcDescription wellsDVT">
                                    <h2>Algorithme interactif axé sur les critères de Wells pour le diagnostic d’une TVP</h2>
                                    Calcul du score de Wells reflétant le risque de TVP
                            </div>

                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells0">
                                            Cancer actif?
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells1">
                                            Immobilisation  récente pendant > 3 jours ou chirurgie majeure au cours des 4 dernières semaines?
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells2">
                                            Enflure du mollet de > 3 cm comparativement à la jambe controlatérale?
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells3">
                                            Présence de veines superficielles (non variqueuses) collatérales?
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells4">
                                            Enflure de toute la jambe?
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells5">
                                            Sensibilité localisée le long du système veineux profond
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells6">
                                            Œdème qui prend le godet (plus important sur la jambe symptomatique)?
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells7">
                                            Paralysie, parésie ou immobilisation (mise en plâtre) récente du membre inférieur?
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells8">
                                            Antécédents de TVP avérée
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wellsAlt">
                                            Autre diagnostic que la TVP plus probable ou aussi probable?
                                    </label>
                            </div>                            
                        </template>
                        <template v-else>
			<div class="calcDescription wellsDVT">
				<h2>Wells' Criteria for DVT</h2>
				Calculates Wells' Score for risk of DVT.
			</div>

			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells0">
					Active cancer?
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells1">
					Bedridden recently > 3 days or major surgery within four weeks?
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells2">
					Calf swelling > 3cm compared to the other leg?
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells3">
					Collateral (nonvaricose) superficial veins present?
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells4">
					Entire leg swollen?
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells5">
					Localized tenderness along the deep venous system?
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells6">
					Pitting edema, greater in the symptomatic leg?
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells7">
					Paralysis, paresis, or recent plaster immobilization of the lower extremety
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells8">
					Previously documented DVT?
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wellsAlt">
					Alternative diagnosis to DVT as likely or more likely?
				</label>
			</div>
      </template> <!--  Wells DVT End -->




                        
      <!--  wells' PE  -->
      <template v-if="language==='fr-ca'">
          <div class="calcDescription wellsPE">
                  <h2>Algorithme interactif axé sur les critères de Wells pour le diagnostic de l’embolie pulmonaire</h2>
                  Calcul du score de Wells reflétant le risque d’embolie pulmonaire (EP)
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('fr-ca');" name="wellPE1">
                          Signes cliniques et symptômes d’une TVP
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('fr-ca');" name="wellPE2">
                          L’EP est le diagnostic primaire, ou est tout aussi probable
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('fr-ca');" name="wellPE3">
                          Fréquence cardiaque > 100 bpm
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('fr-ca');" name="wellPE4">
                          Immobilisation d’au moins 3 jours ou intervention chirurgicale au cours des 4 semaines précédentes
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('fr-ca');" name="wellPE5">
                          Antécédents d’EP ou de TVP diagnostiquée de manière objective
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('fr-ca');" name="wellPE6">
                          Hémoptysie
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('fr-ca');" name="wellPE7">
                          Cancer dont le traitement remonte aux 6 derniers mois, ou soins palliatifs
                  </label>
          </div>                            
      </template>
      <template v-else>
          <div class="calcDescription wellsPE">
                  <h2>Wells' Criteria for PE Interactive Algorithm</h2>
                  Calculates Wells' Score for risk of PE.
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('en-ca');" name="wellPE1">
                          Clinical Signs and Symptoms of DVT
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('en-ca');" name="wellPE2">
                          PE Is #1 Diagnosis, or Equally Likely
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('en-ca');" name="wellPE3">
                          Heart Rate > 100
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('en-ca');" name="wellPE4">
                          Immobilization at least 3 days, or Surgery in the Previous 4 weeks
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('en-ca');" name="wellPE5">
                          Previous, objectively diagnosed PE or DVT
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('en-ca');" name="wellPE6">
                          Hemoptysis
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('en-ca');" name="wellPE7">
                          Malignancy w/ Treatment within 6 mo, or palliative
                  </label>
          </div>
      </template>   <!--  end wells' PE  -->



                    <template v-if="language==='fr-ca'">
			<div class="calcDescription perc">
				<h2>Règle de PERC pour l’embolie pulmonaire</h2>
				Présentation des critères de PERC permettant d’écarter l’embolie pulmonaire (EP) si tous les critères sont remplis et si la probabilité prétest est &le; 15 %.
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc('fr-ca')" name="perc1">
					Âge &lt; 50 ans
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc('fr-ca')" name="perc2">
					FC &lt; 100
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc('fr-ca')" name="perc3">
          Saturation en O<sub>2</sub> à l’air ambiant &gt;94%
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc('fr-ca')" name="perc4">
					Aucun antécédent de TVP/d’EP
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc('fr-ca')" name="perc5">
					Absence de traumatisme récent ou d’intervention chirurgicale récente
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc('fr-ca')" name="perc6">
					Absence d’hémoptysie
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc('fr-ca')" name="perc7">
					Pas de prise d’œstrogène exogène
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc('fr-ca')" name="perc8">
					Aucun signe clinique évoquant une TVP
				</label>
			</div>                        
                    </template>
                    <template v-else>
			<div class="calcDescription perc">
				<h2>PERC Rule for Pulmonary Embolism</h2>
				Shows the PERC criteria, which can rule out PE if all criteria are present and pre-test probability is &le;15%.
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc()" name="perc1">
					Age &lt; 50
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc()" name="perc2">
					HR &lt; 100
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc()" name="perc3">
                                        O<sub>2</sub> Sat on Room Air &gt;94%
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc()" name="perc4">
					No Prior History of DVT/PE
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc()" name="perc5">
					No Recent Trauma or Surgery
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc()" name="perc6">
					No Hemoptysis
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc()" name="perc7">
					No Exogenous Estrogen
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc()" name="perc8">
					No Clinical Signs Suggesting DVT
				</label>
			</div>
                    </template>





                    <template v-if="language==='fr-ca'">
			<div class="calcDescription timiNSTEMI">
				<h2>Algorithme interactif axé sur le score de risque TIMI pour l’angor instable/le NSTEMI</h2>
				Estimation de la mortalité des patients atteints d’angor instable et d’un infarctus du myocarde sans élévation du segment ST.
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI('fr-ca')" name="timiNSTEMI1">
					Âge &ge; 65 ans
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI('fr-ca')" name="timiNSTEMI2">
					&ge; 3 facteurs de risque de maladie coronarienne
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI('fr-ca')" name="timiNSTEMI3">
					Maladie coronarienne avérée (sténose &ge; 50 %)
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI('fr-ca')" name="timiNSTEMI4">
					Prise d’AAS au cours des 7 derniers jours
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI('fr-ca')" name="timiNSTEMI5">
					Angor sévère (&ge; 2 épisodes en 24 heures)
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI('fr-ca')" name="timiNSTEMI6">
					Décalages ST &ge; 0,5 mm
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI('fr-ca')" name="timiNSTEMI7">
					Marqueur cardiaque +
				</label>
			</div>                        
                    </template>
                    <template v-else>
			<div class="calcDescription timiNSTEMI">
				<h2>TIMI Risk Score for UA/NSTEMI</h2>
				Estimates mortality for patients with unstable angina and non-ST elevation MI.
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI()" name="timiNSTEMI1">
					Age &ge; 65 years
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI()" name="timiNSTEMI2">
					&ge; 3 Risk Factors for CAD
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI()" name="timiNSTEMI3">
					Known CAD (stenosis &ge; 50%)
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI()" name="timiNSTEMI4">
					ASA Use in Past 7d
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI()" name="timiNSTEMI5">
					Severe angina (&ge; 2 episodes w/in 24 hrs)
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI()" name="timiNSTEMI6">
					ST changes &ge; 0.5mm
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI()" name="timiNSTEMI7">
					+ Cardiac Marker
				</label>
			</div>
                    </template>






			<!--  timi for STEMI -->
                        <template v-if="language==='fr-ca'">
                            <div class="calcDescription timiSTEMI">
                                    <h2>Algorithme interactif axé sur le score de risque TIMI lié au STEMI</h2>
                                    Estimations du taux de mortalité des patients ayant subi un STEMI .
                            </div>
                            <div class="calcItem timiSTEMI">
                                    Âge<br>
                                    <div class="radiogroup">
                                            <label>
                                                    <input type="radio" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMIAge" value="0" checked="checked">
                                                    &lt; 65 ans
                                            </label>
                                            <label>
                                                    <input type="radio" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMIAge" value="2">
                                                    65-74 ans
                                            </label>
                                            <label>
                                                    <input type="radio" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMIAge" value="3">
                                                    &ge; 75 ans
                                            </label>
                                    </div>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMI1">
                                            Diabète sucré ou hypertension ou angine
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMI2">
                                            TAS &lt; 100 mmHg
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMI3">
                                            FC &gt; 100 bpm
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMI4">
                                            Classe II-IV de Killip
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMI5">
                                            Poids &lt; 67 kg (147.7 lb)
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMI6">
                                            Élévation du segment ST antérieur ou bloc de branche gauche (BBG)
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMI7">
                                            Délai précédant le traitement &gt; 4 h
                                    </label>
                            </div>
                        </template>
                        <template v-else>
                            <div class="calcDescription timiSTEMI">
                                    <h2>TIMI Risk Score for STEMI</h2>
                                    Estimates mortality in patients with STEMI.
                            </div>
                            <div class="calcItem timiSTEMI">
                                    Age<br>
                                    <div class="radiogroup">
                                            <label>
                                                    <input type="radio" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMIAge" value="0" checked="checked">
                                                    &lt; 65
                                            </label>
                                            <label>
                                                    <input type="radio" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMIAge" value="2">
                                                    65-74
                                            </label>
                                            <label>
                                                    <input type="radio" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMIAge" value="3">
                                                    &ge; 75
                                            </label>
                                    </div>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMI1">
                                            DM or HTN or Angina
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMI2">
                                            SBP &lt; 100 mmHg
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMI3">
                                            HR &gt; 100 bpm
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMI4">
                                            Killip Class II-IV
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMI5">
                                            Weight &lt; 67 kg (147.7 lbs)
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMI6">
                                            Anterior ST Elevation or LBBB
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMI7">
                                            Time to Treatment &gt; 4 hrs
                                    </label>
                            </div>
                        </template>






			<!--  PESI  -->
                        <template v-if="language==='fr-ca'">
                            <div class="calcDescription pesi">
                                    <h2>Algorithme interactif de l’indice de gravité de l’embolie pulmonaire (score PESI)</h2>
                                    1.0	Permet de prédire l’issue clinique à 30 jours de patients atteints d’une embolie pulmonaire à l’aide de 11 critères cliniques
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            Âge (ans) <span class="calcItemValue">(+1 per year)</span> <span class="validationMessage">required</span>
                                            <input type="number" onchange="calc.pesi('fr-ca')" onkeyup="calc.pesi('fr-ca')" name="pesiAge" :required="activeCalc==='pesi'">
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiMale">
                                            Sexe masculin	 <span class="calcItemValue">(+10)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiCancer">
                                            Antécédent de cancer	 <span class="calcItemValue">(+30)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiHeartFailure">
                                            Antécédent d’insuffisance cardiaque	 <span class="calcItemValue">(+10)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiLung">
                                            Antécédent de maladie pulmonaire chronique	 <span class="calcItemValue">(+10)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiHeartRate">
                                            Fréquence cardiaque &ge; 110	 <span class="calcItemValue">(+20)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi'fr-ca')" name="pesiBloodPressure">
                                            Tension artérielle systolique &lt; 100 mmHg	 <span class="calcItemValue">(+30)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiRespiratoryRate">
                                            Fréquence respiratoire &ge; 30/min	 <span class="calcItemValue">(+20)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiTemp">
                                            Température &lt; 36&deg; C (96.8&deg; F)	 <span class="calcItemValue">(+20)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiMental">
                                            Altération de l’état mental (désorientation, léthargie, obnubilation mentale ou coma)	 <span class="calcItemValue">(+60)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiO2">
                                            Saturation en O<sub>2</sub> &lt; 90 % à l’air ambiant	 <span class="calcItemValue">(+20)</span>
                                    </label>
                            </div>
                        </template>
                        <template v-else>
                            <div class="calcDescription pesi">
                                    <h2>Pulmonary Embolism Severity Index (PESI)</h2>
                                    Predicts 30-day outcome of patients with pulmonary embolism using 11 clinical criteria.
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            Age (years) <span class="calcItemValue">(+1 per year)</span> <span class="validationMessage">required</span>
                                            <input type="number" onchange="calc.pesi()" onkeyup="calc.pesi()" name="pesiAge" :required="activeCalc==='pesi'">
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiMale">
                                            Male Patient	 <span class="calcItemValue">(+10)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiCancer">
                                            History of Cancer	 <span class="calcItemValue">(+30)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiHeartFailure">
                                            History of heart failure	 <span class="calcItemValue">(+10)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiLung">
                                            History of chronic lung disease	 <span class="calcItemValue">(+10)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiHeartRate">
                                            Heart Rate &ge; 110	 <span class="calcItemValue">(+20)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiBloodPressure">
                                            Systolic Blood Pressure &lt; 100 mmHg	 <span class="calcItemValue">(+30)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiRespiratoryRate">
                                            Respiratory Rate &ge; 30/min	 <span class="calcItemValue">(+20)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiTemp">
                                            Temperature &lt; 36&deg; C (96.8&deg; F)	 <span class="calcItemValue">(+20)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiMental">
                                            Altered Mental Status (disorientation, lethargy, stupor, or coma)	 <span class="calcItemValue">(+60)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiO2">
                                            O<sub>2</sub> Saturation &lt; 90% on Room Air	 <span class="calcItemValue">(+20)</span>
                                    </label>
                            </div>
                        </template>





			<!--  simplified PESI -->
                        <template v-if="language==='fr-ca'">
			<div class="calcDescription simplifiedPesi">
				<h2>Algorithme interactif axé sur la version simplifiée de l’indice de gravité de l’embolie pulmonaire (PESI)</h2>
				Outil prédictif de l’issue à 30 jours d’une embolie pulmonaire à partir de critères moins nombreux que ceux de l’indice PESI original.
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'fr-ca')" name="simplePesiAge">
					Âge &gt; 80 ans <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'fr-ca')" name="simplePesiCancer">
					Antécédents de cancer	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'fr-ca')" name="simplePesiHeartFailure">
					Antécédents de maladie cardiopulmonaire chronique	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'fr-ca')" name="simplePesiHeartRate">
					Fréquence cardiaque &ge; 110/min	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'fr-ca')" name="simplePesiBloodPressure">
					Tension artérielle systolique &le; 100 mmHg	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'fr-ca')" name="simplePesiO2">
					Saturation en O<sub>2</sub> &lt; 90% à l’air ambiant	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(true, 'fr-ca')" name="simplePesiNone">
					Le patient ne remplit aucun de ces critères
				</label>
			</div>                            
                        </template>
                        <template v-else>
			<div class="calcDescription simplifiedPesi">
				<h2>Simplified PESI (Pulmonary Embolism Severity Index)</h2>
				Predicts 30-day outcome of patients with pulmonary embolism with fewer criteria than the original Pulmonary Embolism Severity Index.
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'en-ca')" name="simplePesiAge">
					Patient Age &gt; 80 (years) <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'en-ca')" name="simplePesiCancer">
					History of Cancer	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'en-ca')" name="simplePesiHeartFailure">
					History of Chronic Cardiopulmonary Disease	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'en-ca')" name="simplePesiHeartRate">
					Heart Rate &ge; 110	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'en-ca')" name="simplePesiBloodPressure">
					Systolic Blood Pressure &le; 100 mmHg	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'en-ca')" name="simplePesiO2">
					O<sub>2</sub> Saturation &lt; 90% on Room Air	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(true, 'en-ca')" name="simplePesiNone">
					Patient Has None of These
				</label>
			</div>
                        </template>    



		</div>

		<!-- end of calcs, and score/decription area -->
		<div class="scoreItem">
                        <p id="calc-Thrombophilia-Footer"></p>
			<div id="calcScoreLabel">Score</div>
			<div id="calcScoreWrapper">
				<span id="calcScore"></span>
				<span id="calcScoreUnits"></span>
			</div>
		</div>
		<div class="scoreDescription generalDesc"></div>
                <button v-if="isNativeToolCalc || isLegacyToolCalc" id="calcClear" @click="$emit('reset')"><Str :index="'clinical_tools.buttons.reset'" :language="language"/></button>
                <button v-else id="calcClear" @click="initToolSelection('preopAlgo')" onclick="calc.reset(); calc.clear();"><Str :index="'clinical_tools.buttons.reset'" :language="language"/></button>
                
                <v-btn v-if="debugView==='show' && debugFlag===true" @click="toggleDebug" onClick="calc.dispayPreopDebugIdentifier(true)">ToggleDebug View</v-btn>
                <v-btn v-if="debugView==='show' && debugFlag===false" @click="toggleDebug" onClick="calc.dispayPreopDebugIdentifier(false)">ToggleDebug View</v-btn>
                
		<div id="calcFooter" style="display:none;">
			<img src="https://thrombosiscanada.ca/websiteResources/tc-logo-grey.jpg" alt="thrombosis canada logo">Brought to you by Thrombosis Canada
		</div>
	</form>

    

	<!-- Sample Bridging Protocol popup -->       
        <v-dialog v-model="display_modal.sampleBridgingProtocolWrapper" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="sampleBridgingProtocolWrapper" class="myPopupMessage" style="display: block;">
                    <v-app-bar dense>
                        <v-app-bar-title class="text-capitalize">{{getLocale(preopNotations.sample_bridging_protocol)}}</v-app-bar-title>
                        <v-spacer/>
                        <v-btn x-small light text outlined @click="display_modal.sampleBridgingProtocolWrapper=false">X</v-btn>
                    </v-app-bar>                  
                <v-card tile class="px-2">
                    <table class="sampleBridgingProtocol">
                            <tr class="text-center"><th class="text-capitalize">{{getLocale(preopNotations.day)}}</th> <th>Warfarin</th> <th class="text-uppercase">{{getLocale(preopNotations.lmwh)}}</th></tr>
                            <tr class="text-center"><td>-6</td><td><v-icon color="green">mdi-check</v-icon></td><td><v-icon>mdi-close</v-icon></td></tr>
                            <tr class="text-center"><td>-5</td><td><v-icon>mdi-close</v-icon></td><td><v-icon>mdi-close</v-icon></td></tr>
                            <tr class="text-center"><td>-4</td><td><v-icon>mdi-close</v-icon></td><td><v-icon>mdi-close</v-icon></td></tr>
                            <tr class="text-center"><td>-3</td><td><v-icon>mdi-close</v-icon></td><td><v-icon color="green">mdi-check</v-icon></td></tr>
                            <tr class="text-center"><td>-2</td><td><v-icon>mdi-close</v-icon></td><td><v-icon color="green">mdi-check</v-icon></td></tr>
                            <tr class="text-center"><td>-1</td><td><v-icon>mdi-close</v-icon></td><td><v-icon color="green">mdi-check</v-icon>* </td></tr>
                            <tr class="text-center"><td class="text-capitalize">{{getLocale(preopNotations.surgery)}}</td><td><v-icon>mdi-close</v-icon></td><td><v-icon>mdi-close</v-icon></td></tr>
                            <tr class="text-center"><td>+1</td><td><v-icon color="green">mdi-check</v-icon></td><td><v-icon color="green">mdi-check</v-icon>** </td></tr>
                            <tr class="text-center"><td>+2</td><td><v-icon color="green">mdi-check</v-icon></td><td><v-icon color="green">mdi-check</v-icon>** </td></tr>
                            <tr class="text-center"><td>+3</td><td><v-icon color="green">mdi-check</v-icon></td><td><v-icon color="green">mdi-check</v-icon>*** </td></tr>
                    </table>
                    <p class="py-2">
                        {{getLocale(preopNotations.note_1)}}<br>
                        {{getLocale(preopNotations.note_2)}}<br>
                        {{getLocale(preopNotations.note_3)}}
                    </p>
                </v-card>
            </div>        
        </v-dialog>
	<!-- /Sample Bridging Protocol popup -->

        <v-dialog v-model="display_modal.PCCDosingWrapper" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="PCCDosingWrapper" class="myPopupMessage" style="display: block;">
                <template v-if="language==='fr-ca'">
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn x-small dark text outlined @click="display_modal.PCCDosingWrapper=false">X</v-btn>
                    </v-app-bar>    
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <h2 class="text-decoration-underline"><strong>Posologie et administration du CCP</strong></h2>

                        <table border="1" cellpadding="0" cellspacing="0" style="width:100%">
                            <tbody>
                                <tr>
                                    <td style="height:39px; width:148px">
                                    <p style="text-align: center;">&nbsp;</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;"><strong>RIN 1.6-1.9</strong></p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;"><strong>RIN 2.0-2.9</strong></p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;"><strong>RIN 3.0-5.0</strong></p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;"><strong>RIN &gt; 5.0</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height:39px; width:148px">
                                    <p style="text-align: center;"><strong>Poids &lt; 100 kg</strong></p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">500 unités</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">1000 unités</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">2000 unités</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">3000 unités (maximum)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height:39px; width:148px">
                                    <p style="text-align: center;"><strong>Poids &ge; 100 kg</strong></p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">1000 unités</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">1500 unités</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">2500 unités</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">3000 unités (maximum)</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <p><strong>Dosage de la vitamine K</strong><br />
                        RIN &gt; 1,5 &#8594; 10 mg dans 50 mL de solution saline normale IV immédiatement</p>

                        <p>Si l'RIN n'est pas signalé ou si le poids est inconnu et qu'il est impossible de retarder l'administration de CCP, administrer 2000 unités de CCP IV et 10 mg de vitamine K IV immédiatement</p>

                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>
                <template v-else>
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.PCCDosingWrapper=false">Close</v-btn>
                    </v-app-bar>
                    
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <h2 class="text-decoration-underline"><strong>PCC dosing (based on weight and INR)</strong></h2>

                        <table border="1" cellpadding="0" cellspacing="0" style="width:100%">
                            <tbody>
                                <tr>
                                    <td style="height:39px; width:148px">
                                    <p style="text-align: center;">&nbsp;</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;"><strong>INR 1.6-1.9</strong></p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;"><strong>INR 2.0-2.9</strong></p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;"><strong>INR 3.0-5.0</strong></p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;"><strong>INR &gt; 5.0</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height:39px; width:148px">
                                    <p style="text-align: center;"><strong>Wt &lt; 100 kg</strong></p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">500 units</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">1000 units</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">2000 units</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">3000 units (maximum)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height:39px; width:148px">
                                    <p style="text-align: center;"><strong>Wt &ge; 100 kg</strong></p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">1000 units</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">1500 units</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">2500 units</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">3000 units (maximum)</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <p><strong>Vitamin K Dosing</strong><br />
                        INR &gt; 1.5 &#8594; 10 mg in 50 mL normal saline IV STAT</p>

                        <p>If INR not reported or weight unknown and cannot delay PCC administration give PCC 2000 units IV and vitamin K 10 mg IV STAT</p>

                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>
            </div>
        </v-dialog>

	<div id="ApixabanTable" class="myPopupMessage" style="display: none;">
		<a class="buttonLink" href='#' onclick="javascript:jQuery('#ApixabanTable').toggle(); return false;">Close</a>
		<br><br>
		<table border="1" cellpadding="0" cellspacing="0" style="width:100%">
			<thead>
				<tr>
					<th colspan="4" scope="col">
					<p>Apixaban (Eliquis<sup>&reg;</sup>)**</p>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
					<p style="text-align:center"><strong>Test</strong></p>
					</td>
					<td>
					<p style="text-align:center"><strong>Characteristics</strong></p>
					</td>
					<td>
					<p style="text-align:center"><strong>Normal</strong></p>
					</td>
					<td>
					<p style="text-align:center"><strong>Abnormal</strong></p>
					</td>
				</tr>
				<tr>
					<td>
					<p style="text-align:center">Calibrated anti-Xa</p>
					</td>
					<td>
					<p style="text-align:center">Accurate, reliable</p>
					</td>
					<td>
					<p style="text-align:center">&lt;30-50 ng/mL</p>

					<p style="text-align:center">Likely no significant anticoagulant effect.*</p>
					</td>
					<td>
					<p style="text-align:center">&ge;30-50 ng/mL</p>

					<p style="text-align:center">Likely significant anticoagulant effect*</p>
					</td>
				</tr>
				<tr>
					<td>
					<p style="text-align:center">PT/INR</p>
					</td>
					<td>
					<p style="text-align:center">Poor sensitivity</p>
					</td>
					<td>
					<p style="text-align:center">Does not exclude anticoagulant effect.</p>
					</td>
					<td>
					<p style="text-align:center">May indicate anticoagulant effect.</p>

					<p style="text-align:center">Rule out other causes of abnormal PT/INR (e.g. DIC, coagulopathy of liver disease, vitamin K deficiency, warfarin).</p>
					</td>
				</tr>
				<tr>
					<td>
					<p style="text-align:center">aPTT</p>
					</td>
					<td>
					<p style="text-align:center">Poor sensitivity</p>
					</td>
					<td>
					<p style="text-align:center">Does not exclude anticoagulant effect.</p>
					</td>
					<td>
					<p style="text-align:center">May indicate anticoagulant effect.</p>

					<p style="text-align:center">Rule out other causes of abnormal aPTT (e.g. coagulopathy of liver disease, direct thrombin inhibitor, coagulation factor deficiency)</p>
					</td>
				</tr>
			</tbody>
		</table>

		<p>Anti-Xa, anti-factor Xa activity assay; aPTT, activated partial thromboplastin time; INR, international normalized ratio; PT, prothrombin time.</p>

		<p>*There are no data to establish a hemostatic threshold below which drug levels are unlikely to affect hemostasis. The level chosen (&lt;30-50 ng/mL) is extrapolated from rivaroxaban data and is in agreement with recent guidelines [Pernod 2013].</p>

		<p>**Best practices for the use and interpretation of coagulation tests in patient on DOACs is evolving and will be informed by ongoing research; the guidance herein reflects a summation of the available evidence.</p>


		<p style="text-align:right">&nbsp;</p>

	</div>
       
        <v-dialog v-model="display_modal.ApixabanTable" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="ApixabanTable" class="myPopupMessage" style="display: block;">
                <template v-if="language==='fr-ca'">
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn x-small dark text outlined @click="display_modal.ApixabanTable = false">X</v-btn>
                    </v-app-bar>                   
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">        
                        <table border="1" cellpadding="0" cellspacing="0" style="width:100%">
                                <thead>
                                    <tr>
                                        <th colspan="4" scope="col">
                                            <div style="display: inline-flex;">Apixaban (Eliquis<div style="font-size:11px;">MD</div>)**</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p style="text-align: center;"><strong>Test</strong></p></td>
                                        <td><p style="text-align: center;"><strong>Caractéristiques</strong></p></td>
                                        <td><p style="text-align: center;"><strong>Normal</strong></p></td>
                                        <td><p style="text-align: center;"><strong>Anormal</strong></p></td>
                                    </tr>
                                <tr>
                                    <td><p style="text-align:center">Dosage étalonné de l’activité anti-facteur Xa</p></td>
                                    <td><p style="text-align:center">Précis, fiable</p></td>
                                    <td>
                                        <p style="text-align:center">&lt;30-50 ng/mL</p>
                                        <p style="text-align:center">Probablement aucun effet anticoagulant significatif.*</p>
                                    </td>
                                    <td>
                                        <p style="text-align:center">&ge;30-50 ng/mL</p>
                                        <p style="text-align:center">Effet anticoagulant significatif probable*</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td><p style="text-align:center">Temps de prothrombine/RIN</p></td>
                                    <td><p style="text-align:center">Faible sensibilité</p></td>
                                    <td><p style="text-align:center">Ne permet pas d’écarter un effet anticoagulant.</p></td>
                                    <td>
                                        <p style="text-align:center">Peut indiquer un effet anticoagulant.</p>
                                        <p style="text-align:center">Permet d’écarter d’autres causes d’une valeur anormale du temps de prothrombine/RIN (p. ex., CID, coagulopathie liée à une maladie hépatique, déficit en vitamine K, warfarine).</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td><p style="text-align:center">TCA</p></td>
                                    <td><p style="text-align:center">Faible sensibilité</p></td>
                                    <td><p style="text-align:center">Ne permet pas d’écarter un effet anticoagulant.</p></td>
                                    <td>
                                        <p style="text-align:center">Peut indiquer un effet anticoagulant.</p>
                                        <p style="text-align:center">Permet d’écarter d’autres causes d’une valeur anormale du TCA (p. ex., coagulopathie liée à une maladie hépatique, inhibiteur direct de la thrombine, déficit d’un facteur de la coagulation).</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <p>Anti-Xa : dosage de l’activité anti-facteur Xa; RIN : rapport international normalisé; TCA : temps de céphaline activée; TP : temps de prothrombine.</p>
                        <p>* Aucune donnée ne permet d’établir une valeur seuil hémostatique en dessous de laquelle les concentrations de médicament sont peu susceptibles d’affecter l’hémostase. Le seuil choisi (&lt;30-50 ng/mL), extrapolé à partir des données sur le rivaroxaban, concorde avec les lignes directrices récentes [Pernod 2013]</p>
                        <p>** Les pratiques exemplaires sur l’utilisation et l’interprétation des tests de coagulation chez les patients sous AOD évoluent constamment et seront adaptées aux résultats des recherches en cours. Les directives présentées ici reflètent une synthèse des données probantes actuelles.</p>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>
                <template v-else>
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.ApixabanTable = false">Close</v-btn>
                    </v-app-bar>          
                    
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">        
                        <table border="1" cellpadding="0" cellspacing="0" style="width:100%">
                            <thead>
                                    <tr>
                                        <th colspan="4" scope="col"><div style="display: inline-flex;">Apixaban (Eliquis<div style="font-size:11px;">&reg;</div>)**</div></th>
                                    </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p style="text-align:center"><strong>Test</strong></p></td>
                                    <td><p style="text-align:center"><strong>Characteristics</strong></p></td>
                                    <td><p style="text-align:center"><strong>Normal</strong></p></td>
                                    <td><p style="text-align:center"><strong>Abnormal</strong></p></td>
                                </tr>
                                <tr>
                                    <td><p style="text-align:center">Calibrated anti-Xa</p></td>
                                    <td><p style="text-align:center">Accurate, reliable</p></td>
                                    <td>
                                        <p style="text-align:center">&lt;30-50 ng/mL</p>
                                        <p style="text-align:center">Likely no significant anticoagulant effect.*</p>
                                    </td>
                                    <td>
                                        <p style="text-align:center">&ge;30-50 ng/mL</p>
                                        <p style="text-align:center">Likely significant anticoagulant effect*</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td><p style="text-align:center">PT/INR</p></td>
                                    <td><p style="text-align:center">Poor sensitivity</p></td>
                                    <td><p style="text-align:center">Does not exclude anticoagulant effect.</p></td>
                                    <td>
                                        <p style="text-align:center">May indicate anticoagulant effect.</p>
                                        <p style="text-align:center">Rule out other causes of abnormal PT/INR (e.g. DIC, coagulopathy of liver disease, vitamin K deficiency, warfarin).</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td><p style="text-align:center">aPTT</p></td>
                                    <td><p style="text-align:center">Poor sensitivity</p></td>
                                    <td><p style="text-align:center">Does not exclude anticoagulant effect.</p></td>
                                    <td>
                                        <p style="text-align:center">May indicate anticoagulant effect.</p>
                                        <p style="text-align:center">Rule out other causes of abnormal aPTT (e.g. coagulopathy of liver disease, direct thrombin inhibitor, coagulation factor deficiency)</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <p>Anti-Xa, anti-factor Xa activity assay; aPTT, activated partial thromboplastin time; INR, international normalized ratio; PT, prothrombin time.</p>
                        <p>*There are no data to establish a hemostatic threshold below which drug levels are unlikely to affect hemostasis. The level chosen (&lt;30-50 ng/mL) is extrapolated from rivaroxaban data and is in agreement with recent guidelines [Pernod 2013].</p>
                        <p>**Best practices for the use and interpretation of coagulation tests in patient on DOACs is evolving and will be informed by ongoing research; the guidance herein reflects a summation of the available evidence.</p>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>
            </div>
        </v-dialog>

        <v-dialog v-model="display_modal.DabigatranTable" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="DabigatranTable" class="myPopupMessage" style="display: block;">
                <template v-if="language==='fr-ca'">
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn x-small dark text outlined @click="display_modal.DabigatranTable = false">X</v-btn>
                    </v-app-bar>          
                    
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <table align="center" border="1" cellpadding="0" cellspacing="0" style="width:100%">
                                <thead>
                                    <tr>
                                        <th colspan="4" scope="col">
                                            <div style="display: inline-flex;">Dabigatran (Pradaxa<div style="font-size:11px;">MD</div>)**</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p style="text-align: center;"><strong>Test</strong></p></td>
                                        <td><p style="text-align: center;"><strong>Caractéristiques</strong></p></td>
                                        <td><p style="text-align: center;"><strong>Normal</strong></p></td>
                                        <td><p style="text-align: center;"><strong>Anormal</strong></p></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style="text-align: center;">Test du temps de thrombine diluée (Hemoclot<span style="font-size:11px;vertical-align: top;">&reg;</span>)</p>
                                            <p style="text-align: center;">DCE</p>
                                            <p style="text-align: center;">TCE</p>
                                        </td>
                                        <td><p style="text-align: center;">Précis, fiable</p></td>
                                        <td>
                                            <p style="text-align: center;">&lt;30-50 ng/mL</p>
                                            <p style="text-align: center;">Probablement aucun effet anticoagulant significatif.*</p>
                                        </td>
                                        <td>
                                            <p style="text-align: center;">&ge;30-50 ng/mL</p>
                                            <p style="text-align: center;">Effet anticoagulant significatif probable.*</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><p style="text-align: center;">TT</p></td>
                                        <td><p style="text-align: center;">Très sensible</p></td>
                                        <td><p style="text-align: center;">Probablement aucun effet anticoagulant significatif.</p></td>
                                        <td><p style="text-align: center;">Effet anticoagulant présent</p></td>
                                    </tr>
                                    <tr>
                                        <td><p style="text-align: center;">TCA</p></td>
                                        <td><p style="text-align: center;">Sensibilité modérée, variabilité élevée</p></td>
                                        <td><p style="text-align: center;">Ne permet pas toujours d’écarter un effet anticoagulant significatif.</p></td>
                                        <td><p style="text-align: center;">Effet anticoagulant présent</p></td>
                                    </tr>
                                    <tr>
                                        <td><p style="text-align: center;">Temps de prothrombine/RIN</p></td>
                                        <td><p style="text-align: center;">Faible sensibilité, variabilité élevée</p></td>
                                        <td><p style="text-align: center;">Ne permet pas d’écarter un effet anticoagulant.</p></td>
                                        <td>
                                            <p style="text-align: center;">Peut indiquer un effet anticoagulant.</p>
                                            <p style="text-align: center;">Permet d’écarter d’autres causes d’une valeur anormale du temps de prothrombine/RIN (p. ex., CID, coagulopathie liée à une maladie hépatique, déficit en vitamine K, warfarine).</p>
                                        </td>
                                    </tr>
                                </tbody>
                        </table>

                        <p>DCE : dosage chromogénique à base d’écarine; TCA : temps de céphaline activée; TCE : temps de coagulation à l’écarine; TT : temps de thrombine.</p>

                        <p>* Aucune donnée ne permet d’établir une valeur seuil hémostatique en dessous de laquelle les concentrations de médicament sont peu susceptibles d’affecter l’hémostase. Le seuil choisi (< 30-50 ng/mL) est une estimation prudente basée sur les taux estimés de dabigatran chez les patients qui devaient subir des interventions chirurgicales lors de l’étude RE-LY; ces interventions n’étaient pas associées à un risque accru de saignement périopératoire en cas d’utilisation du dabigatran par rapport à la warfarine; ce seuil est conforme aux lignes directrices récentes (Healy 2012, Pernod 2013).  Notons que la limite inférieure de détection du test Hemoclot<sup>&reg;</sup> est de 50 ng/mL.&nbsp;</p>

                        <p>** Les pratiques exemplaires sur l’utilisation et l’interprétation des tests de coagulation chez les patients sous AOD évoluent constamment et seront adaptées aux résultats des recherches en cours. Les directives présentées ici reflètent une synthèse des données probantes actuelles.</p>

                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>
                <template v-else>
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.DabigatranTable = false">Close</v-btn>
                    </v-app-bar>          
                    
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <table align="center" border="1" cellpadding="0" cellspacing="0" style="width:100%">
                                <thead>
                                    <tr>
                                        <th colspan="4" scope="col">
                                            <div style="display: inline-flex;">Dabigatran (Pradaxa<div style="font-size:11px;">&reg;</div>)**</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p style="text-align: center;"><strong>Test</strong></p></td>
                                        <td><p style="text-align: center;"><strong>Characteristics</strong></p></td>
                                        <td><p style="text-align: center;"><strong>Normal</strong></p></td>
                                        <td><p style="text-align: center;"><strong>Abnormal</strong></p></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style="text-align: center;">Dilute TCT (Hemoclot<span style="font-size:11px;vertical-align: top;">&reg;</span>)</p>
                                            <p style="text-align: center;">ECA</p>
                                            <p style="text-align: center;">ECT</p>
                                        </td>
                                        <td><p style="text-align: center;">Accurate, reliable</p></td>
                                        <td>
                                            <p style="text-align: center;">&lt;30-50 ng/mL</p>
                                            <p style="text-align: center;">Likely no significant anticoagulant effect.*</p>
                                        </td>
                                        <td>
                                            <p style="text-align: center;">&ge;30-50 ng/mL</p>
                                            <p style="text-align: center;">Likely significant anticoagulant effect.*</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><p style="text-align: center;">TCT</p></td>
                                        <td><p style="text-align: center;">Very sensitive</p></td>
                                        <td><p style="text-align: center;">Likely no significant anticoagulant effect.</p></td>
                                        <td><p style="text-align: center;">Anticoagulant effect present</p></td>
                                    </tr>
                                    <tr>
                                        <td><p style="text-align: center;">aPTT</p></td>
                                        <td><p style="text-align: center;">Moderate sensitivity, high variability</p></td>
                                        <td><p style="text-align: center;">May not exclude significant anticoagulant effect.</p></td>
                                        <td><p style="text-align: center;">Anticoagulant effect present</p></td>
                                    </tr>
                                    <tr>
                                        <td><p style="text-align: center;">PT/INR</p></td>
                                        <td><p style="text-align: center;">Poor sensitivity, high variability</p></td>
                                        <td><p style="text-align: center;">Does not exclude anticoagulant effect.</p></td>
                                        <td>
                                            <p style="text-align: center;">May indicate anticoagulant effect.</p>
                                            <p style="text-align: center;">Rule out other causes of abnormal PT/INR (e.g. DIC, coagulopathy of liver disease, vitamin K deficiency, warfarin).</p>
                                        </td>
                                    </tr>
                                </tbody>
                        </table>

                        <p>aPTT, activated partial thromboplastin time; ECA, ecarin chromogenic assay; ECT, ecarin clotting time; TCT, thrombin clotting time.</p>

                        <p>*There are no data to establish a hemostatic threshold below which drug levels are unlikely to affect hemostasis. The level chosen (&lt;30-50 ng/mL) is a conservative estimate based on the estimated dabigatran levels in patients undergoing surgical procedures in the RE-LY trial in which there was no increased risk of perioperative bleeding with dabigatran compared to warfarin, and is in agreement with recent guidelines (Healy 2012, Pernod 2013).&nbsp; Note that the lower limit of detection of the Hemoclot<sup>&reg;</sup> assay is 50 ng/mL.&nbsp;</p>

                        <p>**Best practices for the use and interpretation of coagulation tests in patient on DOACs is evolving and will be informed by ongoing research; the guidance herein reflects a summation of the available evidence.</p>


                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>
            </div>
        </v-dialog>    
        
        <v-dialog v-model="display_modal.RivaroxabanTable" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="RivaroxabanTable" class="myPopupMessage" style="display: block;">
                <template v-if="language==='fr-ca'">
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn x-small dark text outlined @click="display_modal.RivaroxabanTable = false">X</v-btn>
                    </v-app-bar>                   
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">        
                        <table border="1" cellpadding="0" cellspacing="0" style="width:100%">
                            <thead>
                                <tr>
                                    <th colspan="4" scope="col"><div style="display: inline-flex;">Rivaroxaban (Xarelto<div style="font-size:11px;">&reg;</div>)**</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                        <td><p style="text-align: center;"><strong>Test</strong></p></td>
                                        <td><p style="text-align: center;"><strong>Caractéristiques</strong></p></td>
                                        <td><p style="text-align: center;"><strong>Normal</strong></p></td>
                                        <td><p style="text-align: center;"><strong>Anormal</strong></p></td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">Dosage étalonné de l’activité anti-facteur Xa</p></td>
                                    <td><p style="text-align: center;">Précis, fiable</p></td>
                                    <td>
                                        <p style="text-align: center;">&lt;30-50 ng/mL</p>
                                        <p style="text-align: center;">Probablement aucun effet anticoagulant significatif*.</p>
                                    </td>
                                    <td>
                                        <p style="text-align: center;">&ge;30-50 ng/mL</p>
                                        <p style="text-align: center;">Effet anticoagulant significatif probable*.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">Temps de prothrombine/RIN</p></td>
                                    <td><p style="text-align: center;">Sensibilité modérée, variabilité élevée</p></td>
                                    <td><p style="text-align: center;">Ne permet pas d’écarter un effet anticoagulant.</p></td>
                                    <td><p style="text-align: center;">Effet anticoagulant présent.</p></td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">TCA</p></td>
                                    <td><p style="text-align: center;">Faible sensibilité, variabilité élevée</p></td>
                                    <td><p style="text-align: center;">Ne permet pas d’écarter un effet anticoagulant.</p></td>
                                    <td>
                                        <p style="text-align: center;">Peut indiquer un effet anticoagulant.</p>
                                        <p style="text-align: center;">Permet d’écarter d’autres causes d’une valeur anormale du TCA (p. ex., coagulopathie liée à une maladie hépatique, inhibiteur direct de la thrombine, déficit d’un facteur de la coagulation).</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <p>Anti-Xa : dosage de l’activité anti-facteur Xa; RIN : rapport international normalisé; TCA : temps de céphaline activée; TP : temps de prothrombine.</p>
                        <p>* Aucune donnée ne permet d’établir une valeur seuil hémostatique en dessous de laquelle les concentrations de médicament sont peu susceptibles d’affecter l’hémostase. Le seuil choisi (&lt; 30-50 ng/mL) est basé sur les taux estimés du médicament chez les patients qui devaient subir des interventions chirurgicales lors de l’étude ROCKET-AF; ces interventions n’étaient pas associées à un risque accru de saignement périopératoire par rapport à la warfarine; ce seuil est conforme aux lignes directrices récentes [Patel 2011, Pernod 2013].</p>
                        <p>** Les pratiques exemplaires sur l’utilisation et l’interprétation des tests de coagulation chez les patients sous AOD évoluent constamment et seront adaptées aux résultats des recherches en cours. Les directives présentées ici reflètent une synthèse des données probantes actuelles.</p>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>
                <template v-else>
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.RivaroxabanTable = false">Close</v-btn>
                    </v-app-bar>          
                    
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">        
                        <table border="1" cellpadding="0" cellspacing="0" style="width:100%">
                            <thead>
                                <tr>
                                    <th colspan="4" scope="col"><div style="display: inline-flex;">Rivaroxaban (Xarelto<div style="font-size:11px;">&reg;</div>)**</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p style="text-align: center;"><strong>Test</strong></p></td>
                                    <td><p style="text-align: center;"><strong>Characteristics</strong></p></td>
                                    <td><p style="text-align: center;"><strong>Normal</strong></p></td>
                                    <td><p style="text-align: center;"><strong>Abnormal</strong></p></td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">Calibrated anti-Xa</p></td>
                                    <td><p style="text-align: center;">Accurate, reliable</p></td>
                                    <td>
                                        <p style="text-align: center;">&lt;30-50 ng/mL</p>
                                        <p style="text-align: center;">Likely no significant anticoagulant effect.*</p>
                                    </td>
                                    <td>
                                        <p style="text-align: center;">&ge;30-50 ng/mL</p>
                                        <p style="text-align: center;">Likely significant anticoagulant effect.*</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">PT/INR</p></td>
                                    <td><p style="text-align: center;">Moderate sensitivity, high variability</p></td>
                                    <td><p style="text-align: center;">Does not exclude anticoagulant effect.</p></td>
                                    <td><p style="text-align: center;">Anticoagulant effect present.</p></td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">aPTT</p></td>
                                    <td><p style="text-align: center;">Poor sensitivity, high variability</p></td>
                                    <td><p style="text-align: center;">Does not exclude anticoagulant effect.</p></td>
                                    <td>
                                        <p style="text-align: center;">May indicate anticoagulant effect.</p>
                                        <p style="text-align: center;">Rule out other causes of abnormal aPTT (e.g. coagulopathy of liver disease, direct thrombin inhibitor, coagulation factor deficiency).</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <p>Anti-Xa, anti-factor Xa activity assay; aPTT, activated partial thromboplastin time; INR, international normalized ratio; PT, prothrombin time.</p>
                        <p>* There are no data to establish a hemostatic threshold below which drug levels are unlikely to affect hemostasis. The level chosen (&lt; 30-50 ng/mL) is based on the estimated drug levels in patients undergoing surgical procedures in the ROCKET-AF trial in which there was no increased risk of perioperative bleeding compared to warfarin, and is in agreement with recent guidelines [Patel 2011, Pernod 2013].</p>
                        <p>**Best practices for the use and interpretation of coagulation tests in patient on DOACs is evolving and will be informed by ongoing research; the guidance herein reflects a summation of the available evidence.</p>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>
            </div>
        </v-dialog>

        <v-dialog v-model="display_modal.EdoxabanTable" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="EdoxabanTable" class="myPopupMessage" style="display: block;">
                <template v-if="language==='fr-ca'">
                            <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn x-small dark text outlined @click="display_modal.EdoxabanTable = false">X</v-btn>
                    </v-app-bar>
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">         
                        <table border="1" cellpadding="0" cellspacing="0" style="width:100%">
                            <thead>
                                    <tr>
                                        <th colspan="4" scope="col"><div style="display: inline-flex;">Edoxaban (Lixiana<div style="font-size:11px;">&reg;</div>)*</div></th>
                                    </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p style="text-align: center;"><strong>Test</strong></p></td>
                                    <td><p style="text-align: center;"><strong>Caractéristiques</strong></p></td>
                                    <td><p style="text-align: center;"><strong>Normal</strong></p></td>
                                    <td><p style="text-align: center;"><strong>Anormal</strong></p></td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">Dosage étalonné de l’activité anti-facteur Xa</p></td>
                                    <td><p style="text-align: center;">Précis, fiable</p></td>
                                    <td>
                                        <p style="text-align: center;">&lt;30-50 ng/mL</p>
                                        <p style="text-align: center;">Probablement aucun effet anticoagulant significatif.</p>
                                    </td>
                                    <td>
                                        <p style="text-align: center;">&ge;30-50 ng/mL</p>
                                        <p style="text-align: center;">Effet anticoagulant significatif probable.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">Temps de prothrombine/RIN</p></td>
                                    <td><p style="text-align: center;">Sensibilité modérée, variabilité élevée</p></td>
                                    <td><p style="text-align: center;">Ne permet pas d’écarter un effet anticoagulant.</p></td>
                                    <td><p style="text-align: center;">Effet anticoagulant présent.</p></td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">TCA</p></td>
                                    <td><p style="text-align: center;">Faible sensibilité, variabilité élevée</p></td>
                                    <td><p style="text-align: center;">Ne permet pas d’écarter un effet anticoagulant.</p></td>
                                    <td>
                                        <p style="text-align: center;">Peut indiquer un effet anticoagulant.</p>
                                        <p style="text-align: center;">Permet d’écarter d’autres causes d’une valeur anormale du TCA (p. ex., coagulopathie liée à une maladie hépatique, inhibiteur direct de la thrombine, déficit d’un facteur de la coagulation).</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <p>Anti-Xa : dosage de l’activité anti-facteur Xa; RIN : rapport international normalisé; TCA : temps de céphaline activée; TP : temps de prothrombine.</p>
                        <p>* Les pratiques exemplaires sur l’utilisation et l’interprétation des tests de coagulation chez les patients sous AOD évoluent constamment et seront adaptées aux résultats des recherches en cours. Les directives présentées ici reflètent une synthèse des données probantes actuelles.</p>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>
                <template v-else>
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.EdoxabanTable = false">Close</v-btn>
                    </v-app-bar>          
                    
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">         
                        <table border="1" cellpadding="0" cellspacing="0" style="width:100%">
                            <thead>
                                    <tr>
                                        <th colspan="4" scope="col"><div style="display: inline-flex;">Edoxaban (Lixiana<div style="font-size:11px;">&reg;</div>)*</div></th>
                                    </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p style="text-align: center;"><strong>Test</strong></p></td>
                                    <td><p style="text-align: center;"><strong>Characteristics</strong></p></td>
                                    <td><p style="text-align: center;"><strong>Normal</strong></p></td>
                                    <td><p style="text-align: center;"><strong>Abnormal</strong></p></td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">Calibrated anti-Xa</p></td>
                                    <td><p style="text-align: center;">Accurate, reliable</p></td>
                                    <td>
                                        <p style="text-align: center;">&lt;30-50 ng/mL</p>
                                        <p style="text-align: center;">Likely no significant anticoagulant effect.</p>
                                    </td>
                                    <td>
                                        <p style="text-align: center;">&ge;30-50 ng/mL</p>
                                        <p style="text-align: center;">Likely significant anticoagulant effect.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">PT/INR</p></td>
                                    <td><p style="text-align: center;">Moderate sensitivity, high variability</p></td>
                                    <td><p style="text-align: center;">Does not exclude anticoagulant effect.</p></td>
                                    <td><p style="text-align: center;">Anticoagulant effect present.</p></td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">aPTT</p></td>
                                    <td><p style="text-align: center;">Poor sensitivity, high variability</p></td>
                                    <td><p style="text-align: center;">Does not exclude anticoagulant effect.</p></td>
                                    <td>
                                        <p style="text-align: center;">May indicate anticoagulant effect.</p>
                                        <p style="text-align: center;">Rule out other causes of abnormal aPTT (e.g. coagulopathy of liver disease, direct thrombin inhibitor, coagulation factor deficiency).</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <p>Anti-Xa, anti-factor Xa activity assay; aPTT, activated partial thromboplastin time; INR, international normalized ratio; PT, prothrombin time.</p>
                        <p>*Best practices for the use and interpretation of coagulation tests in patient on DOACs is evolving and will be informed by ongoing research; the guidance herein reflects a summation of the available evidence.</p>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>                    
            </div>
        </v-dialog>
        
        <v-dialog v-model="display_modal.HalfLifeDTable" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="HalfLifeDTable" class="myPopupMessage" style="display: block;">
                <template v-if="language==='fr-ca'">
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>                
                        <v-spacer/>
                        <v-btn x-small dark text outlined @click="display_modal.HalfLifeDTable = false">X</v-btn>
                    </v-app-bar>    

                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <h4 class="text-decoration-underline">Effet de l’insuffisance rénale sur la demi-vie du dabigatran</h4>
                        <table border="1" style="width:100%;">
                            <tr class="" style="background-color: lightblue;">
                                <td colspan="2"><div style="font-weight: bold;font-size:16px;display: inline-flex;">Dabigatran (Pradaxa<div style="font-size:11px;">MD</div>)</div></td>                                
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Fonctionnement rénal</td>
                                <td style="font-weight:bold">Demi-vie*</td>
                            </tr>
                            <tr class="CrCl_normal">
                                <td>Normal à insuffisance rénale légère<br>(ClCr &ge; 50 mL/min)</td>
                                <td>7 – 17 h</td>
                            </tr>
                            <tr class="CrCl_moderate">
                                <td>Insuffisance rénale modérée<br>(ClCr 30 de 49 mL/min)</td>
                                <td>17 - 20 h</td>
                            </tr>
                            <tr class="CrCl_severe">
                                <td>Insuffisance rénale sévère<br>(ClCr &lt; 30 mL/min)</td>
                                <td>21 - 35 h</td>
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Schémas posologiques</td>
                                <td>150 mg 2 f.p.j., 110 mg 2 f.p.j., 220 mg par jour, 150 mg par jour, 110 mg par jour, 75 mg par jour</td>
                            </tr>
                        </table>
                        ClCr : clairance de la créatinine
                        <p>* Les demi-vies rapportées sont des estimations qui peuvent varier selon la posologie du médicament, le schéma d’administration et les affections concomitantes du patient.</p>

                        <p>
                            Pour estimer la concentration de médicament dans la circulation, ne pas oublier de tenir compte des éléments suivants :
                            <ul>
                                <li>Schéma posologique</li>
                                <li>Moment d’administration de la dernière dose</li>
                            </ul>
                        </p>
                        <span class="references">
                            <strong>Références</strong>
                            <ol>
                                <li>Stangier et al. Influence of renal impairment on the pharmacokinetics and pharmacodynamics of oral dabigatran etexilate: an open-label, parallel-group, single-centre study. Clin Pharmacokinet. 2010 Apr;49(4):259-68.</li>
                                <li>Stangier, J. et al. The pharmacokinetics, pharmacodynamics and tolerability of dabigatran etexilate, a new oral direct thrombin inhibitor, in healthy male subjects. Br J Clin Pharmacol. 2007; 64:292-303.</li>
                                <li>Blech, S. et al. The metabolism and disposition of the oral direct thrombin inhibitor, dabigatran, in humans. Drug Metab. Dispos. 2008; 36:386-399.</li>
                                <li>Boehringer Ingelheim (Canada) Ltée, monographie de Pradaxa. <a target="_blank" href="https://www.boehringer-ingelheim.com/ca/fr/bipdf/pradaxapmfr">Lien</a>. Consulté le 12 octobre 2015</li>
                            </ol>
                        </span>

                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>
                <template v-else>
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>                
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.HalfLifeDTable = false">Close</v-btn>
                    </v-app-bar>    

                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <h4 class="text-decoration-underline">Effect of renal failure on half-life of dabigatran</h4>
                        <table border="1" style="width:100%;">
                            <tr class="" style="background-color: lightblue;">
                                <td colspan="2"><div style="font-weight: bold;font-size:16px;display: inline-flex;">Dabigatran (Pradaxa<div style="font-size:11px;">&reg;</div>)</div></td>                                
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Renal Function </td>
                                <td style="font-weight:bold">Half-life*</td>
                            </tr>
                            <tr class="CrCl_normal">
                                <td>Normal to Mild Impairment <br>(CrCl &ge; 50 mL/min)</td>
                                <td>7 – 17 hrs</td>
                            </tr>
                            <tr class="CrCl_moderate">
                                <td>Moderate Impairment <br>(CrCl 30 to 49 mL/min)</td>
                                <td>17 - 20 hrs</td>
                            </tr>
                            <tr class="CrCl_severe">
                                <td>Severe Impairment <br>(CrCl &lt; 30 mL/min)</td>
                                <td>21 - 35 hrs</td>
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Dosing regimens</td>
                                <td>150 mg bid, 110 mg bid, 220 mg daily, 150 mg daily, 110 mg daily, 75 mg daily</td>
                            </tr>
                        </table>
                        CrCl = creatinine clearance
                        <p>*Reported half-lives are estimates and may vary in individual patients depending on drug dosage, administration schedule and comorbid conditions.</p>

                        <p>
                            When estimating amount of drug in circulation remember to consider:
                            <ul>
                                <li>Dosing regimen </li>
                                <li>Timing of last dose </li>
                            </ul>
                        </p>
                        <span class="references">
                            <strong>References</strong>
                            <ol>
                                <li>Stangier et al. Influence of renal impairment on the pharmacokinetics and pharmacodynamics of oral dabigatran etexilate: an open-label, parallel-group, single-centre study. Clin Pharmacokinet. 2010 Apr;49(4):259-68.</li>
                                <li>Stangier, J. et al. The pharmacokinetics, pharmacodynamics and tolerability of dabigatran etexilate, a new oral direct thrombin inhibitor, in healthy male subjects. Br J Clin Pharmacol. 2007; 64:292–303.</li>
                                <li>Blech, S. et al. The metabolism and disposition of the oral direct thrombin inhibitor, dabigatran, in humans. Drug Metab. Dispos. 2008; 36:386–399.</li>
                                <li>Boehringer Ingelheim Canada Ltd. Pradaxa Product Monograph. <a target="_blank" href="https://www.boehringer-ingelheim.com/ca/bipdf/pradaxa-product-monograph">Link</a>. Accessed March 23, 2020</li>
                            </ol>
                        </span>

                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>
            </div>
        </v-dialog>    

        <v-dialog v-model="display_modal.HalfLifeATable" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="HalfLifeATable" class="myPopupMessage" style="display: block;">
                <template v-if="language==='fr-ca'">
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn x-small dark text outlined @click="display_modal.HalfLifeATable = false">X</v-btn>
                    </v-app-bar>                
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <h4 class="text-decoration-underline">Effet de l’insuffisance rénale sur la demi-vie de l’apixaban</h4>
                        <table border="1" style="width:100%;">
                            <tr class="" style="background-color: lightgreen;">
                                <td colspan="2"><div style="font-weight: bold;font-size:16px;display: inline-flex;">Apixaban (Eliquis<div style="font-size:11px;">MD</div>)</div></td>
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Fonctionnement rénal</td>
                                <td style="font-weight:bold">Demi-vie*</td>
                            </tr>
                            <tr class="CrCl_normal">
                                <td>Normal à insuffisance rénale légère<br>(ClCr &ge; 50 mL/min)</td>
                                <td>8 – 12 h</td>
                            </tr>
                            <tr class="CrCl_moderate">
                                <td>Insuffisance rénale modérée<br>(ClCr 30 to 49 mL/min)</td>
                                <td>8 - 12 h</td>
                            </tr>
                            <tr class="CrCl_severe">
                                <td>Insuffisance rénale sévère<br>(ClCr &lt; 30 mL/min)</td>
                                <td>12 - 17 h</td>
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Schémas posologiques</td>
                                <td>10 mg 2 f.p.j., 5 mg 2 f.p.j., 2,5 mg 2 f.p.j.</td>
                            </tr>
                        </table>
                        ClCr : clairance de la créatinine
                        <p>* Les demi-vies rapportées sont des estimations qui peuvent varier selon la posologie du médicament, le schéma d’administration et les affections concomitantes du patient.</p>

                        Pour estimer la concentration de médicament dans la circulation, ne pas oublier de tenir compte des éléments suivants :
                        <ul>
                            <li>Schéma posologique</li>
                            <li>Moment d’administration de la dernière dose</li>
                        </ul>
                        <span class="references">
                            <strong>Références</strong>
                            <ol>
                                <li>Chang et al. Effect of renal impairment on the pharmacokinetics, pharmacodynamics and safety of apixaban. J Clin Pharmacol. 2015 Sept. 11. doi: 10.1002/jcph.633. [Publication en ligne avant l’impression]</li>
                                <li>Raghavan, N. et al. Apixaban metabolism and pharmacokinetics after oral administration to humans. Drug Metab. Dispos. 2009; 37:74-81.</li>
                                <li>Frost, C. et al. Apixaban, an oral, direct factor Xa inhibitor: single-dose safety, pharmacokinetics, pharmacodynamics and food effect in healthy subjects. Br. J. Clin. Pharmacol. 2013; 75:476-487.</li>
                                <li>Barrett, Y.C. et al. A randomised assessment of the pharmacokinetic, pharmacodynamic and safety interaction between apixaban and enoxaparin in healthy subjects. Thromb. Haemost. 2012; 107:916-924.</li>
                                <li>Pfizer. Monographie d’Eliquis (2015). <a target="_blank" href="https://webfiles.pfizer.com/file/36e12e8f-5cd1-4a60-9d7c-1028cc70200c?referrer=ccb731e5-4f2d-4f4a-b2dc-e5e912145fc6">Link</a>. Consulté le 12 octobre 2015</li>
                            </ol>
                        </span>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>
                <template v-else>
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.HalfLifeATable = false">Close</v-btn>
                    </v-app-bar>                

                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <h4 class="text-decoration-underline">Effect of renal failure on half-life of apixaban</h4>
                        <table border="1" style="width:100%;">
                            <tr class="" style="background-color: lightgreen;">
                                <td colspan="2"><div style="font-weight: bold;font-size:16px;display: inline-flex;">Apixaban (Eliquis<div style="font-size:11px;">&reg;</div>)</div></td>
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Renal Function </td>
                                <td style="font-weight:bold">Half-life*</td>
                            </tr>
                            <tr class="CrCl_normal">
                                <td>Normal to Mild Impairment <br>(CrCl &ge; 50 mL/min)</td>
                                <td>8 – 12 hrs</td>
                            </tr>
                            <tr class="CrCl_moderate">
                                <td>Moderate Impairment <br>(CrCl 30 to 49 mL/min)</td>
                                <td>8 - 12 hrs</td>
                            </tr>
                            <tr class="CrCl_severe">
                                <td>Severe Impairment <br>(CrCl &lt; 30 mL/min)</td>
                                <td>12 - 17 hrs</td>
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Dosing regimens</td>
                                <td>10 mg bid, 5 mg bid, 2.5 mg bid</td>
                            </tr>
                        </table>
                        CrCl = creatinine clearance
                        <p>*Reported half-lives are estimates and may vary in individual patients depending on drug dosage, administration schedule and comorbid conditions.</p>

                        When estimating amount of drug in circulation remember to consider:
                        <ul>
                            <li>Dosing regimen </li>
                            <li>Timing of last dose </li>
                        </ul>
                        <span class="references">
                            <strong>References</strong>
                            <ol>
                                <li>Chang et al. Effect of renal impairment on the pharmacokinetics, pharmacodynamics and safety of apixaban. J Clin Pharmacol. 2015 Sep 11. doi: 10.1002/jcph.633. [Epub ahead of print]</li>
                                <li>Raghavan, N. et al. Apixaban metabolism and pharmacokinetics after oral administration to humans. Drug Metab. Dispos. 2009; 37:74–81.</li>
                                <li>Frost, C. et al. Apixaban, an oral, direct factor Xa inhibitor: single-dose safety, pharmacokinetics, pharmacodynamics and food effect in healthy subjects. Br. J. Clin. Pharmacol. 2013; 75:476–487.</li>
                                <li>Barrett, Y.C. et al. A randomised assessment of the pharmacokinetic, pharmacodynamic and safety interaction between apixaban and enoxaparin in healthy subjects. Thromb. Haemost. 2012; 107:916–924.</li>
                                <li>Pfizer. Eliquis Product Monograph (2019). <a target="_blank" href="https://www.pfizer.ca/en/our-products/eliquis-apixaban">Link</a>. Accessed October 07, 2019</li>
                            </ol>
                        </span>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>
            </div>
        </v-dialog>

        <v-dialog v-model="display_modal.HalfLifeRTable" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="HalfLifeRTable" class="myPopupMessage" style="display: block;">
                <template v-if="language==='fr-ca'">
                <v-card width="100%">                    
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn x-small dark text outlined @click="display_modal.HalfLifeRTable = false">X</v-btn>
                    </v-app-bar>                    
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <h4 class="text-decoration-underline">Effet de l’insuffisance rénale sur la demi-vie du rivaroxaban</h4>
                        <table border="1" style="width:100%;">
                            <tr class="" style="background-color: lightgrey;">
                                <td colspan="2"><div style="font-weight: bold;font-size:16px;display: inline-flex;">Rivaroxaban (Xarelto<div style="font-size:11px;">&reg;</div>)</div></td>                                
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Fonctionnement rénal</td>
                                <td style="font-weight:bold">Demi-vie*</td>
                            </tr>
                            <tr class="CrCl_normal">
                                <td>Normal à insuffisance rénale légère<br>(ClCr &ge; 50 mL/min)</td>
                                <td>7 - 11 h</td>
                            </tr>
                            <tr class="CrCl_moderate">
                                <td>Insuffisance rénale modérée<br>(ClCr 30 to 49 mL/min)</td>
                                <td>7 - 11 h</td>
                            </tr>
                            <tr class="CrCl_severe">
                                <td>Insuffisance rénale sévère<br>(ClCr &lt; 30 mL/min)</td>
                                <td>11 - 15 h</td>
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Schémas posologiques</td>
                                <td>15 mg 2 f.p.j., 20 mg par jour, 15 mg par jour, 10 mg par jour</td>
                            </tr>
                        </table>
                        ClCr : clairance de la créatinine
                        <p>*Les demi-vies rapportées sont des estimations qui peuvent varier selon la posologie du médicament, le schéma d’administration et les affections concomitantes du patient.</p>

                        Pour estimer la concentration de médicament dans la circulation, ne pas oublier de tenir compte des éléments suivants :
                        <ul>
                            <li>Schéma posologique</li>
                            <li>Moment d’administration de la dernière dose</li>
                        </ul>

                        <span class="references">
                            <strong>Références</strong>
                            <ol>
                                <li>Kubitza D et al. Effects of renal impairment on the pharmacokinetics, pharmacodynamics and safety of rivaroxaban, an oral, direct Factor Xa inhibitor. B J Clin Pharmacol 2010; 70:703-12.</li>
                                <li>Kubitza, D. et al. Safety, pharmacodynamics, and pharmacokinetics of single doses of BAY 59-7939, an oral, direct factor Xa inhibitor. Clin Pharmacol Ther. 2005; 78,:412-421.</li>
                                <li>Kubitza, D. et al. (2005) Safety, pharmacodynamics, and pharmacokinetics of BAY 59-7939 – an oral, direct factor Xa inhibitor – after multiple dosing in healthy male subjects. Eur J Clin Pharmacol. 2005; 61:873-880.</li>
                                <li>Bayer PHarma SA. Monographie de Xarelto (2015). <a target="_blank" href="https://www.bayer.com/sites/default/files/2020-11/xarelto-pm-fr.pdf">Lien</a>. Consulté le 12 octobre 2015</li>
                            </ol>
                        </span>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>
                <template v-else>
                <v-card width="100%">                    
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.HalfLifeRTable = false">Close</v-btn>
                    </v-app-bar>                
                    
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <h4 class="text-decoration-underline">Effect of renal failure on half-life of rivaroxaban</h4>
                        <table border="1" style="width:100%;">
                            <tr class="" style="background-color: lightgrey;">
                                <td colspan="2"><div style="font-weight: bold;font-size:16px;display: inline-flex;">Rivaroxaban (Xarelto<div style="font-size:11px;">&reg;</div>)</div></td>                                
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Renal Function </td>
                                <td style="font-weight:bold">Half-life*</td>
                            </tr>
                            <tr class="CrCl_normal">
                                <td>Normal to Mild Impairment <br>(CrCl &ge; 50 mL/min)</td>
                                <td>7 - 11 hrs</td>
                            </tr>
                            <tr class="CrCl_moderate">
                                <td>Moderate Impairment <br>(CrCl 30 to 49 mL/min)</td>
                                <td>7 - 11 hrs</td>
                            </tr>
                            <tr class="CrCl_severe">
                                <td>Severe Impairment <br>(CrCl &lt; 30 mL/min)</td>
                                <td>11 - 15 hrs</td>
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Dosing regimens</td>
                                <td>15 mg bid, 20 mg daily, 15 mg daily, 10 mg daily</td>
                            </tr>
                        </table>
                        CrCl = creatinine clearance
                        <p>*Reported half-lives are estimates and may vary in individual patients depending on drug dosage, administration schedule and comorbid conditions.</p>

                        When estimating amount of drug in circulation remember to consider:
                        <ul>
                            <li>Dosing regimen </li>
                            <li>Timing of last dose </li>
                        </ul>

                        <span class="references">
                            <strong>References</strong>
                            <ol>
                                <li>Kubitza D et al. Effects of renal impairment on the pharmacokinetics, pharmacodynamics and safety of rivaroxaban, an oral, direct Factor Xa inhibitor. B J Clin Pharmacol 2010; 70:703-12.</li>
                                <li>Kubitza, D. et al. Safety, pharmacodynamics, and pharmacokinetics of single doses of BAY 59-7939, an oral, direct factor Xa inhibitor. Clin Pharmacol Ther. 2005; 78,:412–421.</li>
                                <li>Kubitza, D. et al. (2005) Safety, pharmacodynamics, and pharmacokinetics of BAY 59-7939 – an oral, direct factor Xa inhibitor – after multiple dosing in healthy male subjects. Eur J Clin Pharmacol. 2005; 61:873–880.</li>
                                <li>Bayer PHarma AG. Xarelto Product Monograph (2023). <a target="_blank" href="https://www.bayer.com/sites/default/files/2020-11/xarelto-pm-en.pdf">Link</a>. Accessed April 17, 2023</li>
                            </ol>
                        </span>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>
            </div>
        </v-dialog>
        
        <v-dialog v-model="display_modal.HalfLifeETable" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="HalfLifeETable" class="myPopupMessage" style="display: block;">
                <template v-if="language==='fr-ca'">
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn x-small dark text outlined @click="display_modal.HalfLifeETable = false">X</v-btn>
                    </v-app-bar>
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <h4 class="text-decoration-underline">Effet de l’insuffisance rénale sur la demi-vie de l’edoxaban</h4>

                        <p>La demi-vie (t&frac12;) de l’edoxaban, qui est de 10 à 14 heures, peut être plus longue chez les patients atteints d’insuffisance rénale.</p>
                        <p>Pour estimer la concentration de médicament dans la circulation, ne pas oublier de tenir compte des éléments suivants :</p>
                        <ul>
                            <li>Schéma posologique</li>
                            <li>Moment d’administration de la dernière dose</li>
                        </ul>

                        <span class="references">
                            <strong>Références</strong>
                            <ol>
                                <li>Servier Canada Inc., monographie de Lixiana (2017). <a href='https://servier.ca/wp-content/uploads/sites/24/2022/11/Monographie-de-produit-LIXIANA.pdf?time=1704916845' target='_blank'>Lien</a>.</li>
                            </ol>
                        </span>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>
                <template v-else>
                <v-card width="100%">                    
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.HalfLifeETable = false">Close</v-btn>
                    </v-app-bar>

                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <h4 class="text-decoration-underline">Effect of renal failure on half-life of edoxaban</h4>

                        <p>Half-life (t&frac12;) of edoxaban is 10-14 hours and may be prolonged in patients with renal impairment. </p>
                        <p>When estimating amount of drug in circulation remember to consider:</p>
                        <ul>
                            <li>Dosing regimen</li>
                            <li>Timing of last dose</li>
                        </ul>

                        <span class="references">
                            <strong>References</strong>
                            <ol>
                                <li>Servier Canada Inc. Lixiana Product Monograph (2021). <a href='https://servier.ca/wp-content/uploads/sites/24/2022/03/Product-monograph-Lixiana-December-1-2021.pdf?time=1660220963' target='_blank'>Link</a>.</li>
                            </ol>
                        </span>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
                </template>
            </div>
        </v-dialog>
</div>
</template>

<script>
import vueCalculators from './vueCalculators.vue'
import PreopAlgorithm from './preopAlgorithm.vue'
export default {
	props: {
		language: {
                    type: String,
                    required: true
		},
                activeCalc: {
                    type: String,
                    required: false
                },
	},
	components: {
		vueCalculators,
                PreopAlgorithm
	},
        data() {
            return {
                preop_selected: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: {
                        female: null,
                    },
                    q5: null,
                    q6: null,
                    q7: null,
                    q8: null,
                    q9: null,
                    q10: {doac_effect_level: null, doac_effect_unknown: null,},
                    q11: {weight: null},
                    q12: null,
                },
                preopOptionValueMap: {
                    q1: {elective: 'a', emergency: 'b', urgent: 'c'},
                    q2: {minor_non_dental: 'a', minor_dental: 'b', moderate: 'c', high: 'd'},
                    q3: {apixaban: 'a', dabigatran: 'b', edoxaban: 'c', rivaroxaban: 'd', warfarin: 'e'},
                    q4: {female: null,},
                    q5: null,
                    q6: null,
                    q7: null,
                    q8: null,
                    q9: null,
                    q10: {doac_effect_level: null, doac_effect_unknown: null,},
                    q11: {weight: null},
                    q12: null
                },
                preopNotations: {
                    sample_bridging_protocol: {"en-ca": "Sample Bridging Protocol", "fr-ca": "Exemple de Protocole de Relais"},
                    day: {"en-ca": "day", "fr-ca": "jour"},
                    lmwh: {"en-ca": "LMWH", "fr-ca": "hbpm"},
                    surgery: {"en-ca": "surgery", "fr-ca": "chirurgie"},
                    note_1: {"en-ca": "* Use half daily dose administered 24h prior to procedure", "fr-ca": "* Utiliser la moitié de la dose quotidienne administrée 24 heures avant la procédure.​"},
                    note_2: {"en-ca": "** If high bleeding risk, hold, or use prophylactic dose LMWH", "fr-ca": "** En cas de risque hémorragique élevé, suspendre ou utiliser une dose prophylactique d'HBPM.​"},
                    note_3: {"en-ca": "*** Continue LMWH until INR in therapeutic range", "fr-ca": "*** Continuer l'HBPM jusqu'à ce que l'INR soit dans la plage thérapeutique.​"},
                },
                display_modal: {
                    ApixabanTable: false,
                    DabigatranTable: false,            
                    EdoxabanTable: false,
                    RivaroxabanTable: false,                   
                    HalfLifeATable: false,
                    HalfLifeDTable: false,
                    HalfLifeETable: false,
                    HalfLifeRTable: false,
                    PCCDosingWrapper: false,
                    sampleBridgingProtocolWrapper: false,
                },
                native_tool_list: ['thrombophiliaAlgorithm', 'vipitAlgorithm', 'vteManagement', 'atrialFibrillationManagement'],
                legacy_tool_list: ['vivomap261', 'vivomap263', 'vivomap271', 'vivomap329'],
                debugFlag: false    
            }
        },
        methods: {
            initToolSelection(tool){
                let self = this
                setTimeout(()=>{
                    console.log('reset')
                    if (tool==='preopAlgo'){
                        self.preop_selected=
                            {q1: null,q2: null,q3: null,q4: {female: null,},q5: null,q6: null,q7: null,q8: null,q9: null,q10: {doac_effect_level: null, doac_effect_unknown: null,},q11: {weight: null},q12: null,}
                    }
                    self.$forceUpdate()
                }, 200)
            },
            getManuscriptOption(qid){
                console.log(qid)
                if (this.preop_selected[qid]){
                    let fixIt = this.preop_selected[qid]
                    if (qid=='q2') {
                        fixIt = this.preop_selected[qid].replace(' ', '_').replace('-', '_')
                    }
                    console.log(fixIt)
                    return this.preopOptionValueMap[qid][fixIt]
                }
            },
            toggleDebug(){
                this.debugFlag = !this.debugFlag
            },
            syncDoacEffectLevel(){
                let self = this
                setTimeout(()=>{
                    self.preop_selected.q10.doac_effect_level = self.preop_selected.q10.doac_effect_unknown===true ? null : self.preop_selected.q10.doac_effect_level
                    self.$forceUpdate()
                }, 200)
            },
            mutualExcludeWeight(excludeQID){
                let self = this
                console.log(excludeQID ,self.preop_selected[excludeQID])
                setTimeout(()=>{
                    self.preop_selected[excludeQID].weight = null
                    self.$forceUpdate()
                }, 200)
            },
            getLocale(identifier){
                if (identifier){
                    return identifier[this.language]===undefined || !identifier[this.language] ? identifier["en-ca"] : identifier[this.language]
                }
                return null
            },            
        },
        computed: {
            isNativeToolCalc(){
                return this.native_tool_list.includes(this.activeCalc)
            },
            isLegacyToolCalc(){
                return this.legacy_tool_list.includes(this.activeCalc)
            },
            debug(){
                return this.debugFlag
            },
            debugView(){
                let routeQuery = this.$route.query
                return routeQuery.debugView!==undefined ? routeQuery.debugView : false
            }
        },
        watch: {
            debugView(after, before){
                return this.debugFlag = after!==false ? after==='show' : false
            }
        }        
}
</script>

<style lang="scss" scoped>
a.toggleExamplesWrapper, a span.toggleExamplesWrapper {
    border-radius: 25px;
    background: #1d2a75;
    color: white;
    text-decoration: none;
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-top: 2px;
    text-align: center;
    padding-top: 2px;
    padding-right: 2px;
    border: 10px solid white;
    font-size: 20px;
    font-weight: bolder;
    font-family: 'Material Design Icons';
}

.sampleBridgingProtocol {
    tr {
        th {
            background-color: $primary;
            color: white;
        }
    
    }
}

@media screen and (max-width: 460px) {
    a.toggleExamplesWrapper {
            margin-top: 2px;
    }
}

@media screen and (max-width: 600px) {
    .myPopupMessage {
        font-size: 12px;
        
        .v-app-bar {
            position: fixed;
        }
        
        table {
            margin-top: 12px;
        }
        
        .v-card {
            overflow-x: scroll;
        }
    }
}

</style>
