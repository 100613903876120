<template>
<div>

    <v-card flat>
        <v-card-title>Page Banner</v-card-title>
        <v-card-text>
            <v-switch v-model="config.inherit" :true-value="true" :false-value="false" label="Inherit"/>
            <div v-if="!config.inherit">
                <v-tabs v-model="ui.activeTab">
                    <v-tab v-for="language in languages" :key="`pageBanner-tab-${language}`">
                    {{language}}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="ui.activeTab">
                    <template v-for="(language) in languages">
                    <v-tab-item :key="`pageBanner-language-tab-item-${language}`">
                        <v-text-field v-model="config.background[language]" label="Background"/>
                        <p><strong>Image Sizing Recommendation:</strong> 1600px width</p>
                        <p><strong>Note:</strong> make images at least 1600px wide and then crop to any desired height. This will ensure, first, that the image can span the max site width with appropriate resolution. Cropping or controlling height after this step will ensure no pixel stretching as the screen size gets smaller.</p>
                        <AssetManager  v-model="temp.background[language]"/>
                        <!-- <Browser :search="'backgrounds'" v-model="temp.background[language]"/> -->
                    </v-tab-item>
                    </template>
                </v-tabs-items>
            </div>
            <!-- <div style="text-align: center; width: 80%; margin: 0 auto; border: 1px solid grey;">
                <Banner :config="config"/>
            </div>

            <v-expansion-panels>
                <draggable v-model="config.slides">
                    <v-expansion-panel style="width:800px;" v-for="(slide, slideIndex) in config.slides" :key="`slide_${slideIndex}`">
                        <v-expansion-panel-header>
                            {{slide.button.text}}

                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-text-field v-model="slide.button.text" :label="'Button Text'"></v-text-field>
                            <v-card v-if="slide.html">
                                <v-card-subtitle>
                                    HTML CODE
                                </v-card-subtitle>
                                <v-card-text>
                                    <v-textarea v-model="slide.html[language]"/>
                                    <hr>
                                    <v-card>
                                        <v-card-subtitle>
                                            Preview
                                        </v-card-subtitle>
                                        <v-card-text>
                                            <div v-html="slide.html[language]"/>
                                        </v-card-text>
                                    </v-card>
                                </v-card-text>
                            </v-card>

                            <v-card>
                                <v-card-subtitle>
                                    Background Image
                                </v-card-subtitle>
                                <v-card-text>
                                    <img v-if="slide.image" :src="slide.image[language]" width="400"/>
                                </v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </draggable>

            </v-expansion-panels>

            <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn @click="$emit('save',{definitionIndex, config})" icon>
                        <v-icon>
                            mdi-content-save
                        </v-icon>
                    </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import draggable from 'vuedraggable'
import Banner from '@/components/Banner/pageBanner/pageBanner.vue'
import Browser from '@/components/Assets/Browser'
import AssetManager from '@/components/Assets/Manager'

export default {
    props: {
        definitionIndex: {
            type: Number,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        languages: {
            type: Array,
            required: true
        }
    },
    components: {
        Banner,
        draggable,
        Browser,
        AssetManager
    },
    created: function(){
        let config
        if(this.model){
            config = JSON.stringify(this.model)
            config = JSON.parse(config)
        }

        if(!config.background){
            config = {
                background: {},
                inherit: true
            }
        }
        let background = JSON.stringify(config.background)
        this.temp.background = JSON.parse(background)
        this.config = config
        this.ready = true
    },
    data: function(){
        return {
            ready: false,
            ui: {
                activeTab: 0
            },
            temp: {
                background: {}
            },
            config: null,
            search: null,
            bucket: null
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        },
        background: function(){
            return this.temp.background
        }
    },
    watch: {
        config: {
            deep: true,
            handler: function(){
                this.$emit('input',this.config)
            }
        },
        background: {
            deep: true,
            handler: function(){
                let ready = this.ready
                if(ready){
                    let background = this.background
                    for(let language in background){
                        let asset = background[language]
                        if(asset && asset.Key){
                            this.model.background[language] = asset.Key
                        }
                    }
                }
            }
        }
    }
}
</script>

<style>

</style>
