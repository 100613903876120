<template>
  <div class="filter">
    <v-btn icon small @click="$emit('close')" style="position: absolute; top: 5px; right: 5px;">
      <v-icon small>mdi-close-circle</v-icon>
    </v-btn>
    <strong>FILTER BY TOPIC:</strong>
    <v-list>
      <v-list-item v-for="topic in topics" :key="'filter-'+topic.value">
        <!-- <v-list-item-content> -->
          <v-checkbox dense v-model="model.topics" :label="topic.text" :value="topic.value"/>
        <!-- </v-list-item-content> -->
      </v-list-item>
    </v-list>

  </div>
</template>

<script>
export default {
  props: {
    componentConfig: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  computed: {
    model: function(){
      return this.$attrs.value
    },
    topics: function(){
      let topics = this.componentConfig.topics
      let language = this.language
      let output = []
      for(let key in topics){
        let topic = topics[key]
        output.push({
          text: topic.text[language],
          value: topic.key
        })
      }
      return output
    }
  }
}
</script>

<style scoped lang="scss">
.filter{
  width: 100%;
  min-height: 200px;
  background-color: $secondary-light-2;
  text-align: left;
  padding: 25px;
  position: relative;
}

.filter .v-list{
  background-color: $secondary-light-2;
}
</style>

<style>
/* .filter .v-input--selection-controls__input .v-icon.mdi-checkbox-blank{
  color: white !important;
} */
</style>