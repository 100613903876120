<template>
  <div class="sitemap">
    <div class="container">
        <template v-for="page in _tree[0].children">
            <Page v-if="allowed(page.meta)" :class="[{'private':!page.meta.public}]" :page="page" :language="language" :key="'sitemap_page_'+page.name" :trace="page.name" :depth="1" :maxDepth="4"/>
        </template>
    </div>
  </div>
</template>

<script>
import Page from './page.vue'
export default {
    props: {
        site: {
            type: Object,
            required: true
        }
    },
    components: {
        Page
    },
    methods: {
        allowed: function(meta){
            if(meta.blockSiteMap!=undefined && meta.blockSiteMap===true){
                return false
            }
            let user = this.$store.getters.user
            let groups = user ? user.signInUserSession.accessToken.payload['cognito:groups'] ? user.signInUserSession.accessToken.payload['cognito:groups'] : [] : []
            let allowed = meta.allowed
            for(let i=0; i<groups.length; i++){
                let group = groups[i]
                if(allowed.includes(group)){
                    return true
                }
            }
            return meta.public
        }

    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        _tree: function(){
            let tree = []

            function objectLength(object){
                let length = 0
                for(let key in object){
                    length++
                }
                return length
            }

            function buildTree(tree, site){
                for(let pageName in site){
                    let page = site[pageName]
                    tree.push({
                        name: pageName,
                        definition: page.definition,
                        meta: page.meta,
                        children: []
                    })

                    if(objectLength(page._pages)>0){
                        buildTree(tree[tree.length-1].children, page._pages)
                    }
                }
            }

            buildTree(tree, this.site)

            return tree
        }
    }
}
</script>

<style scoped lang="scss">
.sitemap{
    text-align: left;

    .container{
        display: flex;
        flex-flow: column;
        column-width: 200px;
        font-size: 1em;
        padding: 0 0 16px;
    }
}

</style>

<style lang="scss">

.sitemap ul{
    list-style: none;
}
.sitemap ul li::before {
  content: "\2022";
  color: $tc-red;
  font-weight: bold;
  display: inline;
  width: 1em;
  margin-left: -1em;
  font-size: 1.2em;
  position: relative;
  top: 2px;
}

.private{
    color: red
}
</style>
