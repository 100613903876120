<template>
  <div>
    GUIDE EDITOR
    <hr>    
    <v-card>
        <v-card-text>
            <v-switch v-model="state.uploader" :true-value="true" :false-value="false" label="Upload Image"/>
            <Uploader v-if="state.uploader" :base="`clinical_guides`" :endPoint="`/api/clinical_guides/images/${guide.meta.id}/${guide.language}`"/>
            <!-- <Uploader/> -->
            <v-card>
                <v-card-title>
                    Image Selector
                </v-card-title>
                <v-card-text>
                    <Browser base="clinical_guides" :endPoint="`/api/clinical_guides/images/${guide.meta.id}/${guide.language}`" @selectAsset="selectAsset"/>
                </v-card-text>
            </v-card>
            <TinyEditor v-show="!state.preview" v-model="form.guide.html" :init="config.init" :api-key="config.apiKey"/>
            <div v-if="state.generatePDF" class='wrap_clinicalGuides' style="text-align: left;">
                <div class="clinicalGuideViewer">
                    <GuideViewer :generatePDF="true" :guide="{html:form.guide.html, name: form.guide.meta.name, id: form.guide.meta.id, version: form.guide.meta.version}" @makePDF="makePDF"/>
                </div>
            </div>
            <div id="preRender"/>
        </v-card-text>
    </v-card>

    <v-dialog v-model="state.guideDetails" persistent style="z-index:2000;">
        <v-card v-if="state.guideDetails">
            <v-card-text>
                <v-text-field v-model="form.guide.meta.name" label="Name"/>
                <v-text-field v-model="form.guide.meta.objective" label="Objective"/>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                    <v-btn @click="exit">
                        Cancel
                    </v-btn>
                <v-spacer/>
                    <v-btn :disabled="(!form.guide.meta.name || !form.guide.meta.objective) || (form.guide.meta.name.length==0 || form.guide.meta.objective.length==0)" @click="state.guideDetails = false">
                        Accept
                    </v-btn>
                <v-spacer/>                
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="state.preview" fullscreen persistent style="z-index:2000;">
        <v-card v-if="state.preview">
            <v-card-text>
                <GuideViewer :debug="true" :guide="form.guide"/>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                    <v-btn @click="state.preview = false">Close</v-btn>
                <v-spacer/>                
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="state.selectTopics" style="z-index:2000;">
        <v-card>
            <v-card-text>
                <v-list dense>
                    <v-list-item v-for="(topic) in guideTopics" :key="`topic-checkbox-${topic.name}`" class="hoverable">
                        <v-list-item-action>
                            <v-checkbox :label="topic.name" :value="topic.name" v-model="form.guide.topics"/>
                        </v-list-item-action>
                        <v-list-item-content></v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon x-small @click="deleteTopic(topic.name)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-text-field v-model="form.newTopic" label="New Topic"/>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn small :disabled="!allowTopic" @click="addTopic">
                                Add
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="state.selectTopics=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="state.linkGuide" style="z-index:2000;">
        <v-card>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col v-for="(guideData, languageKey) in $store.getters.clinicalGuideData" :key="`select-guide-${languageKey}`">
                            {{ languageKey }}
                            <v-list dense>
                                <v-list-item class="hoverable" v-for="(guide, guideIndex) in sortGuides(guideData.guides)" :key="`guide-${languageKey}-${guideIndex}`">
                                    <v-list-item-content>
                                        <v-list-item-title><span v-html="guide.name"/></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn small icon @click="getLink(guide)">
                                            <v-icon>mdi-content-copy</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <json-viewer :value="guideData"/>
                        </v-col>
                    </v-row>
                </v-container>


                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="state.linkGuide=false">Close</v-btn>

                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

  </div>
</template>

<script>
import TinyEditor from '@tinymce/tinymce-vue'
import GuideViewer from '@/components/ClinicalGuides/guideViewer'
import Uploader from '@/components/Assets/Uploader'
import Browser from '@/components/Assets/Browser'

export default {
    name: "GuideEditor",
    props: {
        guide: {
            type: Object,
            required: true
        },
        guideTopics: {
            type: Array,
            required: true
        },
        batchPDF: {
            type: Boolean,
            required: false,
            default: false
        },
        sortGuides: {
            type: Function,
            required: true,            
        }
    },
    components: {
        TinyEditor,
        GuideViewer,
        Uploader,
        Browser
    },
    created: function(){
        let guide = JSON.stringify(this.guide)
        guide = JSON.parse(guide)
        this.form.guide = guide
        this.config.apiKey = process.env.VUE_APP_TINYMCE_API_KEY
        // this.initTinyMCE()
        window.GuideEditor = this

        let self = this
        if(self.batchPDF){
            self.generatePDF()
            setTimeout(() => {
                self.$emit('exit')
            }, 1500);
        }
    },
    data: function(){
        return {
            state: {
                ready: false,
                preview: false,
                guideDetails: false,
                selectTopics: false,
                uploader: false,
                generatePDF: false,
                linkGuide: false
            },
            form: {
                guide: null,
                newTopic: null
            },
            config: {
                init: {
                    relative_urls: false,
                    allow_script_urls: true,
                    selector: 'textarea',
                    plugins: 'code fullscreen anchor autolink charmap codesample emoticons image media link lists searchreplace table visualblocks wordcount',
                    toolbar: 'customExitButton | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | customMakePDFButton | customSaveButton | customPreviewButton | customGuideDetails | customSelectTopicsButton | customLinkGuideButton | fullscreen | code',
                    height: "800",
                    setup: function(editor){
                        editor.ui.registry.addButton('customExitButton', {
                            text: 'Exit',
                            onAction: function (_) {
                                GuideEditor.exit()
                            }
                        });
                        editor.ui.registry.addButton('customMakePDFButton', {
                            text: 'Make PDF',
                            onAction: function (_) {
                                GuideEditor.generatePDF()
                            }
                        });
                        editor.ui.registry.addButton('customSaveButton', {
                            text: 'Save',
                            onAction: function (_) {
                                GuideEditor.save()
                            }
                        });
                        editor.ui.registry.addButton('customPreviewButton', {
                            text: 'Preview',
                            onAction: function (_) {  
                                // editor.execCommand('mceFullScreen');
                                GuideEditor.state.preview = true
                            }
                        });
                        editor.ui.registry.addButton('customGuideDetails', {
                            text: 'Guide Details',
                            onAction: function (_) {  
                                // editor.execCommand('mceFullScreen');
                                GuideEditor.state.guideDetails = true
                            }
                        });
                        editor.ui.registry.addButton('customSelectTopicsButton', {
                            text: 'Select Topic(s)',
                            onAction: function (_) {  
                                // editor.execCommand('mceFullScreen');
                                GuideEditor.state.selectTopics = true
                            }
                        });
                        editor.ui.registry.addButton('customLinkGuideButton', {
                            text: 'Link to other guide',
                            onAction: function (_) {  
                                // editor.execCommand('mceFullScreen');
                                GuideEditor.state.linkGuide = true
                            }
                        });
                        
                        editor.on('init', function(e) {
                            editor.execCommand('mceFullScreen');
                            if(!GuideEditor.guide.meta.id){
                                GuideEditor.state.guideDetails = true
                            }
                        });                        
                    }
                },
                apiKey: null
            }
        }
    },
    methods: {
        selectAsset: async function(data){
            let self = this
            navigator.clipboard.writeText(`${this.$store.getters.bucket}/${data.Key}`);
            setTimeout(function(){
                alert('Copied to clipboard')
                self.state.linkGuide = false
            },200)
            
        },
        initTinyMCE: async function(){
            let response = await this.sendRequest('get','https://cdn.tiny.cloud/1/qhhvk9fthun1tge7guz0mzqve0u9nx2bkw59o1gl23n0aoz6/tinymce/6/tinymce.min.js')
            eval(response.data)

            tinymce.init(this.config.init);
            
            this.state.ready = true
        },
        save: async function(){
            let t = new Date()
            let guide = this.form.guide
            guide.meta.version++
            guide.meta.dateUpdated = t.getTime()
            guide.meta.published = false
            this.generatePDF()
            this.$emit('save',guide)
        },
        deleteTopic: function(topicName){
            this.$emit('deleteTopic',{topicName, language: this.guide.language})
        },
        addTopic: function(){
            this.$emit('newTopic',{topic: this.form.newTopic, language: this.guide.language})
        },
        exit: function(){
            this.$emit('exit')
        },
        generatePDF: async function(){
            this.state.generatePDF = true
        },
        makePDF: async function(guide){
            let self = this
            async function generatePDF({html, id, version}){
                let response = await self.sendRequest('post','/api/admin/pdf',{html, id, version})
                return response
            }

            
            function fixImages(guide, preRender){
                function fixImage(img){
                        let trigger_a = '/clinical_guides/'
                        let trigger_b = '/wp-uploads/'
                        let append = 'https://thrombosiscanada.ca'
                        let source = img.src
                        let index_a = source.indexOf(trigger_a)
                        let index_b = source.indexOf(trigger_b)
                        if(index_a>=0){
                            let src = `${append}${source.substr(index_a,source.length)}`
                            img.src = src
                        }
                        if(index_b>=0){
                            let src = `${append}${source.substr(index_b,source.length)}`
                            img.src = src
                        }
                }
                let fixHTML = guide.html //guide.html.replaceAll(`<img src="/clinical_guides/`,`<img src="https://thrombosiscanada.ca/clinical_guides/`).replaceAll(`<img src="clinical_guides/`,`<img src="https://thrombosiscanada.ca/clinical_guides/`)
                preRender.innerHTML = fixHTML

                let fixThese = preRender.getElementsByTagName('img');

                for(let i=0; i<fixThese.length; i++){
                    let img = fixThese[i]
                    fixImage(img)
                }
                
                return preRender.getInnerHTML()
            }

            let preRender = document.getElementById('preRender')
            let fixedImages = fixImages(guide, preRender)
            
            
            let packet = {html: fixedImages, name: guide.name, id: guide.id, version: guide.version}
            
            let response = await generatePDF(packet)
            preRender.innerHTML = ""
            if(response.status==200){
                this.state.generatePDF = false
            }
            if(response.data.url){
                if(!this.batchPDF){
                    window.open(`${response.data.url}`)
                }else{
                    console.log('PDF generation status: ', response.status)
                }
            }else{
                console.error('generatePDF',response)
            }
        },
        getLink: function(guide){
            let self = this
            let code = `javascript:openLinkedGuide('${guide.id}')`
            navigator.clipboard.writeText(code);
            setTimeout(() => {
                alert('link code copied to clipboard')
                self.state.linkGuide = false
            }, 200);
        }
    },
    computed: {
        allowTopic: function(){
            let guideTopics = this.guideTopics
            let newTopic = this.form.newTopic
            let searchResults = guideTopics.filter((item)=>{return item.name==newTopic})
            let isUnique = searchResults.length==0

            return newTopic && newTopic.length>0 && isUnique
        },

    }
}
</script>

<style scoped lang="scss">
textarea{
    display: none;
}

.hoverable:hover{
    font-weight: bold;
    background-color: lightgrey;
}
</style>