<template>
  <div v-if="recent.length>0">
    <Title :config="{title:config.title}" :language="_language"/>


    <div class="resource-container">
        <!-- <v-select v-if="config.bilingual" v-model="lang" label="Resource Language" :items="_languages" style="max-width: 200px;"/> -->
        <template v-if="!config.displayStyle || config.displayStyle=='card'">
            <template v-for="(resource, resourceIndex) in recent">
                <ResourceCard v-if="shouldRender(resource)" class="resource-item" :debug="debug" :background="resolveBackground(resource)" :resource="resource" :language="_language"  :key="`resource-card-${resourceIndex}-${resource.id}`" @downloadResource="downloadResource" @viewResource="viewResource"/>
            </template>
        </template>
        <v-list v-else-if="config.displayStyle=='list'">
            <template v-for="(resource, resourceIndex) in recent">
                <v-list-item v-if="shouldRender(resource)" :key="`list-item-${resourceIndex}-id-${resource.id}`" class="resource-list-item">
                    <div class="leftSide">
                        <v-list-item-content>
                            <v-list-item-title>
                                <strong v-html="resource.definition.title[_language]"/>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <span v-html="resource.definition.subtitle[_language]"/>
                            </v-list-item-subtitle>
                                <span v-html="resource.definition.description[_language]"/>
                        </v-list-item-content>
                        <v-list-item-action>
                            <div style="width: 120px; text-align: center;">
                                <template v-if="resource.definition.type=='video'">
                                    <v-btn v-if="resource.definition.embeded" @click="viewResource(resource.definition.resource.asset[_language]); track(resource)" class="themed-button-dark" elevation="0">{{ _language=='fr-ca' ? 'Regarde maintenant' : 'Watch Now' }}</v-btn>
                                    <v-btn v-else :href="resource.definition.resource.asset[_language]" target="_blank" class="themed-button-dark" elevation="0">{{ _language=='fr-ca' ? 'Regarde maintenant' : 'Watch Now' }}</v-btn>
                                </template>
                                <template v-else>
                                    <v-btn v-if="resource.external=='Y'" :href="resource.definition.resource.asset[_language]" target="_blank">Follow Link</v-btn>
                                    <v-btn v-else :href="resolvePath(resource)" target="_blank" @click="downloadResource(resource)" class="themed-button-dark" elevation="0">{{_language=='fr-ca' ? 'Télécharger' : 'Download'}}</v-btn>
                                </template>
                            </div>
                        </v-list-item-action>
                    </div>
                    <div class="rightSide">
                        <v-list-item-action>
                            <v-img :src="resolveBackground(resource)"/>
                        </v-list-item-action>
                    </div>
                </v-list-item>
            </template>
        </v-list>

        <div v-else-if="config.displayStyle=='videoGrid'" class="video-grid">
            <template v-for="video in recent">
                <EmbededVideoCard class="video-grid-item" :resource="video" :language="_language" :key="`video-${video.id}`"/>
            </template>
        </div>

        <div v-else>
            Display Style: {{config.displayStyle}}
        </div>
    </div>

    <!-- <div v-if="!config.expanded" style="width: 70%; text-align: left; margin: 0 auto; margin-top: 50px;">
        <json-viewer :value="config"/>
        <v-btn class='viewAll_button' text v-if="config.resources.length>config.recent" @click="ui.viewAll = !ui.viewAll">
            <v-icon small class="view-all" :class="[{'expanded':ui.viewAll}]">
                mdi-triangle
            </v-icon>
             {{!ui.viewAll ? 'View All' : 'Summary'}}
        </v-btn>
    </div> -->

    <!-- <div v-if="!config.expanded" style="width: 70%; text-align: left; margin: 0 auto; margin-top: 50px;"> -->
        <!-- <json-viewer :value="config"/> -->
        <v-btn class='themed-button-dark'  v-if="!config.expanded && config.resources.length>config.recent" @click="ui.viewAll = !ui.viewAll">
            <v-icon small class="view-all" :class="[{'expanded':ui.viewAll}]">
                mdi-triangle
            </v-icon>
            {{!ui.viewAll ? 'More' : 'Less'}}
        </v-btn>
    <!-- </div> -->

    

    <v-dialog v-model="ui.dialog" width="800">
        <v-card v-if="ui.dialog">
            <v-card-text>
                <!-- <div v-if="resource" v-html="resource"/> -->
                <div v-if="resource">
                    <iframe :src="resource" style="width:100%; height:600px;" frameborder="0" allowfullscreen="allowfullscreen"/>
                    <!-- <iframe data-v-937b3e30="" title="YouTube video player" style="width:90%; height:90vh;" src="https://www.youtube.com/embed/Sc2iyAJhd-o" frameborder="0" allowfullscreen="allowfullscreen" class="youtubeVideo"></iframe> -->
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="ui.dialog=false;resource=null">Close</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

  </div>
</template>

<script>
import Title from '@/components/Title/Title'
import ResourceCard from './ResourceCard'
import EmbededVideoCard from './embededVideoCard'
export default {
    props: {
        config: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        debug: {
            type: Boolean,
            required: false,
            default: ()=>{return false}
        }
    },
    created: function(){
        let config = this.config
        this.ui.viewAll = config.expanded === true
    },
    components: {
        Title,
        ResourceCard,
        EmbededVideoCard
    },
    data: function(){
        return {
            ui: {
                viewAll: false,
                dialog: false
            },
            lang: 'en-ca',
            resource: null,
            downloadPath: '/resource'
        }
    },
    methods: {
        resolvePath: function(resource){
            let _language = this._language
            return `${this.downloadPath}/${resource.id}/${_language}`
        },
        resolveLanguage: function(record){
            let languages = this.$store.getters.languages
            let resource = record.definition.resource
            let type = record.definition.type
            let language = this.language
            let check = {}

            for(let i=0; i<languages.length; i++){
                let lang = languages[i]
                check[lang] = resource.asset[lang] //type=='link' ? resource.link[lang] : resource.asset[lang]
            }
            
            let output
            if(!check[language]){
                for(let lang in check){
                    let target = check[lang]
                    if(target){
                        output = lang
                        break;
                    }
                }
                return output
            }
            

            return language
        },
        shouldRender: function(resource){
            let resourceMap = this.resourceMap
            return resource && resourceMap[resource.id] && (resource.publish=='Y' && (resource.public=='Y' || resource.public=='N' && this.$store.getters.user))
        },
        downloadResource: function(resource){
            // window.open(`${this.$store.getters.bucket}/${resource}`);

            let _language = this._language
            let record = resource.definition.resource.asset[_language]
            this.track(resource)
        },
        viewResource: function(resource){
            this.resource = resource
            this.ui.dialog = true
        },
        track: async function(_resource){
            let data = {
                type: _resource.definition.type,
                categories: _resource.definition.categories,
                id: _resource.id,
                asset: _resource.definition.resource.asset[this.language],
                title: _resource.definition.title[this.language],
                path: this.$route.path,
                tool: 'website',
                log_key: _resource.definition.title[this.language],
                section: 'Resources'
            }
            this.sendRequest('put',`/api/tracker`, data)            
        },
        resolveBackground: function(resource){
            let overrideMap = this._overrideMap
            let config = this.config
            let language = this._language
            let bucket = this.$store.getters.bucket
            let override = overrideMap[resource.id]
            let hasOverride = {
                background: (override && override.definition.headerImage) ? override.definition.headerImage[language] : false
            }
            if(hasOverride.background){
                return bucket+"/"+hasOverride.background
            }
            // 
            return (config.defaultHeaderImage && config.defaultHeaderImage[language] && config.defaultHeaderImage[language].length>0) ? bucket+"/"+config.defaultHeaderImage[language] : null
        }
    },
    computed: {
        recent: function(){
            let config = this.config
            let resources = config.resources
            let resourceMap = this.resourceMap
            let output = []
            let max = this.ui.viewAll ? resources.length : resources.length<=config.recent ? resources.length: config.recent
            
            for(let i=0; i<max; i++){
                let resource = resourceMap[resources[i].id]
                if(resource){
                    output.push(resource)
                }

            }

            return output
        },
        resourceMap: function(){
            let resources = this.$store.getters.resources
            let resourceMap = {}
            for(let i=0; i<resources.length; i++){
                let resource = resources[i]
                resourceMap[resource.id] = resource
            }
            return resourceMap
        },
        _overrideMap: function(){
            let resources = this.config.resources
            let output = {}
            for(let i=0; i<resources.length; i++){
                let resource = resources[i]
                output[resource.id] = resource
            }
            return output
        },
        _languages: function(){
            let output = []
            let languages = this.$store.getters.languages
            for(let i=0; i<languages.length; i++){
                let language = languages[i];
                output.push({text: language, value: language})
            }

            // return output
            return this.language
        },
        _language: function(){
            // return config.bilingual ? this.lang : this.language
            return this.language
        }
    }
}
</script>

<style scoped lang="scss">

.resource-container {
    // margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    justify-content: center;

    // VIDEOGRID CONFIG (just video)
    .video-grid {
        width: 100%;
    }
}


/****************************
ResourceCard Component
Default Configuration (card)
****************************/
.textblock-container {
    // All ratios EXCLUDING section-ratio-1
    &.section-ratio-1-1,
    &.section-ratio-1-2,
    &.section-ratio-2-1,
    &.section-ratio-1-3,
    &.section-ratio-3-1 {

        .resource-container {

            .ResourceCard {
                width: 100%;
            }
        }
    }
}

/*********************
ResourceCard Component
List Configuration
**********************/
.textblock-container {

    .resource-container {

        .v-list {
            width: 100%;
            padding: 0;
            background-color: transparent;

            .resource-list-item {
                padding: 0;
                margin: 24px 0;
                align-items: initial;
                border-bottom: none;

                .leftSide {
                    width: 80%;
                    @media(max-width: 515px){
                        width: 100%;
                    }

                    .v-list-item__content {
                        padding: 24px 16px;

                        .v-list-item__title {
                            margin: 24px 0;
                            font-size: 24px;
                        }
                    }

                    .v-list-item__action {

                        > div {

                            button.v-btn {
                                background-color: white !important;
                                color: $primary !important;
                                border-radius: none !important;
                            }
                        }
                    }
                }

                .rightSide {
                    display: flex;
                    width: 20%;
                    @media(max-width: 515px){
                        width: 0%;
                    }


                    .v-list-item__action {
                        margin-right: initial;
                        width: 100%;

                        @media(max-width: 515px){
                            display: none;
                        }

                        .v-img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
// COLOUR THEMES
.textblock.theme-red,
.textblock.theme-darkblue {

    .textblock-container {

        .resource-container {

            .v-list {

                .resource-list-item {

                    .leftSide {
                        color: white !important;
                    }
                }
            }
        }
    }
}
// SIDE-BY-SIDES
.textblock-container {

    &.section-ratio-1-1,
    &.section-ratio-1-2,
    &.section-ratio-2-1,
    &.section-ratio-1-3,
    &.section-ratio-3-1 {

        .resource-list-item {
            margin: 0 !important;

            .leftSide {
                width: 100% !important;

                .v-list-item__content {
                    padding: 0 !important;

                    .v-list-item__title {
                        margin: 0 0 16px !important;
                    }
                }
            }

            .rightSide {
                width: 0% !important;

                .v-list-item__action {
                    display: none;
                }
            }
        }
    }
}






.theme-darkblue {
    .viewAll_button {
        color: white;
    }
}

.resource-item{
    width: 401px;
    @media(max-width:1200px){
        width: 360px;
    }

    border: 1px solid gray;
}

.resource-list-item{
    border-bottom: 1px solid lightgrey;
    margin: 5px;
}

.view-all{
    transform: rotate(90deg);
    margin-right: 10px;
}

.view-all.expanded{
    transform: rotate(180deg);
}

.video-grid{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 0.5%;
}

.video-grid-item{
    margin-bottom: 15px;
    width: 24%;
    min-width: 270px;
}
</style>
