<template>
<div v-if="stories.length>0" class='stories'>
    <v-carousel :cycle="true" style="width: 1024px; margin: 0 auto;" hide-delimiter-background show-arrows-on-hover>
        <v-carousel-item v-for="(record) in stories" :key="record.id">
            <v-container fluid>
                <v-row class='slide'>
                    <v-col align-self="center" style="height: 400px; text-align: center;" :class="[`theme-${record.story.theme}`]">
                        <Title :color="'white'" :config="{title:record.story.title, subtitle: record.story.subtitle, dense: true}" :language="language"/>
                        <div v-if="record.story.quote[language] && record.story.quote[language].length>0"  style="width: 100%; max-height: 150px; margin: 40px 10px 10px 10px; font-style: italic; font-size: 0.9em; font-weight: bold;">
                          {{record.story.quote[language].substr(0, record.story.quote[language].length<=350 ? record.story.quote[language].length : 350)}} {{ record.story.quote[language].length>=350 ? '...' : '' }}
                        </div>
                        <v-btn class='readMoreButton' depressed @click="$router.push({path:`${config.storiesPath}/${record.id}`})">READ MORE</v-btn>
                        <br>
                        <br>
                    </v-col>
                    <v-col :style="{'background-image':`URL(${bucket}/${record.story.image[language].Key.replaceAll(' ','%20')})`, 'background-size':'cover'}"/>
                </v-row>
            </v-container>
        </v-carousel-item>
    </v-carousel>
</div>
</template>

<script>
import Title from '@/components/Title/Title.vue'
export default {
  created: async function(){
    this.records = await this.getStories()
  },
  name: "Stories",
  props: {
    config: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  components: {
      Title
  },
  data: function(){
    return {
      records: []
    }
  },
  methods: {
    getStories: async function(){
        let response = await this.sendRequest('get','/api/stories')
        if(response.status==200){
            return response.data
        }else{
            console.error('getStories',response)
            return []
        }
    }
  },
  computed: {
    recordsMap: function(){
      let recordsMap = {}
      let records = this.records
      for(let i=0; i<records.length; i++){
        let record = records[i]
        recordsMap[record.id] = record
      }
      return recordsMap
    },
    stories: function(){
      let config = this.config
      let recordsMap = this.recordsMap
      let output = []
      for(let i=0; i<config.stories.length; i++){
        let recordID = config.stories[i]
        let record = recordsMap[recordID]
        if(record && record.publish=='Y'){
          output.push(record)
        }
      }

      return output
    },
    bucket: function(){
      return this.$store.getters.bucket
    }
  }
}
</script>

<style lang="scss">

.stories {
    position: relative;

    @media(max-width: 1200px){
        width: 114%;
        left: -7%;
    }
    @media(max-width: 599px){
        height: 800px;
    }

    .v-carousel {

        @media(max-width: 599px){
            height: 800px !important;
        }

        .v-responsive,
        .v-responsive__content {
            height: 800px !important;
        }

        .slide {

            @media(max-width: 599px){
                flex-flow: column;
                height: 800px;
            }

            > div:nth-of-type(1) {
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    padding: 48px 24px;

                    @media(max-width: 599px){
                        height: 400px;
                    }

                    .section-title-container {

                        .section-title-item{
                            text-align: center !important;
                        }

                        hr {
                            margin: 16px auto;
                        }
                    }
            }

            > div:nth-of-type(2) {

                @media(max-width: 599px){
                    height: 400px;
                }
            }
        }

        .readMoreButton {
            margin-top: 24px !important;
        }
    }

    .v-window__prev,
    .v-window__next {
        width: 56px;
        height: 56px;
        padding-left: 9px;
        position: absolute;
        top: 200px;
    }

    .v-carousel__controls {
        top: 350px;
        @media(max-width: 599px){
            top: 385px;
        }
    }

    .theme-darkBlue{
      background-color: $primary;
      color: white;
    }
}
</style>
