<template>
  <div>
    Twitter Config Type: 
    <v-select v-model="config.twitter.type" :items="['timeline','highlight']" label="Type"/>
  </div>
</template>

<script>
export default {
  created: function(){
    let config = this.config
    if(!config.twitter || !config.twitter.type){
      this.$set(config,'twitter',{
        type : 'timeline'
      })
    }
  },
  data: function(){
    return {}
  },
  computed: {
    config: function(){
      return this.$attrs.value
    }
  }
}
</script>

<style>

</style>