<template>
  <div class="navHighlights">
    <v-container>
        <v-row>
            <v-col cols="2" align-self="center">
                <div class="themed-header">
                    I'm Looking for:
                </div>
            </v-col>
            <v-col class="pa-0">
                <div class="item-container">
                    <template v-for="(item, itemIndex) in config">
                        <div class="item" :key="`item_${itemIndex}`" :class="{'selected':isPath(item.path)}" @click="navigate(item.path)">
                            <div class="item-content">
                                <div class='icon' v-if="item.icon">
                                    <component :is="item.icon"/>
                                </div>
                                <div style="width: 100%; position: absolute; left: 50%; transform: translateX(-50%);" v-html="item.label ? item.label[language] : item.label"/>
                            </div>
                        </div>
                    </template>
                </div>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
import DropIcon from '../assets/drop.svg'
import InfoIcon from '../assets/info.svg'
import HandIcon from '../assets/hand.svg'
import ClipboardIcon from '../assets/clipboard.svg'
import Team from '../assets/team.svg'
import Members from '../assets/members.svg'
import Partners from '../assets/partners.svg'
import Contribute from '../assets/contribute.svg'

export default {
    name: 'CustomNav',
    props: {
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        site: {
            type: Object,
            required: true
        },
        page: {
            type: Object,
            required: true
        },
        config: {
            type: Array,
            required: true
        }
    },
    components: {
        ClipboardIcon,
        DropIcon,
        InfoIcon,
        HandIcon,
        Team,
        Members,
        Partners,
        Contribute        
    },
    methods: {
        isPath: function(path){
            let route = this.$route.path.split('/')[2]
            let result = route==path
            return result
        },
        navigate: function(path){
            let target = this.$route.matched[0].path.length > 0 ? this.$route.matched[0].path+'/'+path : path
            this.$router.push(target)
        }
    },
    computed: {
        // config: function(){
        //     let site = this.site
        //     let path = this.$route.path
        //     let trace = '.home'+path.replaceAll('/', this.$route.path=='/' ? '' : '._pages.')
        //     let page = eval(`site${trace}`)
        //     let results = page.definition.filter((item)=>{return item.component==this.$options.name})
        //     console.log('customNav config',{
        //         path,
        //         trace,
        //         page,
        //         results
        //     })
        //     if(results[0]){
        //         return results[0].config
        //     }else{
        //         console.error('Missing config for: '+this.$options.name,{results})
        //     }
        // }
    }
}
</script>

<style scoped lang="scss">
.themed-header{
    position: relative;
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    // top: -25px;
    padding: 0px;
    border-bottom: 3px solid $accent-light-1;
}

.navHighlights{
    width: 100%;
    // height: 200px;
    background-color: $primary;
}

.item-container{
    display: flex;
    justify-content: space-around;
}
.item{
    width: 160px;
    padding: 25px;
    color: white;
    font-weight: bold;
    align-self: flex-end;
    flex-grow: 1;
}
.item-content{
    position: relative;
}
.item.selected{
    background-color: white;
    color: $primary-light-2;
}

.item .v-image{
    width: 60px;
    display: inline-block;
    margin-bottom: 25px;
    // background-color: $primary-light-2;
}

.item .icon{
    display: inline-block;
    border-radius: 100%;
    background-color: $primary-light-2;
    width: 80px;
    height: 80px;
    padding: 5px;
    cursor: pointer;
    margin-bottom: 15px;
}
.item svg{
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    fill: white;
    width: 30px;
}

.item .icon:hover{
    background-color: $tc-red;
}

.separator{
    width: 60px;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 25px;
    
}
</style>