<template>
  <div>
    <v-checkbox v-model="model" v-for="component in _globalComponents" :value="component" :label="component" :key="'globalComponentCheckbox'+component"/>
  </div>
</template>

<script>
export default {
    created: function(){
        let model = JSON.stringify(this.hideGlobalComponents ? this.hideGlobalComponents : [])
        model = JSON.parse(model)
        this.model = model
    },
    data: function(){
        return {
            model: []
        }
    },
    computed: {
        hideGlobalComponents: function(){
            return this.$attrs.value
        },
        _globalComponents: function(){
            return this.$store.getters.globalComponents
        }
    },
    watch: {
        model: {
            deep: true,
            handler: function(){
                console.log('emitting', this.model)
                this.$emit('input',this.model)
            }
        }
    }
}
</script>

<style>

</style>