<template>
  <div>
      <!-- Gallery initPath: {{ initPath }}
      <br>
      Gallery path: {{ path }} -->
    <v-btn v-if="branch.Key!='uploads'" @click="back" depressed class="themed-button-dark">
        <v-icon>mdi-chevron-left</v-icon> Back
    </v-btn>
    
    <div class="gallery-container">
        <template v-if="search==null || search.length==0">
            <template v-for="(item, itemIndex) in branch.children">

                <div v-if="!item.asset || (item.asset.Key.lastIndexOf('/')==item.asset.Key.length-1)" class="gallery-item gallery-folder" :key="`folder-${itemIndex}`" @click="selectBranch(item,itemIndex)">
                    <v-icon>mdi-folder</v-icon>
                    {{item.Key}}
                </div>

                <div v-else class="gallery-item gallery-asset" @click="selectItem(item)" :key="`asset-${item.asset.Key}`">
                    <v-card v-if="isImage(item.Key)" height="200" style="overflow: hidden;">
                        <div style="width:100%; height: 120px; text-align: center; background-color: whitesmoke;">
                            <!-- :style="{'background-image':'URL('+`${$store.getters.bucket}/${item.asset.Key.replaceAll(' ','%20')}`+')'}" -->
                            <v-img max-width="160" max-height="120" :src="`${$store.getters.bucket}/${item.asset.Key.replaceAll(' ','%20')}`"/>
                        </div>
                        <v-card-subtitle>
                            {{item.asset.Key}}
                            <!-- {{`${$store.getters.bucket}/${item.asset.Key.replaceAll(' ','%20')}`}} -->
                        </v-card-subtitle>
                    </v-card>
                    <v-card v-else height="200">
                        <v-icon small>mdi-file</v-icon>
                        <v-card-subtitle style="font-size: 9pt;" class="pa-1">
                            {{item.asset.Key}}
                        </v-card-subtitle>
                    </v-card>
                </div>

            </template>                
        </template>

        <template v-else>
            <template v-for="(item, itemIndex) in assets">
                <div class="gallery-item gallery-asset" v-if="isFile(item.Key) && checkFilter(search, item.Key)" :key="`asset-${itemIndex}`" @click="selectItem(item)">
                    <v-card v-if="isImage(item.Key)" height="200" style="overflow: hidden;">
                        <div :style="{'background-image':'URL('+`${$store.getters.bucket}/${item.Key.replaceAll(' ','%20')}`+')'}" style="width:100%; height: 120px; margin:0 auto; background-position: center; background-size: contain;"/>
                        <v-card-subtitle>
                            {{item.Key}}
                        </v-card-subtitle>
                    </v-card>
                    <v-card v-else height="200">
                        <v-icon small>mdi-file</v-icon>
                        <v-card-subtitle style="font-size: 9pt;" class="pa-1">
                            File: {{item.Key.substring(item.Key.lastIndexOf('/')+1, item.Key.length)}}
                            <br><br>
                            Folder: {{item.Key.substring(0, item.Key.lastIndexOf('/'))}}
                        </v-card-subtitle>
                    </v-card>
                </div>
            </template>
        </template>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        assetTree: {
            type: Array,
            required: true
        },
        search: {
            type: String,
            required: false
        },
        assets: {
            type: Array,
            required: true
        },
        initPath: {
            type: Array,
            required: false,
            default: ()=>{return []}
        }
    },
    created: function(){
        this.selectBranch(this.assetTree[0], 0, false)
        let tree = this.assetTree
        let initPath = this.initPath
        let branchString = this.branchPointer(initPath, 'children')

        let branch = eval(`tree${branchString}`)
        let path = JSON.stringify(initPath)
        this.path = JSON.parse(path)
        this.branch = branch
    },
    data: function(){
        return {
            path: [],
            branch: null
        }
    },
    methods: {
        branchPointer(array, childField){
          let output = ""
          for(let i=0; i<array.length; i++){
            let value = array[i]
            if(i<array.length-1){
                output += `[${value}]['${childField}']`
            }else{
                output += `[${value}]`
            }
            
          }
          return output
        },        
        selectBranch: function(branch, itemIndex, emitPath=true){
            // console.log('gallery > selectBranch')
            this.path.push(itemIndex)
            this.branch = branch
            this.$emit('selectBranch',branch)
            if(emitPath){
                this.$emit('setPath', this.path)
            }
        },
        selectItem: function(asset){
            this.$emit('selectItem',asset)
        },
        back: function(){
            let index
            let branch
            let path = this.path
            for(let i=0; i<path.length-1; i++){
                index = path[i]
                branch = i==0 ? this.assetTree[index] : branch.children[index]
            }
            this.branch = branch
            this.path.pop()
            this.$emit('setPath', this.path)
        },
        isImage: function(key){
            let fileExtention = key.substr(key.lastIndexOf('.')+1,key.length)
            return this._ext.image.includes(fileExtention)
        },
        isFile: function(key){
            let filename = key.substr(key.lastIndexOf('/')+1, key.length)
            return filename
        },
        checkFilter: function(searchString, key){
            let a = searchString.toLowerCase()
            let b = key.toLowerCase()
            return b.indexOf(a)>=0
        }
    },
    computed: {
        _ext: function(){
            let output = {
                image: [
                    'png',
                    'jpg',
                    'jpeg',
                    'gif',
                    'bmp',
                    'svg'
                ],
                svg: ['svg'] 
            }

            return output
        }
    }
}
</script>

<style scoped lang="scss">
.gallery-container{
    display: flex;
    flex-wrap: wrap;
}

.gallery-item{
    margin: 5px;
    width: 200px;
    min-height: 50px;
    cursor: pointer;
}

.gallery-asset{
    border-color: red;
    height: 200px;
    width: 31%;
    font-size: 8pt;
}
</style>