<template>
  <div>
    ID: {{record.id}}
    <v-switch true-value="Y" false-value="N" v-model="record.publish" label="Publish"/>
    <v-switch true-value="Y" false-value="N" v-model="record.public" label="Public"/>
    <v-select v-model="record.definition.type" :items="resourceTypes" label="Resource Type"/>
    <v-container>
        <v-row>
            <v-col>
                Categories:
                <v-list dense>
                    <v-list-item v-for="category in categories" :key="category.key">
                        <v-list-item-action>
                            <v-checkbox v-model="record.definition.categories" :value="category.key" :label="category.label[language]"></v-checkbox>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>                            
            </v-col>
        </v-row>
    </v-container>

    
    <v-container>
        <v-row>
            <v-col>
                <v-switch true-value="Y" false-value="N" v-model="record.external" label="External - New tab on click"/>
                <v-switch :true-value="true" :false-value="false" v-model="record.definition.embeded" label="Embeded"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-tabs v-model="ui.tab">
                    <v-tab v-for="language in languages" :key="`resource-tab-${language}`">
                        {{ language }}
                    </v-tab>
                </v-tabs>
    
                <v-tabs-items v-model="ui.tab">
                    <v-tab-item v-for="language in languages" :key="`resource-tab-item-${language}`">
                        <div style="margin: 20px auto;">
                            <v-btn :color="ui.selectHeader ? 'orange' :'blue'" @click="ui.selectHeader=!ui.selectHeader">Default Header Image</v-btn>
                            <v-btn icon small color="red" v-if="record.definition.resource.cardImage[language]!=null" @click="record.definition.resource.cardImage[language]=null;$forceUpdate()"><v-icon>mdi-delete</v-icon></v-btn>
                            <div v-if="ui.selectHeader">
                                <Uploader v-if="ui.uploadHeader" @setPath="setHeaderPath" :initPath="path.headerImage"/>
                                <Browser v-else v-model="record.definition.resource.cardImage[language]" @setPath="setHeaderPath" :initPath="path.headerImage" @selectAsset="ui.selectHeader=false"/>
                            </div>
                            <v-img v-if="!ui.selectHeader && record.definition.resource.cardImage[language]!=null" style="max-width: 400px;" :src="`${$store.getters.bucket}/${record.definition.resource.cardImage[language].Key}`"></v-img>
                        </div>
    
    
                        <v-text-field v-model="record.definition.title[language]" label="Title"/>
                            <v-text-field v-model="record.definition.subtitle[language]" label="Subtitle"/>
                            <v-textarea v-model="record.definition.description[language]" label="Description"/>
                            
                            <v-card>
                                <v-card-subtitle>
                                    <v-btn class="themed-button-dark" @click="ui.tools=!ui.tools">Tools</v-btn>
                                </v-card-subtitle>
                                <v-card-actions v-show="ui.tools">
                                    <v-btn color="warning" @click="migrate(record, language, language=='en-ca' ? 'fr-ca' : 'en-ca')">Migrate to {{ language=='en-ca' ? 'fr-ca' : 'en-ca' }}</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue" @click="ui.import=true">Import from another resource</v-btn>
                                </v-card-actions>
                            </v-card>
                            
                            <v-card>
                                <v-card-text>
                                    <div>
                                        <strong v-if="record.definition.embeded">
                                            Embeded source: <br>
                                            <sup style="font-weight: bold; color: red;">i.e. (https://www.youtube.com/embed/abc123 or https://player.vimeo.com/video/168643432)</sup>
                                        </strong>
                                        <v-text-field v-model="record.definition.resource.asset[language]" label="Asset" :disabled="internalAsset(record)"/>

                                        <div v-if="internalAsset(record)">
                                            <v-btn :color="state.uploader ? 'orange' :'blue'" @click="state.uploader=!state.uploader">
                                                {{state.uploader ? 'Select Asset' : 'Upload asset'}}
                                                <v-icon>
                                                    mdi-toggle
                                                </v-icon>
                                            </v-btn>
                                            <template v-if="state.uploader">
                                                <h3>Upload an asset</h3>
                                                <Uploader @setPath="setAssetPath" :initPath="path.asset"/>
                                            </template>

                                            <template v-else>
                                                <h3>Select an asset</h3>
                                                <Browser v-model="temp.asset" @setPath="setAssetPath" :initPath="path.asset" @selectAsset="temp.language=language"/>
                                            </template>

                                            
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                    </v-tab-item>
                </v-tabs-items>

            </v-col>
        </v-row>
    </v-container>
  
    <v-dialog v-model="ui.import">
        <v-card>
            <v-card-title>
                Import
            </v-card-title>
            <v-card-actions>
                <v-btn @click="ui.import=false">Close</v-btn>
            </v-card-actions>
            <v-card-text>
                <v-list dense >
                <v-list-item class="hoverable" v-for="resource in resources" :key="`import-resource-${resource.id}`">
                    <v-list-item-content>
                        <v-list-item-title>
                            EN: {{ resource.definition.title['en-ca'] }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            FR: {{ resource.definition.title['fr-ca'] }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <div>
                            <v-btn color="blue" medium @click="importResource(resource, record, 'en-ca')">Import English</v-btn> | 
                            <v-btn color="red" medium @click="importResource(resource, record, 'fr-ca')">Import French</v-btn>
                        </div>
                    </v-list-item-action>
                </v-list-item>
            </v-list>                
            </v-card-text>
            <v-card-actions>
                <v-btn @click="ui.import=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Uploader from '@/components/Assets/Uploader'
import Browser from '@/components/Assets/Browser'
export default {
    name: 'ResourceForm',
    components: {
        Uploader,
        Browser
    },
    created: function(){
        let record = this.record
        let languages = this.languages
        if(!record.definition.resource.cardImage){
            record.definition.resource.cardImage = {}
            for(let i=0; i<languages.length; i++){
                let language = languages[i]
                record.definition.resource.cardImage[language] = null
            }
        }
    },
    props: {
        categories: {
            type: Object,
            required: true
        },
        languages: {
            type: Array,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        resourceTypes: {
            type: Array,
            required: true
        }
    },
    data: function(){
        return {
            state: {
                uploader: false
            },
            temp: {
                asset: null,
                language: null
            },
            ui: {
                tab: 0,
                selectHeader: false,
                import: false,
                tools: false
            },
            path: {
                asset: [0],
                headerImage: [0]
            }
        }
    },
    methods: {
        migrate: function(record, from, to){
            let targets = ['title','subtitle','description']
            for(let i=0; i<targets.length; i++){
                let target = targets[i]
                record.definition[target][to] = record.definition[target][from]                
            }

            for(let key in record.definition.resource){
                record.definition.resource[key][to] = record.definition.resource[key][from]
            }
            
        },
        importResource: function(from, to, language){
            let targets = ['title','subtitle','description']
            for(let i=0; i<targets.length; i++){
                let target = targets[i]
                to.definition[target][language] = from.definition[target][language]                
            }

            for(let key in from.definition.resource){
                to.definition.resource[key][language] = from.definition.resource[key][language]
            }
            
        },
        selectAsset: function(data, language){
            let type = this.record.definition.type
            // this.temp.language = language
            // let language = this.language
            this.record.definition.resource.asset[language] = data.Key
            console.log('selectAsset',{
                data,
                language,
                type,
                target: this.record.definition.resource.asset[language],
                temp: this.temp.selectAsset
            })
        },
        internalAsset: function(record){
            return record.definition.type!='link' && (record.external=='N' && !record.definition.embeded)
        },
        setAssetPath(data){
            this.path.asset = data
        },
        setHeaderPath(data){
            this.path.headerImage = data
        }
    },
    computed: {
        record: function(){
            return this.$attrs.value
        },
        resources: function(){
            let language = 'en-ca'
            function compareTitle(a,b){
                const titleA = a.definition.title[language]
                const titleB = b.definition.title[language]
                if (titleA < titleB) {
                    return -1;
                }
                if (titleA > titleB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            let output = this.$store.getters.resources
            
            return output.sort(compareTitle)
        }
    },
    watch: {
        temp: {
            deep: true,
            handler: function(){
                let temp = this.temp
                this.selectAsset(temp.asset, temp.language)
            }
        }
    }
}
</script>

<style scoped>
.hoverable{
    border: p1 solid whitesmoke;
}
.hoverable:hover{
    background-color: whitesmoke;
}
</style>