<template>
  <div class="slideNav">
    <v-btn color="white" icon @click="prev"><v-icon>mdi-arrow-left</v-icon></v-btn>
    <template v-for="(item, itemIndex) in slides">
        <div :key="`slideNav_${itemIndex}`" class="slide-button" :class="{'selected':slideIndex==itemIndex}" @click="jump(itemIndex)">
            {{item.button[language]}}
        </div>
    </template>
    <v-btn color="white" icon @click="next"><v-icon>mdi-arrow-right</v-icon></v-btn>
  </div>
</template>

<script>
export default {
    props: {
        slides: {
            type: Array,
            required: true
        },
        slideIndex: {
            type: Number,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    methods: {
        init: async function(){
            this.$emit('slides',this.slides)
        },
        next: function(){
            this.$emit('next')
        },
        prev: function(){
            this.$emit('prev')
        },
        jump: function(slideIndex){
            this.$emit('jump',slideIndex)
        }
    }
}
</script>

<style scoped lang="scss">
.slideNav{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    padding: 0 48px;
    background-color: $primary;
    text-align: center;

    // Left & Right Arrow Buttons
    > .v-btn {
        position: absolute;
        top: 16px;

        @media(max-width: 959px){
            top: calc(50% - 24px);
        }

        &:first-child {
            left: 8px;
        }
        &:last-child {
            right: 8px;
        }

        &:hover {
            border: 1px solid white;
        }
    }

    // Category Buttons
    .slide-button{
        width: 200px;
        padding: 16px 24px;
        border: 1px solid white;
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;

        @media(max-width: 599px){
            flex-grow: 1;
        }

        &:hover {
            background-color: lightgrey;
            color: $primary-1;
        }

        &.selected {
            background-color: white;
            color: $primary-1;
        }
    }
}
</style>
