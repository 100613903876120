<template>
  <div>
    <SEO :site="site" :page="_page"/>
<!-- <v-select :items="[{text:'English',value:'en-ca'},{text:'French',value:'fr-ca'}]" v-model="ui.language" label="Page Language"/> -->
    <Header :site="site" :page="_page" :language="language"/>
        <PageLanguage :page="_page" @language="setLanguage"/>
        <template v-for="(pageComponent, pageCopmonentIndex) in _page.definition">
          <div :key="pageCopmonentIndex" class='render-body'>
            <component v-if="pageComponent.component=='TextBlock'" :debug="debug" :is="pageComponent.component" :site="site" :page="_page" :config="pageComponent.config" :language="ui.language" @tags="emitTags"/>            
            <component v-else-if="pageComponent.component!=='ClinicalTools'" :debug="debug" :is="pageComponent.component" :site="site" :page="_page" :config="pageComponent.config" :language="language" @tags="emitTags"/>
            <component v-else-if="pageComponent.component=='ClinicalTools' && component_mounting_state.ClinicalTools===true" :debug="debug" :is="pageComponent.component" :site="site" :page="_page" :config="pageComponent.config" :language="clinical_tools_configs.language" @tags="emitTags" @reset="doReset('reset')"/>            
          </div>
        </template>
    <Footer :site="site" :page="_page" :language="language"/>

    <!-- <template v-for="(globalComponent, globalComponentIndex) in _globalComponents">
      <template v-if="globalComponent!='MainBody'">
        <div v-if="displayGlobalComponent(globalComponent)" :key="`global_${globalComponent}`">
          <component :is="globalComponent" :site="site" :page="_page" :config="_configMap[globalComponent]" :language="language"/>
        </div>
      </template>
      <div v-else :key="`globalComponent-${globalComponentIndex}`" class="render-body">

        <template v-for="(pageComponent, pageCopmonentIndex) in _page.definition">
          <div :key="globalComponentIndex+'_'+pageCopmonentIndex" :class="[{'render-body-part':!['NavBar','PageNav'].includes(pageComponent.component)}]">
            <div v-if="debug" style="text-align: left; color: red; font-weight: bold;">
              pageComponent: {{pageComponent.component}}
            </div>
            <component :class="[{'render-body-part-component':!['NavBar','PageNav','Banner','PageBanner'].includes(pageComponent.component)}]" :is="pageComponent.component" :site="site" :page="_page" :config="pageComponent.config" :language="language" @tags="emitTags"/>
            <div v-if="debug && !pageComponent.config" >
              <h5>Missing Config</h5>
              <json-viewer :value="pageComponent"/>
            </div>
          </div>
        </template>

      </div>
    </template> -->
  </div>
</template>

<script>
import SEO from '@/components/SEO/page'
import PageLanguage from '@/components/PageLanguage/PageLanguage'
import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'
import NavBar from '@/components/Nav/NavBar/NavBar'
import MainBanner from '@/components/Banner/mainBanner/mainBanner'
import PageBanner from '@/components/Banner/pageBanner/pageBanner'
import CustomNav from '@/components/Nav/customNav/customNav'
import PageNav from '@/components/Nav/pageNav/pageNav'
import CalloutCards from '@/components/Section/Pages/CalloutCards.vue'
import HighlightResources from '@/components/Section/Highlight/Resources.vue'
import CTATwitter from '@/components/CTA/twitter.vue'
import NewsSection from '@/components/Section/News/NewsSection.vue'
import ArticleBrowser from '@/components/Article/ArticleBrowser.vue'
import Stories from '@/components/Stories/StoriesCarousel.vue'
import TextBlock from '@/components/Section/TextBlock/TextBlock.vue'
import MediaBlock from '@/components/Section/MediaBlock/MediaBlock.vue'
import ClinicalTools from '@/components/ClinicalTools/ClinicalTools.vue'
import ClinicalGuides from '@/components/ClinicalGuides/ClinicalGuides.vue'
import Pins from '@/components/Account/Pins'
import Profile from '@/components/Account/Profile'
import Team from '@/components/Team/Team'
import Members from '@/components/Members/Members'
import Resources from '@/components/Resources/Resources'
import PodCast from '@/components/PodCast/PodCast'
import VideoGrid from '@/components/Grid/VideoGrid'
import Search from '@/components/Search/Search'
import Partners from '@/components/Section/Partners/Partners'
import SignInUp from '@/components/Authorizer/SignInUp'
import ContactUs from '@/components/ContactUs/ContactUs'
import FindUs from '@/components/FindUs/find_us'
import StoryViewer from '@/components/Stories/StoryViewer.vue'
import SiteMap from '@/components/SiteMap/SiteMap'
import PatientSupportTools from '@/components/PatientSupportTools/PatientSupportTools.vue'

export default {
  name: "Render",
  props: {
    site: {
      type: Object,
      required: true
    },
    preview: {
      type: Object,
      required: false
    }
  },
  // metaInfo: function(){
  //   return this.metaData
  // },
  components: {
    SEO,
    Header,
    Footer,
    NavBar,
    MainBanner,
    CustomNav,
    PageBanner,
    CalloutCards,
    HighlightResources,
    CTATwitter,
    NewsSection,
    ArticleBrowser,
    Stories,
    TextBlock,
    MediaBlock,
    PageNav,
    ClinicalTools,
    ClinicalGuides,
    Profile,
    Pins,
    Team,
    Members,
    Resources,
    PodCast,
    VideoGrid,
    Search,
    Partners,
    SignInUp,
    ContactUs,
    FindUs,
    StoryViewer,
    PageLanguage,
    SiteMap,
    PatientSupportTools,
  },
  data: function(){
    return {
      ui: {
        language: 'en-ca'
      },
      draggable: true,
      resizable: true,
      responsive: true,
      search: {
        site: null,
        guides: null
      },
      query: null,
      items: [
          { title: 'Click Me' },
          { title: 'Click Me' },
          { title: 'Click Me' },
          { title: 'Click Me 2' },
      ],
      component_mounting_state: {
          ClinicalTools: true
      },
      clinical_tools_configs: {
          selected: null,
          language: 'en-ca'
      }
    }
  },
  methods: {
    // runSearch: function(){
    //   var startTime = performance.now()
    //   var endTime = performance.now()
    //   let ms = endTime-startTime
    //   let query = this.query
    //   let results = {
    //     site: this.search.site.search([{field:"text",query}]),
    //     guides: this.search.guides.search([{field:"name",query},{field:"objective",query},{field:"html",query}])
    //   }
    //   console.log({
    //     results,
    //     ms,
    //     query
    //   })
    // },
    // getFlexSearch: async function(){
    //   let language = this.language
    //   let response = await this.sendRequest('get','/api/getFlexSearch')
    //   let data = response.data
    //   const { Document } = Flexsearch

    //   const siteDocument = new Document({
    //     document: {
    //       id: "id",
    //       index: ["text"]
    //     }
    //   })
    //     for(let key in data.SiteData[language]){
    //       let page = {
    //         id: key,
    //         text: data.SiteData[language][key]
    //       }
    //       siteDocument.add(page)
    //     }

    //   const guideDocument = new Document({
    //     document: {
    //       id: "id",
    //       index: ["name","objective","html"]
    //     }
    //   })
    //     for(let i=0; i<data.GuideData[language].guides.length; i++){
    //       let guide = data.GuideData[language].guides[i]
    //       guideDocument.add(guide)
    //     }

    //   this.search.site = siteDocument
    //   this.search.guides = guideDocument

    // },
    // buildIndex: async function(){
    //   let response = await this.sendRequest('post','/api/admin/index')
    //   let data = response.data
    //   console.log('buildIndex',data)

    //   const { Document } = Flexsearch
    //   const document = new Document({
    //     document: {
    //       id: "id",
    //       index: ["text"]
    //     }
    //   });

    //   // let response = await this.sendRequest('post','/api/admin/clinical_guides/buildFlexSearch')
    //   // console.log(response)

    //   for(let key in data){
    //     let page = {
    //       id: key,
    //       text: data[key]
    //     }
    //     document.add(page)
    //   }

    //   let results = document.search([{field:"text",query:"Thrombophilia"}])
    //   let collection = []
    //   for(let i=0; i<results.length; i++){
    //     let result = results[i].result
    //     for(let ii=0; ii<result.length; ii++){
    //       let guideID = result[ii]
    //       if(!collection.includes(guideID)){
    //         collection.push(guideID)
    //       }
    //     }
    //   }


    //   console.log('initFlexSearch',{results, collection})


    // },
    displayGlobalComponent: function(globalComponent){
      let _page = this._page
      let result = !_page || (_page.hideGlobalComponents==undefined || !_page.hideGlobalComponents.includes(globalComponent))
      return result
    },
    get_lvl_1_component: function(component){
      if(this.$route.matched[0].path!=''){
        let site = this.site
        let path = this.$route.matched[0].path
        let trace = '.home'+path.replaceAll('/', this.$route.path=='/' ? '' : '._pages.')
        let page = eval(`site${trace}`)
        let pageNav = page.definition ? page.definition.filter((item)=>{return item.component==component}) : []
        return pageNav[0]
      }else{
        return false
      }
    },
    emitTags: function(data){
      this.$emit('tags',data)
    },
    authorize: function(){
        let requiresLogin=['/account']
        if(this.$route.matched.filter((route)=>{return requiresLogin.includes(route.path)}).length>0){
          if(!this.$store.getters.user){
            this.$router.push({path:'/'})
          }
        }
    },
    setLanguage: function(language){
      this.ui.language = language
      this.clinical_tools_configs.language = language
      this.$forceUpdate()
    },
    doReset(){
        let self = this
        self.component_mounting_state.ClinicalTools = false
        setTimeout(function(){
            self.component_mounting_state.ClinicalTools = true
        },300)
    }
  },
  computed: {
    debug: function(){
      return this.$store.getters.debug
    },
    metaData: function(){
      return this.tags
    },
    language: function(){
      // return this.$store.getters.language
      return this.ui.language
    },
    _page: function(){
      function makeShortCutKey(path){
        let parts = path.split('/')
        let output = ''
        for(let i=0; i<parts.length; i++){
          let node = parts[i]
          if(node.length>0){
            if(node!='home'){
              output += `['_pages']['${node}']`
            }else{
              output += node
            }
          }
        }

        // console.log({tracer, parts, output})
        return output
      }

        if(this.preview){
          return this.preview
        }
        let site = this.site
        let path = this.$route.path.replace(/\/$/, "");
        // let trace = '.home'+path.replaceAll('/', this.$route.path=='/' ? '' : "['_pages']")
        // let page = eval(`site${trace}`)
        let trace = makeShortCutKey(path)
        let page = eval(`site.home${trace}`)

        if(!page){
          let matched = this.$route.matched
          for(let i=matched.length-1; i>=0; i--){
            let match = matched[i]
            let p = match.path.replace(/\/$/, "");
            let t = makeShortCutKey(p)
            let _page = eval(`site.home${t}`)
            if(_page){
              return _page
            }
          }
        }else{
          return page
        }
      // let clinicalGuides = [
      //   'ClinicalGuidesLanguageSelection',
      //   'ClinicalGuide'
      // ]
      // if(clinicalGuides.includes(this.$route.name)){
      //   return {
      //     definition: [
      //       {
      //           "config": {
      //               "links": [
      //                   {
      //                       "path": "/about_us",
      //                       "label": {
      //                           "en-ca": "About Us"
      //                       }
      //                   },
      //                   {
      //                       "path": "/hcp",
      //                       "label": {
      //                           "en-ca": "Health Care Professionals"
      //                       }
      //                   },
      //                   {
      //                       "path": "/patients_and_caregivers",
      //                       "label": {
      //                           "en-ca": "Patients & Caregivers"
      //                       }
      //                   },
      //                   {
      //                       "path": "/news",
      //                       "label": {
      //                           "en-ca": "News & Events"
      //                       }
      //                   },
      //                   {
      //                       "path": "/get_involved",
      //                       "label": {
      //                           "en-ca": "Get Involved"
      //                       }
      //                   }
      //               ],
      //               "inherit": false
      //           },
      //           "component": "NavBar"
      //       },
      //       {
      //           "config": {
      //               "background": {
      //                   "en-ca": "uploads/backgrounds/hcp.png",
      //                   "fr-ca": "uploads/backgrounds/hcp.png"
      //               }
      //           },
      //           "component": "PageBanner"
      //       },
      //       {
      //           "config": {
      //               "pages": [
      //                   {
      //                       "icon": "uploads/icons/checks.svg",
      //                       "path": "hcp/practice/clinical_guides",
      //                       "label": {
      //                           "en-ca": "Clinical Guides",
      //                           "fr-ca": ""
      //                       },
      //                       "enabled": true
      //                   },
      //                   {
      //                       "icon": "uploads/icons/clipboard.svg",
      //                       "path": "hcp/practice/clinical_tools",
      //                       "label": {
      //                           "en-ca": "Clinical Tools"
      //                       },
      //                       "enabled": true
      //                   },
      //                   {
      //                       "icon": "uploads/icons/laptop.svg",
      //                       "path": "hcp/events/tc_events",
      //                       "label": {
      //                           "en-ca": "Webinars"
      //                       },
      //                       "enabled": true
      //                   },
      //                   {
      //                       "icon": "uploads/icons/doctor.svg",
      //                       "path": "hcp/practice/patient_support",
      //                       "label": {
      //                           "en-ca": "Patient Support"
      //                       },
      //                       "enabled": true
      //                   }
      //               ],
      //               "persistent": true
      //           },
      //           "component": "PageNav"
      //       },
      //       {
      //           "component": "ClinicalGuides"
      //       }
      //     ]
      //   }
      // }else{
      //   let site = this.site
      //   let path = this.$route.path
      //   let trace = '.home'+path.replaceAll('/', this.$route.path=='/' ? '' : '._pages.')
      //   let page = eval(`site${trace}`)
      //   return page
      // }
    },
    // _pageBanner: function(){
    //   return this.get_lvl_1_component('pageBanner')
    // },
    // _pageNav: function(){
    //   return this.get_lvl_1_component('pageNav')
    // },
    _configMap: function(){
      let output = {}
      let definition = this._page.definition ? this._page.definition : []
      for(let i=0; i<definition.length; i++){
        let item = definition[i]
        output[item.component] = item.config
      }
      return output
    },
    _globalComponents: function(){
      return this.$store.getters.globalComponents
    },
    _queryLanguage: function(){
      return this.$route.query.language
    }
  },
  watch: {
    _page: {
      deep: true,
      handler: function(){
        let language = this.language
        this.$emit('pageUpdate',{
          language,
          title: this._page.name,
          description: this._page.description ,
        })
      }
    },
    _queryLanguage: function(){
      let queryLanguage = this._queryLanguage
      if(queryLanguage){
        this.setLanguage(queryLanguage)
      }
    },
    clinical_tools_configs: {
        deep: true,
        handler: function(after, before){
            let self = this
            self.component_mounting_state.ClinicalTools = false
            setTimeout(function(){
                self.component_mounting_state.ClinicalTools = true
            },300)
        }
    }
  }
}
</script>

<style>
.vue-grid-item.vue-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
}
.vue-grid-item{
  border: 1px solid grey;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.grid::before {
    content: '';
    background-size: calc(calc(100% - 5px) / 12) 40px;
    background-image: linear-gradient(
            to right,
            lightgrey 1px,
            transparent 1px
    ),
    linear-gradient(to bottom, lightgrey 1px, transparent 1px);
    height: calc(100% - 5px);
    width: calc(100% - 5px);
    position: absolute;
    background-repeat: repeat;
    margin:5px;
}
</style>
