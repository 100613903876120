<template>
<div>
    <v-list dense class="topic-container">
        <v-list-item-group class="listbox" mandatory v-model="selected" active-class="blue--text">
            <template v-for="(topic, topicIndex) in _guideTopics">
            <v-list-item v-if="hasPublishedGuides(topic)" :key="'topic_box_'+topicIndex" @click="select(topicIndex)">
                <template v-slot:default="{ active }">
                    <v-list-item-content>
                        <v-list-item-title v-html="topic.name"/>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-list-item-action-text v-text="topic.guideIDs.length"></v-list-item-action-text>
                    </v-list-item-action>
                </template>
            </v-list-item>
            <v-divider v-if="topicIndex < guideTopics.length - 1" :key="topicIndex"/>
            </template>
        </v-list-item-group>
    </v-list>
</div>
</template>

<script>
export default {
    props: {
        guideTopics: {
            type: Array,
            required: true
        },
        guides: {
            type: Array,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    created: function(){
        // this.select(0)
    },
    data: function(){
        return {
            selected: 0
        }
    },
    methods: {
        select: function(index){
            this.selected = index
            this.$emit('filter',this._guideTopics[index].guideIDs)
        },
        hasPublishedGuides: function(topic){
            let guides = this.guides
            let guideIDs = topic.guideIDs
            
            for(let i=0; i<guideIDs.length; i++){
                let guideID = guideIDs[i]
                let found = guides.filter((guide)=>{return guide.id == guideID})
                if(found.length>0){
                    return true
                }
            }
            return false
        }
    },
    computed: {
        _guideTopics: function(){
            let language = this.language
            let guideIDs = this.guides.map(object=>object.id)
            let output = [
                {
                    name: language == 'fr-ca' ? 'Tous les sujets' : 'All Topics',
                    guideIDs
                }
            ]
            return output.concat(this.guideTopics)
        }
    },
    watch: {
        guides: {
            deep: true,
            handler: function(){
                this.$emit('filter',this._guideTopics[this.selected].guideIDs)
            }
        }
    }
}
</script>

<style>

</style>
