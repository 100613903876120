<template>
  <div class="NavBar" :class="{'mobile':this.$store.getters.mainNavOpen==true && this.$store.getters.width<960, 'hidden':this.$store.getters.mainNavOpen==false && this.$store.getters.width<960}">
    <SearchBar class='SearchBar'v-if="this.$store.getters.mainNavOpen==true && this.$store.getters.width<960"/>
    <div v-for="(navItem, navItemIndex) in navItems" :key="`navItem-container-${navItemIndex}`" @click="$router.push({path: navItem.path})" style="position: relative;" :class="{'selected':isPath(navItem.path)}">
        <v-menu v-model="ui.menu[navItemIndex]" close-on-click close-on-content-click close-delay="800" :min-width="300" :max-width="300" :key="`navItem_${navItemIndex}`" class="nav-button" :class="{'selected':isPath(navItem.path)}">
            <template v-slot:activator="{on, attrs}">
                <v-btn large text v-bind="attrs" v-on="on" dark @click="!hasChildren(navItem) ? $router.push({path: navItem.path}) : false"  @mouseenter="closeOthers(navItemIndex)">
                    {{navItem.label[language] && navItem.label[language].length>0 ? navItem.label[language] : navItem.label['en-ca']}}
                    <v-icon v-if="hasChildren(navItem)" class="hasChildren">mdi-chevron-right</v-icon>
                </v-btn>
            </template>

            <Node v-if="hasChildren(navItem)" @navigated="closeOthers" :navItem="navItem" :hasChildren="hasChildren"/>

            <!-- <v-list v-if="objectLength(getChildren(navItem.path))>0">
                <v-list-item @click="$router.push({path:`${navItem.path}`})">
                    <v-list-item-title>
                        {{navItem.label[language]}}
                    </v-list-item-title>
                </v-list-item>

                <template v-for="(child, childIndex) in getChildren(navItem.path)">
                    <v-menu open-on-hover nudge-right="230" :key="`navChildItem_${childIndex}`">
                        <template v-slot:activator="{on, attrs}">
                            <v-list-item v-bind="attrs" v-on="on" @click="$router.push({path:`${navItem.path}/${child.path}`})">
                                <v-list-item-subtitle>
                                    {{child.label[language]}}
                                </v-list-item-subtitle>
                                <v-list-item-action>
                                    <v-icon v-if="objectLength(getChildren(`${navItem.path}/${child.path}`))>0">
                                        mdi-chevron-right
                                    </v-icon>
                                </v-list-item-action>
                            </v-list-item>
                        </template>

                        <v-list v-if="objectLength(getChildren(`${navItem.path}/${child.path}`))>0">
                            <v-list-item @click="$router.push({path:`${navItem.path}/${child.path}/${subChild.path}`}); ui.menu[navItemIndex]=false;" v-for="(subChild, subChildIndex) in getChildren(`${navItem.path}/${child.path}`)" :key="`${navItemIndex}-${childIndex}-${subChildIndex}`">
                                <v-list-item-subtitle>
                                    {{subChild.label[language]}}
                                </v-list-item-subtitle>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>

            </v-list> -->
        </v-menu>
    </div>
  </div>
</template>

<script>
import Node from './linkNode.vue'
import SearchBar from '@/components/Search/searchBar'
export default {
    name: 'NavBar',
    components: {
        Node,
        SearchBar
    },
    created: function(){
        let navItems = this.navItems
        for(let i=0; i<navItems.length; i++){
            this.ui.menu[i] = false
        }
    },
    props: {
        site: {
            type: Object,
            required: true
        },
        page: {
            type: Object,
            required: true
        },
        config: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    data: function(){
        return {
            ready: false,
            ui: {
                menu: [],
                childMenu: {}
            },
            timer: null
        }
    },
    methods: {
        closeOthers: function(index){
            let self = this
            let menu = this.ui.menu
            
            if(index!=undefined){
                for(let i=0; i<menu.length; i++){
                    if(index!=i){
                        if(this.ui.menu[i]){
                            this.ui.menu[i] = false
                        }
                    }
                }
            }else{
                for(let i=0; i<menu.length; i++){
                    this.ui.menu[i] = false
                }
                self.$forceUpdate()
            }

        },
        isPath: function(path){
            // let root = '/'+this.$route.path.split('/')[1]
            // return route==path
            let matched = this.$route.matched
            let search = matched.filter((item)=>{return item.path==path})
            return search.length>0
        },
        getChildren: function(path){
            let siteMap = this._siteMap
            let array = path.split('/')
            for(let i=0; i<array.length; i++){
                let node = array[i]
            }
            let trace = ''
            for(let i=0; i<array.length; i++){
                let node = array[i]
                if(node!=''){
                    trace += i==1 ? `['${node}']` : `['children']['${node}']`
                }
            }
            let target = eval(`siteMap${trace}`)
            let output = []
            for(let key in target.children){
                output.push(target.children[key])
            }
            output.sort((a, b) => {
                return a.path.localeCompare(b.path)
            });
            return output
        },
        hasChildren: function(navItem){
            return !(!navItem.children || navItem.children.length==0)
        },
        objectLength: function(obj){
            let length = 0
            for(let i in obj){
                length++
            }
            return length
        }
    },
    computed: {
        path: function(){
            return this.$route.path
        },        
        _config: function(){
            function buildString(array){
                let string = ''
                for(let f=0; f<array.length; f++){
                    let value = array[f]
                    if(value.length>0){
                        string+=`['${value}']`
                    }
                }
                return string
            }
            let site = this.site
            let matched = this.$route.matched
            let page
            let tree = []
            tree.push(site.home)
            for(let i=0; i<matched.length; i++){
                let path = matched[i].path
                let trace = this.$route.name=='Admin' ? '.'+this.$route.query.edit : '.home'+path.replaceAll('/', '._pages.').replaceAll('._pages.admin','')
                let fallbackArray = trace.split('._pages')
                let fallback = trace.indexOf(':')<0 && fallbackArray[1] ? `.home._pages${fallbackArray[1]}` : '.home'
                // console.log({
                //     page,
                //     trace,
                //     fallback,
                //     fallbackArray
                // })
                let traceTemp = trace.split('.')
                let traceString = buildString(traceTemp)

                let fallbackTemp = fallback.split('.')
                let fallbackString = buildString(fallbackTemp)

                page = trace.indexOf(':')<0 ? eval(`site${traceString}`) : eval(`site${fallbackString}`)
                if(!page){
                    let pathStack = this.$route.path.split('/')
                    let stackTrace
                    for(let p=0; p<pathStack.length; p++){
                        stackTrace = pathStack[p]
                        if(stackTrace.length>0){
                            break;
                        }
                    }
                    page = eval(`site.home._pages.${stackTrace}`)
                }
                tree.push(page)
                let loop = page.definition ? page.definition.length : 0
                for(let c=0; c<loop; c++){
                    let definition = page.definition[c]
                    if(definition.component == this.$options.name && definition.config?.inherit){
                        let inheretFromPage = tree[i>0?i-1:0]
                        let searchResults = inheretFromPage.definition.filter((item)=>{return item.component==definition.component})
                        page.definition[c] = searchResults[0]
                    }
                }
            }
            let results = page.definition.filter((item)=>{return item.component==this.$options.name})
            if(results[0]){
                return results[0].config
            }else{
                console.error('Missing config for: '+this.$options.name,{results})
            }
        },
        navItems: function(){
            return this._config.links
        },
        _siteMap: function(){
            function getPages(route){
                let output = {}
                let pages = route._pages
                for(let path in pages){
                    let node = JSON.stringify(pages[path])
                    node = JSON.parse(node)
                    output[path] = {
                        label: node.meta.label,
                        children: getPages(node),
                        path
                    }
                }
                return output
            }
            let home = this.site.home

            let output = getPages(home)

            return output
        }
    },
    watch: {
        config: {
            deep: true,
            handler: function(){
                // console.log('Nav bar update',{
                //     config: this.config,
                //     _config: this._config,
                //     navItems: this.navItems
                // })
            }
        },
        path: function(after, before){
            if(after!=before){
                this.closeOthers()
            }
        }        
    }
}
</script>

<style scoped lang="scss">

.NavBar{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: $primary;

    &.mobile {
        flex-flow: column;
        align-items: center;
        background-color: white;
        color: $primary;

        > div {
            width: 600px;
            border-bottom: 2px solid rgb(147,147,147);
            @media(max-width: 599px){
                width: calc(100% - 24px);
            }

            &.selected {
                background-color: $primary-light-2;
                border-bottom: none;

                button {
                    color: white;
                }
            }

            button {
                width: 100%;
                color: $primary;
                font-size: 18px;
                font-weight: 800;
                justify-content: flex-start;
                @media(max-width: 599px){
                    font-size: 14px;
                }

                .hasChildren {
                    position: absolute;
                    right: -8px;
                }
            }
        }
    }

    &.hidden {
        display: none;
    }

    .SearchBar {

        .v-text-field {
            box-shadow: none !important;
        }
    }
}

</style>
