<template>
  <div>
    Handled by system, nothing to edit
    
  </div>
</template>

<script>
export default {
  computed: {
    model: function(){
      return this.$attrs.value
    }
  }
}
</script>

<style>

</style>