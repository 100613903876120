<template>
    <v-card>
        <v-card-title>
            Video Details
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col v-for="language in languages" :key="`video-form-${language}`">
                        {{language}}
                        <v-text-field v-model="form.title[language]" label="Title"/>
                        <v-text-field v-model="form.link[language]" label="Video Link"/>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="$emit('cancel')">Cancel</v-btn>
            <v-spacer/>
            <v-btn @click="$emit('ok',form)">Ok</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "VideoForm",
    created: function(){
        this.initForm()
    },
    props: {
        languages: {
            type: Array,
            required: true
        }
    },
    data: ()=>{
        return {
            form: {}
        }
    },
    methods: {
        initForm(){
            let form = {
                title: {},
                link: {}
            }
            let languages = this.languages
            for(let i=0; i<languages.length; i++){
                let language = languages[i]
                form.title[language] = null
                form.link[language] = null
            }
            this.form = form
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        },
        _allowAddVideo: function(){
            let languages = this.languages
            let form = this.form
            for(let i=0; i<languages.length; i++){
                let language=languages[i]
                for(let key in form){
                    let value = form[key][language]
                    if(!value || value.length==0){
                        return false
                    }
                }
            }
            return true
        }
    }
}
</script>

<style>

</style>