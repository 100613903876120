<template>
  <div>
      <v-btn  v-if="adminModule" text @click="$router.push({path:'/admin'})">
        <v-icon small>mdi-chevron-left</v-icon>
        Main Menu
      </v-btn>

      <v-btn text @click="$router.push({name:'Home'})">
        <v-icon>mdi-exit-to-app</v-icon>
        Exit Admin
      </v-btn>


      <div v-if="adminModule">
        <!-- <v-btn text @click="$router.push({path:'/admin'})">
          <v-icon small>mdi-chevron-left</v-icon>
          Back to Admin
        </v-btn> -->
        <component :is="adminModule"/>
      </div>
      <div v-else>
        <v-btn text @click="$router.push({path:'/admin/pages'})">Pages</v-btn> | 
        <v-btn text @click="$router.push({path:'/admin/ClinicalGuideManager'})">Clinical Guides</v-btn> |
        <v-btn text @click="$router.push({path:'/admin/ArticlesManager'})">Articles (news, events, webinars)</v-btn> |
        <v-btn text @click="$router.push({path:'/admin/Assets'})">Uploads</v-btn> | 
        <v-btn text @click="$router.push({path:'/admin/ResourceManager'})">Resources</v-btn> | 
        <v-btn text @click="$router.push({path:'/admin/StoryEditor'})">StoryEditor</v-btn> | 
        <v-btn text @click="$router.push({path:'/admin/RedirectEditor'})">RedirectEditor</v-btn> | 
        <v-btn text @click="$router.push({path:'/admin/UserAdmin'})">UserAdmin</v-btn> | 
        <v-btn text @click="$router.push({path:'/admin/PushNotificationManager'})">Push Notifications</v-btn>
      </div>



      <!-- <v-btn @click="refactorDB">refactorDB</v-btn> -->
  </div>

</template>

<script>
import Pages from '@/components/Admin/CMS.vue'
import Assets from '@/components/Admin/AssetManager.vue'
import ArticlesManager from '@/components/Admin/ArticlesManager.vue'
import ResourceManager from '@/components/Admin/ResourceManager.vue'
import ClinicalGuideManager from '@/components/Admin/ClinicalGuideManager.vue'
import UserAdmin from '@/components/Admin/UserAdmin.vue'
import StoryEditor from '@/components/Admin/StoryEditor.vue'
import RedirectEditor from '@/components/Admin/RedirectEditor.vue'
import PushNotificationManager from '@/components/Admin/PushNotificationManager.vue'

export default {
    components: {
        Pages,
        Assets,
        ArticlesManager,
        ResourceManager,
        ClinicalGuideManager,
        StoryEditor,
        RedirectEditor,
        UserAdmin,
        PushNotificationManager
    },
    data: function(){
      return {
        password: null
      }
    },
    methods: {
      refactorDB: async function(){
        let response = await this.sendRequest('get','/api/refactorDB')
        let records = response.data.records
        for(let i=0; i<records.length; i++){
          let record = JSON.stringify(records[i])
          record = JSON.parse(record.replaceAll('https://s3.ca-central-1.amazonaws.com/thrombosiscanada.ca/uploads/','https://thrombosiscanada.ca/uploads/'))
          await this.sendRequest('post','/api/refactorDB', {record})
        }
      }
    },
    computed: {
        adminModule: function(){
            return this.$route.params.adminModule
        }
    }
}
</script>

<style>
.tox.tox-tinymce-aux {
  z-index: 4000;
}
</style>