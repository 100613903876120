<template>
  <div>
    <h2>{{page_key}}</h2>
    <SEO_TextBlock v-for="(definition, index) in pageContent" :debug="debug" :language="language" :definition="definition" :page_key="page_key" :key="`${page_key}-content-${index}`"/>
  </div>
</template>

<script>
import SEO_TextBlock from './lib/TextBlock.vue'
export default {
    name: "PAGE_CONTENT",
    props: {
        page: {
            type: Object,
            required: true
        },
        page_key: {
          type: String,
          required: true
        },
        language: {
          type: String,
          required: true,
        },
        debug: {
            type: Boolean,
            required: false
        }
    },
    components: {
      SEO_TextBlock
    },
    computed: {
        pageContent: function(){
            let definition = this.page.definition
            let output = []
            for(let i=0; i<definition.length; i++){
                let pageComponent = definition[i]
                if(pageComponent.component=='TextBlock'){
                    output.push(pageComponent)
                }
            }
            return output
        }
    }
}
</script>

<style>

</style>