const rules = function(component){

    // this.positveNumber = [
    //     v => methodLib.positveNumber(v) || validationResponse.positveNumber(component.language)
    // ]

    this.required = [
        v => !!v || validationResponse.required(component.language)
    ]

    this.isNumber = [
        v => !!v || validationResponse.required(component.language),
        v => methodLib.isNumber(v) || validationResponse.isNumber(component.language)
    ]

    this.validPassword = [
        v => !!v || validationResponse.required(component.language),
        v => methodLib.validPassword(v) || validationResponse.validPassword(component.language)
    ]

    this.verifyPasswords = [
        v => !!v || validationResponse.required(component.language),
        // v => methodLib.verifyPasswords(component.form.signUp.password, component.temp.verifyPassword) || validationResponse.verifyPassword(component.language)
        v => methodLib.verifyPasswords(component.password.new, component.password.confirmation) || validationResponse.verifyPassword(component.language)
    ]

    this.validEmail = [
        v => !!v || validationResponse.required(component.language),
        v => methodLib.validEmail(v) || validationResponse.validEmail(component.language)
    ]

    const validationResponse = {
        isNumber: function(language){
            
            return component.$store.getters.labels.forms.validations.isNumber._text[language]
        },
        required: function(language){
            
            // switch(language){
            //     case"fr-ca":
            //         return "Champs requis"
            //     break;
            // }

            // return  'Required field'
            return component.$store.getters.labels.forms.validations.required_field._text[language]
        },
        validPassword: function(language){
            return component.$store.getters.labels.forms.validations.password_complexity._text[language]
        },
        verifyPassword: function(language){
            
            // switch(language){
            //     case"fr-ca":
            //         return "Verify password [FR]"
            //     break;
            // }

            // return  'Verify password'
            return component.$store.getters.labels.forms.validations.verify_password._text[language]
        },
        validEmail: function(language){
            // console.log('validation -> validEmail',{
            //     language,
            //     component
            // })
            // switch(language){
            //     case"fr-ca":
            //         return "Adresse électronique invalide"
            //     break;
            // }

            // return  'Invalid E-mail'
            return component.$store.getters.labels.forms.validations.email._text[language]
        }
    }

    const methodLib = new methods(component)
    
}

const methods = function(component) {

    this.isNumber = function(v){
        try {
            let value = parseInt(v,10)
            return !isNaN(value) && value>=0
        } catch (error) {
            return false
        }

    }

    this.validPassword = function(v){
        function isStrongPwd(password) {
      
            var uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        
            var lowercase = "abcdefghijklmnopqrstuvwxyz";
        
            var digits = "0123456789";
        
            var splChars ="!@#$%&*()-";
        
            var ucaseFlag = contains(password, uppercase);
        
            var lcaseFlag = contains(password, lowercase);
        
            var digitsFlag = contains(password, digits);
        
            var splCharsFlag = contains(password, splChars);
  
            var lengthFlag = password.length>=6;
  
            return (lengthFlag && ucaseFlag && lcaseFlag && digitsFlag && splCharsFlag)
        
            function contains(password, allowedChars) {
          
                for (var i = 0; i < password.length; i++) {
            
                        var char = password.charAt(i);
            
                        if (allowedChars.indexOf(char) >= 0) { return true; }
            
                    }
            
                return false;
            }        
        }

        
        return v && isStrongPwd(v)
    }

    this.verifyPasswords = function(v1, v2){
        let results = {
            areStrings: (typeof v1 == 'string' && typeof v2=='string'),
            vType: typeof v1,
            pType: typeof v2,
            matches: (v1 == v2)
        }
        
        return results.areStrings && results.matches
    }

    this.verifyPassword = function(v1){
        let v2 = component?.form?.signUp?.password
        let results = {
            areStrings: (typeof v1 == 'string' && typeof v2=='string'),
            vType: typeof v1,
            pType: typeof v2,
            matches: (v1 == v2)
        }
        
        return results.areStrings && results.matches
    }

    this.validEmail = function(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email && re.test(email)) {                
            return true;  
        }
        return false;
    }
}

module.exports = {
    rules,
    methods
}