<template>
<div class="clinical-tools" style="margin-top: 50px;" @click="track">
    <Title :title="'Patient Support Tools'"/>
    <div id="toolsContainer">
        <input id="language" type="hidden" v-model="language">

        <v-container style="padding:6px;">
        <v-row class="justify-center">
            <!-- Tool Selector Accordion Placeholder
            <v-col cols="12" sm="3" md="3" lg="3">
                <div id="calcMenu" class="calcMenu">
                                    
                </div>
            </v-col>
            -->
            
            <v-col cols="12" sm="9" md="9" lg="9" class="d-flex justify-center vueCalc">
                <form id="toolsWrapper" onsubmit="return false;" ref="toolsWrapper">
                    <vteManagement :language="language"/>
                </form>
            </v-col>
        </v-row>
        </v-container>
    </div>
</div>
</template>

<script>
require('../ClinicalTools/calc/calc_v2.css')
require('../ClinicalTools/calc/websiteCalc.css')

import vteManagement from './tools/vteManagement.vue'
import Title from '@/components/Section/title.vue'

export default {
    components: {
        Title,
        vteManagement,
    },
    props: {
        language: {
            type: String,
            required: true,
            default: "en-ca",
        }
    },
    data: function(){
        return {
            tool: this.selectedTool()
        }
    },
    methods:{
        track: function(){
            let selectedTool = this.selectedTool()
            if(true || this.tool != selectedTool){
                this.tool = selectedTool
                let data = {
                    log_key: "Venous Thromboembolism (VTE) Information for Patients",
                    tool: 'staging-website',
                    section: 'Patient Support Tools',
                }
                this.sendRequest('put',`/api/tracker`, data)
            }
        },
        selectedTool(){
            return "vteManagement"
        }
    },
}
</script>

<style scoped lang="scss">
.clinical-tools{

    > .v-input {
        max-width: 900px;
        margin: 40px auto 0;
        @media(max-width: 932px){
            margin: 16px 15px 0;
        }
    }
}

.vueCalc .v-label{
    margin: 10px 0px 10px 0px !important;
}

#toolsContainer {
    position: relative;
    text-align: left;
    min-height: 1020px;
}

#toolsWrapper {
    text-align: left;
    /* margin: 0px auto 0px 270px; */
    max-width: 800px;
    border: 1px solid #aaa;
    background: white;
}
</style>