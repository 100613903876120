<template>
  <div>
    <v-container>
        <v-row>
            <v-col v-for="lang in $store.getters.languages"  :key="'CTA_'+lang">
                <div>
                    <h4>{{lang}}</h4>
                    <v-text-field v-model="config.title[lang]" label="Title"/>
                    <v-text-field v-model="config.subtitle[lang]" label="Subtitle"/>
                    <v-text-field v-model="config.button[lang]" label="Button"/>
                </div>
            </v-col>
        </v-row>
    </v-container>
    <v-select v-model="config.layout" :items="_layouts" label="Layout"/>
    <v-select v-model="config.theme" :items="_theme" label="color theme"/>
    <v-btn icon @click="$emit('input',config)">
        <v-icon>mdi-content-save</v-icon>
    </v-btn>
    <v-btn @click="reset">
       RESET
    </v-btn>
  </div>
</template>

<script>
export default {
    created: function(){
        let config = JSON.stringify(this.model ? this.model : this.template())
        config = JSON.parse(config)
        this.config = config
    },
    props: {
        definitionIndex: {
            type: Number,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    data: function(){
        return {
            config: {}
        }
    },
    methods: {
        template: function(){
            let config = {
                theme: null,
                title: {},
                subtitle: {},
                button: {},
                layout: null
            }
            for(let i=0; i<this.$store.getters.languages.length; i++){
                let lang = this.$store.getters.languages[i]
                config.title[lang] = null
                config.subtitle[lang] = null
            }
            return config            
        },
        reset: function(){
            this.config = this.template()
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        },
        _layouts: function(){
            let output = [
                {
                    text: 'text-left / button-right',
                    value: 1
                },
                {
                    text: 'button-left / text-right',
                    value: 2
                },
                {
                    text: 'text-top-center / button-buttom-center',
                    value: 3
                }
            ]

            return output
        },
        _theme: function(){
            let output = [
                {
                    text: 'Blue background / White Text',
                    value: 1
                },
                {
                    text: 'Red background / White Text',
                    value: 2
                }
            ]

            return output
        }
    }
}
</script>

<style>

</style>