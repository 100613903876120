import Vue from 'vue'
// import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
import cache from "./plugins/cache.js";
import vuetify from '@/plugins/vuetify' // path to vuetify export
import JsonViewer from "vue-json-viewer";
import axios from 'axios';

// Vue.use(VueMeta,{
//   refreshOnceOnNavigation: true
// })
Vue.use(JsonViewer)

//TODO
if (location.hostname == "localhost") {
  console.table({
    // 'AG Presentation': [
    //   'clinical guides',
    //   'show ability to find things',
    //   'pinning function',
    //   'login/sign-up'
    // ],
    'comments': [
      'current TC site requires too many update steps - just have changes staged for saving:  just confirm save when leaving',
      'images should have embed link ability to open in new tab for download',
      'textblock to allow for easy image',
      'new layoug: https://thrombosiscanada.ca/2021-annual-conference/: min column with min-width',
      'no need for resource page - resources are broken into each section',
      'Resource Date time - selectable with latest'
    ],
    presentation: [
      'Grid System: video, image, component',
      'Image Selection',
      'Resource Manager: video, link document - internal/external',
      'clinical guides and search capabillities for dec 5'
    ],
    mobileApplication: {
      eLearning: [],
      clinical_guides: [],
      clinical_tools: [],
      resources: []

    },
    todo: [
      {
        flexSearch: {
          clinical_guides: {
            optimize: ['buildFlexSearch - build off targeted publish/unpublish/regress'],
            bugfix: [
              'only search HTML of published guides - seems to search unpublished / resyn data'
            ]
          },
          page_admin: {
            optimize: ['buildFlexSearch - build off page target']
          }
        }
      },
      {
        clinical_guides: [
          'enhance guide not found - look for separate language and switch',
          'direct link to PDF is an issue for old version',
          'image on click display'
        ]
      },
      {
        components: [
          'event summary for donate/act now',
          'DONE - Title > allow font-size override',
          'DONE - News - not pulling latest 3',
          'DONE - News & Events Request: New filter like category: [Patient, HCP] / allow component configuration'
        ]
      },
      {
        componentFixes: [
          'Team > fix UI and test functionality',
          'page banner - cookie crub',
          { 'done - admin save issue': 'page not showing up for new page: fixed' },
          'done - callout card fix'
        ]
      },
      {
        requests: [
          'done - articles: default date shown, allow for disable',
          'done - articles - past events are based on end date',
          'articles - news add end date',
          'make edit member more obvious',
          'done - manual login navigate to home',
          'done - cookies',
          'done - disclaimer'
        ]
      },
      {
        "Clinical Tools": {
          links: 'Search and correct any links to PDFs'
        }
      },
      {
        'TO Do': [
          '1. DONE - URL redirect for old pages',
          '2. Dashboard preparations - tracking links, resource clicks (which resources & from where)',
          '3. Remove route - or _ blocker',
          '4. Prep to rebuild tree according to Davids ask',
          '5. DONE - call out card image css update',
          '6. Authentication emails / forgotpassword etc',
          '7. PINS - move over to cloud',
          '8. SEO - Site map',
          '9. Tracking system',
          '10. Video Resource card - use header image'
        ]
      },
      {
        'Content view': ['DONE - david likes 4x4 video view formatting - component'],
        'Stories': [
          'DONE - editor and end point - unique url',
          'let users email stories to TC'
        ],
        'assets': ['UI / UX'],
        'nav bar editor': ['DONE - nav bar drag toggls'],
        'admin': [
          "DONE - URL remove space",
          "DONE - 'Expand Tree"
        ],
        'Site click': 'DONE - go to top'
      }
    ]
  })
}

/*
Twitter
DONE - Embed solution
DONE - Route changes
Clinical Guide PDF
Current support app

Could you please help with moving couple of pages like we discussed yesterday: 

donate/alexander_graham_turpie_fund -> get_involved/alexander_graham_turpie_fund 
/donate/corey_ross ->  get_involved/corey_ross

Also, please change the route for /disclaimer to /terms_of_use

- french / english tools for resources
- looking for: 
- admin user list
- resources as list
- sharing "items" to social media
*/

import commonComponents from "./components/Globals/loader";
commonComponents.forEach(component => {
  Vue.component(component.name, component);
});

Vue.config.productionTip = false


import RenderView from '@/views/Render.vue'
import RenderArticles from '@/views/RenderArticles.vue'
import LabelAdmin from '@/views/LabelAdmin.vue'
import Admin from '@/views/Admin.vue'
import Logout from '@/views/Logout.vue'
function initRoute(route) {

  if (!route.component || route.component == 'Render') {
    route.component = RenderView
    // if(route.meta){
    //   let label = route.name ? JSON.stringify(route.name) : JSON.stringify({})
    //   route.meta.label = JSON.parse(label)
    //   route.name = route.path
    // }    
  } else if (route.component == 'Admin') {
    route.component = Admin
  } else if (route.component == 'LabelAdmin') {
    route.component = LabelAdmin
  } else if (route.component == 'RenderArticles') {
    route.component = RenderArticles
  } else if (route.component == 'Logout') {
    route.component = Logout
  }

  if (route.children) {
    for (let i = 0; i < route.children.length; i++) {
      let child = route.children[i]
      initRoute(child)
    }
  }
}

async function init(callBack) {
  let URL = process.env.VUE_APP_API + '/getSite'
  // console.log('getting site',{env: process.env, URL})
  // axios.get('/api/getSite').then((response)=>{
  axios.get(URL).then((response) => {
    let routes = response.data.routes
    let site = response.data.site
    let redirects = response.data.redirects
    let componentConfigurations = response.data.componentConfigurations
    let resources = response.data.resources
    let temp = response.data.temp
    for (let r = 0; r < routes.length; r++) {
      let route = routes[r]
      initRoute(route)
      router.addRoute(route)
    }

    if (callBack) {
      callBack(site, componentConfigurations, resources, redirects, temp)
    } else {
      this.site = site
      this.componentConfigurations = componentConfigurations
    }


  })


}

init((site, componentConfigurations, resources, redirects, temp) => {
  let code = new Vue({
    created: function () {
      Vue.prototype.cache = cache
      // console.log('initializing site',{site, componentConfigurations})      
    },
    mounted: function () {
      this.$store.commit('componentConfigurations', componentConfigurations)
      this.$store.commit('resources', resources)
      this.$store.commit('redirects', redirects)
      this.$store.commit('site', site)
    },
    data: function () {
      return {
        // site,
        // componentConfigurations,
        // resources,
        // temp
      }
    },
    methods: {
      initRoute,
      init
    },
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')

  if (location.hostname == "localhost") {
    window.code = code;
  }
})


