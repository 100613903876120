<template>
  <div class='ResourceCard'>
      <v-card :class="[`resource-type-${resource.definition.type}`]">
        <span v-if="debug" style="color: red; font-size: 8pt; position: absolute; top: -20px; left: 0px;">ID: {{ resource.id }}</span>
        <div :style="{'background-image':`URL('${_background}')`}" style="position: relative; width: 100%; height: 225px; background-size: contain; background-position: center;">
            <v-btn icon link class="share" small :href="shareFaceBook(resource)" target="_blank" style="transform: translateX(-120%)">
                <v-icon color="white" large>mdi-facebook</v-icon>
            </v-btn>

            <v-btn icon link class="share" small :href="shareTwitter(resource)" target="_blank">
                <v-icon color="white" large>mdi-twitter</v-icon>
            </v-btn>

            <v-btn icon class="pin" :class="[{'pinned':pins.isPinned('resources', resource)}]" @click="pins.pin('resources', resource);$forceUpdate()">
                <v-icon color="white" large>mdi-pin</v-icon>
            </v-btn>            

            <v-avatar size="45" class="resource-avatar">
                <v-btn icon v-if="resource.definition.type=='video'" @click="viewResource(resource.definition.resource.asset[_language]); track(resource)">
                    <v-icon color="white">
                        mdi-play
                    </v-icon>
                </v-btn>

                <v-btn icon v-if="resource.definition.type=='document'" :href="resolvePath(resource)" target="_blank" @click="downloadResource(resource); track(resource)" >
                    <v-icon color="white">
                        mdi-file-download-outline
                    </v-icon>
                </v-btn>

                <template v-if="resource.definition.type=='link'">
                    <v-btn v-if="resource.external=='N'" link :href="resource.definition.resource.asset[_language]" @click="track(resource)" icon>
                        <v-icon color="white">
                            mdi-link
                        </v-icon>
                    </v-btn>

                    <v-btn v-else icon link :href="resource.definition.resource.asset[_language]" @click="track(resource)" target="_blank">
                        <v-icon color="white">
                            mdi-link
                        </v-icon>
                    </v-btn>
                </template>
            </v-avatar>
        </div>

        <v-card-text class="card-content">
            <div class="card-title">
                <span v-html="resource.definition.title[_language]"/>
                <center>
                    <hr class="small"/>
                </center>
                <div v-if="resource.definition.subtitle[_language] && resource.definition.subtitle[_language].length>0" class="card-subtitle" v-html="resource.definition.subtitle[_language]"/>
            </div>

            <div class="card-description" v-html="resource.definition.description[_language]"/>
        </v-card-text>
        <div class='readMoreButton displayNone'><span>Read more...</span></div>

        <v-card-actions>
            <v-spacer></v-spacer>
            <template v-if="resourceType=='video'">
                <v-btn v-if="resource.definition.embeded" @click="viewResource(resource.definition.resource.asset[_language])" class="themed-button-dark" elevation="0">{{_labels.button.watch}}</v-btn>
                <v-btn v-else :href="resource.definition.resource.asset[_language]" target="_blank" class="themed-button-dark" elevation="0">{{_labels.button.watch}}</v-btn>
            </template>
            <template v-else>
                <template v-if="resource.definition.type=='link'">
                    <v-btn v-if="resource.external=='N'"  @click="track(resource)" :href="resource.definition.resource.asset[_language]" class="themed-button-dark" elevation="0">{{_labels.button.follow}}</v-btn>
                    <v-btn v-else  @click="track(resource)" :href="resource.definition.resource.asset[_language]" target="_blank" class="themed-button-dark" elevation="0">{{_labels.button.view}}</v-btn>
                </template>
                <v-btn v-else :href="resolvePath(resource)" target="_blank" @click="downloadResource(resource)" class="themed-button-dark" elevation="0">{{_labels.button.download}}</v-btn>
            </template>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>

    <v-dialog v-model="ui.dialog" width="800">
        <v-card v-if="ui.dialog">
            <v-card-text style='padding:24px 24px 48px;'>
                <div v-if="video">
                    <iframe class='iframeVideo' :src="video" style="width:100%; height:600px;" frameborder="0" allowfullscreen="allowfullscreen"/>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="ui.dialog=false;video=null">Close</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import pinsController from '@/plugins/pins'
export default {
    props: {
        resource: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        background: {
            type: String,
            required: false
        },
        debug: {
            type: Boolean,
            required: false,
            default: ()=>{return false}
        }
    },
    created: function(){
        // this.pins = new pinsController('resources', this)
        this.pins = this.$store.getters.controllers.pins
    },
    updated: function(){
        this.give_showHideIdsAndEventListener_toResourceCards();
    },
    data: function(){
        return {
            ui: {
                dialog: false
            },
            pins: null,
            video: null,
            downloadPath: '/resource'
        }
    },
    methods: {
        resolvePath: function(resource){
            let _language = this._language
            return `${this.downloadPath}/${resource.id}/${_language}`
        },
        downloadResource: function(resource){
            // console.log('downloadResource',{
            //     resource,
            //     bucket: this.$store.getters.bucket
            // })
            // window.open(`${this.$store.getters.bucket}/${resource}`);

            let _language = this._language
            let record = resource.definition.resource.asset[_language]
            this.track(record)
            this.$emit('downloadResource',record)
        },
        give_showHideIdsAndEventListener_toResourceCards: function(){
            // GET: elements relevant to show/hide more text in Resource Cards
            let vCards          = document.querySelectorAll('.ResourceCard .v-card');
            let containers      = document.querySelectorAll('.ResourceCard .card-content');
            let descriptions    = document.querySelectorAll('.ResourceCard .card-description');
            let readMoreButtons = document.querySelectorAll('.ResourceCard .readMoreButton');

            // LOOP: ResourceCards vCards (indexes sync between vCards, contains, descriptions)
            for(let i = 0; i < vCards.length; i++){

                // GIVE: id tp vCard
                let vCard = vCards[i]
                    vCard.classList.add(`readMoreId_${i}`);

                // GIVE: id to container
                let container = containers[i];
                    container.classList.add(`readMoreId_${i}`);
                // GET: container_bottom
                let container_bottom = container.getBoundingClientRect().bottom;

                // GIVE: id to description
                let description = descriptions[i];
                    description.classList.add(`readMoreId_${i}`);
                // GET: description_bottom
                let description_bottom = description.getBoundingClientRect().bottom;

                // IS: description_bottom lower than container_bottom (aka there is text below overflow)
                if( (description_bottom - 48) > container_bottom){ // -48 is to ignore padding

                    // GET: button
                    let readMoreButton = readMoreButtons[i];
                    // GIVE: readMoreId to button
                    readMoreButton.classList.add(`readMoreId_${i}`);
                    // REMOVE: displayNone from button (they all start off hidden, and are only shown if needed)
                    readMoreButton.classList.remove('displayNone');

                    // IS: button already has hasListener attribute (flag) so subsequent ResourceCard renders don't re-add listeners to buttons that already have it
                    if( readMoreButton.getAttribute('hasListener') != 'true'){
                        readMoreButton.setAttribute('hasListener', 'true');

                        let self = this;
                        readMoreButton.addEventListener('click', function(){self.showHideMoreText(readMoreButton)});
                    };
                };
            };
        },
        showHideMoreText: function(readMoreButton){
            // GET: readMoreId_# class id
            let readMoreButton_classList = readMoreButton.classList; // get classList
            let readMoreButton_classArray = [...readMoreButton_classList]; // convert classList to array
            let readMoreId = readMoreButton_classArray.find(item=>item.includes('readMoreId_')); // use find on array to get readMoreId_# class

            // GET: elements to give show class (readMore) to
            let button      = document.querySelector(`.ResourceCard .readMoreButton.${readMoreId}`);
            let vCard       = document.querySelector(`.v-card.${readMoreId}`);
            let container   = document.querySelector(`.ResourceCard .card-content.${readMoreId}`);
            let description = document.querySelector(`.ResourceCard .card-description.${readMoreId}`);

            if( button.classList.contains('readMore')){ // IS: currently showing
                // REMOVE: show class (readMore) from elements
                button.classList.remove('readMore');
                vCard.classList.remove('readMore');
                container.classList.remove('readMore');
                description.classList.remove('readMore');
                // UPDATE: button text (show less -> read more...)
                button.children[0].innerHTML = 'Read more...';
            }
            else { // IS: currently hidden
                // GIVE: show class (readMore) to elements
                button.classList.add('readMore');
                vCard.classList.add('readMore');
                container.classList.add('readMore');
                description.classList.add('readMore');
                // UPDATE: button text (read more... -> show less)
                button.children[0].innerHTML = 'Show less';
            };
        },
        track: async function(){
            let _resource = this.resource
            let data = {
                type: _resource.definition.type,
                categories: _resource.definition.categories,
                id: _resource.id,
                asset: _resource.definition.resource.asset[this.language],
                title: _resource.definition.title[this.language],
                path: this.$route.path,
                tool: 'website',
                log_key: _resource.definition.title[this.language],
                section: 'Resources'
            }
            this.sendRequest('put',`/api/tracker`, data)
        },
        viewResource: function(resource){
            // console.log('viewResources',{
            //     resource,
            //     bucket: this.$store.getters.bucket
            // })
            // window.open(`${this.$store.getters.bucket}/${resource}`);
            // this.$emit('viewResource',resource)
            this.video = resource
            this.track(resource)
            this.ui.dialog = true
        },
        shareTwitter: function(resource){
            let path = this.resolvePath(resource)
            let _language = this._language
            // let link = `http://twitter.com/share?text=${resource.definition.title[_language]}&url=https://thrombosiscanada.ca${path}`
            let link = `http://twitter.com/intent/tweet?text=${resource.definition.title[_language]}&url=https://thrombosiscanada.ca${path}`
            for(let i=0; i<resource.definition.categories.length; i++){
                let category = resource.definition.categories[i]
                if(i==0){
                    link+='&hashtags='
                }
                link+=category.replaceAll(' ','')
            }
            return link
        },
        shareFaceBook: function(resource){
            let path = this.resolvePath(resource)
            let _language = this._language
            let link = `https://www.facebook.com/dialog/share?app_id=${this.$store.getters.fbid}&display=popup&href=https://thrombosiscanada.ca${path}`
            return link
        }
    },
    computed: {
        resourceType: function(){
            let resource = this.resource
            return resource.definition.type
        },
        _background: function(){
            let resource = this.resource
            let language = this._language
            if(this.background){
                return this.background
            }else if(resource.definition.resource.cardImage && resource.definition.resource.cardImage[language]){
                return `${this.$store.getters.bucket}/${resource.definition.resource.cardImage[language].Key}`
            }else{
                return 'https://cdn.vuetifyjs.com/images/cards/sunshine.jpg'
            }
            // return resource.definition.cardImage ? resource.definition.cardImage : this.background ? this.background : 'https://cdn.vuetifyjs.com/images/cards/sunshine.jpg'
        },
        _language: function(){
            let record = this.resource
            let languages = this.$store.getters.languages
            let resource = record.definition.resource
            let type = record.definition.type
            let language = this.language
            let check = {}

            for(let i=0; i<languages.length; i++){
                let lang = languages[i]
                check[lang] = resource.asset[lang] //type=='link' ? resource.link[lang] : resource.asset[lang]
            }
            
            let output
            if(!check[language]){
                for(let lang in check){
                    let target = check[lang]
                    if(target){
                        output = lang
                        break;
                    }
                }
                return output
            }
            

            return language
        },
        _labels: function(){
            let language = this._language
            let labels = {
                button: {
                    'watch': {
                        'en-ca': 'Watch Now',
                        'fr-ca': 'Regarde Maintenant'
                    },
                    'download': {
                        'en-ca': 'Download',
                        'fr-ca': 'Télécharger'
                    },
                    'read': {
                        'en-ca': 'Read More',
                        'fr-ca': 'En savoir plus'
                    },
                    'follow': {
                        'en-ca': 'Follow Link',
                        'fr-ca': 'Suivre le lien'
                    },
                    'view': {
                        'en-ca': 'View',
                        'fr-ca': 'Voir'
                    }
                }
            }

            return {
                button: {
                    'watch': labels.button.watch[language],
                    'download': labels.button.download[language],
                    'read': labels.button.read[language],
                    'follow': labels.button.follow[language],
                    'view': labels.button.view[language]
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">

.ResourceCard {
    height: 100%;
    max-width: 380px;

    .v-card {
        height: 568px;
            // height: auto;
        &.readMore {
            height: auto;
        }

        > div {
            border-radius: 0 !important;
        }
    }

    .v-card__actions {
        height: 56px;
    }
}

.share{
  position: absolute;
  bottom: -35px;
  right: 5px;
  background-color: #1d9bf0;

    span {
        i {
            font-size: 26px !important;
        }
    }
}

.pin{
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: $primary-1;

    span {
        i {
            font-size: 26px !important;
        }
    }
}

.pinned{
  background-color: $tc-red;
}

.resource-avatar{
    background-color: $tc-red;
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.card-content{
    // border: 1px solid red;
    overflow: hidden;
    height: 240px;
        // height: initial;
    &.readMore {
        height: initial;
    }
}
.card-title{
    text-align: center;
    font-weight: bold;
    font-size: 1.1em;
    margin: 15px;
}
.card-subtitle{
    text-align: center;
    font-weight: normal;
    font-size: 0.8em;
    // margin: 10px;
    // height: 30px;
}

.card-description{
    padding-bottom: 48px;
    // height: 98px;
    height: initial;
}

.v-card__actions{
    // border: 1px solid blue;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
}

.readMoreButton {
    position: relative;
    height: 24px;
    padding-top: 16px;
    padding-right: 24px;
    text-align:right;
    font-size: 14px;
    text-decoration:underline;
    color:slategray;

    &.displayNone {
        display: none;
    }

    &.readMore {
        top: -72px;
    }

    span {
        cursor: pointer;
    }
}

.iframeVideo {
    @media(max-height:600px){
        height: auto !important;
    }
}
</style>
