var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"80%","text-align":"center","margin":"0 auto"}},[_c('div',{attrs:{"id":"buzzsprout-small-player"}}),_c('h3',{staticStyle:{"text-align":"center"}},[_vm._v("Subscribe today on your favourite platform:")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('v-btn',{staticClass:"themed-button-dark",attrs:{"link":"","href":"https://www.paypal.com/donate/?hosted_button_id=TXZVV2JPJ3W8G","target":"_blank"}},[_vm._v(" Donate Through PayPal ")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"center"}},[_c('a',{staticStyle:{"display":"inline-block","overflow":"hidden","border-radius":"13px","width":"250px","height":"83px"},attrs:{"href":"https://podcasts.apple.com/us/podcast/clot-conversations/id1612506101?itsct=podcast_box_badge&itscg=30200&ls=1"}},[_c('img',{staticStyle:{"border-radius":"13px","width":"250px","height":"83px"},attrs:{"src":"https://tools.applemediaservices.com/api/badges/listen-on-apple-podcasts/badge/en-us?size=250x83&releaseDate=1646239740&h=be95e09ea881aee6529036d8b55e9c15","alt":"Listen on Apple Podcasts"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xOTQ2NjgwLnJzcw"}},[_c('img',{staticClass:"aligncenter wp-image-5783 size-full",attrs:{"src":"https://thrombosiscanada.ca/wp-uploads/uploads/2022/03/EN_Google_Podcasts_Badge_1x.png","alt":"","width":"150","height":"38"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"center"}},[_c('a',{staticStyle:{"display":"inline-block","background-color":"#000000","background-image":"url('https://www.buzzsprout.com/images/badges/listen-on-embed.svg')","background-repeat":"no-repeat","border":"1px solid #000000","border-radius":"5px","margin":"0 5px 5px 0","text-indent":"-9000px","background-position":"10px -53px","height":"40px","width":"106px"},attrs:{"href":"https://open.spotify.com/show/5bxKsOsPoCRe7E47Df4LyT?si=bd04a1e9b4e34b9a","target":"_blank","rel":"noopener"}},[_vm._v("Listen on Spotify")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"center"}},[_c('a',{staticStyle:{"display":"inline-block","background-color":"#000000","background-image":"url('https://www.buzzsprout.com/images/badges/listen-on-embed.svg')","background-repeat":"no-repeat","border":"1px solid #000000","border-radius":"5px","margin":"0 5px 5px 0","text-indent":"-9000px","background-position":"10px -173px","height":"40px","width":"111px"},attrs:{"href":"https://www.stitcher.com/podcast/clot-conversations","target":"_blank","rel":"noopener"}},[_vm._v("Listen on Stitcher")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"center"}},[_c('a',{staticStyle:{"display":"inline-block","background-color":"#000000","background-image":"url('https://www.buzzsprout.com/images/badges/listen-on-embed.svg')","background-repeat":"no-repeat","border":"1px solid #000000","border-radius":"5px","margin":"0 5px 5px 0","text-indent":"-9000px","background-position":"10px -293px","height":"40px","width":"162px"},attrs:{"href":"https://music.amazon.com/podcasts/7443e440-787c-4ad5-95b7-1e2b701edaf5/clot-conversations","target":"_blank","rel":"noopener"}},[_vm._v("Listen on Amazon Music")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"center"}},[_c('a',{staticStyle:{"display":"inline-block","background-color":"#000000","background-image":"url('https://www.buzzsprout.com/images/badges/listen-on-embed.svg')","background-repeat":"no-repeat","border":"1px solid #000000","border-radius":"5px","margin":"0 5px 5px 0","text-indent":"-9000px","background-position":"10px -533px","height":"40px","width":"140px"},attrs:{"href":"https://iheart.com/podcast/93646781/","target":"_blank","rel":"noopener"}},[_vm._v("Listen on iHeartRadio")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"text-align":"center"}},[_c('strong',[_vm._v("Support this podcast:")])])
}]

export { render, staticRenderFns }