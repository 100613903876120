<template>
  <div>
    <div v-if="ready">
        <v-btn @click="addStory" text>Add</v-btn>
        <v-container fluid>
            <v-row>
                <v-col>
                    <v-list dense>
                        <v-list-item v-for="(record, index) in stories" :key="`story-${index}`" :class="[{'published':record.publish=='Y'},{'unpublished':record.publish=='N'}]" style="border-bottom: 1px solid lightgrey;">
                            <v-list-item-content>
                                <v-list-item-title>
                                  {{record.story.title[language]}}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{record.story.subtitle[language]}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <div>
                                    <v-btn @click="editStory(index)" icon><v-icon>mdi-pencil</v-icon></v-btn>
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-container>
    </div>
    <div v-else>
        ...loading...
    </div>
    <v-dialog v-model="ui.editStory" fullscreen persistent>
      <v-card v-if="editing">
        <v-card-text>
          <v-tabs v-model="ui.activeTab">
              <v-tab v-for="language in languages" :key="`story-tab-${language}`">
                  {{language}}
              </v-tab>
          </v-tabs>
          <v-tabs-items v-model="ui.activeTab">
              <v-tab-item v-for="language in languages" :key="`story-tab-item-${language}`">
                  <v-card flat>
                      <v-card-text>
                          <v-switch v-model="editing.record.publish" true-value="Y" false-value="N" label="Publish"/>
                          <v-text-field dense outlined v-model="editing.record.story.title[language]" label="Title" clearable/>
                          <v-text-field dense outlined v-model="editing.record.story.subtitle[language]" label="Subtitle" clearable/>
                          <v-text-field dense outlined v-model="editing.record.story.quote[language]" label="Quote" clearable/>
                          <v-card v-if="editing.record.story.image[language]">
                            <v-img :src="`${$store.getters.bucket}/${editing.record.story.image[language].Key}`" width="200"/>
                          </v-card>
                          <v-btn v-if="ui.selectImage===false" @click="ui.selectImage=true" text>Select Image</v-btn>
                          <template v-else>
                              <Browser v-model="editing.record.story.image[language]"/>
                              <v-btn @click="ui.selectImage=false">Close Asset Browser</v-btn>
                          </template>
                          <h3>Story</h3>
                          <TinyEditor v-model="editing.record.story.content[language]" :init="tinyConfig.init" :api-key="tinyConfig.apiKey"/>
                      </v-card-text>
                  </v-card>
              </v-tab-item>
          </v-tabs-items>          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeEditor">Cancel</v-btn>
          <v-btn @click="save">Save</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import TinyEditor from '@tinymce/tinymce-vue'
import Browser from '@/components/Assets/Manager'
export default {
    name: 'StoryEditor',    
    created: async function(){
        this.tinyConfig.apiKey = process.env.VUE_APP_TINYMCE_API_KEY
        let config = JSON.stringify(this._config)
        config = JSON.parse(config)
        if(!config){
            config = {
                stories: [],
                cycle: true,
                interval: 6000,
                storiesPath: null
            }
        }
        await this.getStories()
        this.config = config
        let self = this
        setTimeout(() => {
            self.ready = true
        }, 800);
    },
    components: {
        draggable,
        TinyEditor,
        Browser
    },
    data: function(){
        return {
            ready: false,
            tinyConfig: {
                init: {
                    selector: 'textarea',
                    plugins: 'code fullscreen anchor autolink charmap codesample emoticons image media link lists searchreplace table visualblocks wordcount',
                    toolbar: 'customExitButton | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | fullscreen | code',
                    height: "800"
                },
                apiKey: null
            },
            ui: {
                activeTab: 0,
                selectImage: false,
                editStory: false
            },
            config: null,
            editing: null,
            stories: []
        }
    },
    methods: {
        getStories: async function(){
            this.stories = []
            let response = await this.sendRequest('get','/api/stories')
            if(response.status==200){
                this.$store.commit('stories',response.data)
                this.stories = response.data
            }
        },
        selectImage: function(data){
            navigator.clipboard.writeText(`${this.$store.getters.bucket}/${data.Key}`);
            setTimeout(function(){
                alert('Copied to clipboard')
            },200)            
        },
        closeEditor: function(){
            this.editing = null
            this.ui.editStory = false
        },
        storyTemplate: function(){
            let languages = this.languages
            function blank(languages){
                let output = {}
                for(let i=0; i<languages.length; i++){
                    let language = languages[i]
                    output[language] = null
                }
                return output
            }
            return {
                title: blank(languages),
                subtitle: blank(languages),
                content: blank(languages),
                quote: blank(languages),
                image: blank(languages),
                theme: 'darkBlue'
            }
        },
        addStory: function(){
            let story = this.storyTemplate()
            this.stories.push({
              public: "Y",
              publish: "N",
              story
            })
            this.editStory(this.stories.length-1)
        },
        removeStory: function(index){
            if(confirm('Confirm Delete')){
                if(this.editing.index == index){
                    this.closeEditor()
                }
                this.config.stories.splice(index,1)
            }
        },
        editStory: function(index){
            let record = this.stories[index]
            record = JSON.stringify(record)
            this.editing = {
              record: JSON.parse(record),
              index
            }
            this.ui.editStory = true
        },
        saveStories: async function(){
            let stories = this.stories
            let response = await this.sendRequest('put','/api/componentConfigurations',stories)
        },
        save: async function(){
          let record = this.editing.record
          let response
          if(record.id){
            response = await this.sendRequest('patch',`/api/stories/${record.id}`, record)
          }else{
            response = await this.sendRequest('put','/api/stories', record)
          }

          if(response.status==200){
            this.stories[this.editing.index] = this.editing.record
            await this.getStories()
            this.closeEditor()
          }else{
            alert('Error saving')
          }
        }
    },
    computed: {
        language: function(){
          return this.$store.getters.language
        },
        languages: function(){
            return this.$store.getters.languages
        },
        _config: function(){
            return this.$attrs.value ? this.$attrs.value : {}
        }
    },
    watch: {
        config: {
            deep: true,
            handler: function(){
                let ready = this.ready
                if(ready){
                    let config =  this.config 
                    this.$emit('input',config)
                }

            }
        }
    }    
}
</script>

<style scope lang="scss">
.published{
  background-color: none;
}
.unpublished{
  background-color: lightgray;
}
.unpublished .v-list-item__title, .unpublished .v-list-item__subtitle{
  color: grey !important;
}
</style>