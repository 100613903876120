<template>
    <v-list>
        <v-list-item v-if="showSelf" @click="$router.push({path:`${navItem.path}`})">
            <router-link v-if="navItem.path" :to="`${navItem.path}`">
                {{navItem.label[language]}}
            </router-link>
        </v-list-item>

        <template v-for="(child, childIndex) in navItem.children">
            <v-menu nudge-right="300" :key="`navChildItem_${childIndex}`" :min-width="300" :max-width="300">
                <template v-slot:activator="{on, attrs}">
                        <v-list-item v-bind="attrs" v-on="on" @click="navigate(child)">
                                <v-list-item-subtitle style='white-space:initial;'>
                                    <router-link v-if="child.path && !hasChildren(child)" :to="`${child.path}`">
                                        {{child.label[language]}}
                                    </router-link>
                                    <template v-else>
                                        {{child.label[language]}}
                                    </template>
                                </v-list-item-subtitle>
                                <v-list-item-action>
                                    <v-icon v-if="hasChildren(child)">
                                        mdi-chevron-right
                                    </v-icon>
                                </v-list-item-action>
                        </v-list-item>
                </template>
                <v-list dense v-if="child.path && hasChildren(child)">
                    <v-list-item @click="navigate(child, true)">
                        <router-link :to="`${child.path}`">
                            {{child.label[language]}}
                        </router-link>                        
                    </v-list-item>
                </v-list>
                <childNode v-if="hasChildren(child)" @navigated="$emit('navigated')" :showSelf="false" :language="language" :navItem="child" :hasChildren="hasChildren"/>
            </v-menu>
        </template>
    </v-list>
</template>

<script>
import childNode from './linkNode.vue'
export default {
    name: 'childNode',
    props: {
        showSelf: {
            type: Boolean,
            required: false,
            default: true
        },
        navItem: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        hasChildren: {
            type: Function,
            required: true
        }
    },
    methods: {
        navigate: function(item, isHeader=false){
            if(isHeader || (item.path && !this.hasChildren(item))){
                this.$router.push({path:`${item.path}`})
                this.$emit('navigated')
            }
        }
    },
    components: {
        childNode
    }
}
</script>

<style scoped lang="scss">
a{
    text-decoration: none;
    color: rgba(0,0,0,.6) !important;
}
</style>
