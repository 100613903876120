<template>
  <div>
    <component :is="component" v-model="model" @ok="$emit('ok')"/>
  </div>
</template>

<script>
export default {
    name: 'Editor',
    created: function(){
        let model = JSON.stringify(this._model)
        this.model = JSON.parse(model)
    },
    props: {
        component: {
            type: Object,
            required: true
        }
    },
    data: function(){
        return {
            model: null
        }
    },
    computed: {
        _model: function(){
            return this.$attrs.value
        }
    },
    watch: {
        model: {
            deep: true,
            handler: function(){
                let model = this.model
                this.$emit('input',model)
            }
        }
    }
}
</script>

<style>

</style>