<template>
  <div>
    <!-- <v-select v-model="form.align.x" :label="'X-Align'" :items="xAlignOptions"/>
    <v-select v-model="form.align.y" :label="'Y-Align'" :items="yAlignOptions"/> -->
    <AlignmentForm v-model="form.align"/>
    <v-select v-model="form.justify" :items="[{text:'left',value:'left'}, {text:'center',value:'center'}, {text:'right',value:'right'}]" label="Justfiy Text"/>
    <Browser @selectAsset="selectImage"/>
    <v-container>
      <v-row>
        <v-col>
          <v-tabs v-model="ui.activeTab">
            <v-tab v-for="language in languages" :key="`html-language-tab-${language}`">
              {{language}}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="ui.activeTab">
            <template v-for="(language) in languages">
              <v-tab-item :key="`html-language-tab-item-${language}`">
                <TinyEditor v-model="form.html[language]" :init="tinyConfig.init" :api-key="tinyConfig.apiKey"/>
              </v-tab-item>
            </template>
          </v-tabs-items>
          <!-- {{language}}
          <v-textarea v-model="form.html[language]" label="HTML Text"/> -->
          
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AlignmentForm from './alignment.vue'
import TinyEditor from '@tinymce/tinymce-vue'
import Browser from '@/components/Assets/Browser'
export default {
  name: "HTMLTextForm",
  created: function(){
    if(Object.keys(this.form)==0){
      this.initForm()
    }
    this.tinyConfig.apiKey = process.env.VUE_APP_TINYMCE_API_KEY
  },
  components: {
    AlignmentForm,
    TinyEditor,
    Browser
  },
  props: {
    languages: {
      type: Array,
      required: true
    }
  },
  data: function(){
    return {
      ui: {
        activeTab: 0
      },
      tinyConfig: {
          init: {
              selector: 'textarea',
              plugins: 'code fullscreen anchor autolink charmap codesample emoticons image media link lists searchreplace table visualblocks wordcount',
              toolbar: 'customExitButton | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | fullscreen | code',
              height: "800",
              setup: function(editor){
                  // editor.ui.registry.addButton('customExitButton', {
                  //     text: 'Exit',
                  //     onAction: function (_) {
                  //         GuideEditor.exit()
                  //     }
                  // });
                  // editor.ui.registry.addButton('customSaveButton', {
                  //     text: 'Save',
                  //     onAction: function (_) {
                  //         GuideEditor.save()
                  //     }
                  // });
                  // editor.ui.registry.addButton('customPreviewButton', {
                  //     text: 'Preview',
                  //     onAction: function (_) {  
                  //         // editor.execCommand('mceFullScreen');
                  //         GuideEditor.state.preview = true
                  //     }
                  // });
                  // editor.ui.registry.addButton('customGuideDetails', {
                  //     text: 'Guide Details',
                  //     onAction: function (_) {  
                  //         // editor.execCommand('mceFullScreen');
                  //         GuideEditor.state.guideDetails = true
                  //     }
                  // });
                  // editor.ui.registry.addButton('customSelectTopicsButton', {
                  //     text: 'Select Topic(s)',
                  //     onAction: function (_) {  
                  //         // editor.execCommand('mceFullScreen');
                  //         GuideEditor.state.selectTopics = true
                  //     }
                  // });
                  
                  editor.on('init', function(e) {
                      // editor.execCommand('mceFullScreen');
                      // if(!GuideEditor.guide.meta.id){
                      //     GuideEditor.state.guideDetails = true
                      // }
                  });                        
              }
          },
          apiKey: null
      },      
    }
  },
  methods: {
    initForm: function(){
      this.form.html = {}
      this.form.justify = 'center'
      this.form.align = {
        x: null,
        y: null
      }
    },
    selectImage: function(data){
        navigator.clipboard.writeText(`${this.$store.getters.bucket}/${data.Key}`);
        setTimeout(function(){
            alert('Copied to clipboard')
        },200)            
    }
  },
  computed: {
    form: function(){
      return this.$attrs.value
    },
    // xAlignOptions: function(){
    //   return [
    //     {text: 'left', value: 'left'},
    //     {text: 'center', value: 'center'},
    //     {text: 'right', value: 'right'},
    //   ]
    // },
    // yAlignOptions: function(){
    //   return [
    //     {text: 'top', value: 'top'},
    //     {text: 'center', value: 'center'},
    //     {text: 'bottom', value: 'bottom'},
    //   ]
    // }
  }
}
</script>

<style>

</style>