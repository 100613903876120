<template>
  <div>
    <div>
        <!-- Manager Path: {{ path }} -->
        <div @click="setView('browser')" style="cursor: pointer; display: inline-block; margin: 15px; position: relative; top: -5px;">Browse</div>
        <v-switch style="display: inline-block; positon:" v-model="ui.view" true-value="uploader" false-value="browser"></v-switch>
        <div @click="setView('uploader')" style="cursor: pointer; display: inline-block; margin: 15px; position: relative; top: -5px;">Upload</div>
        <Browser v-if="ui.view=='browser'" v-model="form" @selectAsset="selectAsset" @setPath="setPath" :initPath="path"/>
        <Uploader v-if="ui.view=='uploader'" @selectAsset="selectAsset" @setPath="setPath" :initPath="path"/>
    </div>
  </div>
</template>

<script>
import Browser from './Browser.vue'
import Uploader from './Uploader.vue'
export default {
    name: "AssetManager",
    components: {
        Browser,
        Uploader
    },
    created: function(){
        let form = JSON.stringify(this.model)
        this.form = JSON.parse(form)
        this.getSiteDefinition()

    },
    data: ()=>{
        return {
            ui: {
                view: "browser"
            },
            form: {},
            path: [0],
            siteDefinition: null
        }
    },
    methods: {
        getSiteDefinition: async function(){
            let response = await this.sendRequest('get','/api/admin/getSiteDefinition')
            if(response.status==200){
                let definitions = response.data
                this.siteDefinition = definitions
            }
        },        
        setView(view){
            this.ui.view = view
        },
        selectAsset(data){
            this.$emit('selectAsset',data)
        },
        setPath(data){
            console.log('manager > setPath',data)
            this.path = data
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value ? this.$attrs.value : {}
        }
    },
    watch: {
        form: {
            deep: true,
            handler: function(){
                this.$emit('input',this.form)
            }
        }
    }
}
</script>

<style>

</style>