<template>
<v-container v-if="$store.getters.siteBlocker" style="margin-top: 20%;">
    <v-row align="center">
        <v-col align="center" cols="12">
            <v-card>
                <v-card-title>
                    Please enter site password
                </v-card-title>
                <v-card-text>
                    <v-text-field type="password" outlined label="Password" v-model="form.password" @keydown.enter="checkPassword"/>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                        <v-btn class="themed" color="primary" :disabled="form.password===null || form.password.length==0" @click="checkPassword">
                            Submit
                        </v-btn>
                    <v-spacer/>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import cache from '@/plugins/cache.js'

export default {
    created: function(){
        // let siteBlocker = cache.storage.local.get('siteBlocker')
        // if((siteBlocker===null || siteBlocker===true) && !['local'].includes(process.env.VUE_APP_ENV)){
        //     this.$store.commit('siteBlocker',true)
        // }
        this.init()
    },
    data: function(){
        return {
            form: {
                password: null
            },
            code: 'TCsandbox2@',
            pass: false
        }
    },
    methods: {
        init: async function(){
            let blockResponse = await this.sendRequest('get','/api/siteBlocker')
            let serverBlock = blockResponse.status == 200 ? blockResponse.data : true
            let localBlock = cache.storage.local.get('siteBlocker')
            let pass = !serverBlock || (localBlock!=null && !localBlock)
            this.$store.commit('siteBlocker',!pass)
        },
        checkPassword: function(){
            this.pass = this.form.password!=null && this.form.password.length>0 && this.form.password==this.code
            this.$store.commit('siteBlocker', !this.pass)
            cache.storage.local.set('siteBlocker', !this.pass)
        }
    }
}
</script>

<style>

</style>