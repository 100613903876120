<template>
  <div>
    <v-btn small @click="addChild">Add Child</v-btn> | <v-btn small @click="remove">Remove</v-btn>
    <v-text-field v-model="config.label[language]" label="Name"/>
    <v-text-field v-model="config.path" label="Path"/>

        <v-expansion-panels>
            <draggable v-model="config.children" style="width:100%;">
                <v-expansion-panel v-for="(navButton, navButtonIndex) in config.children" :key="navButtonIndex">
                    <v-expansion-panel-header>
                        {{navButton.label[language]}}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <ChildNode :nodeIndex="navButtonIndex" :linkTemplate="linkTemplate" @removeChild="removeChild" v-model="config.children[navButtonIndex]"/>
                        <!-- <v-text-field v-model="navButton.path" label="Path"/>
                        <div v-for="language in $store.getters.languages" :key="'navBar_'+navButtonIndex+'_'+language">
                            <h3>{{language}}</h3>
                            <v-text-field v-model="navButton.label[language]" label="Label"/>
                        </div>
                        <v-btn @click="remove(navButtonIndex)">Remove</v-btn> -->
                    </v-expansion-panel-content>
                </v-expansion-panel>            
            </draggable>   
        </v-expansion-panels>

  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ChildNode from './editNavBar_Node.vue'
export default {
    name: "ChildNode",
    props: {
        linkTemplate: {
            type: Function,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        nodeIndex: {
            type: Number,
            required: true
        }
    },
    created: function(){
        let config = this.config
        if(!config.children){
            this.$set(config,'children',[])
        }

    },
    components: {
        draggable,
        ChildNode
    },
    methods: {
        addChild: function(){
            let template = JSON.stringify(this.linkTemplate())
            template = JSON.parse(template)
            this.config.children.push(template)
        },
        remove: function(){
            this.$emit('removeChild',this.nodeIndex)
        },
        removeChild: function(index){
            this.config.children.splice(index,1)
        }
    },
    computed: {
        config: function(){
            return this.$attrs.value
        }
    }
}
</script>

<style>

</style>