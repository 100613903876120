<template>
  <div>
    <v-switch v-model="config.expanded" :true-value="true" :false-value="false" label="Expanded"/>
    <v-switch v-model="config.bilingual" :true-value="true" :false-value="false" label="Bilingual"/>
    <template>
        <v-select v-if="this.nonVideoResourceSelected==true" v-model="config.displayStyle" label="List Style" :items="[{text:'default',value:'card'},{text:'List',value:'list'}]"/>
        <v-select v-else v-model="config.displayStyle" label="List Style" :items="[{text:'default',value:'card'},{text:'List',value:'list'},{text:'Video grid',value:'videoGrid'}]"/>
    </template>
    <v-select v-model="config.sorting" :items="[{text:'newest',value:'newest'},{text:'manual',value:'manual'},{text:'title',value:'title'}]" label="Sorting"/>

    <v-container>
        <v-row>
            <v-col v-for="language in languages" :key="`header-config-${language}`">
                {{language}}
                <v-text-field v-model="config.defaultHeaderImage[language]" outlined label="Default Header Image" clearable/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-switch v-model="ui.selectAsset" :true-value="true" :false-value="false" label="Select Default Header"/>
            </v-col>
        </v-row>
        <v-row v-if="ui.selectAsset">
            <v-col v-for="language in languages" :key="`header-config-${language}`">
                <!-- {{language}} -->
                <!-- <v-text-field v-model="config.defaultHeaderImage[language]" outlined label="Default Header Image" clearable/> -->

                <p style='text-decoration:underline;'>Image Sizing Recommendations</p>
                <v-expansion-panels accordion>
                    <v-expansion-panel>
                        <v-expansion-panel-header>For Default Configuration</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <br>
                            <p><span style='margin-right:16px;'><strong>For Ratio-1:</strong></span> <span style='margin-right:16px;'><strong>Image ratio rule:</strong> W 1.77x H</span> <strong>Rendered aspect ratio:</strong> 133:75</p>
                            <p><span style='margin-right:16px;'><strong>For Ratio-1-3:</strong></span> <span style='margin-right:16px;'><strong>Image ratio rule:</strong> W 1.32x H</span> <strong>Rendered aspect ratio:</strong> 298:225</p>
                            <p><span style='margin-right:16px;'><strong>For Ratio-1-2:</strong></span> <span style='margin-right:16px;'><strong>Image ratio rule:</strong> W 1.82x H</span> <strong>Rendered aspect ratio:</strong> 409:225</p>
                            <p><span style='margin-right:16px;'><strong>For Ratio-1-1:</strong></span> <span style='margin-right:16px;'><strong>Image ratio rule:</strong> W 2.87x H</span> <strong>Rendered aspect ratio:</strong> 646:225</p>
                            <p><span style='margin-right:16px;'><strong>For Ratio-2-1:</strong></span> <span style='margin-right:16px;'><strong>Image ratio rule:</strong> W 3.86x H</span> <strong>Rendered aspect ratio:</strong> 869:225</p>
                            <p><span style='margin-right:16px;'><strong>For Ratio-3-1:</strong></span> <span style='margin-right:16px;'><strong>Image ratio rule:</strong> W 4.42x H</span> <strong>Rendered aspect ratio:</strong> 994:225</p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>For List Configuration</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <br>
                            <p><span style='margin-right:16px;'><span style='margin-right:16px;'><strong>Image ratio rule:</strong> W 1.35x H</span> <strong>Rendered aspect ratio:</strong> 269:199</span></p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <Browser v-model="defaultHeaderImage.asset" @selectAsset="defaultHeaderImage.language=language"/>
            </v-col>
        </v-row>
    </v-container>


    <v-container fluid>
        <!-- <v-row>
            <v-col v-for="language in languages" :key="`resources-language-${language}`">
                <v-text-field v-model="config.title[language]" label="Section Title"/>
            </v-col>
        </v-row> -->
        <v-row>
            <v-col>
                <strong>Uploaded Resources</strong>
                <hr>
                <ResourceFilter :resourceTypes="_resourceTypes" :categories="_categories" @update="updateFilter"/>
                <v-list dense>
                    <v-list-item v-for="resource in _filteredResources" :key="`resource-selector-${resource.id}`">
                        <v-list-item-avatar>
                            <v-icon v-if="resource.definition.type=='link'">
                                mdi-link
                            </v-icon>
                            <v-icon v-else-if="resource.definition.type=='video'">
                                mdi-file-video
                            </v-icon>
                            <v-icon v-else>
                                mdi-file
                            </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{resource.definition.title[language]}}
                            </v-list-item-title>
                                <v-list-item-subtitle>
                                    <sub>ID: {{ resource.id }}</sub> - {{resource.definition.categories}}
                                </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn small icon @click="addResource(resource)">
                                <v-icon>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-col>
            <v-col>
                <strong>Selected Resources</strong>
                <hr>
                <v-list dense>
                    <draggable v-model="config.resources">
                        <v-list-item v-for="(resource, resourceIndex) in config.resources" :key="`resource-item-${resourceIndex}-${resource.id}`">
                            <v-list-item-avatar>
                                <v-icon v-if="resource.definition.type=='link'">
                                    mdi-link
                                </v-icon>
                                <v-icon v-else-if="resource.definition.type=='video'">
                                    mdi-file-video
                                </v-icon>
                                <v-icon v-else>
                                    mdi-file
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{resource.definition.title[language]}}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <sub>ID: {{ resource.id }}</sub> - {{resource.definition.categories}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <div>
                                    <v-btn small icon @click="customHeader(resource)">
                                        <v-icon>
                                            mdi-panorama-outline
                                        </v-icon>
                                    </v-btn>
                                    <v-btn small icon @click="removeResource(resourceIndex)">
                                        <v-icon>
                                            mdi-minus
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                    </draggable>
                </v-list>
            </v-col>
        </v-row>
    </v-container>

    <!-- <center>
        <v-btn color="success" @click="$emit('ok')">
            Ok
        </v-btn>
    </center> -->

    <v-dialog v-model="ui.headerImage">
        <v-card v-if="ui.headerImage">
            <v-card-title>
                Override Default Image
            </v-card-title>
            <v-card-subtitle>

            </v-card-subtitle>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col v-for="language in languages" :key="`custom-header-${language}`">
                            <h3 align="center">{{language}}</h3>
                            <v-text-field v-model="editing.definition.headerImage[language]" outlined label="Custom Header Image" clearable/>
                            <Browser v-model="headerImage.asset" @selectAsset="headerImage.language=language"/>
                        </v-col>
                    </v-row>
                </v-container>
                <!-- <json-viewer :value="editing.definition"/> -->
            </v-card-text>
            <v-card-actions>
                <v-btn @click="ui.headerImage = false" text>Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Browser from '@/components/Assets/Manager'
import ResourceFilter from '@/components/Resources/filters'
export default {
    components: {
        draggable,
        Browser,
        ResourceFilter
    },
    created: function(){
        if(!this.config.title){
            this.config.title = {}
        }
        if(this.config.expanded==undefined){
            this.config.expanded = false
        }
        if(this.config.bilingual==undefined){
            this.config.bilingual = false
        }
        if(this.config.displayStyle==undefined){
            this.config.displayStyle = false
        }
        if(this.config.sorting==undefined){
            this.config.sorting = 'newest'
        }
        if(this.config.recent==undefined){
            this.config.recent = 3
        }
        if(this.config.defaultHeaderImage==undefined){
            this.config.defaultHeaderImage = {}
        }

        if(!this.config.resources){
            this.$set(this.config,'resources',[])
        }
        this.getData()

        this.isNonVideoResourceSelected()
    },
    name: "ResourceSelector",
    data: function(){
        return {
            ui: {
                headerImage: false,
                selectAsset: false
            },
            resource: null,
            resources: [],
            defaultHeaderImage: {
                asset: null,
                language: null
            },
            headerImage: {
                asset: null,
                language: null
            },
            editing: null,
            filter: null,
            nonVideoResourceSelected: false
        }
    },
    methods: {
        hasLanguage: function(record, lang) {
            let title = record.definition.title[lang]
            let asset = record.definition.resource.asset[lang]

            let test = [
                title && title.length>0 ? true : false,
                asset && asset.length>0 ? true : false
            ]
            
            return !test.includes(false)
        },
        updateFilter: function(data){
            this.filter = data
        },
        getData: async function(){
            let response = await this.sendRequest('get','/api/admin/resources')
            this.resources = response.data
        },
        addResource: function(resource){
            function template(languages){
                let output = {}
                for(let i=0; i<languages.length; i++){
                    let language = languages[i]
                    output[language] = null
                }
                return output
            }
            this.config.resources.push(resource)
            // this.resource = template(this.languages)
            this.isNonVideoResourceSelected()
        },
        removeResource: function(index){
            this.config.resources.splice(index, 1)
            this.isNonVideoResourceSelected()
        },
        customHeader: function(resource){
            console.log('customHeader',resource)
            if(resource.definition.headerImage==undefined){
                resource.definition.headerImage = {}
            }
            this.editing = resource
            this.ui.headerImage = true
        },
        isNonVideoResourceSelected: function(){
            for(let i = 0; i < this.config.resources.length; i++){
                let item = this.config.resources[i];
                // IS: found resource category that is NOT video
                if( item.definition.categories.includes('Videos') == false){
                    // SET: flag TRUE
                    this.nonVideoResourceSelected = true;
                    // SET: displayStyle to default card
                    this.config.displayStyle = 'card';
                    return;
                }
                else{
                    // SET: flag FALSE
                    this.nonVideoResourceSelected = false;

                }
            }
        }
    },
    computed: {
        config: function(){
            return this.$attrs.value
        },
        language: function(){
            return this.$store.getters.language
        },
        languages: function(){
            return this.$store.getters.languages
        },
        _resourceTypes: function(){
            return [
                {
                    text: 'Link',
                    value: 'link'
                },
                {
                    text: 'Video',
                    value: 'video'
                },
                {
                    text: 'Document',
                    value: 'document'
                }
            ]
        },
        _categories: function(){
            let output = []
            let categories = this.$store.getters.componentConfigurations.resources.config.categories
            if(categories){
                for(let key in categories){
                    output.push({
                        text: key,
                        value: key
                    })
                }
            }

            return output
        },
        _filteredResources: function(){
            let data = this.resources //this.config.resources
            let output = JSON.stringify(data)
            let language = this.language
            output = JSON.parse(output)

            let filter = this.filter

            function compareTitle(a,b){
                const titleA = a.definition.title[language]
                const titleB = b.definition.title[language]
                if (titleA < titleB) {
                    return -1;
                }
                if (titleA > titleB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }

            function compareDate(a,b){
                const updatedA = new Date(a.updated)
                const updatedB = new Date(b.updated)
                if (updatedA < updatedB) {
                    return 1;
                }
                if (updatedA > updatedB) {
                    return -1;
                }

                // names must be equal
                return 0;
            }

            if(filter){
                if(filter.language.length>0){                    
                    let languageResults = output.filter((item)=>{
                        for(let i=0; i<filter.language.length; i++){
                            let language = filter.language[i]
                            if(!self.hasLanguage(item, language)){
                                return false
                            }
                        }
                        return true
                    })
                    output = languageResults
                }

                if(filter.publish!=null){
                    let searchPublishResults = output.filter((item)=>{return item.publish==filter.publish})
                    output = searchPublishResults
                }

                if(filter.enabled!=null){
                    let searchEnabledResults = output.filter((item)=>{return item.enabled==filter.enabled})
                    output = searchEnabledResults
                }

                if(filter.string!=null && filter.string.length>0){
                    let searchString = filter.string.toLowerCase()
                    let stringSearchResults = output.filter((item)=>{
                        return item.definition.title[language].toLowerCase().indexOf(searchString)>=0
                    })
                    output = stringSearchResults
                }

                if(filter.type){
                    let typeResults = output.filter((item)=>{
                        return item.definition.type == filter.type
                    })
                    output = typeResults
                }

                if(filter.categories.length>0){
                    let categoryResults = []
                    for(let i=0; i<output.length; i++){
                        let item = output[i]
                        for(let ii=0; ii<filter.categories.length; ii++){
                            let category = filter.categories[ii]
                            if(item.definition.categories.includes(category)){
                                categoryResults.push(item)
                            }
                        }
                    }
                    output = categoryResults
                }


                if(filter.sort=='title'){
                    output.sort(compareTitle)
                }
                if(filter.sort=='updated'){
                    output.sort(compareDate)
                }
            }

            return output
        }
    },
    watch: {
        defaultHeaderImage: {
            deep: true,
            handler: function(){
                let language = this.defaultHeaderImage.language
                let asset = this.defaultHeaderImage.asset
                if(language && asset){
                    this.config.defaultHeaderImage[language] = asset.Key
                }
            }
        },
        headerImage: {
            deep: true,
            handler: function(){
                let language = this.headerImage.language
                let asset = this.headerImage.asset
                if(language && asset){
                    this.editing.definition.headerImage[language] = asset.Key
                }
            }
        }
    }
}
</script>

<style>

</style>
