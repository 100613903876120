<template>
  <div>
    <v-select v-model="config.background" :items="_backgrounds" label="Background color"/>
    <v-select v-model="config.layout" :items="_layoutItems" label="Layout"/>
    <div v-for="language in $store.getters.languages" :key="'textBlock-'+language">
        <h3>{{language}}</h3>
        <v-text-field v-model="config.title[language]" label="Title"/>
        <v-textarea v-model="config.content[language]" label="Content"/>
        <v-text-field v-model="config.button[language]" label="Button text"/>
        <v-text-field v-model="config.media[language]" label="Media"/>
    </div>
    <v-btn @click="save">Save</v-btn>
  </div>
</template>

<script>
export default {
    created: function(){
        let config = JSON.stringify(this.model ? this.model : this.template())
        config = JSON.parse(config)
        this.config = config
    },
    data: function(){
        return {
            config: {
                layout: null,
                title: {},
                content: {},
                button: {},
                media: {}
            }
        }
    },
    methods: {
        template: function(){
            return {
                layout: null,
                background: null,
                title: {},
                content: {},
                button: {},
                media: {}
            }
        },
        save: function(){
            this.$emit('input',this.config)
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        },
        _backgrounds: function(){
            let output = [
                {
                    text: 'White',
                    value: 'white'
                },
                {
                    text: 'Darkblue',
                    value: 'theme-darkblue'
                }
            ]

            return output
        },
        _layoutItems: function(){
            let output = [
                {
                    text: 'media-left | text-right',
                    value: 'media-left_text-right'
                },
                {
                    text: 'media-right | text-left',
                    value: 'media-right_text-left'
                }
            ]

            return output
        }
    }
}
</script>

<style>

</style>