<template>
  <div>
    <v-switch v-model="config.inherit" :true-value="true" :false-value="false" label="Inherit"/>
    <!-- <NavBar :site="site" :page="structure.page" :language="language" :config="config"/> -->

    <div v-if="!config.inherit">
        <v-btn class="themed-button-dark" @click="add">Add Link</v-btn>
        <v-expansion-panels v-model="ui.panels" style="margin: 15px;">
            <draggable v-model="config.links" :disabled="ui.panels!=undefined" style="width:100%;">
                <v-expansion-panel v-for="(navButton, navButtonIndex) in config.links" :key="navButtonIndex">
                    <v-expansion-panel-header>
                        {{navButton.label[language]}}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <Node :nodeIndex="navButtonIndex" @removeChild="remove" :linkTemplate="template" v-model="config.links[navButtonIndex]"/>
                        <!-- <v-text-field v-model="navButton.path" label="Path"/>
                        <div v-for="language in $store.getters.languages" :key="'navBar_'+navButtonIndex+'_'+language">
                            <h3>{{language}}</h3>
                            <v-text-field v-model="navButton.label[language]" label="Label"/>
                        </div>
                        <v-btn @click="remove(navButtonIndex)">Remove</v-btn> -->
                    </v-expansion-panel-content>
                </v-expansion-panel>            
            </draggable>   
        </v-expansion-panels>

        <!-- <draggable v-model="config.links" style="width:100%;">
            <Node v-for="(link, linkIndex) in config.links" :linkTemplate="template" :key="linkIndex" v-model="config.links[linkIndex]"/>
        </draggable> -->
    </div>


    <!-- <v-btn @click="save" icon>
        <v-icon>mdi-content-save</v-icon>
    </v-btn> -->

  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Node from './editNavBar_Node.vue'
export default {
    name: "editNavBar",
    props: {
        site: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        structure: {
            type: Object,
            required: true
        }
    },
    components: {
        draggable,
        Node
    },
    created: function(){
        let config = JSON.stringify(this.model)
        if(config){
            config = JSON.parse(config)            
        }
        if(!config.links){
            config = {
                inherit: true,
                links: []
            }
        }

        this.config = config
        this.save()
    },
    data: function(){
        return {
            ui: {
                panels: undefined
            },
            config: {
                inherit: true,
                links: []
            }
        }
    },
    methods: {
        template: function(){
            return {
                path: null,
                label: {},
                children: []
            }
        },
        save: function(){
            this.$emit('input',this.config)
        },
        add: function(){
            this.config.links.push(this.template())
        },
        remove: function(index){
            this.config.links.splice(index, 1)
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        }
    },
    watch: {
        config: {
            deep: true,
            handler: function(){
                this.save()
                this.$forceUpdate()
            }
        }
    }
}
</script>

<style>

</style>