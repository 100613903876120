<template>
    <v-card :class="[_article.type, 'articleCard']" rounded="0">
      <div class="header">
        <div class="header-background" :style="{'background-image':`URL('${$store.getters.bucket}/${_article.content.image}')`}"/>
        <!-- <v-img :src="`${$store.getters.bucket}/${_article.content.image}`" height="300px"></v-img> -->
        <!-- <v-img :src="`${$store.getters.bucket}/${_article.content.image}`" height="300px"></v-img> -->
        <v-btn icon class="pin" :class="[{'pinned':pins.isPinned(pinKey, _article)}]" @click="pins.pin(pinKey, _article);$forceUpdate()">
          <v-icon color="white">mdi-pin</v-icon>
        </v-btn>

        <div v-if="(_article.type.includes('events') || _article.type.includes('webinars')) && _article.content.displayStartDate" class="event-date-time">
          <div class="event-month">{{_start[1]}}</div>
          <div class="event-date">{{_start[2]}}</div>
          <div class="event-year">{{_start[3]}}</div>
        </div>
      </div>

      <v-card-text>
        <div class="content">
          <h3 v-html="_article.content.title[language]" class="title"/>
          <hr class="themed-line">
          <div v-html="_article.content.subtitle[language]" class="subtitle"/>
        </div>
      </v-card-text>

        <v-btn @click="$emit('readMore',params)" class="themed-button-dark" elevation="0">
          Read More
        </v-btn>
  </v-card>
</template>

<script>
import pinsController from '@/plugins/pins'
export default {
  props: {
    params: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  created: function(){
      // this.pins = new pinsController('articles', this)
      this.pins = this.$store.getters.controllers.pins
  },
  data: function(){
    return {
      pins: null,
      pinKey: 'articles'
    }
  },
  computed: {
    _article: function(){
      return this.params
    },
    _start: function(){
      let t = this._article.content.start ? new Date(this._article.content.start).toString() : null
      if(t){
        let tArray = t.split(' ')
        // return `${tArray[1]} ${tArray[2]}`
        return tArray
      }
      return null
    },
    _end: function(){
      let t = this._article.content.end ? new Date(this._article.content.end).toString() : null
      if(t){
        let tArray = t.split(' ')
        // return `${tArray[1]} ${tArray[2]}`
        return tArray
      }
      return null
    }
  }
}
</script>

<style scoped lang="scss">

.articleCard {
    width: 360px;
    height: 640px;
    margin: 24px 8px;
    padding: 48px 0 56px;

    @media(max-width: 959px){
        width: 100%;
    }
    @media(max-width: 599px){
        width: 500px;
    }
    @media(max-width: 500px){
        width: 360px;
    }
    @media(max-width: 415px){
        width: 320px;
    }

    .v-btn {
        position: absolute;
        bottom: 16px;
    }
}


.header{
  position: relative;
}

.header-background{
  width: 100%;
  height: 300px;
  background-size: contain;
  background-position: center;
}

.pin{
  position: absolute;
  top: -40px;
  right: 8px;
  background-color: $primary-1;
}

.pinned{
  background-color: $tc-red;
}

.header .event-date-time{
  position: absolute;
  bottom: 20px;
  left: 20px;
  height: 100px;
  width: 80px;
  background-color: $primary-1;
  font-weight: bold;
  text-align: center;
  color: white;
  border-radius: 3px;
}

.articleCard .content{
  color: $primary-1;
  padding: 25px 25px 0px 25px;
}

.title{
  line-height: 22px !important;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;

  display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.themed-line{
  border-color: $accent-light-1;
  width: 100px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.articleCard button.themed-button-dark{
    left: 50%;
    transform: translate(-50%,0);
    font-weight: bold;
    font-size: 1.2em;
}

.event-month{
  display: block;
  font-weight: bold;
  font-size: 1em;
}
.event-date{
  display: block;
  font-weight: bold;
  font-size: 2em;
}
</style>
