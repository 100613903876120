<template>
  <div>
    <div v-if="story">
      <v-container fluid>
        <v-row>
          <v-col cols="3">
            Patient Profile: <h2 v-html="story.title[language]"/>
            <v-img :src="`${$store.getters.bucket}/${story.image[language].Key}`"/>
          </v-col>
          <v-col>
            <h3 v-html="story.subtitle[language]"/>
            <div v-html="story.content[language]"/>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else style="display:flex; flex-wrap:wrap; justify-content:center;">
      <template v-for="record in storiesMap">
        <v-card :key="`story-id-${record.id}`" v-if="record.publish=='Y'" width="300" height="560" style="margin: 15px; display: inline-block;">
          <!-- <v-img height="250" :src="`${$store.getters.bucket}/${record.story.image[language].Key}`"/> -->
          <!-- "{'background-image':`URL('${$store.getters.bucket}/${_article.content.image}')`}" -->
          <div style="width:100%; height: 270px; background-size: cover;" :style="{'background-image':`URL('${$store.getters.bucket}/${record.story.image[language].Key}'`}"/>
          <v-card-title>
            {{record.story.title[language]}}
          </v-card-title>
          <hr>
          <v-card-subtitle style="height: 120px; overflow: hidden;">
            {{record.story.subtitle[language]}}
            <template v-if="record.story.quote[language] && record.story.quote[language].length>0">
            {{record.story.quote[language]}}
            </template>
          </v-card-subtitle>
  
          <v-card-actions>
            <v-spacer/>
            <v-btn class="themed-button-dark" @click="$router.push({path:`/patients_and_caregivers/contribute/stories/${record.id}`})">
              Read More
            </v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>    
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "StoryViewer",
  created: function(){
    let story_id = this.story_id
  },
  methods: {
    allow: function(record){
      return record && (record.publish=='Y' || this.$store.getters.user)
    }
  },
  computed: {
    storiesMap: function(){
      let output = {}
      let stories = this.$store.getters.stories
      for(let i=0; i<stories.length; i++){
        let story = stories[i]
        output[story.id] = story
      }
      return output
    },
    story: function(){
      let storiesMap = this.storiesMap
      let story_id = this.story_id
      let record = storiesMap && story_id ? storiesMap[story_id] : false
      return this.allow(record) ? record.story : false
    },
    story_id: function(){
      return this.$route.params.story_id ? parseInt(this.$route.params.story_id,10) : false
    },
    language: function(){
      return this.$store.getters.language
    }
  }

}
</script>

<style>

</style>
