<template>
  <div>
    <div :class="alignmentClasses">
        <div class='textblock-content' :class="justifyClasses" v-html="content"/>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        config: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    computed: {
        content: function(){
            let language = this.language
            return this.config.html[language]
        },
        alignmentClasses: function(){            
            let output = []
            let align = this.config.align
            for(let axis in align){
                let position = align[axis]
                output.push(`${axis}-align-${position}`)
            }

            return output
        },
        justifyClasses: function(){            
            let output = []
            output.push(`justify-${this.config.justify}`)

            return output
        }
    }
}
</script>

<style scoped lang="scss">
.x-align-left{
    position: absolute;
    left: 0px
}
.x-align-center{
    width: 100%;
    text-align: center;
}
.x-align-right{
    position: absolute;
    right: 0px;
}

.y-align-top{
    position: absolute;
    top: 0px
}
.y-align-center{
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}
.y-align-bottom{
    position: absolute;
    bottom: 0px;
}

.textblock-content{
    text-align: center;
}

.textblock-content.justify-left{
    text-align: left;
}

.textblock-content.justify-right{
    text-align: right;
}
</style>

<style lang="scss">
.textblock-content h3{
    margin: 15px 0px 15px 0px;
}

.textblock-content p{
    margin: 15px;
}
.textblock-content ul{
    list-style: none;
}
.textblock-content ul li::before {
  content: "\2022";
  color: $tc-red;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
  font-size: 1.5em;
  position: relative;
  top: 5px;
}

</style>