<template>
  <div>
    Asset Manager
    <v-switch v-model="ui.upload" :true-value="true" :false-value="false" label="Upload"/>
    <v-container>
        <v-row>
            <v-col v-if="ui.upload">
                <Uploader @refresh="refresh" @selectAsset="selectAsset" @setPath="setPath" :initPath="path"/>
            </v-col>
            <v-col v-else style="padding: 0px;">
                <div v-if="ready">
                    <div>
                        <v-text-field v-model="search" label="Search" :clearable="true"/>
                        <Browser v-model="selection" @setBucket="setBucket" @duplicateSearch="duplicateSearch" @selectAsset="selectAsset" :search="search" @setPath="setPath" :initPath="path"/>                
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
    

    <v-dialog v-model="ui.editAsset">
        <v-card v-if="ui.editAsset && selection">
            <v-card-title>
                Edit Asset
            </v-card-title>
            <v-card-text>
                <div class="preview" v-if="selection" style="max-width: 300px;">
                    <v-img v-if="isImage(selection.Key)" :src="`${bucket}/${selection.Key}`" style="width:100%;"/>
                </div>
                <v-text-field v-model="form.asset.filename" label="File Name"/>
                
                <v-container v-if="usage" fluid>
                    <v-row>
                        <v-col>
                            Pages: {{ usage.pages.length }}
                            <v-list dense>
                                <v-list-item v-for="(page, index) in usage.pages" :key="`${page.key}-${index}`">
                                    <v-list-item-content>
                                        <router-link target="_blank" :to="`/${page.key.replaceAll('.','/').replaceAll('home/','')}`">{{ page.key.replaceAll('.','/').replaceAll('home/','') }}</router-link>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        
                        <v-col>
                            Stories: {{ usage.stories.length }}
                            <v-list dense>
                                <v-list-item v-for="(record, index) in usage.stories" :key="`${record.id}-${index}`">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ record.story.title[language] }}
                                        </v-list-item-title>
                                        <router-link target="_blank" :to="`/patients_and_caregivers/contribute/stories/${record.id}`">{{ `/patients_and_caregivers/contribute/stories/${record.id}` }}</router-link>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        
                        <v-col>
                            Articles: {{ usage.articles.length }}
                            <v-list dense>
                                <v-list-item v-for="(record, index) in usage.articles" :key="`${record.id}-${index}`">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ record.content.title[language] }}
                                        </v-list-item-title>
                                        <router-link target="_blank" :to="`/news_and_events/article/${record.pageName}`">{{ `/news_and_events/article/${record.pageName}` }}</router-link>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>

                        <v-col>
                            Resources: {{ usage.resources.length }}
                            <v-list dense>
                                <v-list-item v-for="(resource, index) in usage.resources" :key="`${resource.id}-${index}`" @click="selectResource(resource)">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ resource.definition.title['en-ca'] }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ resource.definition.subtitle['en-ca'] }}
                                        </v-list-item-subtitle>
                                        Enabled: {{ resource.enabled }}<br>
                                        Public: {{ resource.public }}<br>
                                        <div v-html="resource.definition.description['en-ca']" style="border: 1px solid whitesmoke; padding: 12px; font-size: 0.8rem;"/>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-container>
                <h3 v-else>Not Used</h3>

            </v-card-text>
            <v-card-actions>
                <v-btn @click="closeEdit">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn x-small color="error" @click="deleteAsset">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="saveEdit">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

  </div>
</template>

<script>
import Uploader from '@/components/Assets/Uploader.vue'
import Browser from '@/components/Assets/Browser.vue'
export default {
    components: {
        Uploader,
        Browser
    },
    created: function(){
        this.getSiteDefinition()
    },
    data: function(){
        return {
            ready: true,
            search: null,
            selection: null,
            bucket: null,
            ui: {
                editAsset: false,
                upload: false
            },
            form: {
                asset: {
                    filename: null
                }
            },
            path: [0],
            siteDefinition: null
        }
    },
    methods: {
        getSiteDefinition: async function(){
            let response = await this.sendRequest('get','/api/admin/getSiteDefinition')
            if(response.status==200){
                let definitions = response.data
                this.siteDefinition = definitions
            }
        },  
        refresh: function(){
            let self = this
            self.ready = false
            setTimeout(function(){
                self.ready = true
            },800)
        },
        setBucket: function(data){
            this.bucket = data
        },
        isImage: function(key){
            // console.log('isImage',key)
            let stack = key.split('/')
            let fileName = stack[stack.length-1]
            let extStack = fileName.split('.')
            let fileExtention = extStack[extStack.length-1]
            return this._ext.image.includes(fileExtention)

        },
        selectAsset: function(data){        
            let filename = data.Key.split('/')
            this.form.asset.filename = filename[filename.length-1]
            this.ui.editAsset = true
        },
        selectResource: function(resource){
            let self = this
            let language = this.$store.getters.language
            let title = resource.definition.title[language]
            // navigator.clipboard.writeText(title);
            if(confirm('Edit resource? \r\n'+title)){
                self.$router.push({path: `/admin/ResourceManager`,query:{resource_id:resource.id}})
            }
        },
        closeEdit: function(){
            this.ui.editAsset = false
        },
        saveEdit: async function(){
            let data = {
                selection: this.selection,
                edit: this.form.asset
            }
            let response = await this.sendRequest('patch','/api/admin/assets',data)
            this.refresh()
            this.closeEdit()
        },
        deleteAsset: async function(){
            let selection = this.selection
            if(confirm(`Confirm deletion of: \r\n\r\n${selection.Key}`)){
                let data = {
                    selection
                }
                let response = await this.sendRequest('delete','/api/admin/assets',data)
                this.refresh()
                this.closeEdit()
            }

        },
        setPath(data){
            console.log('asset manager > setPath',data)
            this.path = data
        },
        duplicateSearch(key){
            this.search = key
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        _ext: function(){
            let output = {
                image: [
                    'png',
                    'jpg',
                    'jpeg',
                    'gif',
                    'bmp',
                    'svg'
                ],
                svg: ['svg'] 
            }

            return output
        },
        _resourceMap: function(){
            let output = {}
            let resources = this.$store.getters.resources
            for(let i=0; i<resources.length; i++){
                let resource = resources[i]
                output[resource.id] = resource
            }

            return output
        },
        usage: function(){
            if(this.selection){
                let key = this.selection.Key
                let siteDefinition = this.siteDefinition
                let file = key
                let pages = []
                let resources = []
                let stories = []
                let articles = []
    
                let resourceMap = this._resourceMap
                for(let id in resourceMap){
                    let resource = resourceMap[id]
                    let string = JSON.stringify(resource)
                    if(string.indexOf(file)>=0){
                        resources.push(resource)
                    }                
                }
    
                for(let p=0; p<siteDefinition.length; p++){
                  let page = siteDefinition[p]
                  let string = JSON.stringify(siteDefinition[p])
                  if(page.key && string.indexOf(file)>=0){
                    pages.push(page)
                  }
                }

                for(let s=0; s<this.$store.getters.stories.length; s++){
                    let story = this.$store.getters.stories[s]
                    let string = JSON.stringify(story)
                    if(string.indexOf(file)>=0){
                        stories.push(story)
                    }
                }

                for(let i=0; i<this.$store.getters.articles.length; i++){
                    let article = this.$store.getters.articles[i]
                    let string = JSON.stringify(article)
                    if(string.indexOf(file)>=0){
                        articles.push(article)
                    }
                }
    
                let output = {pages, resources, stories, articles}
                
                return output

            }
            return null
        },
    }
}
</script>

<style scoped lang="scss">
.preview{
    margin: 15px;
    padding: 15px;
    border: 1px solid lightgrey;
}

</style>