<template>
  <div>
    <v-card class='pageCard' rounded="0">
        <!-- <v-img :src="BannerImage" height="400px"></v-img> -->
        <div class="banner-image" :style="{'background-image':'URL(\''+BannerImage+'\')'}"/>
        <!-- <img :src="BannerImage" height="400"/> -->
        <v-card-text class="page-info" :class="[{'bg-primary-0':params.bg_color.theme==0}, {'bg-primary-1':params.bg_color.theme==1}, {'bg-primary-2':params.bg_color.theme==2}]">
            <v-container dense>
                <v-row>
                    <v-col align="start">
                        <!-- <v-img color="white" :src="`${$store.getters.bucket}/${params.icon}`"/> -->
                        <svg :data-src="`${$store.getters.bucket}/${params.icon[language].Key ? params.icon[language].Key : params[language].icon}`"/>
                        <!-- <v-icon color="white" style="font-size: 70px;">{{params.icon}}</v-icon> -->
                    </v-col>
                </v-row>

                <v-row>
                    <v-col align="start">
                        <h3 v-html="params.title[language]" class="page-info-title"/>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col align="start">
                        <div v-html="params.subtitle[language]" class="page-info-subtitle"/>
                    </v-col>
                </v-row>
            </v-container>

            <div class='readMoreButton displayNone'><span>Read more...</span></div>

            <v-btn elevation="0" link :href="params.button.link.newTab ? params.button.link[language] : ''" :target="params.button.link.newTab ? '_blank' : ''" @click="nav(params.button)">
                {{params.button.label[language]}}
            </v-btn>
            <div class="square"></div>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
    name: "pageCard",
    props: {
        params: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        }
        // bg_color:{
        //     type: Object,
        //     required: false,
        //     default: ()=>{
        //         return {
        //             theme: 0,
        //             custom: null
        //         }
        //     }
        // },
        // icon: {
        //     type: String,
        //     required: false,
        //     default: 'mdi-robot'
        // },
        // title: {
        //     type: String,
        //     required: false,
        //     default: 'Page Title'
        // },
        // subtitle: {
        //     type: String,
        //     required: false,
        //     default: 'Page Subtitle'
        // },
        // button: {
        //     type: Object,
        //     required: false,
        //     default: function(){
        //         return {
        //             label: 'Page Button'
        //         }
        //     }
        // }
    },
    created: function(){
        let self = this;
        let delay = setTimeout(function(){
            self.give_showHideIdsAndEventListener_toPageCards();
        },2000);
    },
    methods: {
        give_showHideIdsAndEventListener_toPageCards: function(){
            // GET: elements relevant to show/hide more text in Resource Cards
            let pageInfos       = document.querySelectorAll('.pageCard .page-info');
            let containers      = document.querySelectorAll('.pageCard .page-info .container');
            let descriptions    = document.querySelectorAll('.pageCard .page-info .container .row:last-of-type');
            let readMoreButtons = document.querySelectorAll('.pageCard .readMoreButton');

            // LOOP: pageInfos (indexes sync between pageInfos and containers)
            for(let i = 0; i < pageInfos.length; i++){

                // GIVE: id tp vCard
                let pageInfo = pageInfos[i]
                    pageInfo.classList.add(`readMoreId_${i}`);

                // GIVE: id to container
                let container = containers[i];
                    container.classList.add(`readMoreId_${i}`);
                // GET: container_bottom
                let container_bottom = container.getBoundingClientRect().bottom;

                // GIVE: id to description
                let description = descriptions[i];
                // GET: description_bottom
                let description_bottom = description.getBoundingClientRect().bottom;

                // IS: description_bottom lower than container_bottom (aka there is text below overflow)
                if( (description_bottom) > container_bottom){ //

                    // GET: button
                    let readMoreButton = readMoreButtons[i];
                    // GIVE: readMoreId to button
                    readMoreButton.classList.add(`readMoreId_${i}`);
                    // REMOVE: displayNone from button (they all start off hidden, and are only shown if needed)
                    readMoreButton.classList.remove('displayNone');

                    // IS: button already has hasListener attribute (flag) so subsequent ResourceCard renders don't re-add listeners to buttons that already have it
                    if( readMoreButton.getAttribute('hasListener') != 'true'){
                        readMoreButton.setAttribute('hasListener', 'true');

                        let self = this;
                        readMoreButton.addEventListener('click', function(){self.showHideMoreText(readMoreButton)});
                    };
                };
            };
        },
        nav: function(button){
            console.log('nav',{button})
            if(!button.link.newTab){
                let language = this.language
                this.$router.push({path:button.link[language]})
            }
        },
        showHideMoreText: function(readMoreButton){

            // GET: readMoreId_# class id
            let readMoreButton_classList = readMoreButton.classList; // get classList
            let readMoreButton_classArray = [...readMoreButton_classList]; // convert classList to array
            let readMoreId = readMoreButton_classArray.find(item=>item.includes('readMoreId_')); // use find on array to get readMoreId_# class

            // GET: elements to give show class (readMore) to
            let pageInfo    = document.querySelector(`.pageCard .page-info.${readMoreId}`);
            let container   = document.querySelector(`.pageCard .page-info .container.${readMoreId}`);
            let button      = document.querySelector(`.pageCard .readMoreButton.${readMoreId}`);
            // let description = document.querySelector(`.pageCard .page-info .container .row.${readMoreId}`);

            if( button.classList.contains('readMore')){ // IS: currently showing
                // REMOVE: show class (readMore) from elements
                pageInfo.classList.remove('readMore');
                container.classList.remove('readMore');
                button.classList.remove('readMore');
                // UPDATE: button text (show less -> read more...)
                button.children[0].innerHTML = 'Read more...';
            }
            else { // IS: currently hidden
                // GIVE: show class (readMore) to elements
                pageInfo.classList.add('readMore');
                container.classList.add('readMore');
                button.classList.add('readMore');
                // UPDATE: button text (read more... -> show less)
                button.children[0].innerHTML = 'Show less';
            };
        },
    },
    computed: {
        BannerImage: function(){
            let params = this.params
            let bucket = this.$store.getters.bucket
            let language = this.language
            if(params.banner){
                return `${bucket}/${params.banner[language].Key}`
            }else{
                return 'https://cdn.vuetifyjs.com/images/cards/sunshine.jpg'
            }
        }
    }

}
</script>

<style scoped lang="scss">

.pageCard {
    width: 360px;
    min-width: 320px;
    margin: 16px 0;

    @media(max-width: 959px){
        width: 100%;
    }
    @media(max-width: 599px){
        width: 500px;
    }
    @media(max-width: 500px){
        width: 360px;
    }
    @media(max-width: 415px){
        width: 320px;
    }

}

.banner-image{
    height: 360px;
    width: 100%;
    background-size: cover;
    background-position: center;

    @media(max-width: 959px){
        background-size: contain;
        background-color: whitesmoke;
    }
}

.page-info{
    padding: 25px;
    padding-bottom: 96px;
    height: 360px;

    &.readMore {
        height: initial;
    }
}

.page-info .container{
    color: white;
    max-height: 234px;
    overflow: hidden;

    &.readMore {
        max-height: initial;
    }
}

.page-info-title{
    font-size: 1.5em;
    font-weight: bold;
}

.page-info-subtitle{
    font-size: 1.2em;
    font-weight: bold;
}

.readMoreButton {
    position: relative;
    height: 24px;
    padding-right: 24px;
    text-align:right;
    font-size: 14px;
    text-decoration: underline;
    color: white;

    &.displayNone {
        display: none;
    }

    span {
        cursor: pointer;
    }
}

.page-info .v-btn{
    position: absolute;
    bottom: 35px;
    left: 35px;
    font-weight: bold;
    font-size: 1.3em;
}

.bg-primary-0{
    background-color: $primary;
}
.bg-primary-1{
    background-color: $primary-1;
}
.bg-primary-2{
    background-color: $primary-dark-1;
}

.square{
    position: absolute;
    bottom: 0px;
    right: 0px;
    transform: translate(0,0);
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid $accent;
    border-top: 40px solid transparent;
}

svg{
    display: inline-block;
    width: 56px;
    height: 56px;
    position: relative;
    fill: white;
}
</style>
