<template>
  <div>
    Edit News
    <v-container v-if="ready" fluid>
        <v-row>
            <v-col>
                <v-select
                    v-model="config.timing"
                    :items="[{text: 'upcoming', value: 'upcoming'},{text: 'past', value: 'past'},{text: 'all', value: 'all'}]"
                    label="Timing"
                >
                </v-select>
                <v-text-field type="number" v-model="config.limit" label="Display Limit (0 = no limit)"/>
                <v-text-field v-for="(item, language) in config.title" v-model="config.title[language]" outlined :label="`Section Title - ${language}`" :key="`title-${language}`" style="display: inline-block; width: 45%;"/>
                <!-- <json-viewer :value="config"/> -->
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-checkbox v-model='config.audience' v-for="item in _audience" :value="item.value" :key="'audience-'+item.value" :label="item.text"/>
            </v-col>
            <v-col>
                <v-checkbox v-model='config.categories' v-for="item in _categories" :value="item.value" :key="'category-'+item.value" :label="item.text"/>
            </v-col>
            <v-col>
                <v-checkbox v-model='config.topics' v-for="item in _topics" :value="item.key" :key="'topic-'+item.key" :label="item.text[language]"/>
            </v-col>
        </v-row>        
    </v-container>
    <div v-else>Loading</div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    name: "ArticlesSection",
    props: {
        languages: {
            type: Array,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    components: {
        draggable
    },
    created: async function(){
        function blank(languages){
            let output = {}
            for(let i=0; i<languages.length; i++){
                let language = languages[i]
                output[language] = null
            }
            return output
        }
        let languages = this.languages
        let config = JSON.stringify(this.model)
        config = JSON.parse(config)
        if(!config || !config.audience){
            config = {
                audience: [],
                categories: [],
                topics: [],
                title: blank(languages),
                limit: 3
            }
        }

        if(!config.timing){
            config.timing = 'all'
        }
        
        // this.articles = await this.getArticles()
        this.topics = await this.getTopics()
        this.config = config
        this.ready = true
    },
    data: function(){
        return {
            ready: false,
            topics: null,
            // articles: null,
            config: null
        }
    },
    methods: {
        getTopics: async function(){
            let response = await this.sendRequest('get',`/api/getSite`)
            if(response.status==200){
                return response.data.componentConfigurations.articles.config.topics
            }else{
                return false
            }
        },
        // getArticles: async function(){
        //     let response = await this.sendRequest('get',`/api/admin/articles`)
        //     if(response.status==200){
        //         return response.data
        //     }else{
        //         return false
        //     }
        // },
        template: function(){
            return {
                path: null,
                label: {}
            }
        },
        save: function(){
            this.$emit('input',this.config)
        },
        add: function(){
            this.config.links.push(this.template())
        },
        remove: function(index){
            this.config.links.splice(index, 1)
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        },
        _categories: function(){
            return [
                {text:'News',value:'news'},
                {text:'Events',value:'events'},
                {text:'Webinars',value:'webinars'}
            ]
        },
        _topics: function(){
            return this.topics ? this.topics : {}
        },
        _audience: function(){
            return [
                {text:'HCP',value:'hcp'},
                {text:'Patient',value:'patient'}
            ]
        }
    },
    watch: {
        config: {
            deep: true,
            handler: function(){
                if(this.ready){
                    this.save()
                }
            }
        }
    }
}
</script>

<style>

</style>