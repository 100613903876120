<template>
  <div>
    <div class="member-container">
        <div class="member" style="text-align: left; padding: 15px; border: none;">
            <h1>Member Directory</h1>
            <hr class="small">
            <strong>We gratefully acknowledge the support from our members.</strong>
        </div>
        <div v-for="(member, memberIndex) in members" :key="`member-${memberIndex}`" class="member">
            <v-card flat>
                <v-card-title style="padding: 10px; font-size: 1.2em;">
                    {{member.lastname}}, {{member.firstname}} {{member.middle}}
                </v-card-title>
                <v-card-subtitle class="credentials" v-if="member.credentials[language] && member.credentials[language].length>0">
                    {{member.credentials[language]}}
                </v-card-subtitle>
                <v-card-text style="padding: 0px;">
                    <v-container>
                        <v-row dense>
                            <v-col cols="4">Specialty</v-col>
                            <v-col cols="8" style="text-align: left;">{{member.specialty[language]}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="4">Position</v-col>
                            <v-col cols="8" style="text-align: left;">{{member.position[language]}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="4">Affillation</v-col>
                            <v-col cols="8" style="text-align: left;">{{member.affillation[language]}}</v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        language: {
            type: String,
            required: true
        }
    },
    computed: {
        members: function(){
            return this.$store.getters.componentConfigurations.members.config.members
        }
    }
}
</script>

<style scoped lang="scss">
.member-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    width: 80%;
    margin: 0 auto;
    padding: 25px 0px 25px 0px;
}

.member:first-of-type {
    @media(max-width: 768px){
        min-height: auto !important;
    }
}

.member{
    border-radius: 8px;
    flex-grow: 1;
    width: 22%;
    max-width: 300px;
    width: 300px;
    min-height: 300px;
    border: 1px solid grey;
    padding: 5px;
}

.credentials{
    padding-left: 10px;
    text-align: left;
}
</style>
