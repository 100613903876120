<template>
  <div class="textblock-container" :class="[config.layout, config.background]">
    <div class="textblock-item textblock-media">
        <div v-html="media" style="text-align: right; margin: 25px;"></div>
    </div>
    <div class="textblock-item">
        <div class="textblock-title" v-html="title"/>
        <div class="textblock-content" v-html="content"/>
    </div>
  </div>
</template>

<script>
import Title from '../title.vue'
export default {
    props: {
        config: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    components: {
        Title
    },
    computed: {
        title: function(){
            let language = this.language
            return this.config.title && this.config.title[language] ? this.config.title[language] : null
        },
        subtitle: function(){
            let language = this.language
            return this.config.subtitle && this.config.subtitle[language] ? this.config.subtitle[language] : null
        },
        content: function(){
            let language = this.language
            return this.config.content[language]
        },
        media: function(){
            let language = this.language
            return this.config.media[language]
        }
    }
}
</script>

<style scoped lang="scss">
.textblock-container{
    margin: 25px;
}
.textblock-container.theme-darkblue{
    background-color: $primary;
    color: white;
}

.media-left_text-right{
    display: flex;
}


.media-left_text-right .textblock-item{
    text-align: left;
    width: 100%;
}

.textblock-media{
    text-align: center;
}

.textblock-title{
    font-size: 1.5em;
    font-weight: bold;
}

.textblock-content{
    font-size: 1.2em;
}
</style>

<style>
.textblock-media iframe{
    width: 500px;
    height: 300px;
    margin: 0 auto;
}

</style>