<template>
  <div v-if="ArticleCards.length>0">
    <Title :config="config" :language="language"/>
    <Layout :preKey="_category" :columnComponents="ArticleCards" :language="language" @readMore="readMore"/>
  </div>
  <div v-else>
    <center>
      Check back for new articles, and events
    </center>
    <!-- <Title :config="{title:{'en-ca':'News & Events'}, subtitle:{'en-ca':'Check back for new articles and events'}}" :language="language"/> -->
    <!-- <Title :title="'News & Events'" :subtitle="'Check back for new articles and events'"/> -->
  </div>
</template>

<script>
import Title from '@/components/Title/Title.vue'
import Layout from '../layout_3col.vue'
import ArticleCard from '@/components/Article/lib/articleCard.vue'
export default {
    props: {
      config: {
        type: Object,
        required: false
      },
      language: {
        type: String,
        required: true
      }
    },
    components: {
        Title,
        Layout,
        ArticleCard
    },
    created: function(){
      this.init()
    },
    data: function(){
      return {
        articles: null,
        now: null
      }
    },
    methods: {
      init: async function(){
        this.now = new Date()
        await this.getArticles()
      },
      getArticles: async function(){
        let response = await this.sendRequest('get','/api/news')
        if(response.status==200){
          this.articles = response.data
        }
      },
      isAllowed: function(article){
        let now = this.now
        let config = this.config
        let audience = config.audience
        let categories = config.categories
        let topics = config.topics
        let timing = config.timing

        function compare(filter, source){
          if(filter.length==0){
            return true
          }else if(!source || source.length==0){
            return false
          }
          
          for(let i=0; i<filter.length; i++){
            let filterValue = filter[i]
            if(source.includes(filterValue)){
              return true
            }
          }
          return false
        }

        function checkTiming(timing, article){
          if(timing == 'all'){
            return true
          }

          if(article.type.includes('news')){
            let published = new Date(article.content.date)
            return timing == 'past' ? published<now : published>now
          }

          if(article.type.includes('events') || article.type.includes('webinars')){
            let ends = new Date(article.content.end)
            return timing == 'past' ? ends<now : ends>now
          }

          return false
        }

        let test = {
          audience: compare(audience, article.audience),
          categories: compare(categories, article.type),
          topics: compare(topics, article.topics),
          timing: timing ? checkTiming(timing, article) : true
        }
        
        for(let key in test){
          if(!test[key]){
            return false
          }
        }
        return true
      },
      readMore: function(article){
        this.$router.push({name:'Article',params:{articleId:article.pageName}})
      }
    },
    computed: {
      _category: function(){
        return 'news'
      },
      ArticleCards: function(){
        function template(component, title, body, link){
           return {
            component,
            params: {
              title,
              body,
              link
            }
          }
        }
        function byDates(a, b){
          let result = a.params.content.date<b.params.content.date
          return result ? 1 : -1;
        }
        let config = this.config
        let limit = parseInt(config.limit,10)
        let articles = this.articles ? this.articles : []
        let category = this._category
        let output = []
        for(let i=0; i<articles.length; i++){
          let article = articles[i]
          let allow = this.isAllowed(article)
          if(allow){
            article.content.date = new Date(article.content.date)
            output.push({component: ArticleCard, params:article})
          }
        }

        
        output.sort(byDates)
        // (output.length<limit || limit==0)
        let cards = []
        for(let i=0; i<output.length; i++){
          let card = output[i]
          if(cards.length < limit || limit==0){
            cards.push(card)
          }
        }

        return cards
      }
    }
}
</script>

<style scoped lang="scss">

</style>