<template>
<a href="https://twitter.com/ThrombosisCan" target="_blank" style="text-decoration: none;">
  <div class="cta">
    <div class="cta-container">
        <div class="cta-item">
            <div class="cta-item-content">
                <v-icon x-large color="white">mdi-twitter</v-icon>
            </div>
        </div>
        <div class="cta-item">
            <div class="cta-item-content">
                <span  v-html="config.title[language]"/>
            </div>
        </div>
    </div>
  </div>  
</a>
</template>

<script>
export default {
    props: {
        language: {
            type: String,
            required: true
        }
    },
    computed: {
        config: function(){
            return this.$attrs.config
        }
    }
}
</script>

<style scoped lang="scss">
.cta{
    margin: 25px 0px 0px 0px;
    width: 100%;
    height: 180px;
    background-color: $primary;
    color: white;
}
.cta-container{
    display: flex;
    width: 60%;
    margin: 0 auto;
    align-items: center;
    height: 100%;
    gap: 25px;
}


.cta-item-content{
    text-align: left;
}

.cta-item.action{
    flex-grow: 1;
}


.title{
    font-size: 1.8em;
    font-weight: bold;
}
</style>