<template>
  <div>
    <div class="logo-container">
        <div class="logo-item" v-for="partner in _partners" :key="`partner-${partner.ETag}`">
            <v-img :src="`${$store.getters.bucket}/${partner.Key.replaceAll(' ','%20')}`"/>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    computed: {
        _partners: function(){
            function isPartnerLogo(Key){
                return Key.search('uploads/partner/logos/')
            }
            let output = []
            let assets = this.$store.getters.assets.map
            for(let Key in assets){
                let asset = assets[Key]
                if(isPartnerLogo(Key)>=0 && output.filter((item)=>{return item.ETag==asset.ETag}).length==0){
                    output.push(asset)
                }
            }

            return output
        }
    }
}
</script>

<style scoped lang="scss">
.logo-container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    gap: 35px;
    justify-content: center;
}

.logo-item{
    align-self: center;
    flex-grow: 1;
    width: 25%;
    max-width: 300px;
}
</style>