<template>
  <div class='search'>
    <form @submit.prevent="search">
        <v-text-field label="Search..." flat v-model="query" append-icon="mdi-magnify" clearable @keyup="$emit('query',query)" @click:clear="clear" @click:append="search"/>
    </form>
  </div>
</template>

<script>
export default {
    props: {
        incoming: {
            type: String,
            required: false
        }
    },
    created: function(){
        if(this.$store.getters.searchBar){
            this.query = this.$store.getters.searchBar
        }
    },
    data: function(){
        return {
            query: null
        }
    },
    methods: {
        search: function(){
            let query = this.query
            this.$store.commit('searchBar',query)
            if(this.$route.name!='/search'){
                this.$router.push({path:'/search'})
            }
            this.query = null
        },
        clear: function(){
            this.$store.commit('searchBar',null)
        }
    },
    computed: {
        searchBar: function(){
            return this.$store.getters.searchBar
        }
    },
    watch: {
        incoming: function(){
            let incoming = this.incoming
            if(incoming!=this.query){
                this.query = incoming
            }
        }
    }
}
</script>

<style lang='scss'>

// Search component GENERAL STYLING
.search {

    .v-icon {
        color: $primary !important;
    }

    form {
        .v-text-field {

            .v-input__slot {
                background-color: whitesmoke !important;
                border-radius: 0;
                box-shadow: none !important;
                color: $primary !important;

                label {
                    color: $primary;
                    font-weight: 500;
                }

                &:focus {
                    .v-icon {
                        color: $primary !important;
                    }
                }
            }

            .v-text-field__details {
                margin-bottom: -20px;
            }

            /* better positions the label when active, and matches primary colour */
            .v-label--active {
                transform: translateY(-22px) scale(0.75);
                color: $primary !important;
            }
        }

        /* removes bottom border of text-field */
        .v-input__slot:before {
            width: 0 !important;
        }

    }
}

// Search component IN HEADER ONLY
.header {
    .search {

        .v-icon {
            font-size: 24px !important;
        }

        form {
            .v-text-field {

                .v-input__slot {
                    border-left: 3px solid $primary;
                    border-right: 3px solid $primary;
                    padding: 0px 16px;
                }
            }
        }
    }
}


// Search component in mobile hamburger-menu ONLY
div.NavBar.mobile {

    .search {

        .v-icon {
            font-size: 22px !important;
        }

        form {
            .v-text-field {
                box-shadow: none !important;
                padding: 16px 0 32px !important;
                @media(max-width: 599px){
                    padding: 16px 0px 16px !important;
                }

                .v-input__slot {
                    padding: 0 16px !important;

                    label {
                        font-size: 16px !important;
                    }
                }
            }

            /* better positions the label when active, and matches primary colour */
            .v-label--active {
                transform: translateY(-30px) scale(0.75);
            }
        }
    }
}


// Search component IN PAGE BODY ONLY
.render-body {

    .search {

        .v-icon {
            font-size: 32px !important;
            margin-top: 16px;
            @media(max-width: 575px){ // xs <= 576
                font-size: 24px !important;
            }
        }

        form {

            .v-text-field {

                .v-input__slot {
                    border-left: 4px solid $primary;
                    border-right: 4px solid $primary;
                    height: 48px;
                    padding: 0 24px !important;

                    @media(max-width: 575px){
                        padding: 0 8px !important;
                    }

                    label {
                        font-size: 22px;

                        @media(max-width: 575px){
                            font-size: 18px;
                        }
                    }
                }

                .v-input__append-inner {
                    margin-left: 8px;
                }
            }

            /* better positions the label when active, and matches primary colour */
            .v-label--active {
                transform: translateY(-30px) scale(0.75);
            }
        }
    }
}

</style>
