<template>
<!--  Preoperative Anticoagulant Mangement Algo  -->
<div>
<template v-if="language==='fr-ca'">
    <div class="calcDescription perioperativeAnticoagulantAlgorithm">
            <h2>Algorithme de prise en charge de l’anticoagulation périopératoire</h2>
    </div>
    <div class="perioperativeAnticoagulantAlgorithm preopAlgo_step01 calcItem groupItem step01">
        <span class="error--text" v-show="debug===true">QID-1</span>
        <h3>Veuillez sélectionner le type d’intervention chirurgicale :</h3>
            <div>
                    <label>
                            <input v-model="preop_selected.q1" type="radio" name="preopAlgoSurgeryType" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step01', '.preopAlgo_step1');" value="elective">&nbsp;Intervention non urgente
                    </label>
            </div>
            <div>
                    <label>
                            <input v-model="preop_selected.q1" type="radio" name="preopAlgoSurgeryType" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step01', '.preopAlgo_step02');" value="emergency">&nbsp;Intervention chirurgicale ou autre type d’intervention d’urgence dans &lt; 12 h (p. ex., en cas d’hémorragie intracérébrale, de rupture d’un viscère, de tamponnade cardiaque)
                    </label>
            </div>
            <div>
                    <label>
                            <input v-model="preop_selected.q1" type="radio" name="preopAlgoSurgeryType" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step01', '.preopAlgo_step02');" value="urgent">&nbsp;Intervention chirurgicale ou autre type d’intervention urgente dans les 12-24 h (p. ex., réparation d’une fracture de la hanche, cholécystite aiguë) 
                    </label>
            </div>
    </div>
    <div class="preopAlgo_step1 calcItem groupItem step1">
        <span class="error--text" v-show="debug===true">QID-2</span>
            <h3>Risque d’hémorragie associé à l’intervention :</h3>
            <div>
                    <label>
                            <input v-model="preop_selected.q2" type="radio" name="preopAlgoBleedRisk" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step1', '.preopAlgo_step2');" value="minor non-dental">&nbsp;Faible (petite intervention non dentaire)
                    </label>
                    <a href="javascript:calcToggleExamples('#periopExamples1');" class="toggleExamplesWrapper">?</a>
                    <ul id="periopExamples1" class="examples">
                            <li>Chirurgie de la cataracte</li>
                            <li>Interventions dermatologiques (p. ex., une biopsie)</li>
                            <li>Gastroscopie ou coloscopie sans biopsie</li>
                            <li>Coronarographie</li>
                            <li>Implantation d’un stimulateur cardiaque ou installation d’un défibrillateur cardiaque (si on n’a pas recours à une anticoagulothérapie de relais)</li>
                            <li>Certaines interventions (p. ex., thoracentèse, paracentèse, arthrocentèse)</li>
                    </ul>
            </div>
            <div>
                    <label>
                            <input v-model="preop_selected.q2" type="radio" name="preopAlgoBleedRisk" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step1', '.preopAlgo_step2');" value="minor dental">&nbsp;Faible (petite intervention dentaire)
                    </label>
                    <a href="javascript:calcToggleExamples('#periopExamples2');" class="toggleExamplesWrapper">?</a>
                    <ul id="periopExamples2" class="examples">
                            <li>Extractions dentaires (1 ou 2 dents)</li>
                            <li>Intervention endodontique (traitement de canal)</li>
                            <li>Détartrage sous-gingival ou autre nettoyage dentaire</li>
                    </ul>
            </div>
            <div>
                    <label>
                            <input v-model="preop_selected.q2" type="radio" name="preopAlgoBleedRisk" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step1', '.preopAlgo_step2');" value="moderate">&nbsp;Modéré
                    </label>
                    <a href="javascript:calcToggleExamples('#periopExamples3');" class="toggleExamplesWrapper">?</a>
                    <ul id="periopExamples3" class="examples">
                        <li>Chirurgie abdominale (p. ex., cholécystectomie, réparation herniaire, résection du côlon)</li>
                        <li>Autre chirurgie générale (p. ex., mammaire)</li>
                        <li>Autre chirurgie intrathoracique</li>
                        <li>Autre chirurgie orthopédique</li>
                        <li>Autre chirurgie vasculaire</li>
                        <li>Chirurgie ophtalmologique autre qu’une chirurgie de la cataracte</li>
                        <li>Gastroscopie ou coloscopie avec biopsie</li>
                        <li>Certaines interventions (p. ex., biopsie de moelle osseuse, biopsie de ganglions lymphatiques)</li>
                        <li>Intervention dentaire complexe (p. ex., extractions dentaires multiples)</li>
                    </ul>
            </div>
            <div>
                    <label>
                        <input v-model="preop_selected.q2" type="radio" name="preopAlgoBleedRisk" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step1', '.preopAlgo_step2');" value="high">&nbsp;Élevé
                    </label>
                    <a href="javascript:calcToggleExamples('#periopExamples4');" class="toggleExamplesWrapper">?</a>
                    <ul id="periopExamples4" class="examples">
                            <li>Toute intervention chirurgicale ou autre sous anesthésie neuraxiale (rachidienne ou péridurale)</li>
                            <li>Neurochirurgie (intracrânienne ou rachidienne)</li>
                            <li>Chirurgie cardiaque (p. ex., pontage aortocoronarien, remplacement valvulaire)</li>
                            <li>Chirurgie vasculaire lourde (p. ex., réparation d’un anévrisme aortique, pontage aortofémoral)</li>
                            <li>Chirurgie orthopédique lourde (p. ex., chirurgie de remplacement de la hanche ou du genou)</li>
                            <li>Résection pulmonaire</li>
                            <li>Chirurgie urologique (p. ex., prostatectomie, résection d’une tumeur de la vessie)</li>
                            <li>Chirurgie oncologique lourde (p. ex., pancréas, foie)</li>
                            <li>Anastomose intestinale</li>
                            <li>Chirurgie plastique reconstructive</li>
                            <li>Certaines interventions (p. ex., biopsie du rein, biopsie de la prostate, conisation du col de l’utérus, péricardiocentèse, polypectomie du côlon)</li>
                    </ul>
            </div>
    </div>
    <div class="calcItem preopAlgo_step1" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step1', '.preopAlgo_step01')" class="calcButton">&lt; Retour</button>
    </div>

    <div class="preopAlgo_step2 calcItem groupItem">      
        <span class="error--text" v-show="debug===true">QID-3</span>
            <h3>Anticoagulant utilisé :</h3>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step3');" value="apixaban">
                    Apixaban
            </label>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step3');" value="dabigatran">
                    Dabigatran
            </label>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step3');" value="edoxaban">
                    Edoxaban
            </label>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step3');" value="rivaroxaban">
                    Rivaroxaban
            </label>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step3');" value="warfarin">
                    Warfarin
            </label>
    </div>
    <div class="calcItem preopAlgo_step2" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step1')" class="calcButton">&lt; Retour</button>
    </div>
    <div class="preopAlgo_step02 calcItem groupItem">
        <span class="error--text" v-show="debug===true">QID-3</span>
            <h3>Anticoagulant utilisé :</h3>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step02D');" value="apixaban">
                    Apixaban
            </label>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step02D');" value="dabigatran">
                    Dabigatran
            </label>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step02D');" value="edoxaban">
                    Edoxaban
            </label>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step02D');" value="rivaroxaban">
                    Rivaroxaban
            </label>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step02D');" value="warfarin">
                    Warfarin
            </label>
    </div>
    <div class="calcItem preopAlgo_step02" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step01')" class="calcButton">&lt; Retour</button>
    </div>

    <!-- PreopAlog POPUP-1 -->    
    <div class="preopAlgo_step02N calcItem">
            <span class="error--text" v-show="debug===true">POPUP 1</span>
            <h3>Prise en charge initiale</h3>
    </div>
    <div class="calcItem preopAlgo_step02N">
            <ul>
                    <li>Orienter le patient en vue d’une intervention chirurgicale ou d’un autre type d’intervention</li>
                    <li>Vérifier le taux d’hémoglobine et la numération plaquettaire pour orienter la transfusion.</li>
                    <li>Transfusion thérapeutique
                            <ul>
                                    <li>Administrer une transfusion de globules rouges en cas d’anémie symptomatique.</li>
                                    <li>Plaquettes en cas de thrombocytopénie (nombre de plaquettes inférieur à 50 × 10<sup>9</sup>/L) ou si le patient prend des antiplaquettaires.</li>
                            </ul>
                    </li>
                    <li>Arrêter l’anticoagulant</li>
            </ul>
    </div>
    <div class="calcItem preopAlgo_step02N" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step02N', '.preopAlgo_step02')" class="calcButton">&lt; Retour</button>
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step02N', '.preopAlgo_step03b');" class="calcButton">Suivant &gt;</button>
    </div>

    <div class="preopAlgo_step02W calcItem">
            <span class="error--text" v-show="debug===true">POPUP 1</span>
            <h3>Prise en charge initiale</h3>
    </div>
    <div class="calcItem preopAlgo_step02W">
            <ul>
                    <li>Orienter le patient en vue d’une intervention chirurgicale ou d’un autre type d’intervention</li>
                    <li>Vérifier le taux d’hémoglobine et la numération plaquettaire pour orienter la transfusion.</li>
                    <li>Transfusion thérapeutique
                            <ul>
                                    <li>Administrer une transfusion de globules rouges en cas d’anémie symptomatique.</li>
                                    <li>Plaquettes en cas de thrombocytopénie (nombre de plaquettes inférieur à 50 × 10<sup>9</sup>/L) ou si le patient prend des antiplaquettaires.</li>
                            </ul>
                    </li>
                    <li>Arrêter l’anticoagulant</li>
            </ul>
    </div>
    <div class="calcItem preopAlgo_step02W" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step02W', '.preopAlgo_step02')" class="calcButton">&lt; Retour</button>
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step02W', '.preopAlgo_step03bw')" class="calcButton">Suivant &gt;</button>
    </div>


    <!--preopAlgo QID-4 -->
    <div class="preopAlgo_step3b preopAlgo_step03b calcItem">
        <span class="error--text" v-show="debug===true">QID-4</span>
            <h3>Clairance de la créatinine</h3>
    </div>
    <div class="preopAlgo_step03bw calcItem">
        <span class="error--text" v-show="debug===true">QID-11</span>
            <h3>Poids</h3>
    </div>
    <div class="calcItem preopAlgo_step3b preopAlgo_step03b">
            <label>
                    <input v-model="preop_selected.q4.female" type="checkbox" name="preopAlgoCrClisFemale" onclick="calc.preopAnticoagulantAlgorithmCrCl()">
                    Sexe féminin
            </label>
    </div>
    <div class="calcItem preopAlgo_step3b preopAlgo_step03b">
            <label class="withNumber">
                    Âge (ans)
                    <input v-model="preop_selected.q4.age" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" onchange="calc.preopAnticoagulantAlgorithmCrCl()" onkeyup="calc.preopAnticoagulantAlgorithmCrCl()" name="preopAlgoCrClAge">
                    <div class="validationMessage">!</div>
            </label>
    </div>
    <div class="calcItem preopAlgo_step03bw">
            <label class="withNumber">
                    Poids (kg) 
                    <input v-model="preop_selected.q11.weight" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" onchange="" onkeyup="" name="preopAlgoWeight">
                    <div class="validationMessage">!</div>
            </label>
    </div>
    <div class="calcItem preopAlgo_step3b preopAlgo_step03b">
            <label class="withNumber">
                    Poids (kg)
                    <input v-model="preop_selected.q4.weight" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" onchange="calc.preopAnticoagulantAlgorithmCrCl()" onkeyup="calc.preopAnticoagulantAlgorithmCrCl()" name="preopAlgoCrClweight">
                    <div class="validationMessage">!</div>
            </label>
    </div>
    <div class="calcItem preopAlgo_step3b preopAlgo_step03b">
            <label class="withNumber">
                    Créatinine sérique <span class="calcUnit">(µmol/L)</span>
                    <input  v-model="preop_selected.q4.serum_creatinine" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" onchange="calc.preopAnticoagulantAlgorithmCrCl()" onkeyup="calc.preopAnticoagulantAlgorithmCrCl()" name="preopAlgoCrClserumCreatinine">
                    <div class="validationMessage">!</div>
            </label>
    </div>
    <div class="calcItem preopAlgo_step3b" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step3b', '.preopAlgo_step2')" class="calcButton">&lt; Retour</button>
            <button class="calcButton" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step3b', '.preopAlgo_step4');">Suivant &gt;</button>
    </div>
    <div class="calcItem preopAlgo_step03b" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step03b', '.preopAlgo_step02BackD')" class="calcButton">&lt; Retour</button>
            <button class="calcButton" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step03b', '.preopAlgo_step03bD');">Suivant &gt;</button>
    </div>
    <div class="calcItem preopAlgo_step03bw" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step03bw', '.preopAlgo_step02BackD')" class="calcButton">&lt; Retour</button>
            <button class="calcButton" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step03bw', '.preopAlgo_step03bD');">Suivant &gt;</button>
    </div>

    <div class="calcItem preopAlgo_step04">
        <span class="error--text" v-show="debug===true">QID-12</span>
            <label class="withNumber">
                    RIN
                    <input v-model="preop_selected.q12" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" name="preopAlgoINR">
                    <div class="validationMessage">!</div>
            </label>
    </div>
    <div class="calcItem preopAlgo_step04" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step04', '.preopAlgo_step02W')" class="calcButton">&lt; Retour</button>
            <button class="calcButton" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step04', '.preopAlgo_step04D');">Suivant &gt;</button>
    </div>
            <div class="calcItem preopAlgo_step04D">
                    <div class="calcItem preopAlgo_step04P3">
                            <strong>Recommandations</strong><span class="error--text" v-show="debug===true">POPUP 16</span>
                            <p>Remesurer le RIN avant l’intervention chirurgicale</p>
                            <ul>
                                    <li>Si le RIN est &le; 1.5 &#8594; aucun antidote requis, effectuer l’intervention chirurgicale/intervention</li>
                                    <li>Effectuer l’intervention chirurgicale/intervention</li>
                                    <li>Envisager le CCP pour les interventions chirurgicales/interventions associées à un risque très élevé d’hémorragie</li>
                            </ul>
                    </div>
                    <div class="calcItem preopAlgo_step04P4">
                            <strong>Recommandations</strong><span class="error--text" v-show="debug===true">POPUP 17</span>
                            <br>Administrer de la vitamine K à 10 mg par voie i.v.
                            <br><br>
                            Remesurer le RIN avant l’intervention chirurgicale
                            <ul>
                                    <li>Si le RIN est &le; 1.5 &#8594; aucun antidote requis, effectuer l’intervention chirurgicale/intervention</li>
                                    <li>Si le RIN est &gt; 1.5, envisager le CCP</li>
                            </ul>
                    </div>
                    <div class="calcItem preopAlgo_step04P2">
                            <strong>Recommendations</strong><span class="error--text" v-show="debug===true">POPUP 15</span>
                            <ul>
                                    <li>Si le RIN est &le; 1.5 &#8594; aucun antidote requis</li>
                                    <li>Effectuer l’intervention chirurgicale/intervention</li>
                            </ul>
                    </div>
                    <div class="calcItem preopAlgo_step04P5">
                            <strong>Recommandations</strong><span class="error--text" v-show="debug===true">POPUP 14</span>
                            <p><strong>Administrer de la vitamine K (10 mg dans 50 mL de soluté physiologique par voie i.v. IMMÉDIATEMENT) and <span class="PCC_VAR"></span> unités de CCP (NE PAS L’ADMINISTRER DE NOUVEAU S’IL A DÉJÀ ÉTÉ ADMINISTRÉ)</strong> <a href='#' @click.prevent="display_modal.PCCDosingWrapper=!display_modal.PCCDosingWrapper" style="color: #1976d2;">Posologie et administration du CCP </a>:</p>
                            <ul>
                                    <li>Perfuser le CCP par voie intraveineuse :
                                            <ul>
                                                    <li>OCTAPLEX<sup>&reg;</sup> à 1 mL/min, suivi d’un débit maximal de 2-3 mL/min (180 mL/h)</li>
                                                    <li>BERIPLEX<sup>&reg;</sup>  par voie i.v. à 1 mL/min, suivi d’un débit maximal de 8 mL/min (480 mL/h)</li>
                                            </ul>
                                    </li>
                                    <li>CCP contre-indiqué en cas de thrombocytopénie induite par l’héparine</li>
                                    <li>Informer les patients/leur famille concernant le faible (&lt;2%) risque de thrombose lié au CCP (p. ex., AVC, IM, TVP, EP), mais leur faire savoir que les répercussions d’une hémorragie non réprimée pendant l’intervention chirurgicale l’emportent probablement sur ce risque.
                                    <ul>
                                            <li>Si le CCP n’est pas disponible ou s’il est contre-indiqué, transfuser du plasma frais congelé à raison de 10-15 mL/kg (environ 3-4 unités pour un adulte).</li>
                                    </ul></li>
                                    <li>Si le RIN n’est pas consigné ou si le poids est inconnu et qu’il est impossible de retarder l’administration du CCP, administrer 2000 unités de CCP par voie i.v. et 10 mg de vitamine K par voie i.v. IMMÉDIATEMENT.</li>
                            </ul>
                            <strong>Remesurer le RIN 15 min après la perfusion de CCP</strong>
                            <br>
                            Si le RIN est &le; 1.5 : effet de la warfarine inversé<br>
                            Si le RIN &gt; 1.5 : envisager d’administrer une autre dose de CCP, penser aux autres causes possibles d’un RIN élevé (p. ex., CID, coagulopathie par dilution, insuffisance hépatique).
                    </div>
            </div>


    <div class="calcItem preopAlgo_step04Ea" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step04Ea', '.preopAlgo_step04')" class="calcButton">&lt; Retour</button>
    </div>

    <!--preopAlgo QID-10 -->
    <div class="preopAlgo_step05 calcItem">
        <span class="error--text" v-show="debug===true">QID-10</span>
            <h3>Analyses de laboratoire pour la détection d’un effet résiduel de l’AOD</h3>
    </div>   
    <div class="calcItem preopAlgo_step05">
            <label class="withNumber">
                    ng/mL 
                    <span class="preopAlgoNOACEffectLabel">
                        <a href='#' v-if="preop_selected.q3==='apixaban'" @click.prevent="display_modal.ApixabanTable=!display_modal.ApixabanTable" style="color: #1976d2;">Essai de l'activité anti-Xa calibré pour l'apixaban</a>
                        <a href='#' v-if="preop_selected.q3==='dabigatran'" @click.prevent="display_modal.DabigatranTable=!display_modal.DabigatranTable" style="color: #1976d2;">Temps de thrombine dilué (Hemoclot<sup>&reg;</sup>)</a>
                        <a href='#' v-if="preop_selected.q3==='edoxaban'" @click.prevent="display_modal.EdoxabanTable=!display_modal.EdoxabanTable" style="color: #1976d2;">Essai de l'activité anti-Xa calibré pour l'édoxaban</a>
                        <a href='#' v-if="preop_selected.q3==='rivaroxaban'" @click.prevent="display_modal.RivaroxabanTable=!display_modal.RivaroxabanTable" style="color: #1976d2;">Essai de l'activité anti-Xa calibré pour le rivaroxaban</a>                        
                    </span>
                    <input  v-model="preop_selected.q10.doac_effect_level" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" min="1" name="preopAlgoNOACEffect" onclick="calc.preopAnticoagulantAlgorithmNOAC();" onkeyup="calc.preopAnticoagulantAlgorithmNOAC();">
                    <div class="validationMessage">!</div>
            </label>
    </div>
    <div class="calcItem preopAlgo_step05">
            <label>
                    <input v-model="preop_selected.q10.doac_effect_unknown" @click="syncDoacEffectLevel" type="checkbox" name="preopAlgoNOACEffectNotAvailable" onclick="calc.preopAnticoagulantAlgorithmNOAC()">
                    Non disponible ou effet de l’AOD inconnu
            </label>
    </div>

    <div class="calcItem preopAlgo_step05" style="text-align:center;">
            <!-- <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step02')" class="calcButton">&lt; Retour</button> -->
            <button class="calcButton" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step05', '.preopAlgo_step05D');">Suivant &gt;</button>
    </div>
            <div class="calcItem preopAlgo_step05D">
                    <div class="calcItem preopAlgo_step05P8">
                            <strong>Recommandations</strong>
                            <ul>
                                    <li>Effectuer l’intervention chirurgicale/intervention</li>
                            </ul>
                    </div>
                    <div class="calcItem preopAlgo_step05P7">
                            <strong>Recommendations</strong><span class="error--text" v-show="debug===true">POPUP 13</span>
                            <ul>
                                    <li>Réévaluer l’effet anticoagulant juste avant l’intervention chirurgicale.</li>
                            </ul>
                    </div>
                    <div class="calcItem preopAlgo_step05P9d"><span class="error--text" v-show="debug===true">POPUP 6 / POPUP 10</span>
                        <p>1. Déterminer la concentration plasmatique de dabigatran à l’aide <v-btn dense text link class="px-1" @click="display_modal.DabigatranTable=!display_modal.DabigatranTable" style="cursor: pointer;text-transform: none;"><u>du temps de thrombine diluée/de l’épreuve Hemoclot<sup>&reg;</sup></u></v-btn></p>

<ul>
<li>Si le taux de dabigatran est &lt;30 &ndash; 50 ng/mL : aucune inversion requise</li>
<li>Si l’épreuve du temps de thrombine diluée n’est pas disponible, vérifier le schéma posologique, le moment d’administration de la dernière dose et la clairance de la créatinine pour déterminer la présence du médicament</li>
</ul>
<div class="d-flex">   
<button href='#' @click="display_modal.HalfLifeDTable=!display_modal.HalfLifeDTable" style="padding: 5px;border: 1px solid black;border-radius: 10px; background: cornflowerblue; margin-left: 2px; margin-right: 2px;">Voir la demi-vie du dabigatran</button>
<button href='#' @click="display_modal.DabigatranTable=!display_modal.DabigatranTable" style="padding: 5px;border: 1px solid black;border-radius: 10px; background: cornflowerblue;margin-left: 2px; margin-right: 2px;">Voir l’interprétation des tests de coagulation</button>
</div>
<p>2. Si le taux de dabigatran est &ge; 30-50 ng/mL, OU si le temps de thrombine diluée n’est pas disponible et que l’on soupçonne des taux de dabigatran cliniquement significatifs :</p>

<ul>
<li>L’idarucizumab s’administre en 2 perfusions bolus de 50 mL contenant chacune 2,5 g d’idarucizumab (total de 5 g), espacées d’un maximum de 15 minutes.</li>
</ul>

<p>3. Si l’idarucizumab n’est pas disponible : substituer un</p>

<ul>
<li>Concentré de complexe prothrombique ou CCP (à 50 unités/kg, max. de 3000 unités)
<ul>
<li>OCTAPLEX&reg; par voie i.v. à 1 mL/min, suivi d’un débit maximal de 2-3 mL/min (180 mL/h)</li>
<li>BERIPLEX&reg; par voie i.v. à 1 mL, suivi d’un débit maximal de 8 mL/min (480 mL/h)</li>
</ul>
</li>
<li>CCP contre-indiqué en cas de thrombocytopénie induite par l’héparine</li>
<li>FEIBA&reg; à 2000 unités (50 unités/kg, max. de 2000 unités)</li>
<li>Informer les patients/leur famille concernant le faible risque de thrombose liée au CCP et à FEIBA&reg; (p. ex., AVC, IM, TVP, EP), mais leur faire savoir que les répercussions du report de l’intervention chirurgicale/intervention l’emportent probablement sur ce risque.</li>
</ul>

<p>4. Traitement d’appoint</p>

<ul>
<li>Hémodialyse (élimination d’environ 65 % du médicament après 4 heures) si possible</li>
</ul>

<p>&nbsp;</p>

<p><strong>Avis de non-responsabilité :</strong><strong> </strong><em>Ces recommandations générales ne doivent pas se substituer au jugement du clinicien. Les médecins doivent pondérer les risques et les avantages relatifs de l’application de ces recommandations chez chaque patient.<br />
<br />
Les épreuves de coagulation et les seuils recommandés relativement aux concentrations plasmatiques d’AOD cliniquement pertinentes proviennent d’estimations fondées sur les données probantes disponibles devant faire l’objet d’autres études et être corroborées. Le seuil peut être plus ou moins élevé, selon l’épreuve utilisée.</em></p>
                    </div>
                    <div class="calcItem preopAlgo_step05P9d">
                        <ol>
                            <li>Déterminer la concentration plasmatique de dabigatran à l’aide <a href='#' @click.prevent="display_modal.DabigatranTable=!display_modal.DabigatranTable" style="color: #1976d2;">du temps de thrombine diluée (TTd)/de l’épreuve Hemoclot<sup>&reg;</sup></a>. Si l’épreuve du temps de thrombine diluée n’est pas disponible, vérifier le schéma posologique, le moment d’administration de la dernière dose et la clairance de la créatinine pour déterminer la présence du médicament. <a href='#' @click.prevent="display_modal.HalfLifeDTable=!display_modal.HalfLifeDTable" style="color: #1976d2;">Voir la demi-vie du dabigatran</a>.</li>
                            <li>Envisager l’idarucizumab (antidote spécifique du dabigatran)
                                <ul>
                                    <li>2,5 g par perfusion i.v., puis 2,5 g par perfusion i.v. dans les 15 minutes suivantes</li>
                                </ul>
                            </li>
                            <li>
                            Si l’idarucizumab n’est pas disponible, envisager un traitement prohémostatique.
                            <!-- Consider pro-hemostatic therapy -->
                                <ul>
                                    <li>Concentré de complexe prothrombique ou CCP (50 unités/kg, max. de 3000 unités)
                                        <ul>
                                            <li>OCTAPLEX<sup>&reg;</sup>  par voie i.v. à 1 mL/min, suivi d’un débit maximal de 2-3 mL/min (180 mL/h)</li>
                                            <li>BERIPLEX<sup>&reg;</sup> par voie i.v. à 1 mL, suivi d’un débit maximal de 8 mL/min (480 mL/h)</li>
                                        </ul>
                                    </li>
                                    <li>CCP contre-indiqué en cas de thrombocytopénie induite par l’héparine</li>
                                    <li>FEIBA à <span class="FEIBA_VAR"></span> unités (50 unités/kg, max. de 2000 unités)</li>
                                    <li>Informer les patients/leur famille concernant le faible risque de thrombose lié au CCP et à FEIBA (p. ex., AVC, IM, TVP, EP)</li>
                                </ul>
                            </li>
                            <li>Traitement d’appoint
                                <ul>
                                    <li>Hémodialyse (élimination d’environ 65 % du médicament après 4 heures) si possible</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                    <div class="calcItem preopAlgo_step05P9a"><span class="error--text" v-show="debug===true">POPUP 5 / POPUP 9</span>
                        <ol>
                            <li>Vérifier la présence d’apixaban avec le <a href='#' @click.prevent="display_modal.ApixabanTable=!display_modal.ApixabanTable" style="color: #1976d2;">dosage étalonné de l’activité anti-Xa de l’apixaban</a>. Si le dosage étalonné de l’activité anti-Xa de l’apixaban n’est pas disponible, vérifier le schéma posologique, le moment d’administration de la dernière dose et la clairance de la créatinine pour déterminer la présence du médicament. <a href='#' @click.prevent="display_modal.HalfLifeATable=!display_modal.HalfLifeATable" style="color: #1976d2;">Voir la demi-vie de l’apixaban</a>.</li>
                            <li>Envisager un traitement prohémostatique (aucun argument clinique) 
                                <ul>
                                    <li>Concentré de complexe prothrombique ou CCP (50 unités/kg, max. de 3000 unités) 
                                        <ul>
                                            <li>OCTAPLEX<sup>&reg;</sup>  par voie i.v. à 1 mL/min, suivi d’un débit maximal de 2-3 mL/min (180 mL/h)</li>
                                            <li>BERIPLEX<sup>&reg;</sup> par voie i.v. à 1 mL, suivi d’un débit maximal de 8 mL/min (480 mL/h)</li>
                                        </ul>
                                    </li>
                                    <li>CCP contre-indiqué en cas de thrombocytopénie induite par l’héparine</li>
                                    <li>Informer les patients/leur famille concernant le faible risque de thrombose liée au CCP (p. ex., AVC, IM, TVP, EP)</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                    <div class="calcItem preopAlgo_step05P9r"><span class="error--text" v-show="debug===true">POPUP 8 / POPUP 12</span>
                        <ol>
                            <li>Vérifier la présence du rivaroxaban avec le <a href='#' @click.prevent="display_modal.RivaroxabanTable=!display_modal.RivaroxabanTable" style="color: #1976d2;">le dosage étalonné de l’activité anti-Xa du rivaroxaban</a>. Si le dosage étalonné de l’activité anti-Xa du rivaroxaban n’est pas disponible, vérifier le schéma posologique, le moment d’administration de la dernière dose et la clairance de la créatinine pour déterminer la présence du médicament. <a href='#' @click.prevent="display_modal.HalfLifeRTable=!display_modal.HalfLifeRTable" style="color: #1976d2;">Voir la demi-vie du rivaroxaban</a>.</li>
                            <li>Envisager un traitement prohémostatique (aucun argument clinique)
                                <ul>
                                    <li>Concentré de complexe prothrombique ou CCP (50 unités/kg, max. de 3000 unités)
                                        <ul>
                                            <li>OCTAPLEX<sup>&reg;</sup> par voie i.v. à 1 mL/min, suivi d’un débit maximal de 2-3 mL/min (180 mL/h)</li>
                                            <li>BERIPLEX<sup>&reg;</sup> par voie i.v. à 1 mL, suivi d’un débit maximal de 8 mL/min (480 mL/h)</li>
                                        </ul>
                                    </li>
                                    <li>CCP contre-indiqué en cas de thrombocytopénie induite par l’héparine</li>
                                    <li>Informer les patients/leur famille concernant le faible risque de thrombose liée au CCP (p. ex., AVC, IM, TVP, EP)</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                    <div class="calcItem preopAlgo_step05P9e"><span class="error--text" v-show="debug===true">POPUP 11</span>
                        <ol>
                            <li>Vérifier la présence d’edoxaban avec le <a href='#' @click.prevent="display_modal.EdoxabanTable=!display_modal.EdoxabanTable" style="color: #1976d2;">dosage étalonné de l’activité anti-Xa de l’edoxaban</a>. Si le dosage étalonné de l’activité anti-Xa de l’edoxaban n’est pas disponible, vérifier le schéma posologique, le moment d’administration de la dernière dose et la clairance de la créatinine pour déterminer la présence du médicament. <a href='#' @click.prevent="display_modal.HalfLifeETable=!display_modal.HalfLifeETable" style="color: #1976d2;">Voir la demi-vie de l’edoxaban</a>.</li>
                            <li>Envisager un traitement prohémostatique (aucun argument clinique)
                                <ul>
                                    <li>Concentré de complexe prothrombique ou CCP (50 unités/kg, max. de 3000 unités)
                                        <ul>
                                            <li>OCTAPLEX<sup>&reg;</sup> par voie i.v. à 1 mL/min, suivi d’un débit maximal de 2-3 mL/min (180 mL/h)</li>
                                            <li>BERIPLEX<sup>&reg;</sup> par voie i.v. à 1 mL, suivi d’un débit maximal de 8 mL/min (480 mL/h)</li>
                                        </ul>
                                    </li>
                                    <li>CCP contre-indiqué en cas de thrombocytopénie induite par l’héparine</li>
                                    <li>Informer les patients/leur famille concernant le faible risque de thrombose liée au CCP (p. ex., AVC, IM, TVP, EP)</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
            </div>

    <!--preopAlgo QID-5 / QID-6 -->
    <div class="preopAlgo_step4 calcItem groupItem">
        <span class="error--text" v-show="debug===true">QID-5/6</span>
            <h3>Indication de l’antithrombotique</h3>
            <label><input v-model="preop_selected.q5" type="radio" name="preopAlgo_IndicationAT" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step4', '.preopAlgo_IndicationAT_AF');" value="atrial fibrillation"> Fibrillation auriculaire</label>
            <label><input v-model="preop_selected.q5" type="radio" name="preopAlgo_IndicationAT" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step4', '.preopAlgo_IndicationAT_DVT_PE');" value="DVT/PE"> TVP/EP</label>
            <label class="warfarinOnly"><input v-model="preop_selected.q6" type="radio" name="preopAlgo_IndicationAT" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step4', '.preopAlgo_IndicationAT_Valve');" value="mechanical valve"> Valve mécanique</label>
    </div>
    <div class="calcItem preopAlgo_step4" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step4', '.preopAlgo_step3b')" class="calcButton">&lt; Retour</button>
    </div>

    <!-- QID-7 -->
    <div class="preopAlgo_IndicationAT_AF calcItem groupItem">
        <span class="error--text" v-show="debug===true">QID-7</span>
            <h3>Risque de thromboembolie (fibrillation auriculaire)</h3>
            <label>
                    <input v-model="preop_selected.q7" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_AF', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="high"> Élevé
            </label>
            <ul class="examples">
                    <li>Le score CHADS est de 5-6, ou</li>
                    <li>AVC/AIT dans les 3 mois précédents, ou</li>
                    <li>Rétrécissement mitral rhumatismal</li>
            </ul>
            <label>
                    <input v-model="preop_selected.q7" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_AF', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="moderate"> Modéré
            </label>
            <ul class="examples">
                    <li>Le score CHADS est de 3-4</li>
            </ul>
            <label>
                    <input v-model="preop_selected.q7" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_AF', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="low"> Faible
            </label>
            <ul class="examples">
                    <li>Le score CHADS est de 0-2</li>
            </ul>
    </div>
    <div class="calcItem preopAlgo_IndicationAT_AF"  style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_AF', '.preopAlgo_step4')" class="calcButton">&lt; Retour</button>
    </div>

    <!--preopAlgo QID-8 -->
    <div class="preopAlgo_IndicationAT_DVT_PE calcItem groupItem">
        <span class="error--text" v-show="debug===true">QID-8</span>
            <h3>Risque de thromboembolie (TVP/EP)</h3>
            <label>
                    <input v-model="preop_selected.q8" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_DVT_PE', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="high"> Élevé
            </label>
            <ul class="examples">
                    <li>TVP/EP 3 mois auparavant, ou</li>
                    <li>Thrombophilie sévère avérée*</li>
            </ul>
            <label>
                    <input v-model="preop_selected.q8" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_DVT_PE', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="moderate"> Modéré
            </label>
            <ul class="examples">
                    <li>TEV 3 à 12 mois auparavant, ou</li>
                    <li>Récurrence de TEV, ou</li>
                    <li>Cancer actif</li>
            </ul>
            <label>
                    <input v-model="preop_selected.q8" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_DVT_PE', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="low"> Faible
            </label>
            <ul class="examples">
                    <li>Une seule TEV > 12 mois auparavant</li>
            </ul>
    </div>
    <div class="calcItem preopAlgo_IndicationAT_DVT_PE"  style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_DVT_PE', '.preopAlgo_step4')" class="calcButton">&lt; Retour</button>
    </div>

    <!--preopAlgo QID-9 -->
    <div class="preopAlgo_IndicationAT_Valve calcItem groupItem">
        <span class="error--text" v-show="debug===true">QID-9</span>
            <h3>Valve mécanique</h3>
            <label>
                    <input v-model="preop_selected.q9" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_Valve', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="high"> Risque élevé
            </label>
            <ul class="examples">
                    <li>Toute prothèse valvulaire mitrale, ou</li>
                    <li>Prothèse aortique avec valves à bille ou à disque basculant, ou</li>
                    <li>AVC/AIT dans les 6 mois précédents</li>

            </ul>
            <label>
                    <input v-model="preop_selected.q9" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_Valve', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="moderate"> Risque modéré
            </label>
            <ul class="examples">
                    <li>Valve aortique à double ailette associée à une fibrillation auriculaire ou à un score CHADS ≥ 1</li>
            </ul>
            <label>
                    <input v-model="preop_selected.q9" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_Valve', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="low"> Faible risque
            </label>
            <ul class="examples">
                    <li>Valve aortique à double ailette en l’absence de fibrillation auriculaire, et score CHADS = 0</li>
            </ul>
    </div>
    <div class="calcItem preopAlgo_IndicationAT_Valve"  style="text-align:center;">
            <button onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_Valve', '.preopAlgo_step4')" class="calcButton">&lt; Retour</button>
    </div>



    <div class="preopAlgoResults calcItem">
            <h3>Sommaire</h3>
            <div class="summary"></div>
            <br>
            <h3>Recommandations pour la phase préopératoire</h3>
            <div class="results"></div>
            <br>
            <h3>Recommandations pour la phase postopératoire</h3>
            <div class="postop"></div>
            <a href="javascript:void(0)" class="bridgingSample" @click.prevent="display_modal.sampleBridgingProtocolWrapper=!display_modal.sampleBridgingProtocolWrapper">Cliquer ici pour voir un exemple de protocole de relais périopératoire.</a>

            <div class="drugScheduleSection">
                    <h3>Calendrier</h3>
                    <table class="drugScheduleTable">
                            <tr>
                                    <th>Jour</th>
                                    <th>Directives</th>
                            </tr>
                            <tr id="periopScheduleTemplate">
                                    <td class="$day"></td>
                                    <td class="$instructions"></td>
                            </tr>
                    </table>
            </div>
    </div>    
</template>
<template v-else>
    <div class="calcDescription perioperativeAnticoagulantAlgorithm">
            <h2>Perioperative Anticoagulant Management Algorithm</h2>
    </div>
    <div class="perioperativeAnticoagulantAlgorithm preopAlgo_step01 calcItem groupItem step01">
        <span class="error--text" v-show="debug===true">QID-1</span>
        <h3>Please select the type of surgery: </h3>
            <div>
                    <label>
                            <input v-model="preop_selected.q1" type="radio" name="preopAlgoSurgeryType" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step01', '.preopAlgo_step1');" value="elective"> Elective
                    </label>
            </div>
            <div>
                    <label>
                            <input v-model="preop_selected.q1" type="radio" name="preopAlgoSurgeryType" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step01', '.preopAlgo_step02');" value="emergency"> Emergency Surgery/procedure &lt;12 h (e.g. intracranial bleed, ruptured viscus, cardiac tamponade)

                    </label>
            </div>
            <div>
                    <label>
                            <input v-model="preop_selected.q1" type="radio" name="preopAlgoSurgeryType" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step01', '.preopAlgo_step02');" value="urgent"> Urgent surgery/procedure 12-24 h (e.g. hip fracture repair, acute cholecystitis)
                    </label>
            </div>
    </div>
    <div class="preopAlgo_step1 calcItem groupItem step1">
        <span class="error--text" v-show="debug===true">QID-2</span>
            <h3>Procedural Bleeding Risk</h3>
            <div>
                    <label>
                            <input v-model="preop_selected.q2" type="radio" name="preopAlgoBleedRisk" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step1', '.preopAlgo_step2');" value="minor non-dental"> Low (minor non-dental procedure)
                    </label>
                    <a href="javascript:calcToggleExamples('#periopExamples1');" class="toggleExamplesWrapper">?</a>
                    <ul id="periopExamples1" class="examples">
                            <li>Cataract surgery</li>
                            <li>Dermatologic procedures (e.g. biopsy)</li>
                            <li>Gastroscopy or colonoscopy without biopsies</li>
                            <li>Coronary angiography</li>
                            <li>Permanent pacemaker insertion or internal defibrillator placement (if bridging anticoagulation is not used)</li>
                            <li>Selected procedures (e.g. thoracentesis, paracentesis, arthrocentesis)</li>
                    </ul>
            </div>
            <div>
                    <label>
                            <input v-model="preop_selected.q2" type="radio" name="preopAlgoBleedRisk" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step1', '.preopAlgo_step2');" value="minor dental"> Low (minor dental procedure)
                    </label>
                    <a href="javascript:calcToggleExamples('#periopExamples2');" class="toggleExamplesWrapper">?</a>
                    <ul id="periopExamples2" class="examples">
                            <li>Dental extractions (1 or 2 teeth)</li>
                            <li>Endodontic (root canal) procedure</li>
                            <li>Subgingival scaling or other cleaning</li>
                    </ul>
            </div>
            <div>
                    <label>
                            <input v-model="preop_selected.q2" type="radio" name="preopAlgoBleedRisk" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step1', '.preopAlgo_step2');" value="moderate"> Moderate
                    </label>
                    <a href="javascript:calcToggleExamples('#periopExamples3');" class="toggleExamplesWrapper">?</a>
                    <ul id="periopExamples3" class="examples">
                            <li>Abdominal surgery (e.g. cholecystectomy, hernia repair, colon resection)</li>
                            <li>Other general surgery (e.g. breast)</li>
                            <li>Other intrathoracic surgery</li>
                            <li>Other orthopedic surgery</li>
                            <li>Other vascular surgery</li>
                            <li>Non-cataract ophthalmologic surgery</li>
                            <li>Gastroscopy or colonoscopy with biopsies</li>
                            <li>Selected procedures (e.g. bone marrow biopsy, lymph node biopsy)</li>
                            <li>Complex dental procedure (e.g. multiple tooth extractions)</li>
                    </ul>
            </div>
            <div>
                    <label>
                        <input v-model="preop_selected.q2" type="radio" name="preopAlgoBleedRisk" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step1', '.preopAlgo_step2');" value="high"> High
                    </label>
                    <a href="javascript:calcToggleExamples('#periopExamples4');" class="toggleExamplesWrapper">?</a>
                    <ul id="periopExamples4" class="examples">
                            <li>Any surgery or procedure with neuraxial &nbsp;(spinal or epidural) anesthesia</li>
                            <li>Neurosurgery (intracranial or spinal)</li>
                            <li>Cardiac surgery (e.g. CABG, heart valve replacement)</li>
                            <li>Major vascular surgery (e.g. aortic aneurysm repair, aortofemoral bypass)</li>
                            <li>Major orthopedic surgery (e.g. hip/knee joint replacement surgery)</li>
                            <li>Lung resection surgery</li>
                            <li>Urological surgery (e.g. prostatectomy, bladder tumour resection)</li>
                            <li>Extensive cancer surgery (e.g. pancreas, liver)</li>
                            <li>Intestinal anastomosis surgery</li>
                            <li>Reconstructive plastic surgery</li>
                            <li>Selected procedures (e.g. kidney biopsy, prostate biopsy, cervical cone biopsy, pericardiocentesis, colonic polypectomy)</li>

                    </ul>
            </div>
    </div>
    <div class="calcItem preopAlgo_step1" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step1', '.preopAlgo_step01')" class="calcButton">&lt; Back</button>
    </div>

    <div class="preopAlgo_step2 calcItem groupItem">
        <span class="error--text" v-show="debug===true">QID-3</span>
            <h3>Anticoagulant Used</h3>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step3');" value="apixaban">
                    Apixaban
            </label>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step3');" value="dabigatran">
                    Dabigatran
            </label>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step3');" value="edoxaban">
                    Edoxaban
            </label>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step3');" value="rivaroxaban">
                    Rivaroxaban
            </label>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step3');" value="warfarin">
                    Warfarin
            </label>
    </div>
    <div class="calcItem preopAlgo_step2" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step1')" class="calcButton">&lt; Back</button>
    </div>
    <div class="preopAlgo_step02 calcItem groupItem">
        <span class="error--text" v-show="debug===true">QID-3</span>
            <h3>Anticoagulant Used</h3>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step02D');" value="apixaban">
                    Apixaban
            </label>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step02D');" value="dabigatran">
                    Dabigatran
            </label>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step02D');" value="edoxaban">
                    Edoxaban
            </label>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step02D');" value="rivaroxaban">
                    Rivaroxaban
            </label>
            <label>
                    <input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step02D');" value="warfarin">
                    Warfarin
            </label>
    </div>
    <div class="calcItem preopAlgo_step02" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step01')" class="calcButton">&lt; Back</button>
    </div>

    <!-- PreopAlog POPUP-1 -->    
    <div class="preopAlgo_step02N calcItem">
        <span class="error--text" v-show="debug===true">POPUP 1</span>
        <h3>Initial management</h3>
    </div>
    <div class="calcItem preopAlgo_step02N">
            <ul>
                    <li>Refer for procedural/surgical intervention</li>
                    <li>Check hemoglobin concentration and platelet count to help guide transfusion</li>
                    <li>Transfusion therapy
                            <ul>
                                    <li>RBC transfusion for symptomatic anemia</li>
                                    <li>Platelets for thrombocytopenia (platelets less than 50 x 10<sup>9</sup>/L) or patients on antiplatelet agents</li>
                            </ul>
                    </li>
                    <li>Discontinue anticoagulant </li>
            </ul>
    </div>
    <div class="calcItem preopAlgo_step02N" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step02N', '.preopAlgo_step02')" class="calcButton">&lt; Back</button>
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step02N', '.preopAlgo_step03b');" class="calcButton">Next &gt;</button>
    </div>

    <div class="preopAlgo_step02W calcItem">
            <span class="error--text" v-show="debug===true">POPUP 1</span>
            <h3>Initial management</h3>
    </div>
    <div class="calcItem preopAlgo_step02W">
            <ul>
                    <li>Refer for procedural/surgical intervention</li>
                    <li>Check hemoglobin concentration and platelet count to help guide transfusion</li>
                    <li>Transfusion therapy
                            <ul>
                                    <li>RBC transfusion for symptomatic anemia</li>
                                    <li>Platelet transfusion for thrombocytopenia (platelets less than 50 x 10<sup>9</sup>/L) or patients on antiplatelet agents</li>
                            </ul>
                    </li>
                    <li>Discontinue anticoagulant </li>
            </ul>
    </div>
    <div class="calcItem preopAlgo_step02W" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step02W', '.preopAlgo_step02')" class="calcButton">&lt; Back</button>
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step02W', '.preopAlgo_step03bw')" class="calcButton">Next &gt;</button>
    </div>


    <!--preopAlgo QID-4 -->
    <div class="preopAlgo_step3b preopAlgo_step03b calcItem">
        <span class="error--text" v-show="debug===true">QID-4</span>
            <h3>Creatinine Clearance</h3>
    </div>
    <div class="preopAlgo_step03bw calcItem">
        <span class="error--text" v-show="debug===true">QID-11</span>
            <h3>Weight</h3>
    </div>
    <div class="calcItem preopAlgo_step3b preopAlgo_step03b">
            <label>
                    <input v-model="preop_selected.q4.female" type="checkbox" name="preopAlgoCrClisFemale" onclick="calc.preopAnticoagulantAlgorithmCrCl()">
                    Female patient
            </label>
    </div>
    <div class="calcItem preopAlgo_step3b preopAlgo_step03b">
            <label class="withNumber">
                    Age (years)
                    <input v-model="preop_selected.q4.age" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" onchange="calc.preopAnticoagulantAlgorithmCrCl()" onkeyup="calc.preopAnticoagulantAlgorithmCrCl()" name="preopAlgoCrClAge">
                    <div class="validationMessage">!</div>
            </label>
    </div>
    <div class="calcItem preopAlgo_step03bw">
            <label class="withNumber">
                    Weight (kg)
                    <input v-model="preop_selected.q11.weight" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" onchange="" onkeyup="" name="preopAlgoWeight">
                    <div class="validationMessage">!</div>
            </label>
    </div>
    <div class="calcItem preopAlgo_step3b preopAlgo_step03b">
            <label class="withNumber">
                    Weight (kg)
                    <input v-model="preop_selected.q4.weight" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" onchange="calc.preopAnticoagulantAlgorithmCrCl()" onkeyup="calc.preopAnticoagulantAlgorithmCrCl()" name="preopAlgoCrClweight">
                    <div class="validationMessage">!</div>
            </label>
    </div>
    <div class="calcItem preopAlgo_step3b preopAlgo_step03b">
            <label class="withNumber">
                    Serum Creatinine <span class="calcUnit">(µmol/L)</span>
                    <input  v-model="preop_selected.q4.serum_creatinine" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" onchange="calc.preopAnticoagulantAlgorithmCrCl()" onkeyup="calc.preopAnticoagulantAlgorithmCrCl()" name="preopAlgoCrClserumCreatinine">
                    <div class="validationMessage">!</div>
            </label>
    </div>
    <div class="calcItem preopAlgo_step3b" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step3b', '.preopAlgo_step2')" class="calcButton">&lt; Back</button>
            <button class="calcButton" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step3b', '.preopAlgo_step4');">Next &gt;</button>
    </div>
    <div class="calcItem preopAlgo_step03b" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step03b', '.preopAlgo_step02BackD')" class="calcButton">&lt; Back</button>
            <button class="calcButton" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step03b', '.preopAlgo_step03bD');">Next &gt;</button>
    </div>
    <div class="calcItem preopAlgo_step03bw" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step03bw', '.preopAlgo_step02BackD')" class="calcButton">&lt; Back</button>
            <button class="calcButton" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step03bw', '.preopAlgo_step03bD');">Next &gt;</button>
    </div>

    <div class="calcItem preopAlgo_step04">
        <span class="error--text" v-show="debug===true">QID-12</span>
            <label class="withNumber">
                    INR
                    <input v-model="preop_selected.q12" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" name="preopAlgoINR">
                    <div class="validationMessage">!</div>
            </label>
    </div>
    <div class="calcItem preopAlgo_step04" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step04', '.preopAlgo_step02W')" class="calcButton">&lt; Back</button>
            <button class="calcButton" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step04', '.preopAlgo_step04D');">Next &gt;</button>
    </div>
            <div class="calcItem preopAlgo_step04D">
                    <div class="calcItem preopAlgo_step04P3">
                            <strong>Recommendations</strong>
                            <p>Repeat INR before surgery</p>
                            <ul>
                                    <li>If INR &le; 1.5 &#8594; no reversal needed</li>
                                    <li>Proceed to Surgery/Procedure</li>
                                    <li>Consider PCC for very high bleeding risk surgeries/proceedures</li>
                            </ul>
                    </div>
                    <div class="calcItem preopAlgo_step04P4">
                            <strong>Recommendations</strong>
                            <br>Give vitamin K, 10 mg IV

                            <br><br>
                            Repeat INR  before surgery
                            <ul>
                                    <li>If INR &le; 1.5 &#8594; no reversal needed, Proceed to Surgery/Procedure</li>
                                    <li>If INR >1.5, consider PCC</li>
                            </ul>
                    </div>
                    <div class="calcItem preopAlgo_step04P2">
                            <strong>Recommendations</strong>
                            <ul>
                                    <li>If INR &le; 1.5 &#8594; no reversal needed</li>
                                    <li>Proceed to Surgery/Procedure</li>
                            </ul>
                    </div>
                    <div class="calcItem preopAlgo_step04P5">
                            <strong>Recommendations</strong>
                            <p><strong>Give vitamin K (10 mg in 50 mL normal saline IV STAT) and <span class="PCC_VAR"></span> units of PCC (DO NOT REPEAT IF ALREADY GIVEN)</strong> <a href='#' @click.prevent="display_modal.PCCDosingWrapper=!display_modal.PCCDosingWrapper" style="color: #1976d2;">PCC Dosing and Administration</a>:</p>
                            <ul>
                                    <li>Infuse PCC intravenously:
                                            <ul>
                                                    <li>OCTAPLEX<sup>&reg;</sup> 1 mL/min followed by maximum 2-3 mL/min (180 mL/hr)</li>
                                                    <li>BERIPLEX<sup>&reg;</sup> IV at 1 mL/min followed by maximum 8 mL/min (480 mL/hr)</li>
                                            </ul>
                                    </li>
                                    <li>PCC contraindicated in heparin-induced thrombocytopenia</li>
                                    <li>Inform patients/families regarding small (&lt;2%) thrombotic risk of PCC (e.g. stroke MI, DVT, PE), but consequences of uncontrolled surgical bleeding likely exceed this risk
                                    <ul>
                                            <li>If PCC is unavailable or contraindicated transfuse fresh frozen plasma 10-15 mL/kg (approx. 3-4 units for adults)</li>
                                    </ul></li>
                                    <li>If INR not reported or weight unknown and cannot delay PCC administration give PCC 2000 units IV and vitamin K 10 mg IV STAT</li>
                            </ul>
                            <strong>Repeat INR 15 min after PCC infusion</strong>
                            <br>
                            If INR &le; 1.5: Warfarin reversed<br>
                            If INR > 1.5: Consider additional dose of PCC, consider other causes elevated INR (e.g. DIC, dilutional coagulopathy, liver failure)
                    </div>
            </div>


    <div class="calcItem preopAlgo_step04Ea" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step04Ea', '.preopAlgo_step04')" class="calcButton">&lt; Back</button>
    </div>

    
    <!--preopAlgo QID-10 -->
    <div class="preopAlgo_step05 calcItem">
        <span class="error--text" v-show="debug===true">QID-10</span>
            <h3>Lab testing for Residual DOAC Effect</h3>
    </div>
    <div class="calcItem preopAlgo_step05">
            <label class="withNumber">
                ng/mL 
                <span class="preopAlgoNOACEffectLabel">
                    <a v-if="preop_selected.q3==='apixaban'" @click.prevent="display_modal.ApixabanTable=!display_modal.ApixabanTable">Apixaban-calibrated anti-Xa activity assay</a>
                    <a v-if="preop_selected.q3==='dabigatran'" @click.prevent="display_modal.DabigatranTable=!display_modal.DabigatranTable">Dilute thrombin time (Hemoclot<sup>&reg;</sup>)</a>
                    <a v-if="preop_selected.q3==='edoxaban'" @click.prevent="display_modal.EdoxabanTable=!display_modal.EdoxabanTable">Edoxaban-calibrated anti-Xa activity assay</a>
                    <a v-if="preop_selected.q3==='rivaroxaban'" @click.prevent="display_modal.RivaroxabanTable=!display_modal.RivaroxabanTable">Rivaroxaban-calibrated anti-Xa activity assay</a>
                </span>
                    <input  v-model="preop_selected.q10.doac_effect_level" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" min="1" name="preopAlgoNOACEffect" onclick="calc.preopAnticoagulantAlgorithmNOAC();" onkeyup="calc.preopAnticoagulantAlgorithmNOAC();">
                    <div class="validationMessage">!</div>
            </label>
    </div>
    <div class="calcItem preopAlgo_step05">
            <label>
                    <input v-model="preop_selected.q10.doac_effect_unknown" @click="syncDoacEffectLevel" type="checkbox" name="preopAlgoNOACEffectNotAvailable" onclick="calc.preopAnticoagulantAlgorithmNOAC()">
                    Not available or DOAC effect not known
            </label>
    </div>

    <div class="calcItem preopAlgo_step05" style="text-align:center;">
            <!-- <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step02')" class="calcButton">&lt; Back</button> -->
            <button class="calcButton" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step05', '.preopAlgo_step05D');">Next &gt;</button>
    </div>
            <div class="calcItem preopAlgo_step05D">
                    <div class="calcItem preopAlgo_step05P8">
                            <strong>Recommendations</strong>
                            <ul>
                                    <li>Proceed to Surgery/Procedure</li>
                            </ul>
                    </div>
                    <div class="calcItem preopAlgo_step05P7">
                            <strong>Recommendations</strong>
                            <ul>
                                    <li>Reassess anticoagulant effect just before surgery</li>
                            </ul>
                    </div>
                    <div class="calcItem preopAlgo_step05P9d">
                        <p>1. Determine plasma dabigatran concentration using <v-btn dense text link class="px-1" @click="display_modal.DabigatranTable=!display_modal.DabigatranTable" style="cursor: pointer; text-transform: none;"><u>dilute thrombin time/Hemoclot</u><sup>&reg;</sup></v-btn>assay</p>

<ul>
<li>If dabigatran level &lt;30 &ndash; 50 ng/mL: no reversal</li>
<li>If dTT unavailable use dosing regimen, timing of last dose and creatinine clearance to determine presence of drug</li>
</ul>
<div class="d-flex">   
<button href='#' @click="display_modal.HalfLifeDTable=!display_modal.HalfLifeDTable" style="padding: 5px;border: 1px solid black;border-radius: 10px; background: cornflowerblue; margin-left: 2px; margin-right: 2px;">View half-life of dabigatran</button>
<button href='#' @click="display_modal.DabigatranTable=!display_modal.DabigatranTable" style="padding: 5px;border: 1px solid black;border-radius: 10px; background: cornflowerblue;margin-left: 2px; margin-right: 2px;">View interpretation of coagulation tests</button>
</div>
<p>2. If dabigatran level &ge; 30-50 ng/mL, OR dTT unavailable and clinically significant dabigatran levels suspected:</p>

<ul>
<li>Idarucizumab administered as two 50-mL bolus infusions containing 2.5 g each of idarucizumab (total 5 g) no more than 15 minutes apart</li>
</ul>

<p>3. If idarucizumab unavailable: alternative therapies</p>

<ul>
<li>Prothrombin complex concentrate (PCC) (50 units/kg, max 3000 units)
<ul>
<li>OCTAPLEX&reg; IV at 1 mL/min followed by maximum 2-3 mL/min (180 mL/hr)</li>
<li>BERIPLEX&reg; IV at 1 mL followed by maximum 8 mL/min (480 mL/hr)</li>
</ul>
</li>
<li>PCC contraindicated in heparin-induced thrombocytopenia</li>
<li>FEIBA&reg; 2000 units (50 units/kg , max 2000 units)</li>
<li>Inform patients/families regarding small thrombotic risk of PCC and FEIBA&reg; (e.g. stroke MI, DVT, PE), but consequences of delaying the surgery/procedure likely exceed this risk.</li>
</ul>

<p>4. Adjunctive therapy</p>

<ul>
<li>Hemodialysis (~65% removal after 4 hrs) if feasible</li>
</ul>

<p>&nbsp;</p>

<p><strong>Disclaimer:</strong><strong> </strong><em>These general recommendations do not replace clinical judgement. Physicians must consider relative risks and benefits in each patient in applying these recommendations.<br />
<br />
Recommended assays and thresholds for clinically relevant plasma NOAC concentrations are estimates based on available evidence that require further study/validation. The threshold may be higher or lower depending on the assay. </em></p>
                    </div>
                    <div class="calcItem preopAlgo_step05P9d">
                        <ol>
                            <li>Determine plasma dabigatran concentration using <a href='#' @click.prevent="display_modal.DabigatranTable=!display_modal.DabigatranTable" style="color: #1976d2;">dilute thrombin time (dTT)/Hemoclot<sup>&reg;</sup> assay</a>. If dTT unavailable use dosing regimen, timing of last dose and creatinine clearance to determine presence of drug. <a href='#' @click.prevent="display_modal.HalfLifeDTable=!display_modal.HalfLifeDTable" style="color: #1976d2;">View half-life of dabigatran</a>.</li>
                            <li>Consider idarucizumab (specific dabigatran reversal agent)
                                <ul>
                                    <li>2.5 grams IV infusion, repeat 2.5 grams IV infusion within 15 minutes  </li>
                                </ul>
                            </li>
                            <li>
                            If idarucizumab not available, consider pro-hemostatic therapy
                            <!-- Consider pro-hemostatic therapy -->
                                <ul>
                                    <li>Prothrombin complex concentrate (PCC) (50 units/kg, max 3000 units)
                                        <ul>
                                            <li>OCTAPLEX<sup>&reg;</sup> IV 1 mL/min followed by maximum 2-3 mL/min (180 mL/hr)</li>
                                            <li>BERIPLEX<sup>&reg;</sup> IV at 1 mL followed by maximum 8 mL/min (480 mL/hr)</li>
                                        </ul>
                                    </li>
                                    <li>PCC contraindicated in heparin-induced thrombocytopenia</li>
                                    <li>FEIBA <span class="FEIBA_VAR"></span> units (50 units/kg , max 2000 units)</li>
                                    <li>Inform patients/families regarding small thrombotic risk of PCC and FEIBA (e.g. stroke MI, DVT, PE)</li>
                                </ul>
                            </li>
                            <li>Adjunctive therapy
                                <ul>
                                    <li>Hemodialysis (~65% removal after 4 hrs) if feasible</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                    <div class="calcItem preopAlgo_step05P9a">
                        <ol>
                            <li>Determine presence of apixaban using <a href='#' @click.prevent="display_modal.ApixabanTable=!display_modal.ApixabanTable" style="color: #1976d2;">apixaban-calibrated anti-Xa activity assay</a>. If apixaban-calibrated anti-Xa activity assay unavailable use dosing regimen, timing of last dose and creatinine clearance to determine presence of drug. <a href='#' @click.prevent="display_modal.HalfLifeATable=!display_modal.HalfLifeATable" style="color: #1976d2;">View half-life of apixaban</a>.</li>
                            <li>Consider pro-hemostatic therapy (no clinical evidence)
                                <ul>
                                    <li>Prothrombin complex concentrate (PCC) (50 units/kg, max 3000 units)
                                        <ul>
                                            <li>OCTAPLEX<sup>&reg;</sup> IV 1 mL/min followed by maximum 2-3 mL/min (180 mL/hr)</li>
                                            <li>BERIPLEX<sup>&reg;</sup> IV at 1 mL followed by maximum 8 mL/min (480 mL/hr)</li>
                                        </ul>
                                    </li>
                                    <li>PCC contraindicated in heparin-induced thrombocytopenia</li>
                                    <li>Inform patients/families regarding small thrombotic risk of PCC (e.g. stroke MI, DVT, PE)</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                    <div class="calcItem preopAlgo_step05P9r">
                        <ol>
                            <li>Determine presence of rivaroxaban using <a href='#' @click.prevent="display_modal.RivaroxabanTable=!display_modal.RivaroxabanTable" style="color: #1976d2;">rivaroxaban-calibrated anti-Xa activity assay</a>. If rivaroxaban-calibrated anti-Xa activity assay unavailable use dosing regimen, timing of last dose and creatinine clearance to determine presence of drug. <a href='#' @click.prevent="display_modal.HalfLifeRTable=!display_modal.HalfLifeRTable" style="color: #1976d2;">View half-life of rivaroxaban</a>.</li>
                            <li>Consider pro-hemostatic therapy (no clinical evidence)
                                <ul>
                                    <li>Prothrombin complex concentrate (PCC) (50 units/kg, max 3000 units)
                                        <ul>
                                            <li>OCTAPLEX<sup>&reg;</sup> IV 1 mL/min followed by maximum 2-3 mL/min (180 mL/hr)</li>
                                            <li>BERIPLEX<sup>&reg;</sup> IV at 1 mL followed by maximum 8 mL/min (480 mL/hr)</li>
                                        </ul>
                                    </li>
                                    <li>PCC contraindicated in heparin-induced thrombocytopenia</li>
                                    <li>Inform patients/families regarding small thrombotic risk of PCC (e.g. stroke MI, DVT, PE)</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                    <div class="calcItem preopAlgo_step05P9e">
                        <ol>
                            <li>Determine presence of edoxaban using <a href='#' @click.prevent="display_modal.EdoxabanTable=!display_modal.EdoxabanTable" style="color: #1976d2;">edoxaban-calibrated anti-Xa activity assay</a>. If edoxaban-calibrated anti-Xa activity assay unavailable use dosing regimen, timing of last dose and creatinine clearance to determine presence of drug. <a href='#' @click.prevent="display_modal.HalfLifeETable=!display_modal.HalfLifeETable" style="color: #1976d2;">View half-life of edoxaban</a>.</li>
                            <li>Consider pro-hemostatic therapy (no clinical evidence)
                                <ul>
                                    <li>Prothrombin complex concentrate (PCC) (50 units/kg, max 3000 units)
                                        <ul>
                                            <li>OCTAPLEX<sup>&reg;</sup> IV 1 mL/min followed by maximum 2-3 mL/min (180 mL/hr)</li>
                                            <li>BERIPLEX<sup>&reg;</sup> IV at 1 mL followed by maximum 8 mL/min (480 mL/hr)</li>
                                        </ul>
                                    </li>
                                    <li>PCC contraindicated in heparin-induced thrombocytopenia</li>
                                    <li>Inform patients/families regarding small thrombotic risk of PCC (e.g. stroke MI, DVT, PE)</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
            </div>

    <!--preopAlgo QID-5 / QID-6 -->
    <div class="preopAlgo_step4 calcItem groupItem">
        <span class="error--text" v-show="debug===true">QID-5/6</span>
            <h3>Indication for Antithrombotic</h3>
            <label><input v-model="preop_selected.q5" type="radio" name="preopAlgo_IndicationAT" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step4', '.preopAlgo_IndicationAT_AF');" value="atrial fibrillation"> Atrial Fibrillation</label>
            <label><input v-model="preop_selected.q5" type="radio" name="preopAlgo_IndicationAT" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step4', '.preopAlgo_IndicationAT_DVT_PE');" value="DVT/PE"> DVT/PE</label>
            <label class="warfarinOnly"><input v-model="preop_selected.q6" type="radio" name="preopAlgo_IndicationAT" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step4', '.preopAlgo_IndicationAT_Valve');" value="mechanical valve"> Mechanical Valve</label>
    </div>
    <div class="calcItem preopAlgo_step4" style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step4', '.preopAlgo_step3b')" class="calcButton">&lt; Back</button>
    </div>

    <!-- QID-7 -->
    <div class="preopAlgo_IndicationAT_AF calcItem groupItem">
        <span class="error--text" v-show="debug===true">QID-7</span>
            <h3>Thromboembolic Risk (Atrial Fibrillation)</h3>
            <label>
                    <input v-model="preop_selected.q7" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_AF', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="high"> High
            </label>
            <ul class="examples">
                    <li>CHADS is 5 - 6, or</li>
                    <li>stroke/TIA within 3 months, or</li>
                    <li>rheumatic mitral stenosis</li>
            </ul>
            <label>
                    <input v-model="preop_selected.q7" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_AF', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="moderate"> Moderate
            </label>
            <ul class="examples">
                    <li>CHADS is 3 - 4</li>
            </ul>
            <label>
                    <input v-model="preop_selected.q7" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_AF', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="low"> Low
            </label>
            <ul class="examples">
                    <li>CHADS is 0 - 2</li>
            </ul>
    </div>
    <div class="calcItem preopAlgo_IndicationAT_AF"  style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_AF', '.preopAlgo_step4')" class="calcButton">&lt; Back</button>
    </div>

    <!--preopAlgo QID-8 -->
    <div class="preopAlgo_IndicationAT_DVT_PE calcItem groupItem">
        <span class="error--text" v-show="debug===true">QID-8</span>
            <h3>Thromboembolic Risk (DVT/PE)</h3>
            <label>
                    <input v-model="preop_selected.q8" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_DVT_PE', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="high"> High
            </label>
            <ul class="examples">
                    <li>DVT/PE within 3 months, or</li>
                    <li>Known severe thrombophilia*</li>
            </ul>
            <label>
                    <input v-model="preop_selected.q8" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_DVT_PE', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="moderate"> Moderate
            </label>
            <ul class="examples">
                    <li>VTE within 3-12 months, or</li>
                    <li>Recurrent VTE, or</li>
                    <li>Active cancer</li>
            </ul>
            <label>
                    <input v-model="preop_selected.q8" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_DVT_PE', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="low"> Low
            </label>
            <ul class="examples">
                    <li>Single VTE > 12 months prior</li>
            </ul>
    </div>
    <div class="calcItem preopAlgo_IndicationAT_DVT_PE"  style="text-align:center;">
            <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_DVT_PE', '.preopAlgo_step4')" class="calcButton">&lt; Back</button>
    </div>

    <!--preopAlgo QID-9 -->
    <div class="preopAlgo_IndicationAT_Valve calcItem groupItem">
        <span class="error--text" v-show="debug===true">QID-9</span>
            <h3>Thromboembolic Risk (Mechanical Valves)</h3>
            <label>
                    <input v-model="preop_selected.q9" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_Valve', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="high"> High
            </label>
            <ul class="examples">
                    <li>Any mitral valve prothesis, or</li>
                    <li>Caged ball or tilting disc aortic prosthesis, or</li>
                    <li>stroke/TIA within 6 months  </li>

            </ul>
            <label>
                    <input v-model="preop_selected.q9" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_Valve', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="moderate"> Moderate
            </label>
            <ul class="examples">
                    <li>Bileaflet aortic valve with either atrial fibrillation or CHADS score >= 1</li>
            </ul>
            <label>
                    <input v-model="preop_selected.q9" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_Valve', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="low"> Low
            </label>
            <ul class="examples">
                    <li>Bileaflet aortic valve without atrial fibrillation and CHADS score = 0</li>
            </ul>
    </div>
    <div class="calcItem preopAlgo_IndicationAT_Valve"  style="text-align:center;">
            <button onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_Valve', '.preopAlgo_step4')" class="calcButton">&lt; Back</button>
    </div>



    <div class="preopAlgoResults calcItem">
            <h3>Summary</h3>
            <div class="summary"></div>
            <br>
            <h3>Preoperative Recommendations</h3>
            <div class="results"></div>
            <br>
            <h3>Postoperative Recommendations</h3>
            <div class="postop"></div>
            <a href="javascript:void(0)" class="bridgingSample" @click.prevent="display_modal.sampleBridgingProtocolWrapper=!display_modal.sampleBridgingProtocolWrapper">Click here for a sample of a perioperative bridging protocol.</a>

            <div class="drugScheduleSection">
                    <h3>Schedule</h3>
                    <table class="drugScheduleTable">
                            <tr>
                                    <th>Day</th>
                                    <th>Instructions</th>
                            </tr>
                            <tr id="periopScheduleTemplate">
                                    <td class="$day"></td>
                                    <td class="$instructions"></td>
                            </tr>
                    </table>
            </div>
    </div>
</template><!--  End of Preoperative Anticoagulant Mangement Algo  -->
</div>
</template>

<script>

export default {
    props: {
        language: {
            type: String,
            required: true,
            default: "en-ca"
        },
        preop_selected: {
            type: Object,
            required: false,
            default: () => ({})
        },
        preopOptionValueMap: {
            type: Object,
            required: false,
            default: () => ({})
        },
        debug: {
            type: Boolean,
            required: true,
            default: false
        },
        syncDoacEffectLevel: {
            type: Function,
            required: true,
        },
        display_modal: {
            type: Object,
            required: true
        }
    },
    components: {

    },
    data: function(){
        return {
            activeCalc: 'perioperativeAnticoagulantAlgorithm'
        }
    }
}

</script>

<style lang="scss" scoped>
a.toggleExamplesWrapper, a span.toggleExamplesWrapper {
    border-radius: 25px;
    background: #1d2a75;
    color: white;
    text-decoration: none;
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-top: 2px;
    text-align: center;
    padding-top: 2px;
    padding-right: 2px;
    border: 10px solid white;
    font-size: 20px;
    font-weight: bolder;
    font-family: 'Material Design Icons';
}

a.bridgingSample {
    color: #1976d2 !important;
    display:block; 
    margin-top:12px;
    margin-bottom: 12px;
}

.preopAlgoNOACEffectLabel {
    a {
        color: #1976d2 !important;
    }
}

@media screen and (max-width: 460px) {
    a.toggleExamplesWrapper {
            margin-top: 2px;
    }
}
</style>

