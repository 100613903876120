<template>
  <div class="vueCalc">
        <!-- <v-switch :true-value="true" :false-value="false" v-model='calcs.thrombophiliaAlgorithm' label="thrombophiliaAlgorithm"></v-switch> -->
        <thrombophiliaAlgorithm :language="language" v-if="calcs.thrombophiliaAlgorithm"/>
        <!-- <v-switch :true-value="true" :false-value="false" v-model='calcs.vipitAlgorithm' label="vipitAlgorithm"></v-switch> -->
        <vipitAlgorithm :language="language" v-if="calcs.vipitAlgorithm"/>
        <vteManagement :language="language" v-if="calcs.vteManagement"/>
        <atrialFibrillationManagement :language="language"/>
        
  </div>
</template>

<script>
import vipitAlgorithm from './vueCalculators/vipitAlgorithm'
import thrombophiliaAlgorithm from './vueCalculators/thrombophiliaAlgorithm'
import vteManagement from '../PatientSupportTools/tools/vteManagement.vue'
import atrialFibrillationManagement from './vueCalculators/atrialFibrillationManagement.vue'

export default {
    props: {
        language: {
            type: String,
            required: true
        }
    },
    components: {
        vipitAlgorithm,
        thrombophiliaAlgorithm,
        vteManagement,
        atrialFibrillationManagement
    },
    data: function(){
        return {
            calcs: {
                vipitAlgorithm: true,
                thrombophiliaAlgorithm: true,
                atrialFibrillationManagement: true,
                vteManagement: false
            }
        }
    }
}
</script>

<style lang="scss">
.vueCalc .v-label{
    margin: 10px 0px 10px 0px !important;
}
</style>