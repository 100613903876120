<template>
<div class="navHighlights">
    <v-container>
        <v-row>
            <div class="themed-header">
                <p>{{language=='en-ca' ? "I'm Looking for" : "Je recherche"}}:</p>
            </div>
            <div class="item-container">
            <template v-for="(item, itemIndex) in config.pages">
                <div v-if="!item.newTab && item.enabled" class="item" :key="`item_${itemIndex}`" :class="{'selected':isPath(item.path)}" @click="navigate(item)">
                    <div class="item-content">
                        <div class='icon' v-if="item.icon && getType(item.icon)=='svg'">
                            <!-- <svg v-if="getType(item.icon)=='svg'" :data-src="`${$store.getters.bucket}/${item.icon.Key ? item.icon.Key : item.icon}`"/> -->
                            <svg :data-src="`${$store.getters.bucket}/${getFileName(item.icon)}`"/>
                        </div>
                        <v-avatar size="100" v-else>
                            <v-img :src="`${$store.getters.bucket}/${getFileName(item.icon)}`"/>
                        </v-avatar>
                        <div class="item-text" v-html="item.label ? item.label[language] : item.label"/>
                    </div>
                </div>
                <a v-else-if="item.newTab && item.enabled" class="item" :key="`item_${itemIndex}`" :href="item.path" target="_blank">
                    <div class="item-content">
                        <div class='icon' v-if="item.icon && getType(item.icon)=='svg'">
                            <!-- <svg v-if="getType(item.icon)=='svg'" :data-src="`${$store.getters.bucket}/${item.icon}`"/> -->
                            <svg :data-src="`${$store.getters.bucket}/${getFileName(item.icon)}`"/>
                        </div>
                        <v-avatar size="100" v-else>
                            <v-img :src="`${$store.getters.bucket}/${getFileName(item.icon)}`"/>
                        </v-avatar>
                        <div class="item-text" v-html="item.label ? item.label[language] : item.label"/>
                    </div>
                </a>
            </template>
            </div>
        </v-row>
    </v-container>
</div>
</template>

<script>
// import Team from '@/components/Nav/assets/team.svg'
// import Partners from '@/components/Nav/assets/partners.svg'
// import Members from '@/components/Nav/assets/members.svg'
// import Contribute from '@/components/Nav/assets/contribute.svg'
// import Drop from '@/components/Nav/assets/drop.svg'
// import Info from '@/components/Nav/assets/info.svg'
// import Hand from '@/components/Nav/assets/hand.svg'
// import Clipboard  from '@/components/Nav/assets/clipboard.svg'
// import Checks  from '@/components/Nav/assets/checks.svg'
// import Laptop  from '@/components/Nav/assets/laptop.svg'
// import Presenter  from '@/components/Nav/assets/presenter.svg'
// import Doctor  from '@/components/Nav/assets/doctor.svg'
// import Pills  from '@/components/Nav/assets/pills.svg'
// import Shield  from '@/components/Nav/assets/shield.svg'
// import Medboard  from '@/components/Nav/assets/medboard.svg'
// import Pin  from '@/components/Nav/assets/pin.svg'
// import Profile  from '@/components/Nav/assets/profile.svg'
// import Gear  from '@/components/Nav/assets/gear.svg'

export default {
    name: 'PageNav',
    props: {
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        site: {
            type: Object,
            required: true
        },
        page: {
            type: Object,
            required: true
        }
    },
    components: {
        // Team,
        // Partners,
        // Members,
        // Contribute,
        // Drop,
        // Info,
        // Hand,
        // Clipboard,
        // Checks,
        // Laptop,
        // Presenter,
        // Doctor,
        // Pills,
        // Shield,
        // Medboard,
        // Pin,
        // Profile,
        // Gear
    },
    methods: {
        isPath: function(path){
            // let route = this.$route.path.split('/')[2]
            // let result = route==path
            let p = path.split('/')
            let r = this.$route.path.split('/')
            let result = p[p.length-1] == r[r.length-1]
            return result
        },
        navigate: function(item){
            // let target = this.$route.matched[0].path.length > 0 ? this.$route.matched[0].path+'/'+path : path
            // this.$router.push(target)
            this.$router.push('/'+item.path)
        },
        getType: function(icon){
            let fileName = this.getFileName(icon)
            let type = fileName.substr(fileName.lastIndexOf('.')+1, fileName.length).toLowerCase()

            return type
        },
        getFileName(icon){
            if(typeof icon=='string'){
                return icon
            }else{
                return icon.Key
            }
        }
    },
    computed: {
        // icon: function(){
        //     return {
        //         drop: DropIcon,
        //         info: InfoIcon,
        //         hand: HandIcon,
        //         clipboard: ClipboardIcon
        //     }
        // },
        config: function(){
            let matched = this.$route.matched
            let isAdmin = matched.filter((route)=>{return route.name=='Admin'}).length>0
            if(isAdmin){
                return this.$attrs.config
            }else{
                let site = this.site
                let page
                let tree = []
                for(let i=0; i<matched.length; i++){
                    let path = matched[i].path
                    let trace = this.$route.name=='Admin' ? '.'+this.$route.query.edit : '.home'+path.replaceAll('/', this.$route.path=='/' ? '' : '._pages.')
                    page = eval(`site${trace}`)
                    tree.push(page)
                    let loop = page.definition ? page.definition.length : 0
                    for(let c=0; c<loop; c++){
                        let definition = page.definition[c]
                        if(definition.component == this.$options.name && definition.config?.inherit){
                            let inheretFromPage = tree[i>0?i-1:0]
                            let searchResults = inheretFromPage.definition.filter((item)=>{return item.component==definition.component})
                            page.definition[c] = searchResults[0]
                        }
                    }
                }
                let results = page.definition.filter((item)=>{return item.component==this.$options.name})
                if(results[0]){
                    return results[0].config
                }else{
                    console.error('Missing config for: '+this.$options.name,{results})
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">

.navHighlights{
    width: 100%;
    background-color: $primary;
    padding: 0 24px;

    @media(max-width: 599px){
        padding: 4px;
    }

    .row {
        @media(max-width: 599px){
            display: flex;
            flex-flow: column;
        }

        .themed-header{
            position: relative;
            width: 20%;
            color: white;
            font-weight: bold;

            @media(max-width: 599px){
                width: auto;
            }

            p {
                display: block;
                width: fit-content;
                margin-top: 48px;
                margin: 48px auto 0;
                border-bottom: 3px solid $accent-light-1;
                font-size: 1.5em;

                @media(max-width: 959px){
                    font-size: 20px;
                }
                @media(max-width: 599px){
                    margin: 24px 16px;
                }
            }
        }

        .item-container{
            display: flex;
            justify-content: space-around;
            width: 80%;
            text-align: center;

            @media(max-width: 599px){
                width: auto;
                flex-wrap: wrap;
            }

            .item{
                position: relative;
                display: flex;
                justify-content: center;
                padding: 24px;
                color: white;
                font-weight: bold;
                cursor: pointer;

                @media(max-width: 959px){
                    padding: 16px;
                }
                @media(max-width: 599px){
                    width: 140px;
                    padding: 4px;
                    margin-bottom: 8px;
                }

                &.selected {
                    background-color: #e6f6fc;
                    color: $primary-light-2;

                    .icon {
                        background-color: $tc-red;
                    }
                }

                &:hover {
                    .icon {
                        background-color: $tc-red;
                    }
                }

                .icon {
                    display: inline-block;
                    border-radius: 100%;
                    background-color: $primary-light-2;
                    width: 80px;
                    height: 80px;
                    padding: 5px;
                    cursor: pointer;
                    margin-bottom: 15px;

                    @media(max-width: 959px){
                        width: 48px;
                        height: 48px;
                    }

                    svg {
                        position: relative;
                        top: 50%;
                        transform: translate(0, -50%);
                        fill: white;
                        width: 60%;
                    }
                }

                .item-text{
                    @media(max-width: 599px){
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.separator{
    width: 60px;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 25px;
}
</style>
