<template>
  <span v-if="!ignore.includes(page.name)">
    <!-- <div class="clickable" @click="navigate(`/${trace}`)">
        {{page.meta && page.meta.label ? page.meta.label[language] : page.name}}
    </div> -->
    <v-expansion-panels v-model="panel" focusable flat dense class="pa-0" :accordion="false" :hover="true" :multiple="false" v-if="page.children.length>0">
        <v-expansion-panel dense class="pa-0">
            <v-expansion-panel-header dense class="pa-0">
                <router-link :to="`/${trace}`">
                    {{page.meta && page.meta.label ? page.meta.label[language] : page.name}}
                </router-link>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <template v-for="child in page.children">
                    <Page v-if="child.definition && child.definition.length>0" :page="child" :trace="`${trace}/${child.name}`" :depth="depth+1" :maxDepth="maxDepth" :key="'sitemap_'+page.name+'_'+child.name"/>
                </template>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    <div v-else style="margin: 3px 0px 3px 0px; font-size: 1.2em;">
        <router-link :to="`/${trace}`">
            {{page.meta && page.meta.label ? page.meta.label[language] : page.name}}
        </router-link>
    </div>
    <!-- <ul v-if="depth<maxDepth">
        <template v-for="child in page.children">
            <li v-if="child.definition && child.definition.length>0" :key="'sitemap_'+page.name+'_'+child.name">
                <Page :page="child" :trace="`${trace}/${child.name}`" :depth="depth+1" :maxDepth="maxDepth"/>
            </li>
        </template>
    </ul> -->
  </span>
</template>

<script>
import Page from '@/components/SiteMap/page.vue'
export default {
    name: 'Page',
    props: {
        page: {
            type: Object,
            required: true
        },
        depth: {
            type: Number,
            required: true
        },
        maxDepth: {
            type: Number,
            required: true
        },
        trace: {
            type: String,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        }
    },
    components: {
        Page
    },
    data: function(){
        return {
            panel: []
        }
    },
    methods: {
        navigate: function(path){
            let target = `/${path.split('home/')[1]}`
            console.log('navigate',target)
            this.$router.push({path})
        }
    },
    computed: {
        ignore: function(){
            return [
                'search',
                'sign_up',
                'sign_in',
                'account'
            ]
        }
    }
}
</script>

<style scoped lang="scss">
.clickable{
    margin-left: 5px;
    display: inline-block;
    cursor: pointer;
}
// .v-expansion-panel-header {
//     border: 1px solid red !important;
// }
</style>

<style lang="scss">

.sitemap {

    .v-expansion-panel-header {

        > div {
            margin-left: 16px !important;
        }
    }
}

.v-expansion-panel-header{
    min-height: 30px !important;
}

a{
    text-decoration: none;
}
</style>
