<template>
  <div class="disclaimer">
    <v-dialog
        v-model="ui.dialog.display"
        width="400"
        persistent
    >
        <v-card>
            <center style="padding: 25px;">
                <v-img
                    :src="images.logo"
                    width="200"
                />
            </center>
            <v-card-title style="display: block; text-align: center;">
                Disclaimer
            </v-card-title>
            <v-card-text>
                Please note that the information contained herein is not to be interpreted as an alternative to medical advice from your doctor or other professional healthcare provider. If you have any specific questions about any medical matter, you should consult your doctor or other professional healthcare providers, and as such you should never delay seeking medical advice, disregard medical advice or discontinue medical treatment because of the information contained herein.
            </v-card-text>
            <v-card-actions>
                <v-spacer/>

                <v-btn @click="accept('accept_disclaimer',true)" class="themed-button-dark">
                    Ok
                </v-btn>

                <v-spacer/>

            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Logo from '@/assets/logo.png'
import cache from '@/plugins/cache.js'
export default {
    created: function(){
        let accept_disclaimer = cache.storage.local.get('accept_disclaimer')
        if(!accept_disclaimer){
            this.ui.dialog.display = true
        }
        this.$store.commit('Disclaimer',this)
    },
    data: function(){
        return {
            ui: {
                dialog: {
                    display: false
                }
            }
        }
    },
    methods: {
        accept: function(key, value){
            cache.storage.local.set(key, value)
            this.ui.dialog.display = false
        },
        display: function(){
            this.ui.dialog.display = true
        }
    },
    computed: {
        images: function(){
            return {
                logo: Logo
            }
        }
    }
}
</script>

<style scoped lang="scss">
.disclaimer{
    text-align: center;
}
</style>