<template>
  <div>
    <!-- <v-btn @click="updateMeta">updateMeta</v-btn> -->
    <!-- 
    <h1>{{page.meta.label[language]}}</h1>

    <v-btn icon small @click="debug=!debug">
        <v-icon>{{debug ? 'mdi-bug-check' : 'mdi-bug'}}</v-icon>
    </v-btn>

    
    <PAGE_CONTENT :debug="debug" :page="page" :page_key="page_key" :language="language"/> -->
    

    <!-- <template v-for="(child, child_key) in page._pages">
        <CHILD :page="child" :page_key="`${page_key}/${child_key}`" :key="child_key" :language="language"/>
    </template>     -->
  </div>
</template>

<script>
import CHILD from './page'
import PAGE_CONTENT from './pageContent.vue'

export default {
    name: "CHILD",
    props: {
        page: {
            type: Object,
            required: true
        },
        page_key: {
            type: String,
            required: false,
            default: 'home'
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        }
    },
    components: {
        CHILD,
        PAGE_CONTENT
    },
    mounted: function(){
        this.updateMeta()
    },
    data: function(){
        return {
            debug: false
        }
    },
    methods: {
        updateMeta: function(){
            let page = this.page
            let language = this.language
            let pageTitle = page.meta.label[language]
            let pageDescription = page.meta.description ? page.meta.description[language] : null
            let head = document.getElementsByTagName("head")[0]

            let title = head.querySelectorAll("title")[0]
            let meta = head.querySelectorAll("meta[name='description']")[0]


            title.text = `${pageTitle} | Thrombosis Canada`
            meta.setAttribute('content',pageDescription ? pageDescription : '')
            // console.log({
            //     head,
            //     title,
            //     meta,
            //     pageTitle
            // })
        }
    },
    computed: {
        path: function(){
            return this.$route.path
        },
        query: function(){
            return this.$route.query
        }
    },
    watch: {
        path: function(){
            this.updateMeta()
        },
        query: {
            deep: true,
            handler: function(){
                this.updateMeta()
            }
        }
    }
}
</script>

<style>

</style>