<template>
  <div>
    <v-btn icon @click="getData">
        <v-icon>
            mdi-reload
        </v-icon>
    </v-btn>
    <template v-if="ready">
        <template v-if="mode=='editor'">
            Resource Editor
            <v-select v-model="resourceType" :items="_resourceTypes" label="Resource Type"/>
            <v-btn small :disabled="resourceType===null || resourceType.length==0" @click="newResource">Add Resource</v-btn>
            | 
            <v-btn small @click="ui.category=true">Category Manager</v-btn>
        </template>

        <v-card flat>
            <v-card-text>
                <Filters :resourceTypes="_resourceTypes" :categories="_categories" @update="updateFilter"/>
            </v-card-text>            
            <v-card-text>
                <v-list dense>
                    <v-list-item class="hoverable" :class="[{published:resource.publish=='Y'},{disabled:resource.enabled=='N'}]" :dark="resource.enabled=='N'" v-for="(resource, resourceIndex) in _filteredResources" :key="`resource-${resourceIndex}`">
                        <v-list-item-action style="text-align:center;">
                            <v-icon v-if="resource.definition.type=='link'">
                                mdi-link
                            </v-icon>
                            <v-icon v-else-if="resource.definition.type=='video'">
                                mdi-file-video
                            </v-icon>
                            <v-icon v-else>
                                mdi-file
                            </v-icon>
                            <sup style="width: 100%; font-size: 8pt;">{{ resource.id }}</sup>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                <!-- {{resource.definition.title[language]}} - {{resource.definition.categories}} -->
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{resource.definition.title[language]}} - [{{resource.definition.type}}]<br>
                                <sup>Created: {{new Date(resource.created).toString()}}</sup><br>
                                <sup>Updated: {{new Date(resource.updated).toString()}}</sup>
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <div><span :class="[{'active':hasLanguage(resource,'en-ca')}]">English</span> | <span :class="[{'active':hasLanguage(resource,'fr-ca')}]">French</span></div>
                        </v-list-item-action>

                        <v-list-item-action>
                            <div>
                                <v-btn icon v-if="used(resource).length>0" @click="showUsage(resource, used(resource))">
                                    <v-icon>
                                        mdi-eye
                                    </v-icon>
                                    {{ used(resource).length }}
                                </v-btn>
                                <v-btn v-else icon disabled></v-btn>
                                <v-btn v-if="mode=='editor'" icon @click="$router.replace({query:{resource_id:resource.id}})">
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                                <v-btn v-else-if="mode=='selector'" icon @click="$emit('selectResource',{base:'/resource/:resourceId/:language',resource})">
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                            </div>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>


        <v-dialog v-model="ui.category" persistent>
            <v-card>
                <v-card-text>
                    <CategoryForm v-model="config.categories" :languages="languages" :language="language" @refresh="getData" @close="ui.category=false"/>
                </v-card-text>
            </v-card>
        </v-dialog>


        <v-dialog v-model="ui.edit" persistent>
            <v-card v-if="ui.edit">
                <v-card-title></v-card-title>
                <v-card-subtitle></v-card-subtitle>
                <v-card-text>
                    <ResourceForm @refresh="getData" v-model="_editingResource" :categories="config.categories" :resourceTypes="_resourceTypes" :languages="languages" :language="language"/>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="cancelEdit">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <template v-if="editing!=null">
                        <v-btn v-if="form.resource.enabled=='Y'" color="red" icon @click="deleteResource(editing)">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                            Disable
                        </v-btn>
                        <v-btn v-else icon color="orange" @click="restoreResource(editing)">
                            <v-icon>
                                mdi-file-restore
                            </v-icon>
                            Enable
                        </v-btn>                    
                    </template>
                    <v-spacer></v-spacer>
                    <v-btn @click="saveResource">
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="ui.usage">
            <v-card v-if="ui.usage">
                <v-card-title>
                    {{ usage.resource.definition.title[language] }}
                </v-card-title>
                <v-card-subtitle>ID: {{ usage.resource.id }}</v-card-subtitle>
                <v-card-text>
                    <v-list dense>
                        <v-list-item v-for="(page, index) in usage.instances" :key="`usage-${index}-${page}`">
                            <v-list-item-content>
                                <a href="/" target="_blank" v-if="page==''">Home</a>
                                <a v-else :href="page.replace('home.','/').replace('home','/').replaceAll('.','/')" target="_blank">{{ page.replace('home.','/').replaceAll('.','/') }}</a>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="ui.usage=false">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </template>
    <div v-else>...loading...</div>
  </div>
</template>

<script>
import ResourceForm from './ResourceManager/ResourceForm'
import CategoryForm from './ResourceManager/CategoryForm.vue'
import Filters from '@/components/Resources/filters.vue'
export default {    
    name: 'ResourcesEditor',
    components: {
        ResourceForm,
        CategoryForm,
        Filters
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: 'editor'
        }
    },
    created: async function(){
        // if(!(this.config)){
        //     this.config = {}
        // }
        // if(!this.config.resources){
        //     this.$set(this.config,'resources',[])
        // }
        // if(!this.config.categories){
        //     this.$set(this.config,'categories',[])
        // }        
        await this.getData()
        if(this._resource_id){
            this.editResource(this._resource_id)
        }
    },
    data: function(){
        return {
            ready: false,
            resourceType: null,
            editing: null,
            ui: {
                edit: false,
                category: false,
                usage: false,
                language: 'en-ca'
            },
            undo: null,
            form: {
                newCategory: {
                    key: null,
                    text: {}
                },
                resource: null
            },
            config: {},
            filter: null,
            usage: {
                resource: null,
                instances: []
            }
            // filter: {
            //     string: null,
            //     type: null,
            //     categories: [],
            //     sort: 'title'
            // },
            // timer: null
        }
    },
    methods: {
        hasLanguage: function(record, lang) {
            let title = record.definition.title[lang]
            let asset = record.definition.resource.asset[lang]

            let test = [
                title && title.length>0 ? true : false,
                asset && asset.length>0 ? true : false
            ]
            
            return !test.includes(false)
        },
        showUsage: function(resource, instances){
            this.usage = {
                resource,
                instances
            }
            // console.log('showUsage',{resource, instances})
            this.ui.usage = true
        },
        used: function(resource){
            let result = false
            let site = this.$store.getters.site

            function searchResources(definition, debug){
                let resources = []
                let htmls = []

                for(let i=0; i<definition.length; i++){
                    let def = definition[i]
                    if(def.component=="TextBlock"){
                        let sections = def.config.section
                        // console.log({def, sections})
                        for(let s=0; s<sections.length; s++){
                            let sectionComponents = sections[s]

                            for(let c=0; c<sectionComponents.length; c++){
                                let sectionComponent = sectionComponents[c]
                                if(sectionComponent.config.contentType=='Resources'){
                                    resources.push(sectionComponent.content.resources)
                                }
                                if(sectionComponent.config.contentType=='html'){
                                    htmls.push(JSON.stringify(sectionComponent.content))
                                }
                            }
                        }
                    }
                }

                return {resources, htmls}
            }

            function searchNode(node, resource, trace='', found=[]){
                function searchResearchContainers(resource, containers){
                    let output = []
                    for(let i=0; i<containers.length; i++){
                        let resources = containers[i]
                        let searchResults = resources.filter((item)=>{return item.id==resource.id})
                        if(searchResults.length>0){
                            output.push(searchResults)
                        }
                    }
                    return output
                }
                function searchHtmlContainers(resource, containers, debug){
                    return containers.filter((item)=>{return item.indexOf(`/resource/${resource.id}`)>=0})
                }
                // let debug = [202,187,26].includes(resource.id)
                let debug = false //[204].includes(resource.id)
                for(let pageKey in node){
                    let page = node[pageKey]
                    let definition = page.definition// ? JSON.stringify(page.definition) : ''
                    let children = page._pages
                    let containers = searchResources(definition, debug)
                    let searchResults = {
                        resources: searchResearchContainers(resource, containers.resources), //containers.resources.filter((items)=>{return items.filter((item)=>{return item.id==resource.id}).length>0}),
                        htmls: searchHtmlContainers(resource, containers.htmls, debug)
                    }
                    if(searchResults.resources.length>0 || searchResults.htmls.length>0){
                        if(debug){
                            console.log(resource.id,{containers, searchResults, trace, pageKey})
                        }
                        found.push(`${trace}.${pageKey}`)
                    }
                    searchNode(children, resource, trace=='' ? pageKey : `${trace}.${pageKey}`, found)
                }

                if(debug && found.length>0){
                    console.log('searchNode',{
                        node, trace, found, resource: resource.id
                    })
                }
                return found

            }

            let used = searchNode(site, resource)

            return used
        },
        updateFilter: function(data){
            this.filter = data
        },
        getData: async function(){
            this.ready = false
            let response

            response = await this.sendRequest('get','/api/admin/resources')
            this.$root.resources = response.data
            this.$store.commit('resources',this.$root.resources)
            this.$set(this.config,'resources',response.data)


            response = await this.sendRequest('get','/api/admin/resources/category')
            this.$set(this.config,'categories',response.data)

            this.ready = true
        },
        resourceTemplate: function(type){
            function initLanguages(target, languages){
                console.log('initLanguages',{target, languages})
                for(let i=0; i<languages.length; i++){
                    let language = languages[i]
                    target[language] = null
                }
            }

            let languages = this.languages
            let output = {
                publish: 'Y',
                public: 'Y',
                external: 'N',
                definition: {
                    embeded: false,
                    type,
                    title: {},
                    subtitle: {},
                    description: {},
                    categories: []
                }
            }
            initLanguages(output.definition.title, languages)
            initLanguages(output.definition.subtitle, languages)
            initLanguages(output.definition.description, languages)


            output.definition.resource = {
                link: {},
                asset: {},
                headerImage: {}
            }
            initLanguages(output.definition.resource.link, languages)
            initLanguages(output.definition.resource.asset, languages)
            initLanguages(output.definition.resource.headerImage, languages)
            /* VIDEO */
            // if(type=='video'){
                // output.definition.video = {
                //     embed: {},
                //     link: {},
                //     asset: {}
                // }
                // initLanguages(output.definition.video.embed, languages)
                // initLanguages(output.definition.video.link, languages)
                // initLanguages(output.definition.video.asset, languages)
            // }

            /* LINK */
            // if(type=='link'){
                // output.definition.link = {
                //     href: {},
                //     asset: {}
                // }
                // initLanguages(output.definition.link.href, languages)
                // initLanguages(output.definition.link.asset, languages)
            // }

            /* DOCUMENT */
            // if(type=='document'){
                // output.definition.document = {
                //     embed: {},
                //     link: {},
                //     asset: {}
                // }
                // initLanguages(output.definition.document.asset, languages)
            // }

            return output
        },
        newResource: function(){
            let resourceType = this.resourceType
            this.form.resource = this.resourceTemplate(resourceType)
            this.ui.edit = true
            this.editing = null
            this.resourceType = null
        },
        saveResource: async function(){
            let form = this.form.resource
            let resource = JSON.stringify(form)
            resource = JSON.parse(resource)

            let editing = this.editing
            let response
            if(editing!=null){
                this.config.resources[editing] = resource
                response = await this.sendRequest('patch','/api/admin/resources',resource)
            }else{
                this.config.resources.push(resource)
                response = await this.sendRequest('put','/api/admin/resources',resource)
            }
            this.getData()
            this.closeEdit()
        },
        deleteResource: async function(index){
            if(confirm('Delete?')){
                // let resource = this.config.resources[index]
                // let response = await this.sendRequest('delete','/api/admin/resources',resource)
                // this.getData()
                // this.config.resources.splice(index,1)
                this.closeEdit()
                let resource = this.config.resources[index]
                resource.enabled="N"
                await this.sendRequest('patch','/api/admin/resources',resource)
                this.form.resource.enabled="N"                
            }
        },
        restoreResource: async function(index){
            let resource = this.config.resources[index]
            resource.enabled="Y"
            await this.sendRequest('patch','/api/admin/resources',resource)
            this.form.resource.enabled="Y"
            
        },
        editResource: function(resource_id){
            let resources = this.config.resources
            let index = resources.findIndex((item)=>{return item.id==resource_id})
            if(index>=0){
                this.editing = index
                let resource = JSON.stringify(this.config.resources[index])
                this.form.resource = JSON.parse(resource)
                this.ui.edit = true
            }else{
                alert('Unable to locate index of resource ID: '+resource_id)
            }
        },
        cancelEdit: function(){
            this.closeEdit()
        },
        closeEdit: function(){
            this.editing = null
            this.ui.edit = false
            this.$router.replace({query: null})
        }
    },
    computed: {
        _filteredResources: function(){
            let self = this
            let data = this.config.resources
            let output = JSON.stringify(data)
            let language = this.language
            output = JSON.parse(output)

            let filter = this.filter

            function compareTitle(a,b){
                const titleA = a.definition.title[language]
                const titleB = b.definition.title[language]
                if (titleA < titleB) {
                    return -1;
                }
                if (titleA > titleB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }

            function compareDate(a,b){
                const updatedA = new Date(a.updated)
                const updatedB = new Date(b.updated)
                if (updatedA < updatedB) {
                    return 1;
                }
                if (updatedA > updatedB) {
                    return -1;
                }

                // names must be equal
                return 0;
            }

            if(filter){
                if(filter.language.length>0){                    
                    let languageResults = output.filter((item)=>{
                        for(let i=0; i<filter.language.length; i++){
                            let language = filter.language[i]
                            if(!self.hasLanguage(item, language)){
                                return false
                            }
                        }
                        return true
                    })
                    output = languageResults
                }

                if(filter.publish!=null){
                    let searchPublishResults = output.filter((item)=>{return item.publish==filter.publish})
                    output = searchPublishResults
                }

                if(filter.enabled!=null){
                    let searchEnabledResults = output.filter((item)=>{return item.enabled==filter.enabled})
                    output = searchEnabledResults
                }

                if(filter.string!=null && filter.string.length>0){
                    let searchString = filter.string.toLowerCase()
                    let stringSearchResults = output.filter((item)=>{
                        if(item && item.definition && item.definition.title && item.definition.title[language]){
                            return item.definition.title[language].toLowerCase().indexOf(searchString)>=0
                        }
                        return false
                    })
                    output = stringSearchResults
                }
    
                if(filter.type){
                    let typeResults = output.filter((item)=>{
                        return item.definition.type == filter.type
                    })
                    output = typeResults
                }
    
                if(filter.categories.length>0){
                    let categoryResults = []
                    for(let i=0; i<output.length; i++){
                        let item = output[i]
                        for(let ii=0; ii<filter.categories.length; ii++){
                            let category = filter.categories[ii]
                            if(item.definition.categories.includes(category)){
                                categoryResults.push(item)
                            }
                        }
                    }
                    output = categoryResults
                }
    
    
                if(filter.sort=='title'){
                    output.sort(compareTitle)
                }
                if(filter.sort=='updated'){
                    output.sort(compareDate)
                }
            }

            return output
        },
        language: function(){
            return this.$store.getters.language
        },
        languages: function(){
            return this.$store.getters.languages
        },
       _editingResource: function(){
            return this.form.resource
        },
        _resourceTypes: function(){
            return [
                {
                    text: 'Link',
                    value: 'link'
                },
                {
                    text: 'Video',
                    value: 'video'
                },
                {
                    text: 'Document',
                    value: 'document'
                }
            ]
        },
        _allowAddCategory: function(){
            let form = this.form.newCategory
            if(!form.key || form.key.length==0){
                return false
            }

            for(let i=0; i<this.languages.length; i++){
                let language = this.languages[i]
                if(!form.text[language] || form.text[language].length==0){
                    return false
                }
            }

            let categories = this.config.categories
            let search = categories.filter((category)=>{return category.key==form.key})
            if(search.length!=0){
                return false
            }

            return true
        },
        _allowEdit: function(){
            let form = this.form.newCategory
            let categories = this.config.categories
            let search = categories.filter((category)=>{return category.key==form.key})
            if(search.length==1){
                return true
            }
            return false

        },
        _resource_id: function(){
            return this.$route.query.resource_id
        },
        _categories: function(){
            let output = []
            let categories = this.config.categories
            if(categories){
                for(let key in categories){
                    output.push({
                        text: key,
                        value: key
                    })
                }
            }

            return output
        }
    },
    watch: {
        config: {
            deep: true,
            handler: function(){
                // console.log('config udpated', this.config)
            }
        },
        _resource_id: function(){
            let resource_id = this._resource_id
            if(resource_id){
                this.editResource(resource_id)
            }
        }
    }
}
</script>

<style scoped lang="scss">
.hoverable{
    border: p1 solid whitesmoke;
}
.hoverable:hover{
    background-color: whitesmoke;
}

.published{
    border-left: 3px solid green;
}

.active{
    color: green;
    font-weight: bold;
}

.disabled, .disabled:hover{
    border-left: 3px solid red;
    color: whitesmoke !important;
    background-color: rgb(129, 129, 129);
}
</style>