<template>
  <div>
    <v-card>
      <v-card-title>
        Articles Manager
      </v-card-title>
      <v-card-actions>
          <v-btn class="themed-button-dark" @click="controller.add">New Article</v-btn>
      </v-card-actions>
      <v-card-text>
          <v-container fluid>
            <!-- <v-row>
              <v-col v-for="type in _types" :key="`promote-type-${type.text}`">
                {{type.text}}
              </v-col>
            </v-row> -->
            <v-row>
              <v-col>
                <h2 align="center">Article Promotion - select an article to promote/highlight</h2>                
              </v-col>
            </v-row>
            <v-row>
              <v-col v-for="type in _types" :key="`promote-type-${type.text}`">
                <v-select clearable :disabled="busy" :label="`Promote Article: ${type.text}`" v-model="form.promote[type.value]" :items="getType(type.value, articles)" @change="controller.promote({articleId:form.promote[type.value],type:type.value})"/>
              </v-col>
            </v-row>
          </v-container>

          <v-card flat>
            <v-card-title>
              Filter
            </v-card-title>
            <v-card-text>
              <v-select v-model="ui.filters.types" style="display: inline-block; width: 20%; margin: 0% 5% 0% 5%;"  multiple label="Article Type" :items="_types"/>
              <v-select v-model="ui.filters.topics" style="display: inline-block; width: 20%; margin: 0% 5% 0% 5%;"  multiple label="Topics" :items="_topics"/>
              <v-select v-model="ui.filters.audience" style="display: inline-block; width: 20%; margin: 0% 5% 0% 5%;"  multiple label="Audience" :items="_audience"/>
            </v-card-text>
          </v-card>

          <div v-if="form.article">
            <EditArticle v-model="form.article" :articles="articles" :language="language" :_types="_types" :_componentConfig="_componentConfig" :isPromoted="isPromoted(form.article)"/>
            <v-btn v-if="!form.article.id" @click="controller.save(form.article)">Save</v-btn>
            <v-btn v-if="form.article.id" @click="controller.update(form.article)">Update</v-btn>
            <v-btn @click="form.article=null">Cancel</v-btn>
          </div>
          <!-- <NewsCard v-for="article in news" :key="'preview_news_'+article.id" :params="article" :language="language"/> -->
          <div v-else style="max-width:800px; margin: 0 auto;">
            <v-list>
              <template v-for="(article, articleIndex) in articles">
                <template v-if="allow(article)">
                  <v-list-item :key="'preview_news_'+article.id" :class="[{'enabled':article.enabled=='Y', 'publish':article.publish=='Y'}]">
                    <v-list-item-avatar>
                      <!-- {{articleIndex+1}} -->
                      {{article.id}}
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{article.content.title[language]}}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        TYPE: {{article.type}} | Topics: {{article.topics}} | Audience: {{article.audience}}<br>
                        {{article.content.date}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div>
                        <v-btn v-if="article.enabled=='Y'" icon small @click="controller.edit(articleIndex)"><v-icon>mdi-pencil</v-icon></v-btn>

                        <template v-if="!isPromoted(article)">
                          <v-btn  color="green" text x-small v-if="article.enabled=='Y'" @click="controller.disable(articleIndex)">Disable</v-btn> <!--:disabled="isPromoted(article)"-->
                          <v-btn color="red" text x-small v-if="article.enabled=='N'" @click="controller.enable(articleIndex)">Enable</v-btn> <!--:disabled="isPromoted(article)" -->
                          <v-btn  color="green" text x-small v-if="article.publish=='Y'" @click="controller.unpublish(articleIndex)">Unpublish</v-btn> <!--:disabled="isPromoted(article)"-->
                          <v-btn color="red" text x-small v-if="article.publish=='N'" @click="controller.publish(articleIndex)">Publish</v-btn> <!--:disabled="isPromoted(article)" -->
                        </template>
                        <i v-else>
                          Article is promoted
                        </i>

                      </div>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="`divider-${articleIndex}`"/>
                </template>
              </template>            
            </v-list>
          </div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import EditArticle from './lib/editArticle.vue'
// import NewsCard from '@/components/Section/News/newsCard.vue'
let controller = function($){
  let controller = this

  function busy(state){
    $.busy = state
  }

  function resolveArticle(articleIndex){
    return $.articles[articleIndex]
  }

  function template(){
    let output = {
      pageName: null,
      enabled: "Y",
      publish: "Y",
      date: null,
      audience: [],
      type: [],
      topics: [],
      content: {
        displayStartDate: true,
        image: null,
        title: {},
        subtitle: {},
        body: {},
        footer: {},
        start: null,
        end: null
      }
    }

    for(let i=0; i<$.$store.getters.languages.length; i++){
      let language = $.$store.getters.languages[i]
      output.content.title[language] = null
      output.content.subtitle[language] = null
      output.content.body[language] = null
      output.content.footer[language] = null
    }
    return output
  }  

  this.getRecords = async function(){
    let response = await $.sendRequest('get','/api/admin/articles')
    $.articles = []
    $.form.article = null
    if(response.status==200){
      $.articles = response.data
      $.$store.commit('articles',response.data)  
    }



    response = await $.sendRequest('get','/api/getSite')
    if(response.status==200){
      $.$store.commit('componentConfigurations',response.data.componentConfigurations)    
    }
    
  }

  this.add = async function(){
    $.form.article = template()
  }

  this.edit = async function(articleIndex){
    let article = resolveArticle(articleIndex)
    let model = template()
    for(let key in model){
      if(article[key]==undefined){
        article[key] = model[key]
      }
    }
    console.log({
      article, model
    })
    $.form.article = article
  }

  this.save = async function(data){
    let response = await $.sendRequest('post','/api/admin/articles/new',data)
    controller.getRecords()
    console.log('controller.article.save',{
      data, response
    })
  }

  this.update = async function(data){
    busy(true)
    let response = await $.sendRequest('post','/api/admin/articles/update',data)
    controller.getRecords()
    busy(false)
  }

  this.enable = async function(articleIndex){
    let article = resolveArticle(articleIndex)
    article.enabled='Y'
    controller.update(article)
  }

  this.disable = async function(articleIndex){
    let article = resolveArticle(articleIndex)
    article.enabled='N'
    controller.update(article)
  }

  this.publish = async function(articleIndex){
    let article = resolveArticle(articleIndex)
    article.publish='Y'
    controller.update(article)
  }

  this.unpublish = async function(articleIndex){
    let article = resolveArticle(articleIndex)
    article.publish='N'
    controller.update(article)
  }

  this.promote = async function(data){
    busy(true)
    let response = await $.sendRequest('patch','/api/admin/articles/topic/promote', data)
    console.log('promote',{
      data, response
    })
    busy(false)

  }
}
export default {
  components: {
    // NewsCard,
    EditArticle
  },
  created: function(){
    this.controller = new controller(this)
    let promote = JSON.stringify(this._componentConfig.config.promote)
    this.form.promote = JSON.parse(promote)
    this.init()
  },
  data: function(){
    return {
      busy: false,
      form: {
        article: null,
        promote: {}
      },
      articles: [],
      ui: {
        filters: {
          topics: [],
          types: [],
          audience: []
        }
      },
      controller: null
    }
  },
  methods: {
    init: function(){
      this.controller.getRecords()
    },
    getType: function(type, articles){
      let language = this.language
      let filtered = articles.filter((article)=>{return article.type.includes(type)})
      let output = []
      for(let i=0; i<filtered.length; i++){
        let article = filtered[i]
        output.push({
          text: article.content.title[language],
          value: article.id
        })
      }
      return output
    },
    isPromoted: function(article){
      let form = this.form
      return (article.id==form.promote.news) || (article.id==form.promote.events) || (article.id==form.promote.webinars)
    },
    allow: function(article){
      let filters = this.ui.filters

      function check(array1, array2){
        if(array1.length==0 || array2.length==0){
          return true
        }
        let intersection = array1.filter(element => array2.includes(element));        
        return intersection.length>0
      }

      
      let tests = {
        type: check(article.type, filters.types),
        topic: check(article.topics, filters.topics),
        audience: check(article.audience, filters.audience)
      }
      

      for(let test in tests){
        if(!tests[test]){
          return false
        }
      }
      return true
    }
  },
  computed: {
    language: function(){
      return this.$store.getters.language
    },
    _componentConfig: function(){
      return this.$store.getters.componentConfigurations.articles
    },
    _types: function(){
      let types = [
        {
          text: 'News',
          value: 'news'
        },
        {
          text: 'Events',
          value: 'events'
        },
        {
          text: 'Webinars',
          value: 'webinars'
        }
      ]
      return types
    },
    _audience: function(){
      let output = [
        {
          text: 'HCP',
          value: 'hcp'
        },
        {
          text: 'Patient',
          value: 'patient'
        }
      ]
      return output
    },
    _topics: function(){
      let output = []
      let articles = this.articles
      for(let i=0; i<articles.length; i++){
        let article = articles[i]
        let topics = article.topics
        for(let ii=0; ii<topics.length; ii++){
          let topic = topics[ii]
          if(!output.includes(topic)){
            output.push(topic)
          }
        }
      }
      return output
    }
  }
}
</script>

<style scoped lang="scss">
.publish .v-list-item{
  font-weight: bold;
  color: green;
}
</style>