<template>
  <div>
     <AlignmentForm v-model="form.align"/>
     <v-switch v-model="form.dense" :true-value="true" :false-value="false" label="Dense"/>
    <v-container>
      <v-row>
        <v-col v-for="language in languages" :key="`title-language-${language}`">
          {{language}}
          <v-text-field v-model="form.title[language]" label="Title"/>
          <v-text-field v-model="form.subtitle[language]" label="Subtitle"/>
          <!-- Working Together -->
          <!-- We gratefully acknowledge the support from our partners. -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AlignmentForm from './alignment.vue'
export default {
  name: "TitleForm",
  created: function(){
    if(Object.keys(this.form)==0){
      this.initForm()
    }
  },
  components: {
    AlignmentForm
  },
  props: {
    languages: {
      type: Array,
      required: true
    }
  },
  methods: {
    initForm: function(){
      this.form.title = {}
      this.form.subtitle = {}
      this.form.dense = false
      this.form.align = {
        x: null,
        y: null
      }
    }
  },
  computed: {
    form: function(){
      return this.$attrs.value
    }
  }
}
</script>

<style>

</style>