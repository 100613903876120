<template>
  <div>
    <v-btn v-if="allow" fab :color="'darkblue'" :dark="true" style="position: fixed; bottom: 25px; right: 25px; z-index: 5000;" @click="toggle">
      {{ui.language=='en-ca' ? 'fr-ca' : 'en-ca'}}
    </v-btn>
  </div>
</template>

<script>
export default {
    props: {
        page: {
            type: Object,
            required: true
        }
    },
    mounted: function(){
      this.init()
    },
    data: function(){
      return {
        ui: {
          language: 'en-ca'
        }
      }
    },
    methods: {
      init: function(){
        // console.log('pageLanguage',this.page)
        let queryLanguage = this._queryLanguage
        if(queryLanguage && queryLanguage!=this.ui.language){
          this.toggle()
        }
      },
      toggle: function(){
        this.ui.language = (this.ui.language=='en-ca' ? 'fr-ca' : 'en-ca')
        // console.log('page language toggle', this.ui.language)
        this.$emit('language',this.ui.language)
      }
    },
    computed: {
      allow: function(){
        return this.page.enableFrench === true
      },
      _queryLanguage: function(){
        return this.$route.query.language
      }
    },
    watch: {
      definition: {
        deep: true,
        handler: function(){
          this.init()
        }
      },
      allow: function(){
        let allow = this.allow
        if(!allow && this.ui.language=='fr-ca'){
          this.toggle()
        }
      },
      _queryLanguage: function(){
        this.init()
      }
    }
}
</script>

<style>

</style>