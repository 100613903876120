<template>
<div v-if="service">
    <!-- VIPIT Algorithm -->
    <div class="calcDescription vipitAlgorithm">
        <h2 v-if="language==='fr-ca'">Algorithme interactif permettant de diagnostiquer, d’écarter et de prendre en charge la thrombocytopénie thrombotique immunitaire induite par le vaccin (TTIV)</h2>
        <h2 v-else>Diagnosing, Ruling Out and Managing Vaccine-induced Immune Thrombotic Thrombocytopenia (VITT)</h2>        
    </div>
    <div class="vipitAlgorithm calcItem groupItem">
        <div id="vipitAlgorithm">
                <div v-if="state=='SYMPTOMS_PRESENCE'">
                    <h3 v-if="language==='fr-ca'">Le patient présente-t-il un ou plusieurs des symptômes suivants? (l’utilisateur doit cocher tout ce qui s’applique)</h3>
                    <h3 v-else>Does the patient exhibit one or more of the following symptoms:</h3>
                        <div v-for="(value, name) in baseContext.SYMPTOMS_PRESENCE.map" :key="`vipitAlgorithm-SYMPTOMS_PRESENCE-${name}`">
                            <div class='d-flex'>
                            <label>
                                <input type="checkbox" :value="name" v-model="symptoms" @change="symptomCheckEvent(name, $event);">
                                {{baseContext.SYMPTOMS_PRESENCE.locale_map[map_lang_attr][name]}}
                            </label>
                            <div v-if="name!=='G'" class='mt-5'>
                                <a @click="toggleLocalleMapCommentsDisplay(name)"><span class="toggleExamplesWrapper" style="border:3px solid white;margin-right:6px">?</span></a>
                            </div>
                            </div>
                            <div v-if="name!=='G' && baseContext.SYMPTOMS_PRESENCE.locale_map_comments[map_lang_attr][name].show===true" :id="'info-symptom-'+name" class="calc_definition examples" style="font-style:italic;color:#666;display: block;">
                                {{baseContext.SYMPTOMS_PRESENCE.locale_map_comments[map_lang_attr][name].text}}
                            </div>
                        </div>
                        <div style="text-align:center;">
                            <button :disabled="symptoms.length==0" @click="service.send('NEXT')" :class="{'calcButton': symptoms.length>0}">
                                <Str :index="'clinical_tools.buttons.next'" :language="language"/>
                            </button>
                        </div>                                                  
                </div>
                <div v-if="state=='POST_VACCINATION_PERIOD_ONSET'">
                        <h3 v-if="language==='fr-ca'">Le patient a-t-il reçu son vaccin contre la COVID-19 il y a 4 à 28 jours?</h3>                    
                        <h3 v-else>Has the patient received their COVID-19 vaccine between 4 and 28 days ago?</h3>
                        <v-radio-group v-model="form.POST_VACCINATION_PERIOD_ONSET" :key="`vipitAlgorithm-POST_VACCINATION_PERIOD_ONSET`">
                            <v-radio @click="service.send('YES')" :label="locale_common_labels.yes[map_lang_attr]" value="YES"/>
                            <v-radio @click="service.send('NO')" :label="locale_common_labels.no[map_lang_attr]" value="NO"/>
                        </v-radio-group>
                        <!-- <label>
                            <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.vipitAlgorithm.app.service.send('YES');">
                            Yes
                        </label>
                        <label>
                            <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.vipitAlgorithm.app.service.send('NO')">
                            No
                        </label> -->
                </div>


                <div v-if="state=='CBC_RANGE'">
                        <h3 v-if="language==='fr-ca'">L’hémogramme du patient fait-il état d’un nombre de plaquettes < 150 × 10<sup>9</sup>/L?</h3>
                        <h3 v-else>Does the patient’s complete blood count (CBC) show a platelet count <150 x 10<sup>9</sup>/L?</h3>
                        <p class="question-subtext" v-if="language==='fr-ca'"><sup>*</sup>Effectuer un hémogramme complet et (ou) envoyer le patient au service d’urgence le plus proche.</p>
                        <p class="question-subtext" v-else><sup>*</sup>Draw a complete blood count (CBC) and/or send the patient to the nearest emergency department.</p>                        
                        <v-radio-group v-model="form.CBC_RANGE" :key="`vipitAlgorithm-CBC_RANGE`">
                            <v-radio @click="service.send('YES')"  :label="locale_common_labels.yes[map_lang_attr]" value="YES"/>
                            <v-radio @click="service.send('NO')"  :label="locale_common_labels.no[map_lang_attr]" value="NO"/>
                        </v-radio-group>                                                
                        <!-- <label>
                            <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.vipitAlgorithm.app.service.send('YES');">
                            Yes
                        </label>
                        <label>
                            <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.vipitAlgorithm.app.service.send('NO')">
                            No
                        </label> -->
                </div>


                <div v-if="state=='ADDITIONAL_TEST'">
                    <template v-if="language==='fr-ca'">
                        <h3>Les tests du patient font-ils état des résultats suivants?</h3>
                        <p class="question-subtext"><sup>*</sup>Faire passer d’autres tests au patient.</p>
                        <ul style="list-style-type: square;">
                            <li>Taux élevé de D-dimères</li>
                            <li>Frottis sanguin normal (hormis le faible nombre de plaquettes)</li>
                            <li>Une thrombose veineuse ou artérielle est confirmée par l’examen d’imagerie motivé par la présomption clinique.</li>
                        </ul>
                        <p class="question-subtext"><sup>**</sup>Les cas de TTIV ne présentent pas tous un caillot sanguin initialement. Le diagnostic de présomption d’une TTIV n’exige pas la confirmation d’un caillot sanguin.<sup>**</sup></p>
                    </template>
                    <template v-else>
                        <h3>Does the patient’s tests show the following results?</h3>
                        <p class="question-subtext"><sup>*</sup>Perform additional tests with the patient.</p>
                        <ul style="list-style-type: square;">
                            <li>D-dimer: Elevated</li>
                            <li>Blood film: Normal (apart from low platelets)</li>
                            <li>Imaging based on clinical suspicion confirms venous or arterial thrombosis</li>
                        </ul>
                        <p class="question-subtext"><sup>**</sup>Not all cases of VITT initially present with a clot. A confirmed blood clot is not required to make a presumptive diagnosis of VITT<sup>**</sup></p>
                    </template>
                        <v-radio-group v-model="form.ADDITIONAL_TEST" :key="`vipitAlgorithm-ADDITIONAL_TEST`">
                            <v-radio @click="service.send('YES')" :label="locale_common_labels.yes[map_lang_attr]" value="YES"/>
                            <v-radio @click="service.send('NO')" :label="locale_common_labels.no[map_lang_attr]" value="NO"/>
                        </v-radio-group>
                        <!-- <label>
                            <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.vipitAlgorithm.app.service.send('YES');">
                            Yes
                        </label>
                        <label>
                            <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.vipitAlgorithm.app.service.send('NO')">
                            No
                        </label> -->
                </div>


                <div v-if="state=='VIPIT_UNLIKELY'"  classs="recommendation-container">
                    <template v-if="language==='fr-ca'">
                        <h3><u>RECOMMANDATION :</u></h3>
                        <div class="summary">
                            <p><b>VIPIT/TTIV improbable.</b> Ne pas effectuer de test de dépistage d’une thrombocytopénie induite par l’héparine (TIH)</p>
                            <ol class="referenceList"><li>Pai M, Grill A, Ivers N, et al. Vaccine-induced prothrombotic immune thrombocytopenia VIPIT following AstraZeneca COVID-19 vaccination. Science Briefs of the Ontario COVID-19 Science Advisory Table. 2021;1(17). <a href="https://doi.org/10.47326/ocsat.2021.02.17.1.0" target="_blank">https://doi.org/10.47326/ocsat.2021.02.17.1.0</a></li></ol>
                        </div>
                    </template>
                    <template v-else>
                        <h3><u>RECOMMENDATION</u></h3>
                        <div class="summary">
                            <p><b>VIPIT/VITT unlikely.</b> Do not proceed to HIT testing.</p>
                            <ol class="referenceList"><li>Pai M, Grill A, Ivers N, et al. Vaccine-induced prothrombotic immune thrombocytopenia VIPIT following AstraZeneca COVID-19 vaccination. Science Briefs of the Ontario COVID-19 Science Advisory Table. 2021;1(17). <a href="https://doi.org/10.47326/ocsat.2021.02.17.1.0" target="_blank">https://doi.org/10.47326/ocsat.2021.02.17.1.0</a></li></ol>
                        </div>
                    </template>
                </div>


                <div v-if="state=='VIPIT_DIAGNOSIS'"  classs="recommendation-container">
                    <template v-if="language==='fr-ca'">
                        <h3><u>RECOMMANDATION</u></h3>
                        <div class="summary">
                            <p><b>Diagnostic de présomption d’une VIPIT/TTIV.</b> Effectuer les analyses hématologiques de dépistage d’une TIH. Les cas de présomption ou confirmés de VIPIT/TTIV doivent être traités de manière analogue à un cas de TIH :</p>
                            <ul>
                                <li>Pas d’héparine</li>
                                <li>Pas de transfusion de plaquettes</li>
                                <li>Anticoagulants de première intention : inhibiteurs directs du facteur Xa par voie orale (p. ex., rivaroxaban, apixaban, edoxaban)</li>
                                <li>Consultation en hématologie</li>
                                <li>IgIV à 1 g/kg par jour pendant au moins 2 jours (notamment en cas de thrombose grave ou menaçant le pronostic vital)</li>
                            </ul>
                            <p><b>Méthodes de laboratoire recommandées :</b></p>
                            <ul>
                                <li>Épreuve de liaison à un antigène (ELISA) pour le dosage des anticorps anti-PF4/héparine : test ELISA; d’autres tests peuvent produire des faux-négatifs.</li>
                            </ul>
                            <p>Demander un test avant l’instauration du traitement. Ne pas attendre les résultats du test pour instaurer le traitement.</p>
                            <p>REMARQUE : Tous les effets indésirables soupçonnés suivant l’immunisation (ESSI), y compris la thrombose ainsi que les cas de présomption et confirmés de VIPIT, doivent être signalés à l’aide du formulaire d’ESSI propre à chaque province, et transmis au bureau de santé publique local.</p>
                            <ol class="referenceList"><li>Pai M, Grill A, Ivers N, et al. Vaccine-induced prothrombotic immune thrombocytopenia VIPIT following AstraZeneca COVID-19 vaccination. Science Briefs of the Ontario COVID-19 Science Advisory Table. 2021;1(17). <a href="https://doi.org/10.47326/ocsat.2021.02.17.1.0" target="_blank">https://doi.org/10.47326/ocsat.2021.02.17.1.0</a></li></ol>
                        </div>
                    </template>
                    <template v-else>
                        <h3><u>RECOMMENDATION</u></h3>
                        <div class="summary">
                            <p><b>Presumptive diagnosis of VIPIT/VITT.</b> Proceed to hematology for HIT testing.  Patients with presumptive and confirmed VIPIT/VITT should be treated similarly to HIT:</p>
                            <ul>
                                <li>No heparin</li>
                                <li>No platelet transfusions</li>
                                <li>First line anticoagulants: direct oral anti-Xa inhibitors (e.g., rivaroxaban, apixaban, edoxaban)</li>
                                <li>Consult hematologist</li>
                                <li>IVIG 1 g/kg daily for at least 2 days. (Particularly for severe or life-threatening thrombosis.)</li>
                            </ul>
                            <p><b>Recommended Laboratory Methods:</b></p>
                            <ul>
                                <li>Antigen-binding assay (ELISA) for PF4/heparin antibodies: ELISA Testing – other tests may produce false negatives</li>
                            </ul>
                            <p>Send for testing before initiation of treatment. Do not wait for test results before initiation of treatment.</p>
                            <p>NOTE: All suspected adverse events following immunization (AEFI), including thrombosis, and both presumptive and confirmed VIPIT, should be reported using the provincial AEFI form and sent to the local Public Health Unit.</p>
                            <ol class="referenceList"><li>Pai M, Grill A, Ivers N, et al. Vaccine-induced prothrombotic immune thrombocytopenia VIPIT following AstraZeneca COVID-19 vaccination. Science Briefs of the Ontario COVID-19 Science Advisory Table. 2021;1(17). <a href="https://doi.org/10.47326/ocsat.2021.02.17.1.0" target="_blank">https://doi.org/10.47326/ocsat.2021.02.17.1.0</a></li></ol>
                        </div>
                    </template>
</div>                                        
        </div>
    </div> 
</div>    
</template>

<script>
import { createMachine, actions, interpret, assign, cond } from 'xstate';
export default {
    props: {
            language: {
                    type: String,
                    require: true
            }
    },
    name: "vipitAlgorithm",
    created: function(){
        this.machine = createMachine({
            id: 'app',
            initial: 'SYMPTOMS_PRESENCE',
            context: null,
            states: {
                SYMPTOMS_PRESENCE: {                         
                    on: {
                        'NEXT': [
                            {
                                    target: 'POST_VACCINATION_PERIOD_ONSET',
                                    actions: assign({
                                        SYMPTOMS_PRESENCE: (context,event) => context.SYMPTOMS_PRESENCE.value = vipitComponent.symptoms
                                    }),
                                    cond: (context, event) => {
                                        return vipitComponent.symptoms.length > 0 && !vipitComponent.symptoms.includes("G");
                                    }

                            },
                            {
                                    target: 'VIPIT_UNLIKELY',
                                    actions: assign({
                                        SYMPTOMS_PRESENCE: (context,event) => context.SYMPTOMS_PRESENCE.value = vipitComponent.symptoms
                                    }),
                            }
                        ]
                    }
                },
                POST_VACCINATION_PERIOD_ONSET: {                         
                    on: {
                        YES: {
                            target: 'CBC_RANGE',
                            actions: assign({
                                    POST_VACCINATION_PERIOD_ONSET: (context,event) => context.POST_VACCINATION_PERIOD_ONSET.value = event.type
                            })
                        },
                        NO: {
                            target: 'VIPIT_UNLIKELY',
                            actions: assign({
                                    POST_VACCINATION_PERIOD_ONSET: (context,event) => context.POST_VACCINATION_PERIOD_ONSET.value = event.type
                            })
                        }
                    }                        
                },
                CBC_RANGE: {
                    on: {
                        YES: {
                            target: 'ADDITIONAL_TEST',
                            actions: assign({
                                    CBC_RANGE: (context,event) => context.CBC_RANGE.value = event.type
                            })
                        },
                        NO: {
                            target: 'VIPIT_UNLIKELY',
                            actions: assign({
                                    CBC_RANGE: (context,event) => context.CBC_RANGE.value = event.type
                            })
                        }
                    }
                },
                ADDITIONAL_TEST: {
                    on: {
                        YES: {
                            target: 'VIPIT_DIAGNOSIS',
                            actions: assign({
                                    ADDITIONAL_TEST: (context,event) => context.ADDITIONAL_TEST.value = event.type
                            })
                        },
                        NO: {
                            target: 'VIPIT_UNLIKELY',
                            actions: assign({
                                    ADDITIONAL_TEST: (context,event) => context.ADDITIONAL_TEST.value = event.type
                            })
                        }
                    }
                },
                VIPIT_UNLIKELY: {
                    type: 'final'
                },
                VIPIT_DIAGNOSIS: {
                    type: 'final'
                }
            }
        });
        let vipitComponent = this;

        this.initialize();
        window.vipitAlgorithm_onreset = this.reset;
    },
    data: function(){
        return {
            machine: null,
            form: {
                SYMPTOMS_PRESENCE: null,
                POST_VACCINATION_PERIOD_ONSET: null,
                CBC_RANGE: null,
                ADDITIONAL_TEST: null,
            },
            state: null,
            context: null,
            service: null,
            done: null,
            displayPatientSummary: false,
            displayRecommendationContainer: true,
            symptoms: [],
            baseContext: {
                SYMPTOMS_PRESENCE: {
                    summary_identifier: "Patient exhibits one or more VIPIT related symptoms",
                    value: null,
                    full_description: null,
                    map: {
                        A: 'a persistent and severe headache',
                        B: 'focal neurological symptoms, seizures, or blurred or double vision',
                        C: 'shortness of breath or chest pain',
                        D: 'abdominal pain',
                        E: 'swelling and redness in a limb',
                        F: 'pallor and coldness in a limb',
                        G: 'None of the above'
                    },
                    locale_map: {
                        en_ca: {
                            A: 'a persistent and severe headache',
                            B: 'focal neurological symptoms, seizures, or blurred or double vision',
                            C: 'shortness of breath or chest pain',
                            D: 'abdominal pain',
                            E: 'swelling and redness in a limb',
                            F: 'pallor and coldness in a limb',
                            G: 'None of the above'                            
                        },
                        fr_ca: {
                            A: 'Céphalées intenses et persistantes',
                            B: 'Symptômes neurologiques focaux, crises convulsives, ou vision floue/vision double',
                            C: 'Essoufflements ou douleur thoracique',
                            D: 'Douleur abdominale',
                            E: 'Enflure et rougeur à un membre',
                            F: 'Pâleur et refroidissement d’un membre',
                            G: 'Aucune de ces réponses'                            
                        }
                    },
                    locale_map_comments: {
                        en_ca: {
                            A: {text: 'suggesting CVST or arterial stroke', show: false},
                            B: {text: 'suggesting CVST or arterial stroke', show: false},
                            C: {text: 'suggesting pulmonary embolism or acute coronary syndrome', show: false},
                            D: {text: 'suggesting splanchnic vein thrombosis', show: false},
                            E: {text: 'suggesting deep vein thrombosis', show: false},
                            F: {text: 'suggesting deep vein thrombosis or acute limb ischemia', show: false},
                            G: null
                        },
                        fr_ca: {
                            A: {text: 'Évoque une thrombose des sinus veineux cérébraux (TSVC) ou un accident vasculaire cérébral (AVC) artériel', show: false},
                            B: {text: 'Évoque une thrombose des sinus veineux cérébraux (TSVC) ou un accident vasculaire cérébral (AVC) artériel', show: false},
                            C: {text: 'Évoque une embolie pulmonaire ou un syndrome coronarien aigu', show: false},
                            D: {text: 'Évoque une thrombose de la veine splanchnique', show: false},
                            E: {text: 'Évoque une thrombose veineuse profonde', show: false},
                            F: {text: 'Évoque une thrombose veineuse profonde ou une ischémie aiguë d’un membre', show: false},
                            G: null
                        }
                    },                    
                    exclusive: "G"
                },
                POST_VACCINATION_PERIOD_ONSET: {
                    summary_identifier: "Patient received their COVID-19 vaccine between 4 and 28 days ago", value: null, full_description: null
                },
                CBC_RANGE: {
                    summary_identifier: "Complete blood count (CBC) shows a platelet count <150 x 10<sup>9</sup>/L", value: null, full_description: null
                },
                ADDITIONAL_TEST: {
                    summary_identifier: "D-dimer:Elevated, Blood film: Normal(apart from low platelets),Imaging based on clinical suspicion confirms venous or arterial thrombosis",
                    value: null,
                    full_description: null
                }
            },
            locale_common_labels: {
                yes: {en_ca: "YES", fr_ca: "Oui"},
                no: {en_ca: "NO", fr_ca: "Non"}
            }
        }
    },
    methods: {
        toggleLocalleMapCommentsDisplay(symptomIndex){
            return this.baseContext.SYMPTOMS_PRESENCE.locale_map_comments[this.map_lang_attr][symptomIndex].show = !this.baseContext.SYMPTOMS_PRESENCE.locale_map_comments[this.map_lang_attr][symptomIndex].show
        },
        initialize: function(){
            let self = this;
            let machine = this.machine
            self.state = null;
            self.symptoms = [];
            self.service = null;
            self.displayPatientSummary = true;
            self.displayRecommendationContainer = true;
            var service = interpret(machine).onTransition(state => {
                if (state.event.type=='xstate.init'){
                    state.context = JSON.parse(JSON.stringify(this.baseContext));
                }
                
                self.state = state.value;
                self.context = state.context;
                self.done = state.nextEvents.length == 0					
            });
            this.service = service;
            for(let i in this.form){
                this.form[i] = null
            }
            service.start();
        },
        reset: function(){
            let self = this;
            self.service.stop();
            self.initialize();
        },
        togglePatientSummaryDisplay: function(){
            this.displayPatientSummary = !this.displayPatientSummary;
        },
        toggleRecommendationContainerDisplay: function(){
            this.displayRecommendationContainer = !this.displayRecommendationContainer
        },
        test: function(param){
            console.log("Test!!!!");
            console.log("Param::"+ JSON.stringify(param));
        },
        symptomCheckEvent: function( value, event ){
            if (value!==this.baseContext.SYMPTOMS_PRESENCE.exclusive && event.target.checked===true){
                if (this.symptoms.includes("G")){
                    return this.symptoms = this.symptoms.filter(elem => elem!=="G");
                }
            }
        },
        downloadPDF: function( node, excludeIdList ) {
                return new Promise((resolve, reject) => {
                        const $contentToOutput = $(node);
                        var excludeIdParentList = [];
                        for(let i=0; i<excludeIdList.length; i++){
                        let $parent = $contentToOutput.find(excludeIdList[i]).parent();
                        excludeIdParentList.push({
                                parentElem: $parent,
                                excludedElem: $parent.find(excludeIdList[i]).detach()
                        });
                        }
                        
                        const position = $(node).offset();
                        const contentToOutput = $contentToOutput[0];
                        html2canvas(contentToOutput, { scale: 2, y: position.top }).then(canvas => {
                        if (canvas){
                                const imgData = canvas.toDataURL("image/jpeg", 0.95);
                                for(let i=0; i<excludeIdParentList.length; i++){
                                excludeIdParentList[i]['excludedElem'].appendTo( excludeIdParentList[i]['parentElem'] );
                                }
                        
                                const doc = position.top > 200 ? new jsPDF("p", "mm", "a4") : new jsPDF("landscape", "mm");
                                const imgProps= doc.getImageProperties(imgData);
                                const pdfWidth = doc.internal.pageSize.getWidth();
                                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                                doc.addImage(imgData, "JPEG", 30, 30, 148, pdfHeight*0.7);
                                doc.save("tctool_download.pdf",{returnPromise: true});
                                resolve(canvas);
                        }
                        else {
                                for(let i=0; i<excludeIdParentList.length; i++){
                                excludeIdParentList[i]['excludedElem'].appendTo( excludeIdParentList[i]['parentElem'] );
                                }
                                reject( new Error("PDF canvas failure"));
                        }
                        });
                });
        }
    },
    computed: {
        map_lang_attr(){
            return this.language==='fr-ca' ? 'fr_ca' : 'en_ca'
        }
    },
    watch: {
        symptoms: function(after, before){
            setTimeout( function(){
                if (after.length && after.length>1 && after.includes('G')){
                    return this.symptoms = ["G"];                                    
                }                                    
            }.bind(this),250)

        }
    }
}
</script>

<style scoped>
#calcWrapper a {
    color: #1976d2;
}
</style>