<template>
  <div>
    <!-- <v-btn @click="batchPDF">Batch PDF</v-btn> -->
    <!-- <v-btn @click="buildFlexSearch">buildFlexSearch</v-btn> -->
    <v-sheet v-if="!ui.editor">
        <v-container>
            <v-row>
                <v-col>
                     <v-btn @click="newGuide('en-ca')">New English Guide</v-btn> | <v-btn @click="newGuide('fr-ca')">New French Guide</v-btn>
                </v-col>
                <v-col>
                    <v-switch v-model="state.allowRegress" :true-value="true" :false-value="false" label="Unlock Regression"/>
                    <v-switch v-model="state.allowUnpublish" :true-value="true" :false-value="false" label="Unlock Unpublish"/>
                </v-col>
                <v-col v-if="debug">
                    <v-list dense>
                        <template v-for="(action,index) in state.comms.log.slice().reverse()">
                            <v-list-item :key="`log-item-${index}`">
                                <v-list-item-content>
                                    {{action}}
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-col>
            </v-row>
        </v-container>       
        <v-container v-if="state.ready">
            <v-row>
                <v-col  cols="6" v-for="language in languages" :key="`manage-guides-${language}`">
                    <h3>{{language}}</h3>

                    <v-list dense style="text-align: left;">
                        <template v-for="guide in sortGuides(definition.guideData[language].guides)">
                            <v-list-item :key="`guide-selector-${guide.id}`">
                                <v-list-item-content class="hoverable" @click="editGuide({guideId: guide.id, language})">
                                    <v-list-item-title>
                                        <div v-html="guide.name"/>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <template v-if="resolvedPublishedGuide({guide, language})">
                                            Published Version: {{resolvedPublishedGuide({guide, language}).version}} - {{dateTime(resolvedPublishedGuide({guide, language}).datePublished)}}
                                        </template>
                                        <template v-else>Not Published</template>
                                        <div v-if="guide.published!=undefined">
                                            Staged Version: {{guide.version}} - {{dateTime(guide.dateUpdated)}}
                                        </div>
                                        <div v-else><br></div>
                                        <div v-if="debug">{{guide.id}} | {{guide.originalUrl}}</div>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn color="success" v-if="guide.id && ((guide.published!=undefined && !guide.published) || !resolvedPublishedGuide({guide, language}))" x-small @click="publishGuide({meta:guide, language})">
                                        Publish
                                    </v-btn>
                                    <v-btn v-if="state.allowRegress" color="warning" x-small @click="regressGuide({meta:guide, language})">
                                        Regress Version
                                    </v-btn>
                                    <v-btn v-if="state.allowUnpublish" color="error" x-small @click="unpublishGuide({meta:guide, language})">
                                        Unpublish Guide
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider :key="`guide-divider-${guide.id}`"/>
                        </template>
                    </v-list>

                </v-col>
            </v-row>
        </v-container>
        <div v-else>
            {{state.comms[state.comms.length-1]}}
        </div>    
    </v-sheet>

    <v-card v-if="ui.editor">
        <v-card-title>
            <div v-html="guide.meta.name"/> &nbsp;- Version {{guide.meta.version}}
        </v-card-title>
        <v-card-subtitle>
            <div v-html="guide.meta.objective"/>
        </v-card-subtitle>
        <v-card-text>
            <GuideEditor :batchPDF="state.batchPDF" :guide="guide" :sortGuides="sortGuides" :guideTopics="definition.guideData[guide.language].guideTopics" @exit="ui.editor=false" @newTopic="newTopic" @deleteTopic="deleteTopic" @save="saveGuide"/>
        </v-card-text>
        <v-card-actions>
            <v-spacer>
                <v-btn text @click="closeEditor">
                    Close
                </v-btn>
            </v-spacer>
        </v-card-actions>
    </v-card>

    <v-dialog v-model="busyComms" width="150">
        <v-card>
            <v-card-text style="text-align: center;">                
                <v-icon style="padding: 30px;" x-large class="mdi-spin">mdi-loading</v-icon>
            </v-card-text>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import GuideViewer from '@/components/ClinicalGuides/guideViewer'
import GuideEditor from './ClinicalGuideManager/GuideEditor.vue'
export default {
    name: "ClinicalGuideManager",
    components: {
        // GuideViewer,
        GuideEditor
    },
    created: function(){
        this.init()
    },
    data: function(){
        return {
            state: {
                ready: false,
                comms: {
                    log: [],
                    queue: 0
                },
                allowUnpublish: false,
                allowRegress: false,
                batchPDF: false
            },
            definition: null,
            ui: {
                editor: false,
                preview: false
            },
            guide: {
                meta: null,
                html: null,
                topics: null,
                language: null
            }
        }
    },
    methods: {
        sortGuides: function(input){
            function compareName(a,b){
                const A = a.name
                const B = b.name
                if (A < B) {
                    return -1;
                }
                if (A > B) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            let output = JSON.stringify(input)
            output = JSON.parse(output).sort(compareName)
            return output
        },
        batchPDF: function(){
            let self = this
            let languages = this.languages
            let definition = this.definition
            let counter = 0
            let delay = 0
            self.state.batchPDF = true
            
            for(let i=0; i<languages.length; i++){
                let language = languages[i]
                let guides = definition.guideData[language].guides
                for(let ii=0; ii<guides.length; ii++){
                    let guide = guides[ii]

                    setTimeout(() => {
                        self.editGuide({guideId: guide.id, language})   
                        console.log('counter: ',counter)
                        counter++                  
                    }, delay);
                    delay+=4000
                }
            }
        },
        init: async function(){
            this.state.ready = false
            let response = await this.getData()
            this.definition = response
            this.state.allowUnpublish = false
            this.state.ready = true
        },
        getData: async function(){
            this.state.comms.log.push('getData - started')
            this.state.comms.queue++
            let response = await this.sendRequest('get','/api/clinical_guides')
            this.state.comms.queue--

            /* Correct French Naming Convention */
            let language = 'fr-ca'
            let guides = response.data.guideData[[language]].guides
            for(let i=0; i<guides.length; i++){
                let guide = guides[i]                    
                guide.url = guide.url.replaceAll('guides_french/','guides/')
            }
            guides = response.data.guideDataStaged[[language]].guides
            for(let i=0; i<guides.length; i++){
                let guide = guides[i]                    
                guide.url = guide.url.replaceAll('guides_french/','guides/')
            }
            
            /* Used Staged Data */
            if(response.data.guideDataStaged){
                response.data.guideDataPublished = response.data.guideData
                response.data.guideData = response.data.guideDataStaged
            }

            this.state.comms.log.push('getData - done')
            return response.data
        },
        closeEditor: function(){
            this.ui.editor = false
        },
        newGuide: function(language){
            function template(language){
                return {
                    "url": null,
                    "pdf": null,
                    "topic": null,
                    "name": null,
                    "objective": null,
                    "id": null,
                    language,
                    "version": 0,
                    "originalUrl": null,
                    "images": [],
                    "dateUpdated": null,
                    "datePublished": null
                }
            }
            let guide = this.getGuideMetaDataById(this.definition.guideData[language].guides, null)
            if(!guide){
                let record = template(language)
                this.definition.guideData[language].guides.push(record)
            }
            this.editGuide(({guideId: null, language}))
        },
        editGuide: async function({guideId, language}){
            let guideData = this.definition.guideData[language]
            this.guide.meta = this.getGuideMetaDataById(guideData.guides, guideId) 
            this.guide.topics = this.getGuideTopics(guideData.guideTopics, guideId)
            this.guide.html = this.guide.meta.url ? await this.getGuideHTML(`clinical_guides/${language}/${this.guide.meta.url}`) : null
            this.guide.language = language
            this.ui.editor = true
        },
        getGuideMetaDataById: function(guides, guideId){
            let searchResults = guides.filter((item)=>{return item.id==guideId})
            let guide = searchResults.length==1 ? searchResults[0] : false
            return guide
        },
        getGuideTopics: function(guideTopics, guideId){
            let topics = []
            for(let i=0; i<guideTopics.length; i++){
                let record = guideTopics[i]
                let searchResults = record.guideIDs.includes(guideId)
                if(searchResults){
                    topics.push(record.name)
                }
            }
            return topics
        },
        getGuideHTML: async function(url){
            this.state.comms.log.push('getGuideHTML - started')
            let Bucket = this.definition.Bucket
            this.state.comms.queue++
            let response = await this.sendRequest('get',`${Bucket}/${url}`)
            this.state.comms.queue--
            this.state.comms.log.push('getGuideHTML - done')
            return response.data
        },
        newTopic: function(data){
            function template(){
                let output = {
                    guideIDs: [],
                    name: null,
                    selected: false
                }
                return output
            }
            let topic = template()
            topic.name = data.topic
            this.definition.guideData[data.language].guideTopics.push(topic)
        },
        deleteTopic: function(data){
            let guideData = this.definition.guideData[data.language]
            let index = guideData.guideTopics.map(record => record.name).indexOf(data.topicName);
            guideData.guideTopics.splice(index, 1)
        },
        resolvedPublishedGuide: function({guide, language}){
            let guideData = this.definition.guideDataPublished[language]
            let index = guideData.guides.map(record => record.id).indexOf(guide.id);
            if(index>=0){
                let record = guideData.guides[index]
                return record
            }else{
                return undefined
            }
        },
        saveGuide: async function(data){
            this.state.comms.log.push('saveGuide - started')
            let guideTopics = this.definition.guideData[data.language].guideTopics


            for(let i=0; i<data.topics.length; i++){
                let topicName = data.topics[i]
                for(let ii=0; ii<guideTopics.length; ii++){
                    let guideTopic = guideTopics[ii]
                    if(topicName==guideTopic.name && !guideTopic.guideIDs.includes(data.meta.id)){
                        guideTopic.guideIDs.push(data.meta.id)
                    }
                }
            }
            data.guideTopics = guideTopics
            this.state.comms.queue++
            let response = await this.sendRequest('post', '/api/admin/clinical_guides', data)
            this.state.comms.queue--
            if(response.status==200){
                this.ui.editor = false
                this.init()
            }else{
                console.error('saveGuide',{
                    response,
                    guideTopics,
                    data
                })
            }
            this.state.comms.log.push('saveGuide - done')

            this.state.comms.queue++
            // let pdf = await this.sendRequest('post','https://api.vivomap.ca/pdf/make/cua',{html:"LOCAL TEST"})
            this.state.comms.queue--
            // console.log('pdf',pdf)
        },
        publishGuide: async function(data){
            this.state.comms.log.push('publishGuide - started')
            this.state.comms.queue++
            let response = await this.sendRequest('post', '/api/admin/clinical_guides/publish', data)
            let flexSearchResponse = await this.buildFlexSearch()
            this.state.comms.queue--
            if(response.status==200){
                this.ui.editor = false
                this.init()
            }else{
                console.error('publishGuide',{
                    response,
                    data
                })
            }

            this.state.comms.log.push('publishGuide - done')
        },
        unpublishGuide: async function(data){
            if(confirm('Unpublish guide?')){
                this.state.comms.log.push('unpublishGuide - started')
                this.state.comms.queue++
                let response = await this.sendRequest('delete','/api/admin/clinical_guides',data)
                let flexSearchResponse = await this.buildFlexSearch()
                this.state.comms.queue--
                if(response.status==200){
                    this.ui.editor = false
                    this.init()
                }else{
                    console.error('unpublishGuide',{
                        response,
                        data
                    })
                }

                this.state.comms.log.push('unpublishGuide - done')
            }
        },
        regressGuide: async function(data){
            if(confirm('Regress to the previous version?')){
                this.state.comms.log.push('regressGuide - started')
                this.state.comms.queue++
                let response = await this.sendRequest('patch','/api/admin/clinical_guides',data)
                let flexSearchResponse = await this.buildFlexSearch()
                this.state.comms.queue--
                if(response.status==200){
                    this.ui.editor = false
                    this.init()
                }else{
                    console.error('regressGuide',{
                        response,
                        data
                    })
                }
                this.state.comms.log.push('regressGuide - done')
            }
        },
        dateTime: function(input){
            let t = new Date(input)
            return t.toString()
        },
        buildFlexSearch: async function(){
            alert('Reindexing for search...could take a minute')
            let response = await this.sendRequest('post','/api/admin/clinical_guides/buildFlexSearch')
            return response
        }
    },
    computed: {
        languages: function(){
            return this.$store.getters.languages
        },
        busyComms: function(){
            return this.state.comms.queue!=0
        },
        debug: function(){
            return this.$store.getters.debug
        }
    }
}
</script>

<style scoped lang="scss">
.hoverable{
    cursor: pointer;
}
.hoverable:hover{
    background-color: lightgrey;
    font-weight: bold;
}
</style>