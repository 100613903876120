<template>
<fieldset>
    <legend>Upload: {{type.toUpperCase()}}</legend>
    <input style="display: none" type="file" @change="onImageSelected" :ref="type" :name="type"  :accept="config.imageType" required/>
    <v-btn @click="$refs[type].click()" dark color="#1ea1bd">
        Select File
    </v-btn>
    <img v-if="previewImg" :src="previewImg" :class="$style.previewImage" style="width:1024px;"/>

        <transition 
            enter-active-class="animated zoomIn"
            leave-active-class="animated zoomOut"
            >
            <div v-if="selectedImage">
                [{{selectedImage.name}}] <v-icon v-if="uploaded" color='green'>ok</v-icon>
            </div>
        </transition>
            <transition 
                enter-active-class="animated zoomIn"
                leave-active-class="animated zoomOut"
                >
                <v-btn v-if="selectedImage && !uploaded" @click="onUpload(type)" :color="selectedImage ? 'green' : 'grey'" fab small>
                    <v-icon>mdi-upload</v-icon>
                </v-btn>

            </transition>
</fieldset>  
</template>

<script>
export default {
    name: 'fileUpload',
    props: {
        type: {
            type: String,
            required: true
        },
        config: {
            type: Object,
            required: true
        },
        editLanguage: {
            type: String,
            required: false,
            default: '_'
        }
    },
    data: function(){
        return {
            selectedImage: null,
            previewImg: null,
            uploaded: false
        }
    },
    methods: {
        // onImageSelected: function(){
        //     this.$emit('onImageSelected')
        // },
        onImageSelected(event) {
            var target = event.target;
            var type = target.name;
            console.log('onImageSelect',type);

            if(target.files[0]) {
                if(target.files[0].type==this.config.imageType_string){
                    if((target.files[0].size / 1024 / 1024) < 64) {
                        this.selectedImage = target.files[0];
                        this.createImage(type,this.selectedImage);
                        this.uploaded = false;
                    } else {
                        this.errorText = "Image Size cannot exceed 64MB";
                    }
                } else {
                    alert('Must be: [file].png')
                }
            }
        },
        createImage(type,file) {
            var reader = new FileReader();
            var self = this;

            reader.onload = (e) => {
                self.previewImg = e.target.result;
            };
            reader.readAsDataURL(file);

        }, 
        onUpload(type) {
            var self = this;
            const data = new FormData();
            // data.append('type',type);
            // data.append('language',this.editLanguage);
            // data.append('action','programAdmin');
            // data.append('call','uploadProgramImage');
            // data.append('image',this.selectedImage , this.selectedImage.name);
            
            this.$emit('input',this.previewImg)

            // this.$sendFile(data).then(function(response){
            //     if(response.data.programAdmin.uploadProgramImage.result){
            //         self.uploaded = true;
            //         self.ready = true;
            //         self.$emit('uploaded',self.type)
            //         setTimeout(function(){
            //             self.selectedImage = null;
            //             self.uploaded = false;
            //         },3000)
            //     }else{
            //         self.uploaded = false;
            //     }
            // });
        },                 
    }
}
</script>

<style module>
.previewImage{
    display: block;
    margin: 15px auto;
}
</style>