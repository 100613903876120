<template>
  <div>
    <Title :title="config.title[language]" :subtitle="config.subtitle[language]"/>
    <div class="Member-Container">
        <div class="Member-Item" v-for="(member, memberIndex) in config.members" :key="'member-card-'+memberIndex">
            <Card :member="member" :language="language"/>
        </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/Section/title.vue'
import Card from './MemberCard.vue'
export default {
    components: {
        Title,
        Card
    },
    props: {
        language: {
            type: String,
            required: true
        },
        config: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.Member-Container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
    padding: 0 24px;

    @media(max-width: 959px){
        column-gap: 10px;
        padding: 0 16px;
    }
    @media(max-width: 599px){
        padding: 0 8px;
    }
}

.Member-Item{
    border: 1px solid lightgrey;
    flex-grow: 1;

    max-width: 440px;
    @media(max-width: 959px){
        width: calc(50% - 12px);
    }
    @media(max-width: 599px){
        width: calc(100% - 12px);
    }
}
</style>
