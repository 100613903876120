<template>
  <div>
    <div :class="alignmentClasses">
      <div class="section-title-container" v-if="title">
        <div v-if="!config.dense" class="section-title-item decoration"></div>
        <h2 class="section-title-item text" :style="{'color':color ? color : '', 'font-size':config.fontSize && config.fontSize.title ? config.fontSize.title : ''}">
          <span v-html="title"/>
        </h2>
        <div v-if="!config.dense" class="section-title-item decoration"></div>
        <hr v-if="config.dense" class="small"/>
      </div>

      <div class="section-subtitle" v-if="subtitle" v-html="subtitle" :style="{'font-size':config.fontSize && config.fontSize.subtitle ? config.fontSize.subtitle : ''}"/>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        config: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false
        },
        color: {
            type: String,
            required: false
        }
    },
    computed: {
        lang: function(){
          let language = this.language
          if(language){
            return language
          }else{
            return this.$store.getters.language
          }
        },
        title: function(){
            let language = this.lang
            let config = this.config
            return config.title && config.title[language] ? config.title[language] : null
        },
        subtitle: function(){
            let language = this.lang
            let config = this.config
            return config.subtitle && config.subtitle[language] ? config.subtitle[language] : null
        },
        alignmentClasses: function(){
            let output = []
            let align = this.config.align ? this.config.align : {}
            if(this.config.dense){
              output.push('dense')
            }
            for(let axis in align){
                let position = align[axis]
                output.push(`${axis}-align-${position}`)
            }

            return output
        }
    }
}
</script>

<style lang="scss">


.StoriesViewer {

    .section-title-container {

        h2 {
            text-align: center;
            margin-top: 32px;
        }

        hr {
            margin: 16px auto;
        }
    }

    .section-subtitle {
        font-size: 17px !important;
        text-align: center !important;
    }
}

.section-title-container{
  display: flex;
  justify-content: center;
}

.dense .section-title-container{
  display: block;
}

.section-title-item{
  align-self: center;
}
.decoration{
  margin: 15px;
  border: 2px solid $tc-red;
  max-width: 100px;
  min-width: 16px;
  height: 2px;
}
  .section-title-item.decoration{
    flex-grow: 1;
  }

.text{
  color: $primary-1;
  font-size: 2em;
  font-weight: bold;
  // text-transform: uppercase;
  line-height: 1em;
}
  .section-title-item.text{
    flex-shrink: 2;
  }

.theme-darkblue .text{
  color: white;
}

.dense .section-title-item{
  text-align: left;
  padding: 0px;
  margin: 0px;
  font-size: 1.5em;
}

.section-subtitle{
  padding: 15px;
  margin: 15px;
  font-size: 0.8em;
  line-height: 1.5em;
  text-align: center;
}

.dense .section-subtitle{
  text-align: left;
  padding: 0px;
  margin: 0px;
  font-size: 0.8em;
}


.x-align-left{
    position: absolute;
    left: 0px
}
.x-align-center{
    width: 100%;
    text-align: center;
}
.x-align-right{
    position: absolute;
    right: 0px;
}

.y-align-top{
    position: absolute;
    top: 0px
}
.y-align-center{
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}
.y-align-bottom{
    position: absolute;
    bottom: 0px;
}
</style>
