<template>
  <div>
    <Title :title="'Recent Publications'"/>
    <Layout :preKey="_category" :columnComponents="Cards" :language="language"/>
  </div>
</template>

<script>
import Title from '../title.vue'
import Layout from '../layout_3col.vue'
import Card from './resourceCard.vue'
export default {
    props: {
      config: {
        type: Object,
        required: false
      },
      language: {
        type: String,
        required: true
      }
    },
    components: {
        Title,
        Layout,
        Card
    },
    created: function(){
      this.init()
    },
    data: function(){
      return {
        articles: null
      }
    },
    methods: {
      init: async function(){
        await this.getArticles()
      },
      getArticles: async function(){
        let response = await this.sendRequest('get','/api/resources')
        if(response.status==200){
          this.articles = response.data
        }
      }
    },
    computed: {
      _category: function(){
        return 'resources'
      },
      Cards: function(){
        function template(component, title, body, link){
           return {
            component,
            params: {
              title,
              body,
              link
            }
          }
        }
        let config = this.config
        let articles = this.articles ? this.articles : []
        let category = this._category
        let output = []
        for(let i=0; i<articles.length; i++){
          let article = articles[i]
          if(i<config.limit){
            output.push({component: Card, params:{article, category}})
          }
        }
        return output
      }
    }
}
</script>

<style scoped lang="scss">

</style>