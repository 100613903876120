<template>
  <v-container>
    <v-row>
        <v-col cols="3">
            <Selector :labels="labels"/>
        </v-col>
        <v-col cols="9">
            <v-text-field v-if="$route.name=='newLabel'" v-model="form.index" label="Label Index: eg buttons.cancel"/>
            <LabelEditor v-model="label" :index="index" :suffix="'_text'" @initLabels="initLabels" :languages="$store.getters.languages"/>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Selector from '@/components/LabelEditor/Selector.vue'
import LabelEditor from '@/components/LabelEditor/LabelEditor.vue'
export default {
    components: {
        Selector,
        LabelEditor
    },
    data: function(){
        return {
            form: {
                index: ''
            }
        }
    },
    methods: {
        initLabels: function(){
            console.log('label admin initLabels')
            this.$emit('initLabels')
        }
    },
    computed: {
        index: function(){
            return this.$route.params.index ? this.$route.params.index : this.form.index
        },
        labels: function(){
            return this.$store.getters.labels
        },
        label: function(){
            let labels = this.labels
            let label
            
            let index = this.index
            
            if(this.$route.name=='newLabel'){
                label = {}
            }else{
                if(index && labels){
                    label = eval("labels."+index+"._text")
                }
            }

            return label
        }
    }
}
</script>

<style>

</style>