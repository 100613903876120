<template>
  <div class="sign-in-up">
    <template v-if="!user">

        <!-- SIGN IN -->
        <v-container v-if="state.view=='sign_in'" class='signIn'>
            <v-row>
                <v-col cols="12" sm="12" md="3">
                    <Title class='Title' :language="language" :config="{title: {'en-ca': `<p style='line-height:40px;'>Log into your account</p>`}, dense: true}"/>
                </v-col>
                <v-col cols="12" sm="5" md="4">
                    <form @submit.prevent="signIn">
                        <p class='fieldLabel'><span>*</span> Email Address:</p>
                        <v-text-field background-color="white" v-model="form.email" @keydown="rememberMe=false" outlined/>
                        <p class='fieldLabel'><span>*</span> Password:</p>
                        <v-text-field background-color="white" v-model="form.password" :type="state.showPassword ? 'text' : 'password' " :append-icon="state.showPassword ? 'mdi-eye' : 'mdi-eye-off' " @keydown.enter="signIn" @click:append="state.showPassword=!state.showPassword" outlined/>
                        <v-btn class='forgotPassword' text @click="state.view='forgotPassword'">Forgot Password</v-btn>
                        <v-checkbox :disabled="!isValid('email',form.email)" v-model="rememberMe" :true-value="true" :false-value="false" label="Remember Me"/>
                        <br>
                        <v-btn :disabled="!allowSignIn" class="login_button themed-button-dark" elevation="0" @click="signIn">Log In</v-btn>
                        <br><br><br>
                        Need an account?
                        <v-btn class="signUp_button themed-button-dark" elevation="0" @click="$router.push({path:'/sign_up'})">Sign Up</v-btn>
                    </form>
                </v-col>
                <v-col cols="12" sm="1" class='divideSection'>
                    <div class="vertical-line"/>
                    <div class="vertical-line-text">OR</div>
                    <div class="vertical-line"/>
                </v-col>
                <v-col cols="12" sm="3" md="3" class='federationBoxes'>
                    <div @click="federation('Facebook')">
                        <FederationBox style="margin: 22px 15px 15px;" icon="mdi-facebook" text="Sign in with Facebook" bgColor="#262B76" borderColor="#262B76" iconColor="#262B76"/>
                    </div>
                    <div @click="federation('Google')">
                        <FederationBox style="margin: 15px;" icon="mdi-google" text="Sign in with Google" bgColor="#4C79BD" borderColor="#4C79BD" iconColor="#4C79BD"/>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <!-- FORGOT PASSWORD -->
        <v-container v-if="state.view=='forgotPassword'">
            <v-row>
                <v-col cols="12" sm="3">
                    <Title :language="language" :config="{title: {'en-ca':'Log into your account'}, dense: true}"/>
                </v-col>
                <v-col>
                    <form @submit.prevent="signIn">
                        <p class='fieldLabel'><span>*</span> E-mail:</p>
                        <v-text-field background-color="white" v-model="form.email" outlined />
                        <v-btn :disabled="!isValid('email',form.email)" class="themed-button-dark" elevation="0" @click="forgotPassword">Send reset code</v-btn>
                        <hr>
                        <v-btn :disabled="!isValid('email',form.email)" class="themed-button-dark" style='margin-bottom:16px; margin-right:16px;' elevation="0" @click="state.resetPassword=true">Have a reset code?</v-btn>
                        <v-btn class="themed-button-dark" style='margin-bottom:16px;' elevation="0" @click="state.view='sign_in'">Cancel</v-btn>
                    </form>
                </v-col>
            </v-row>
        </v-container>

        <!-- SIGN UP -->
        <v-container v-if="state.view=='sign_up'" class="signUp">
            <v-row>
                <v-col cols="12" sm="12" md="3">
                    <Title class='Title' :language="language" :config="{title: {'en-ca':`<p style='line-height:40px;'>Create your <br>account</p>`}, subtitle: {'en-ca':'Register to pin content for easy access'}, dense: true}"/>
                </v-col>
                <v-col>
                    <form @submit.prevent="false">
                        <!-- Names Wrap -->
                        <div class="names_wrap">
                            <div>
                                <p class='fieldLabel'><span>*</span> First Name:</p>
                                <v-text-field background-color="white" v-model="form.given_name" class='firstName_field' outlined/>
                            </div>
                            <div>
                                <p class='fieldLabel'><span>*</span> Last Name:</p>
                                <v-text-field background-color="white" v-model="form.family_name" class='lastName_field' outlined/>
                            </div>
                        </div>
                        <p class='fieldLabel'><span>*</span> Email Address:</p>
                        <v-text-field background-color="white" v-model="form.email" outlined/>
                        <!-- Healthcare Professional + Country Wrap -->
                        <div class="hcp_specialty_country_wrap">
                            <div class='hcp_specialty_wrap'>
                                <!-- HCP -->
                                <div>
                                    <p class='fieldLabel'><span>*</span> I am a Healthcare Professional</p>
                                    <v-radio-group row v-model="form.hcp">
                                        <v-radio value="Y" label="Yes"/> <v-radio value="N" label="No"/>
                                    </v-radio-group>
                                </div>
                                <!-- Specialty -->
                                <div v-if="form.hcp=='Y'">
                                    <p class='fieldLabel'><span>*</span> Specialty:</p>
                                    <v-select background-color="white" :items="hcpSpecialtyList" item-text="label" item-value="value" v-model="form.specialty" placeholder="Select ..." outlined></v-select>
                                </div>
                            </div>
                            <!-- Country -->
                            <div class='country_wrap'>
                                <p class='fieldLabel'><span>*</span> Country:</p>
                                <v-text-field background-color="white" v-model="form.country" outlined/>
                            </div>
                        </div>
                        <!-- Password -->
                        <p class='fieldLabel'><span>*</span> Password:</p>
                        <v-text-field background-color="white" v-model="form.password" :type="state.showPassword ? 'text' : 'password' " :append-icon="state.showPassword ? 'mdi-eye' : 'mdi-eye-off' " @click:append="state.showPassword=!state.showPassword" @keyup="passwordValidationMessage" outlined/>
                        <!-- Repeat Password -->
                        <p class='fieldLabel'><span>*</span> Repeat Password:</p>
                        <v-text-field background-color="white" v-model="temp.confirmPassword" :type="state.showPassword ? 'text' : 'password' " :append-icon="state.showPassword ? 'mdi-eye' : 'mdi-eye-off' " @click:append="state.showPassword=!state.showPassword" @keyup="passwordValidationMessage" outlined/>
                        <div v-if="showPasswordValidationMessage==true" style='margin:-16px 0 16px 8px; font-size:13px;'>
                            <p style='color:red;'>Password must contain:</p>
                            <ul style='margin-top:-16px;'>
                                <li>6 or more characters</li>
                                <li>an upper & lower case letter</li>
                                <li>a number & special character</li>
                                <li>"Password" and "Repeat Password" must match</li>
                            </ul>
                        </div>
                        <v-btn :disabled="!allowRegister" class="themed-button-dark" elevation="0" @click="signUp">Register</v-btn>
                        <hr>
                        <!-- <v-btn class="themed-button-dark" elevation="0" @click="state.verifySignUp=true">Received a verfication code?</v-btn>
                        <br> -->
                        <br>
                        <v-btn class="themed-button-dark" elevation="0" @click="$router.push({path:'/sign_in'})">Cancel</v-btn>
                    </form>
                </v-col>
            </v-row>
        </v-container>

        <!-- RESUME SIGN-UP ("Received a verification code?") -->
        <v-dialog v-model="state.verifySignUp" width="600">
            <v-card>
                <v-card-title>
                    Verify your account
                </v-card-title>
                <v-card-text>
                    An account verification link has been sent to: {{ form.email }}
                    <br>
                    Please check your inbox (or junk folder) to find the verification link.
                    <!-- <form @submit.prevent="false">
                        <v-text-field v-model="form.email" label="*E-mail Address" outlined/>
                        <v-otp-input
                            :disabled="!allowVerificationPinEntry"
                            v-model="temp.verificationCode"
                            type="number"
                            :length="config.pinLength"
                        />
                    </form> -->
                </v-card-text>
                <v-card-actions>
                    <v-btn :disabled="!isValid('email',form.email)" class="themed-button-dark" elevation="0" @click="resendConfirmationCode">Resend</v-btn>
                    <v-spacer></v-spacer>
                    <!-- <v-btn class="themed-button-dark" elevation="0" @click="state.verifySignUp=false">Close</v-btn> -->
                    <v-btn class="themed-button-dark" elevation="0" @click="$router.push({path:'/sign_in'});state.verifySignUp = false">Continue</v-btn>
                    <!-- <v-spacer></v-spacer>
                    <v-btn :disabled="!allowSignUpVerify" class="themed-button-dark" elevation="0" @click="verifySignUp">Verify</v-btn> -->
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- RESET PASSWORD MODAL ("Have a reset code?") -->
        <v-dialog v-model="state.resetPassword" width="600" class='targetModal'>
            <v-card>
                <v-card-title>
                    Reset Password
                </v-card-title>
                <v-card-text>
                    <form @submit.prevent="false">
                        <p style='margin-bottom:0;'><span style='color:red;'>*</span> Email Address:</p>
                        <v-text-field v-model="form.email" outlined/>

                        <v-row>
                            <v-col cols='12' sm='6' style='padding: 0 12px;'>
                                <p style='display:inline-block; margin-bottom:0;'><span style='color:red;'>*</span> Password:</p>
                                <v-text-field v-model="form.password" :type="state.showPassword ? 'text' : 'password' " append-icon="mdi-eye" @click:append="state.showPassword=!state.showPassword" outlined />
                            </v-col>
                            <v-col cols='12' sm='6' style='padding: 0 12px;'>
                                <p style='display:inline-block; margin-bottom:0;'><span style='color:red;'>*</span> Repeat Password:</p>
                                <v-text-field v-model="temp.confirmPassword" :type="state.showPassword ? 'text' : 'password' " append-icon="mdi-eye" @click:append="state.showPassword=!state.showPassword" outlined />
                            </v-col>
                        </v-row>

                        <v-otp-input
                            :disabled="!allowVerificationPinEntry"
                            v-model="temp.verificationCode"
                            type="number"
                            :length="config.pinLength"
                        />
                    </form>
                </v-card-text>
                <v-card-actions>
                    <v-container>
                        <v-row>
                            <v-col cols='12' sm='4' style='padding: 0 8px 16px;'>
                                <v-btn :disabled="!allowResetPassword" class="themed-button-dark" elevation="0" @click="resetPassword">Reset Password</v-btn>
                            </v-col>
                            <v-col cols='12' sm='4' style='padding: 0 8px;'>
                                <v-btn :disabled="!isValid('email',form.email)" class="themed-button-dark" elevation="0" @click="forgotPassword">Resend Code</v-btn>
                            </v-col>
                            <v-col cols='12' style='padding: 16px 8px;'>
                                <v-btn class="themed-button-dark" elevation="0" @click="state.resetPassword=false">Close</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </template>

    <template v-else>
        Welcome back, {{user.attributes.given_name}}
        <!-- Nothing to do, you're already logged in.
        <hr>
        Did you want to log out?
        <br>
        <v-btn class="themed-button-dark" @click="$store.getters.Authorizer.signOut()">Sign Out</v-btn> -->
    </template>

    <v-snackbar v-model="state.snackbar.display" rounded="pill">
        <span v-html="state.snackbar.message"/>
    </v-snackbar>
  </div>
</template>

<script>
import validations from '@/plugins/validations.js'
import Title from '@/components/Title/Title'
import cache from '@/plugins/cache.js'
import FederationBox from './FederationBox'
import hcpSpecialtyList from '@/assets/hcpSpecialtyList.json'

export default {
    components: {
        Title,
        FederationBox
    },
    created: function(){
        this.validations = new validations.methods(this)
        this.rules = new validations.rules(this)
        let remember_me = cache.storage.local.get('remember_me')
        if(remember_me){
            this.rememberMe = true
            this.form.email = remember_me
        }

        this.state.view = this.path
        if(this.user){
            // this.$router.push({path:'/account'})
        }
    },
    data: function(){
        return {
            config: {
                pinLength: 6
            },
            validations: null,
            rules: null,
            state: {
                view: 'sign_in',
                verifySignUp: false,
                showPassword: false,
                resetPassword: false,
                snackbar: {
                    display: false,
                    message: null
                }
            },
            rememberMe: false,
            form: {
                email: null,
                given_name: null,
                family_name: null,
                password: null,
                specialty: null,
                country: null,
                hcp: null,
                language: null
            },
            temp: {
                confirmPassword: null,
                verificationCode: null,
                user: null
            },
            hcpSpecialtyList: hcpSpecialtyList,
            showPasswordValidationMessage: false
        }
    },
    methods: {
        federation: async function(provider){
            console.log('attempting federation signIn',{
                provider
            })
            let response = await this.Authorizer.federation(provider)
            console.log('Federation',{
                provider,
                response
            })
        },
        signIn: async function(){
            let data = this.getFields('signIn')
            let response = await this.Authorizer.signIn(data)
            console.log('signIn', response)
            if(response.code=='NotAuthorizedException'){
                this.state.snackbar.display = true
                this.state.snackbar.message = response.message
            }
        },
        signUp: async function(){
            let fields = this.getFields('signUp')
            let data = {
                username: fields.email,
                password: fields.password,
                attributes: {
                    email: fields.email,
                    given_name: fields.given_name,
                    family_name: fields.family_name,
                    "custom:country": fields.country,
                    "custom:hcp": fields.hcp,
                    "custom:language": fields.language,
                    "custom:specialty": fields.specialty
                },
                authSignIn: {
                    enabled: true
                }
            }

            let response = await this.Authorizer.signUp(data)
            if(response.event=='signUp'){
                this.state.snackbar.message = response.message + '<br>A verficiation code has been sent to your email (please check your junk folder)'
                this.state.verifySignUp = true
            }
            if(response.event=='signUp_failure'){
                this.state.snackbar.message = response.message + "<br>Have you already signed up?  Check your inbox/junk folder for the verfication code"
                await this.resendConfirmationCode()
                this.state.verifySignUp = true
            }

            this.state.snackbar.display = true
            console.log('signUp', response)
        },
        resendConfirmationCode: async function(){
            let username = this.form.email
            let response = await this.Authorizer.resendConfirmationCode(username)
            this.state.snackbar.message = 'A verficiation code has been sent to your email (please check your junk folder)'
            this.state.snackbar.display = true
            console.log('resendConfirmationCode', response)
        },
        forgotPassword: async function(){
            let username = this.form.email
            let response = await this.Authorizer.forgotPassword(username)
            this.state.snackbar.message = 'A reset code has been sent to your email (please check your junk folder)'
            this.state.snackbar.display = true
            console.log('sendResetCode', response)
        },
        resetPassword: async function(){
            let username = this.form.email
            let password = this.form.password
            let verificationCode = this.temp.verificationCode
            let response = await this.Authorizer.resetPassword(username, verificationCode, password)
            if(response=='SUCCESS'){
                this.state.snackbar.display = true
                this.state.snackbar.message = 'Password reset successful'
                this.state.view=='sign_in'
            }
            console.log('sendResetCode', response)
        },
        verifySignUp: async function(){
            let form = this.form
            let username = form.email
            let verificationCode = this.temp.verificationCode
            let response = await this.Authorizer.confirmSignUp({username, verificationCode})
            if(response.event =='confirmSignUp'){
                this.state.snackbar.message = response.message
                this.state.verifySignUp = false
                this.state.snackbar.display = true
                this.$router.push({path:'/sign_in'})
            }

            console.log('verifySignUp', response)
        },
        getFields: function(formType){
            let language = this.language
            let form = this.form
            form.language= language
            if(formType=='signIn'){
                return {
                    email: form.email,
                    password: form.password
                }
            }else if(formType=='signUp'){
                return form
            }
        },
        isValid: function(type, input){
            if(input===null){
                return false
            }

            if(type=='givenName'
            || type=='familyName'){
                return input.trim().length>=1
            }

            if(type=='email'){
                return this.validations.validEmail(input)
            }

            if(type=='password'){
                return this.validations.validPassword(input)
            }

            if(type=='hcp'){
                return input.length==1
            }

            if(type=='specialty'){
                if( (input.hcp == 'Y' && input.specialty != null)
                ||   input.hcp == 'N'){
                    return true;
                }
            }

            if(type=='country'){
                return input.trim().length>=2
            }

            if(type=='verificationCode'){
                return input.length==this.config.pinLength
            }
        },
        passwordValidationMessage: function(){
            // GET: password and confirmPassword values
            let password = this.form.password;
            let confirmPassword = this.temp.confirmPassword;
            // REMOVE: spaces from password & confirm password fields
            if( password != null){
                password.split(' ').join('');
                password = password.trim();
            };
            if( confirmPassword != null){
                confirmPassword.split(' ').join('');
                confirmPassword = confirmPassword.trim();
            };
            // SET: trimmed values back to data
            this.form.password = password;
            this.temp.confirmPassword = confirmPassword;

            // Don't show password validation message if empty fields
            if( password.length == 0 && confirmPassword.length == 0){
                this.showPasswordValidationMessage = false;
                return;
            };

            let isValid = this.validations.validPassword(password);
            if( isValid
            &&  password == confirmPassword){
                this.showPasswordValidationMessage = false;
            }
            else{
                this.showPasswordValidationMessage = true;
            };
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        path: function(){
            return this.$route.path.replaceAll('/','')
        },
        allowSignIn: function(){
            let form = this.form
            return this.isValid('email', form.email) && this.isValid('password', form.password)
        },
        allowRegister: function(){
            let form = this.form
            let confirmPassword = this.temp.confirmPassword
            let tests = {
                validGivenName: this.isValid('givenName', form.given_name),
                validFamilynName: this.isValid('familyName', form.family_name),
                validEmail: this.isValid('email', form.email),
                validPassword: this.isValid('password', form.password),
                validConfirmPassword: this.isValid('password', confirmPassword),
                matching: form.password==confirmPassword,
                hcp: this.isValid('hcp', form.hcp),
                country: this.isValid('country', form.country),
                specialty: this.isValid('specialty', {specialty:form.specialty, hcp:form.hcp})
            }

            function allTrue(object){
                for(let key in object){
                    if(!object[key]){
                        return false
                    }
                }
                return true
            }
            return allTrue(tests)
        },
        allowVerificationPinEntry: function(){
            let form = this.form
            return this.isValid('email', form.email)
        },
        allowSignUpVerify: function(){
            let form = this.form
            let temp = this.temp
            return this.isValid('email', form.email) && this.isValid('verificationCode', temp.verificationCode)
        },
        allowResetPassword: function(){
            let form = this.form
            let temp = this.temp
            return this.isValid('email', form.email) && this.isValid('verificationCode', temp.verificationCode)
        },
        Authorizer: function(){
            return this.$store.getters.Authorizer
        },
        user: function(){
            return this.$store.getters.user
        }
    },
    watch: {
        path: function(){
            this.state.view = this.path
        },
        user: function(){
            if(this.user){
                // this.$router.push({path:'/account'})
            }
        },
        rememberMe: function(){
            let rememberMe = this.rememberMe
            if(rememberMe){
                cache.storage.local.set('remember_me', this.form.email)
            }else{
                cache.storage.local.clear('remember_me')
            }
        }
    }
}
</script>

<style scoped lang="scss">

.sign-in-up{
    padding: 25px;
    background-color: $secondary-light-2;

    /******
    GENERAL
    *******/
    .Title {
        font-size: 20px;
    }

    .fieldLabel {
        margin-bottom: 0 !important;
        margin-left: 0px;
        font-size: 14px;

        > span {
            color: red;
        }
    }

    /******
    SIGN IN
    *******/
    .signIn {

        .forgotPassword {
            padding: 0 !important;
            text-decoration: underline;
            text-transform: none;
            font-weight: normal !important;
            margin-top: -24px;

            &:before {
                &:hover {
                    background-color: transparent;
                }
            }
        }

        .login_button {
            margin-top: -24px;
            padding: 0 32px !important;
            font-size: 20px;
            font-weight: 500;
        }

        .signUp_button {
            margin-left: 8px;
        }

        .divideSection {
            text-align: center;
            @media(max-width:599px){
                text-align: left;
            }

            .vertical-line{
                display: block;
                width: 1px;
                height: 30%;
                background-color: black;
                margin: 0 auto;

                @media(max-width:599px){
                    width: 100%;
                    height: 1px;
                    margin: 16px 0;
                }

                // second line display none on mobile phone size
                &:nth-of-type(3) {
                    @media(max-width:599px){
                        display: none;
                    }
                }
            }

            .vertical-line-text{
                font-size: 1.8em;
                margin: 15px;
                @media(max-width:599px){
                    margin: 0;
                }
            }
        }

        .federationBoxes {
            @media(max-width:599px){
                margin-top: -24px;
                margin-left: -16px;
            }
        }
    }
    // END signIn

    /******
    SIGN UP
    *******/
    .signUp {

        @media(max-width:515px){
            padding: 0;
        }

        .names_wrap {
            display: flex;
            justify-content: space-between;

            @media(max-width: 515px){
                flex-flow: column;
            }

            > div {
                width: calc(50% - 8px);

                @media(max-width: 515px){
                    width: 100%;
                }
            }
        }

        .names_wrap {
            > div {
                @media(max-width:515px){
                    width: 100%;
                }
            }
        }

        .hcp_specialty_country_wrap {
            display: flex;

            @media(max-width:515px){
                display: block;
            }

            .hcp_specialty_wrap {
                width: calc(50% - 8px);
                margin-right: 16px;

                @media(max-width:515px){
                    width: 100%;
                }

                > div:nth-of-type(2){
                    @media(max-width:515px){
                        width: 100%;
                    }
                }
            }

            .country_wrap {
                width: calc(50% - 8px);

                @media(max-width:515px){
                    width: 100%;
                }
            }
        }
    }
}
</style>
