<template>
<div>
    <div v-if="!ready">
      ...loading...
    </div>
    <div v-else>
        <v-container>
            <v-row style='margin-top: 16px;'>
                <v-col>
                    <Title :title="'News & Events'" :subtitle="'Stay up to date with the latest Thrombosis Canada news and upcoming events'"/>
                    <v-tabs v-model="tabIndex" class="themed" center-active dark rounded>
                        <v-tabs-slider color="red"></v-tabs-slider>
                            <v-tab v-for="tab in _tabs" :key="'tab-'+tab.value" @click="selectTab(tab.value)">
                                {{tab.text}}
                            </v-tab>
                    </v-tabs>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-tabs-items v-model="tabIndex">
                        <v-tab-item v-for="item in _tabs" :key="'tab-item-'+item.value">
                            <v-container>
                                <v-row v-if="!ui.filter.display">
                                    <v-col>
                                        <v-btn text @click="ui.filter.display=!ui.filter.display" class="themed-button-dark">Filter by topic</v-btn>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col v-if="ui.filter.display">
                                        <ArticleFilter v-model="form.filters" @close="ui.filter.display=false" :componentConfig="componentConfig" :language="language"/>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <ArticleHighlight v-if="resolveHighlight(item.value)" @readMore="readMore" :language="language" :article="resolveHighlight(item.value)"/>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <div class="article-container" v-if="categories">
                                            <template v-if="categories[resolveTabValue(tabIndex)].length>0">
                                            <div v-for="(article, articleIndex) in categories[resolveTabValue(tabIndex)]" :key="'article-'+article.value+'-item-'+tabIndex+'-'+articleIndex" class="article-item">
                                                <ArticleCard v-if="article.type.includes(resolveTabValue(tabIndex))" @readMore="readMore" :language="language" :params="article"/>
                                            </div>
                                            </template>
                                            <div v-else>No articles found</div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-container>
    </div>
</div>
</template>

<script>
import Title from '@/components/Section/title'
import ArticleFilter from "./articleFilter.vue"
import ArticleHighlight from "./articleHighlight.vue"
import ArticleCard from './lib/articleCard.vue'
// import NewsCard from '@/components/Section/News/newsCard'
// import EventCard from '@/components/Section/Events/eventCard'
// import WebinarCard from '@/components/Section/Events/eventCard'
export default {
  created: function(){
    this.init()
  },
  props: {
    language: {
      type: String,
      required: true
    }
  },
  components: {
    ArticleFilter,
    ArticleHighlight,
    Title,
    ArticleCard
    // NewsCard,
    // EventCard,
    // WebinarCard
  },
  data: function(){
    return {
      ui: {
        filter: {
          display: false
        }
      },
      ready: false,
      articles: [],
      categories: null,
      tabIndex: 0,
      page: {
        news: 1,
        events: 1,
        webinars: 1
      },
      form: {
        filters: {topics: []}
      }
    }
  },
  methods: {
    init: async function(){

      function byDates(a, b){
        let result = a.content.date<b.content.date
        return result ? 1 : -1;
      }

      let response = await this.sendRequest('get','/api/news')
      this.ready = true
      if(response.status==200){
        this.articles = response.data
        this.articles.sort(byDates)
        this.categorizeAndFilter()
      }
      this.displayTab()
    },

    categorizeAndFilter: function(){
      function filter(articles, type, filters){
        // console.log('filter',{
        //   articles,
        //   type,
        //   filters,
        //   output: {
        //     byType: byType(articles, type)
        //   }
        // })
        function byType(articles, filter){
          return articles.filter((article)=>{return article.type.includes(filter)})
        }

        function byTopic(articles, topicFilters){
          return articles.filter((article)=>{
            if(topicFilters.length==0){
              return true
            }else{
              return article.topics.filter((topic)=>{
                let found = topicFilters.includes(topic)
                return found
              }).length>0
            }

            // return article.topics.filter((topic)=>{
            //   let found = topicFilters.includes(topic)
            //   let noFilters = topicFilters.length==0
            //   return found || noFilters
            // }).length>0
          })
        }

        return byTopic(byType(articles, type), filters.topics)
      }
      let articles = this.articles
      let filters = this._filters

      this.categories = {
        news: filter(articles, 'news', filters),
        events: filter(articles, 'events', filters),
        webinars: filter(articles, 'webinars', filters),
      }
    },
    selectTab: function(tab){
      this.$router.push({
        query:{tab}
      })
    },
    displayTab: function(){
      let query = this._query
      if(query.tab){
        this.tabIndex = this.resolveTabIndex(query.tab)
      }else{
        this.tabIndex = 0
      }
    },
    resolveTabValue: function(tabIndex){
      let tabs = this._tabs
      return tabs[tabIndex].value
    },
    resolveTabIndex: function(tabValue){
      let tabs = this._tabs
      for(let i=0; i<tabs.length; i++){
        let tab = tabs[i]
        if(tab.value==tabValue){
          return i
        }
      }
      return null
    },
    resolveHighlight: function(tab){
      let articles = this.articles
      let componentConfig = this.componentConfig
      let promote = componentConfig.promote
      let articleId = promote[tab]
      let article = articles.filter((article)=>{return article.id==articleId})
      return articleId && article.length>0 ? article[0] : null
    },
    readMore: function(article){
      this.$router.push({name:'Article',params:{articleId:article.pageName}})
    }
  },
  computed: {
    componentConfig: function(){
      return this.$store.getters.componentConfigurations.articles.config
    },
    _tabs: function(){
      return [
        {
          text: 'News',
          value: 'news'
        },
        {
          text: 'Events',
          value: 'events'
        },
        {
          text: 'Webinars',
          value: 'webinars'
        }
      ]
    },
    _filters: function(){
      return this.form.filters
    },
    _query: function(){
      return this.$route.query
    }
  },
  watch: {
    _query: function(){
      this.displayTab()
    },
    _filters: {
      deep: true,
      handler: function(){
        this.categorizeAndFilter()
      }
    }
  }
}
</script>

<style scoped lang="scss">

.article-container{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.article-item{
    flex-grow: 1;
    min-width: 30%;
    max-width: 360px;
    @media(max-width: 959px){
        max-width: none;
    }

    .articleCard {
        margin: 0;
    }
}

.articles_topRow {
    border: 2px solid red;
}

</style>


<style lang="scss">

.themed .v-tabs-bar{
    background-color: $primary !important;
}

</style>
