var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textblock",class:[_vm.config.theme],attrs:{"_uid":_vm._uid}},[(!_vm.config.componentType || _vm.config.componentType=='template')?_c('div',{staticClass:"textblock-container",class:[`section-layout-${_vm.config.layout.display}`, `section-ratio-${_vm.config.layout.ratio}`, _vm.config.background, _vm.config.theme],style:([{'padding-top': `${_vm.config.layout.paddingTop}px`},{'padding-bottom': `${_vm.config.layout.paddingBottom}px`},{'margin-top': `${_vm.config.layout.marginTop}px`},{'margin-bottom': `${_vm.config.layout.marginBottom}px`},{'flex-direction':_vm.config.layout.display}])},_vm._l((_vm.config.section),function(sectionRecord,sectionIndex){return _c('div',{key:`section-${sectionIndex}`,staticClass:"textblock-section",style:({'align-self':sectionRecord[0] && sectionRecord[0].config.alignSelf ? sectionRecord[0].config.alignSelf : 'center'})},_vm._l((sectionRecord),function(contentRecord,contentIndex){return _c('div',{key:`section-${sectionIndex}-content-${contentIndex}`,style:([{display:contentRecord.config.contentType=='button' ? contentRecord.content[_vm.lang].display : 'block'}])},[(contentRecord.config.contentType=='title')?_c('Title',{staticClass:"title",attrs:{"config":contentRecord.content,"language":_vm.language}}):(contentRecord.config.contentType=='html' && contentRecord.config.columns.number==1)?_c('div',{staticClass:"content-column",class:[contentRecord.config.columns.fill],style:({
                            'max-height': contentRecord.config.columns.maxHeight ? contentRecord.config.columns.maxHeight+'px' : 'none'
                        }),domProps:{"innerHTML":_vm._s(_vm.getContent(contentRecord.content))}}):(contentRecord.config.contentType=='html')?_c('div',{staticClass:"content-column",class:[contentRecord.config.columns.fill],style:({
                            '-webkit-columns': contentRecord.config.columns.number,
                            '-moz-columns': contentRecord.config.columns.number,
                            'columns': contentRecord.config.columns.number,
                            'max-height': contentRecord.config.columns.maxHeight ? contentRecord.config.columns.maxHeight+'px' : 'none'
                        }),domProps:{"innerHTML":_vm._s(_vm.getContent(contentRecord.content))}}):(contentRecord.config.contentType=='button')?_c('div',{class:[`button-align-${_vm.getContent(contentRecord.content).align}`,contentRecord.config.buttonTheme ? `button-${contentRecord.config.buttonTheme}` : ''],style:([{display:contentRecord.content.display}])},[_c('v-btn',{class:[`v-size--${_vm.getContent(contentRecord.content).size}`],on:{"click":function($event){_vm.buttonHandler(_vm.getContent(contentRecord.content))}}},[_vm._v(" "+_vm._s(_vm.getContent(contentRecord.content).text)+" ")])],1):(contentRecord.config.contentType=='icon')?_c('Icon',{staticClass:"icon",attrs:{"config":contentRecord.content}}):[_c(contentRecord.config.contentType,{tag:"component",attrs:{"debug":_vm.debug,"componentConfig":contentRecord,"config":contentRecord.content,"language":_vm.language,"site":_vm.$root.site}})]],2)}),0)}),0):_vm._e(),(_vm.config.componentType=='grid')?_c('div',[_c('div',{class:[_vm.config.layout, _vm.config.background, _vm.config.theme]},[_vm._l((_vm.config.contentComponents),function(contentComponent,contentComponentIndex){return [_c(contentComponent.key,{key:`contentComponent-${contentComponentIndex}-${contentComponent.key}`,tag:"component",attrs:{"debug":_vm.debug,"language":_vm.language,"componentConfig":contentComponent,"config":contentComponent.config}})]})],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }