<template>
    <div class="pins">
        <!-- <json-viewer :value="{_categories, myPins}"/> -->
        <div class="pinCategoriesFilter">
            <div style="margin: 35px 0px 15px 0px;">
                <!-- <svg :data-src="images.Triangle" class="triangle" :class="{'pinned':true}"/> <span class="title">Pinned Content Categories</span> -->
            </div>
            <div v-for="(item) in _categories" :key="'pinned_category_'+item.category" class="chip-holder">
                <v-chip class="ma-2 chip-pin" :class="{'pinned':havePinType(item.pinType, item.category)}" label text-color="white">
                    {{item.category}}
                </v-chip>
            </div>
        </div>
    

    
        <template v-for="record in _categories">
            <div :key="'category_'+record.category" class="pin-category" v-if="havePinType(record.pinType, record.category)">
                <!-- <svg :data-src="images.Triangle" class="triangle pinned"/> <span class="title">{{record.category}}</span> -->
                <div class="card-container">
                    <template v-for="(pinned, id, cardIndex) in myPins[record.pinType]">
                        <div v-if="pinned && record.pinType=='articles' && _articles[id] && _articles[id].type.includes(record.category)" class="card" :key="`${record.category}_card_${id}_${cardIndex}`">
                            <ArticleCard v-if="record.pinType=='articles' && _articles[id] && _articles[id].type.includes(record.category)" :params="_articles[id]" :language="language" @readMore="articlesReadMore"/>
                        </div>
                        <div v-if="pinned && record.pinType=='resources' && _resources[id]" class="card" :key="`${record.category}_card_${id}_${cardIndex}`">
                            <ResourceCard v-if="record.pinType=='resources'" @downloadResource="downloadResource" :resource="_resources[id]" :language="language" :debug="$store.getters.debug"/>
                        </div>
                        <div v-if="pinned && record.pinType=='clinicalGuides' && _clinicalGuides[id]" class="card" :key="`${record.category}_card_${id}_${cardIndex}`">
                            <ClincialGuideCard v-if="record.pinType=='clinicalGuides'" @selectGuide="selectGuide" :guide="_clinicalGuides[id]" :guideTopics="$store.getters.clinicalGuideData[language].guideTopics" :language="language"/>                        
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </div>
    </template>
    
    <script>
    import Triangle from './assets/triangle.svg'
    import ArticleCard from '@/components/Article/lib/articleCard'
    import ResourceCard from '@/components/Resources/ResourceCard'
    import ClincialGuideCard from '@/components/ClinicalGuides/guideCard'
    
    export default {
        props: {
            language: {
                type: String,
                required: true
            }
        },
        components: {
            ArticleCard,
            ResourceCard,
            ClincialGuideCard
        },
        created: function(){
            // let pins = this.cache.storage.local.get('pins')
            // this.myPins = pins ? pins : {}
            this.myPins = this.$store.getters.controllers.pins.get()
    
            /* HANDLE BUG: removes empty pinned cards at passed milisecond.
                - uses setTimeout delay to wait for vue loops to finish rendering ui
                - multiple fires, starting with tight timing of 0.1 seconds (fast connection, fast removal, so don't notice space for empty card)
                to 5 seconds (slow connection, more likely to notice space for empty cards)
                this.removeEmptyPinnedCards(100);
                this.removeEmptyPinnedCards(500);
                this.removeEmptyPinnedCards(1000);
                this.removeEmptyPinnedCards(2000);
                this.removeEmptyPinnedCards(3000);
                this.removeEmptyPinnedCards(4000);
                this.removeEmptyPinnedCards(5000);
            */
            if(!this.$store.getters.user){
                this.$router.push({path:'/sign_in'})
            }
        },
        data: function(){
            return {
                // news: null,
                // resources: null,
                myPins: {},
                config: {
                    articles: {path: '/news_and_events/article'}
                }
            }
        },
        methods: {
            articlesReadMore: function(data){
                let config = this.config
                this.$router.push({path:`${config.articles.path}/${data.pageName}`})
            },
            havePinType: function(pinType, filter){
                let pins = this.myPins[pinType] ? this.myPins[pinType] : null
                let articles = this._articles
                let resources = this._resources
                let clinicalGuides = this._clinicalGuides
    
                if(pins && pinType=='articles'){
                    for(let id in pins){
                        let pinned = pins[id]
                        let article = articles[id]
                        if(pinned && filter && article && article.type.includes(filter)){
                            return true
                        }
                    }
                }
    
                if(pins && pinType=='resources'){
                    for(let id in pins){
                        let pinned = pins[id]
                        let resource = resources[id]
                        if(pinned && resource){
                            return true
                        }
                    }
                }
    
                if(pins && pinType=='clinicalGuides'){
                    for(let id in pins){
                        let pinned = pins[id]
                        let clinicalGuide = clinicalGuides[id]
                        if(pinned && clinicalGuide){
                            return true
                        }
                    }
                }
                return false
                // if(pinned){
                //     for(let id in pinned){
                //         return pinned[id]
                //     }
                // }else{
                //     return false
                // }
    
            },
            removeEmptyPinnedCards: function(miliseconds){
                let delay = setTimeout(function(){
                    let cards = document.querySelectorAll('.pin-category .card-container .card');
                    for(let i = 0; i < cards.length; i++){
                        let card = cards[i];
                        let cardChildrenLength = card.children.length;
                        if( cardChildrenLength == 0){
                            card.remove();
                        };
                    };
                },miliseconds);
            },
            selectGuide: function(guide){
                let language = this.language
                let target = {
                    path: '/hcp/practice/clinical_guides',
                    query: {
                        language: guide.language=='french' ? 'fr-ca' : 'en-ca',
                        guideID: guide.id
                    }
                }
                this.$router.push(target)
            },
            downloadResource: function(resource){
                // window.open(`${this.$store.getters.bucket}/${resource}`);
            },
            // getNews: async function(){
            //     this.ready = false
            //     let response = await this.sendRequest('get','/api/news')
            //     if(response.status==200){
            //         this.news = response.data
            //     }
            //     this.ready = true
            // },
            // getResources: async function(){
            //     this.ready = false
            //     let response = await this.sendRequest('get','/api/resources')
            //     if(response.status==200){
            //         this.resources = response.data
            //     }
            //     this.ready = true
            // },
            _items: function(category){
                let pins = this.myPins[category]
                let output = []
    
                if(records){
                    for(let i in pins){
                        let id = parseInt(i,10)
                        if(pins[id]){
                            let found = records.filter((item)=>{let match = item.id==id; return match})
                            if(found.length>0){
                                output.push({
                                    article: found[0],
                                    category
                                })
                            }
                        }
                    }
                }
                return output
            }
        },
        computed: {
            ready: function(){
                let ready = this.$store.getters.clinicalGuideData && this.news && this.resources
            },
            _articles: function(){
                let output = {}
                let articles = this.$store.getters.articles ? this.$store.getters.articles : []
                for(let i=0; i<articles.length; i++){
                    let article = articles[i]
                    if(article.enabled=='Y' && article.publish=='Y'){
                        output[article.id] = article
                    }
                }
                return output
            },
            _resources: function(){
                let output = {}
                let resources = this.$store.getters.resources ? this.$store.getters.resources : []
                for(let i=0; i<resources.length; i++){
                    let resource = resources[i]
                    if(resource.enabled=='Y' && resource.publish=='Y' && resource.public=='Y'){
                        output[resource.id] = resource
                    }
                }
                return output
            },
            _clinicalGuides: function(){
                // let language = this.language
                // let output = {}
                // let clinicalGuides = this.$store.getters.clinicalGuideData && this.$store.getters.clinicalGuideData[language].guides ? this.$store.getters.clinicalGuideData[language].guides : []
                // for(let i=0; i<clinicalGuides.length; i++){
                //     let cliniclGuide = clinicalGuides[i]
                //     output[cliniclGuide.id] = cliniclGuide
                // }
                // return output
    
                let output = {}
                for(let language in this.$store.getters.clinicalGuideData){
                    let clinicalGuides = this.$store.getters.clinicalGuideData[language].guides ? this.$store.getters.clinicalGuideData[language].guides : []
                    for(let i=0; i<clinicalGuides.length; i++){
                        let cliniclGuide = clinicalGuides[i]
                        output[cliniclGuide.id] = cliniclGuide
                    }
                }
                return output
            },
            _categories: function(){
                let output = [
                    {category: 'news', pinType: 'articles'},
                    {category: 'events', pinType: 'articles'},
                    {category: 'webinars', pinType: 'articles'},
                    {category: 'resources', pinType: 'resources'},
                    {category: 'clinicalGuides', pinType: 'clinicalGuides'}
                ]
    
                return output
            },
            images: function(){
                return {
                    Triangle
                }
            }
        },
        watch: {
            // myPins: {
            //     deep: true,
            //     handler: function(){
            //         let myPins = this.myPins
            //         if(this.havePin(myPins.articles)){
            //             this.getNews()
            //         }
            //         if(this.havePin(myPins.resources)){
            //             this.getResources()
            //         }
            //     }
            // }
        }
    }
    </script>
    
    <style scoped lang="scss">
    
    .title{
        font-size: 2em;
        font-weight: bold;
        color: $primary;
        text-transform: uppercase;
    }
    
    .pins{
        text-align: left;
    
        .pinCategoriesFilter,
        .pin-category {
            width: 88%;
            margin: 0 auto 24px;
    
            @media(max-width:1200px){
                width: 94%;
            }
        }
    
        .card-container {
            flex-flow: wrap;
            justify-content: start;
    
            .card {
                width: 33%;
                min-width: 360px;
    
                @media(max-width:515px){
                    width: 100%;
                    min-width: 100%;
                }
    
                .articleCard,
                .ResourceCard {
                    margin: 24px 0;
                }
    
                .ResourceCard {
                    max-width: 360px;
                }
            }
        }
    }
    .triangle{
        transform: translateY(25%);
        display: inline-block;
        width: 20px;
        fill: $primary;
        margin-right: 16px;
        background-color: white;
    }
        .triangle.pinned{
            fill: $tc-red;
            background-color: white;
        }
    
    .chip-holder{
        display: inline-block;
    }
    .chip-pin{
        background: $primary !important;
        font-weight: bold;
    }
    
    .chip-pin.pinned{
        background: $tc-red !important;
    }
    
    .pin-category{
        margin-top: 50px;
    }
    
    .card-container{
        display: flex;
        gap: 5px;
    }
    
    .card{
        width: 360px;
    }
    </style>
    