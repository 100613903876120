<template>
  <div v-if="config.twitter.type=='highlight'">
    <v-text-field v-model="content['en-ca']" label="English Tweet ID" type="number"/>
    <v-text-field v-model="content['fr-ca']" label="French Tweet ID" type="number"/>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  data: function(){
    return {}
  },
  computed: {
    content: function(){
      return this.$attrs.value
    }
  }
}
</script>

<style>

</style>