<template>
  <div class="allowCookies">
    <v-snackbar
        color="#555555"
        v-model="ui.snackbar.display"
        :multi-line="true"
        :timeout="-1"
    >

    ThrombosisCanada.ca uses cookies strictly to improve your experience and does not share or sell your information
      <template v-slot:action="{ attrs }">
        <v-btn
            class="themed-button-dark"
            style="margin: 8px;"
            v-bind="attrs"
            @click="accept('allow_cookies',true)"
        >
          Accept
        </v-btn>

        <v-btn
            class="themed-button-dark"
            style="margin: 8px;"
            v-bind="attrs"
            @click="accept('allow_cookies',false)"
        >
          Reject
        </v-btn>
      </template>

    </v-snackbar>
  </div>
</template>

<script>
import cache from '@/plugins/cache.js'
export default {
    created: function(){
        let allow_cookies = cache.storage.local.get('allow_cookies')
        if(allow_cookies === null){
            this.ui.snackbar.display = true
        }
    },
    data: function(){
        return {
            ui: {
                snackbar: {
                    display: false
                }
            }
        }
    },
    methods: {
        accept: function(key, value){
            cache.storage.local.set(key, value)
            this.ui.snackbar.display = false
        }
    },
}
</script>

<style scoped lang="scss">
.allowCookies-SnackBar{
    border: 1px solid green;
}
</style>