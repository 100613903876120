<template>
  <div>
    <v-tabs v-model="ui.sectionTab">
        <v-tab :key="`section-tab-0`">
            Content Settings
        </v-tab>
        <v-tab :key="`section-tab-1`" :disabled="!validation.layout">
            Section 1
        </v-tab>
        <v-tab :key="`section-tab-2`" :disabled="!validation.layout">
            Section 2
        </v-tab>

        <v-tab-item :key="`section-tab-0`">
            <v-form ref="layoutForm" v-model="validation.layout" @submit.prevent>
                <v-select v-model="config.componentType" :items="[{text:'Template',value:'template'},{text:'Grid',value:'grid'}]" label="Type"/>
                <v-card flat>
                    <v-card-title>
                        Theme Settings
                    </v-card-title>
                
                    <v-card-text class="pa-0">
                        <template v-if="config.componentType">
                            <v-select v-model="config.theme" :items="_themes" label="Color theme" required :rules="rules.theme"/>
                        </template>

                        <template  v-if="config.componentType=='grid'">

                            <Browser @selectAsset="selectImage"/>
                            <v-list dense>                                
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Content Component Selector
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-for="(component, componentKey) in componentMap" :key="`select-component-${componentKey}`">
                                    <v-list-item-action>
                                        <v-btn text small @click="addComponent({key:componentKey,config: {}})">
                                            Add: {{componentKey}}
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>

                            <v-card v-for="(component, componentIndex) in config.contentComponents" :key="`contentComponent-${componentIndex}`">
                                <v-card-title>
                                    {{component.key}}
                                </v-card-title>
                                <v-card-actions>
                                    <v-btn icon small @click="removeComponent(componentIndex)">
                                        <v-icon>
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </v-card-actions>
                                <v-card-text>
                                    <component :is="component.key" v-model="component.config"/>
                                </v-card-text>
                            </v-card>

                        </template>
                        

                        <template v-if="config.componentType=='template'">
                            <v-select v-model="config.layout.display" :items="_layoutItems" label="Layout - how each sections should flow" required :rules="rules.layout"/>
                            <v-select v-model="config.layout.ratio" :items="[
                                {text:'1:3',value:'1-3'},
                                {text:'1:2',value:'1-2'},
                                {text:'3:1',value:'3-1'},
                                {text:'2:1',value:'2-1'},
                                {text:'1:1',value:'1-1'},
                                {text:'1',value:'1'}
                            ]" required :rules="rules.ratio" label="Ratio - How much space is taken by each section"/>

                            
                            <v-text-field style="display: inline-block; width: 50%;" outlined v-model="config.layout.paddingTop" type="number" required :rules="rules.required" label="Padding top - space within the section"/>
                            <v-text-field style="display: inline-block; width: 50%;" outlined v-model="config.layout.paddingBottom" type="number" required :rules="rules.required" label="Padding bottom - space within the section"/>
                            <v-text-field style="display: inline-block; width: 50%;" outlined v-model="config.layout.marginTop" type="number" required :rules="rules.required" label="Margin top - space outside the section"/>
                            <v-text-field style="display: inline-block; width: 50%;" outlined v-model="config.layout.marginBottom" type="number" required :rules="rules.required" label="Margin bottom - space outside the section"/>
                        </template>
                    </v-card-text>
                </v-card> 
            </v-form>           
        </v-tab-item>

        <v-tab-item v-for="(sectionRecord, sectionIndex) in config.section" :key="`section-tab-${sectionIndex+1}`">            
            <v-card flat>
                <v-card-text class="pa-0">
                    <v-card flat style="margin: 25px 0px 25px 0px;">
                        <v-card-title>
                            <h3>Section - {{sectionIndex+1}}</h3>  
                        </v-card-title>
                        <v-card-text class="pa-0">

                            <v-btn small depressed @click="ui.asset=!ui.asset" class="themed-button-dark">
                                <v-icon x-small :class="[{'mdi-rotate-90':!ui.asset}, {'mdi-rotate-180':ui.asset}]">
                                    mdi-triangle 
                                </v-icon>
                                 Assets
                            </v-btn>
                            <Browser v-show="ui.asset" @selectAsset="selectImage"/>
                            
                            <v-expansion-panels v-model="ui.panel" style="margin: 15px;">
                                <draggable v-model="config.section[sectionIndex]" style="width:100%;" :disabled="ui.panel!=undefined">
                                    <v-expansion-panel v-for="(contentRecord, contentIndex) in sectionRecord" :key="`content-${contentIndex}`">
                                        <v-expansion-panel-header>
                                            <div>
                                                <v-icon v-show="ui.panel==undefined">mdi-menu</v-icon>
                                                Content Type: <strong>{{contentRecord.config.contentType ? ` ${contentRecord.config.contentType.toUpperCase()}` : ''}}</strong>
                                            </div>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-card v-if="ui.panel == contentIndex" style="margin-bottom: 25px;">
                                                <v-card-text>
                                                    <v-menu min-width="300" max-height="100%" :allow-overflow="true" :top="true">
                                                        <template v-slot:activator="{on, attrs}">
                                                            <v-btn large text v-bind="attrs" v-on="on">
                                                                <v-icon>
                                                                    mdi-view-module
                                                                </v-icon>
                                                                Content Type{{contentRecord.config.contentType ? `: ${contentRecord.config.contentType}` : ''}}
                                                            </v-btn>
                                                        </template>   

                                                        <v-list>
                                                            <v-list-item v-for="(element, index) in _contentItems" :key="'componentList_'+index" style="border-bottom: 1px solid whitesmoke;" @click="contentRecord.config.contentType = element.value; initContentType(sectionIndex, contentIndex)">                                                    
                                                                <v-list-item-content>
                                                                    {{element.text}}
                                                                    <v-list-item-title>
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                                <v-list-item-action>
                                                                    <v-img max-height="120" max-width="200" v-if="element.example" :src="element.example"/>
                                                                </v-list-item-action>
                                                            </v-list-item>
                                                        </v-list>                                                                               
                                                    </v-menu>
                                                    <v-select v-model="contentRecord.config.alignSelf" :items="['top','center','bottom']" label="Vertical Align"/>


                                                    <template v-if="contentRecord.config.contentType=='icon'">
                                                        <v-text-field outlined v-model="contentRecord.content.icon" label="Asset" required :rules="rules.required" @keyup="cleanIconSource(sectionRecord,contentIndex)" :disabled="false"/>
                                                        <v-text-field outlined v-model="contentRecord.content.color" label="Color" required :rules="rules.required" :disabled="false"/>
                                                    </template>

                                                    <template v-else-if="contentRecord.config.contentType=='title'">
                                                        <v-switch v-model="contentRecord.content.dense" :true-value="true" :false-value="false" label="Dense Title"/>
                                                        <v-container>
                                                            <v-row>
                                                                <v-col>
                                                                    <v-tabs v-model="ui.activeTab">
                                                                        <v-tab v-for="language in languages" :key="`textBlock-tab-${language}`">
                                                                        {{language}}
                                                                        </v-tab>
                                                                    </v-tabs>
                                                                        
                                                                    <v-tabs-items v-model="ui.activeTab">
                                                                        <template v-for="(language) in languages">
                                                                        <v-tab-item :key="`html-language-tab-item-${language}`">
                                                                            <br>
                                                                            <v-text-field outlined style="display: inline-block; width: 80%;" v-model="contentRecord.content.title[language]" label="Title"/>
                                                                            <v-text-field outlined style="display: inline-block; width: 20%;" v-model="contentRecord.content.fontSize.title" type="number" clearable label="Override font-size"/>

                                                                            <v-text-field outlined style="display: inline-block; width: 80%;" v-model="contentRecord.content.subtitle[language]" label="Subtitle"/>
                                                                            <v-text-field outlined style="display: inline-block; width: 20%;" v-model="contentRecord.content.fontSize.subtitle" type="number" clearable label="Override font-size"/>

                                                                        </v-tab-item>
                                                                        </template>
                                                                    </v-tabs-items>

                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </template>

                                                    <template v-else-if="contentRecord.config.contentType=='html'">
                                                        <v-text-field type="number" v-model="contentRecord.config.columns.number" label="Columns"/>
                                                        <v-select v-model="contentRecord.config.columns.fill" :items="[{text:'Balanced',value:'balance'},{text:'Auto',value:'auto'}]" label="Column Fill"/>
                                                        <v-text-field type="number" v-model="contentRecord.config.columns.maxHeight" label="Max Height" clearable/>

                                                        
                                                        <v-dialog v-model="ui.resourceSelector">
                                                            <v-card>
                                                                <v-card-text>
                                                                    <ResourceSelector :mode="'selector'" @selectResource="selectResource"/>
                                                                </v-card-text>
                                                                <v-card-actions>
                                                                    <v-btn text @click="ui.resourceSelector=false">Close</v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-dialog>

                                                        
                                                        <v-dialog v-model="ui.assetSelector">
                                                            <v-card>
                                                                <v-card-text>
                                                                    <AssetSelector @selectAsset="selectAsset"/>
                                                                </v-card-text>
                                                                <v-card-actions>
                                                                    <v-btn text @click="ui.assetSelector=false">Close</v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-dialog>



                                                        <v-container>
                                                            <v-row>
                                                                <v-col>
                                                                    <v-tabs v-model="ui.activeTab">
                                                                        <v-tab v-for="language in languages" :key="`textBlock-tab-${language}`">
                                                                        {{language}}
                                                                        </v-tab>
                                                                    </v-tabs>            
                                                                        
                                                                    <v-tabs-items v-model="ui.activeTab">
                                                                        <template v-for="(language) in languages">
                                                                        <v-tab-item :key="`html-language-tab-item-${language}`">
                                                                            <v-btn small class="themed-button-dark" style="margin: 15px;" @click="ui.resourceSelector=true;ui.resourceLanguage=language">Get Direct Resource Link</v-btn>
                                                                            <v-btn small class="themed-button-dark" style="margin: 15px;" @click="ui.assetSelector=true;ui.assetLanguage=language">Get Asset Link</v-btn>
                                                                            <TinyEditor v-model="contentRecord.content[language]" :init="tinyConfig.init" :api-key="tinyConfig.apiKey"/>
                                                                        </v-tab-item>
                                                                        </template>
                                                                    </v-tabs-items>

                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </template>

                                                    <template v-else-if="contentRecord.config.contentType=='button'">
                                                        <v-select v-model="contentRecord.config.buttonTheme" :items="_themes" label="Button Theme" clearable/>
                                                        <v-container>
                                                            <v-row>
                                                                <v-col>
                                                                    <v-tabs v-model="ui.activeTab">
                                                                        <v-tab v-for="language in languages" :key="`textBlock-tab-${language}`">
                                                                        {{language}}
                                                                        </v-tab>
                                                                    </v-tabs>
                                                                        
                                                                    <v-tabs-items v-model="ui.activeTab">
                                                                        <template v-for="(language) in languages">
                                                                        <v-tab-item :key="`html-language-tab-item-${language}`">
                                                                            <v-switch v-model="contentRecord.content[language].internal" :true-value="true" :false-value="false" @change="$forceUpdate()" label="Internal Link"/>
                                                                            <v-text-field v-if="!contentRecord.content[language].internal" v-model="contentRecord.content[language].url" required :rules="rules.links" label="URL (https://[url])"/>
                                                                            <v-text-field v-if="contentRecord.content[language].internal" v-model="contentRecord.content[language].path" required :rules="rules.links" label="Path (/[path])"/>
                                                                            <v-text-field v-model="contentRecord.content[language].text" label="Text"/>
                                                                            <v-select @change="$forceUpdate()" label="Display" v-model="contentRecord.content[language].display" :items="[{text:'block', value:'block'},{text:'inline-block', value:'inline-block'}]"></v-select>
                                                                            <v-select v-if="contentRecord.content[language].display=='block'" label="Alignment" v-model="contentRecord.content[language].align" :items="[{text:'left', value:'left'},{text:'center', value:'center'},{text:'right', value:'right'}]"></v-select>
                                                                            <v-select label="Size" v-model="contentRecord.content[language].size" :items="[{text:'x-small', value:'x-small'},{text:'small', value:'small'},{text:'default', value:'default'},{text:'large', value:'large'},{text:'x-large', value:'x-large'}]"></v-select>
                                                                        </v-tab-item>
                                                                        </template>
                                                                    </v-tabs-items>

                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </template>                                                  

                                                    <template v-else-if="contentRecord.config.contentType=='Stories'">
                                                        <Stories v-model="contentRecord.content"/>
                                                    </template>

                                                    <template v-else-if="contentRecord.config.contentType=='Articles'">
                                                        <Articles v-model="contentRecord.content" :languages="languages" :language="$store.getters.language"/>
                                                    </template>

                                                    <template v-else-if="contentRecord.config.contentType=='Resources'">
                                                        <Resources v-model="contentRecord.content" :languages="languages" :language="$store.getters.language"/>
                                                    </template>

                                                    <template v-else-if="contentRecord.config.contentType=='ContactUs'">
                                                        <ContactUs v-model="contentRecord.content"/>
                                                    </template>

                                                    <template v-else>
                                                        <!-- <component :is="contentRecord.config.contentType" v-model="contentRecord.content"/> -->
                                                        <v-container fluid>
                                                            <v-row>
                                                                <v-col v-if="`${contentRecord.config.contentType}_Config`">
                                                                    <component :is="`${contentRecord.config.contentType}_Config`" v-model="contentRecord.config"/>
                                                                </v-col>
                                                                <v-col v-if="`${contentRecord.config.contentType}`">
                                                                    <component :is="contentRecord.config.contentType" :config="contentRecord.config" v-model="contentRecord.content"/>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </template>

                                                    <!-- <div v-else>No editor for: {{contentRecord.config.contentType}}</div> -->


                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-btn icon color="error" @click="deleteContent(sectionIndex, contentIndex)">
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                    <v-spacer></v-spacer>
                                                </v-card-actions>
                                            </v-card>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </draggable>
                            </v-expansion-panels>

                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="addContent(sectionIndex)"> Add Content to section: {{sectionIndex+1}} </v-btn>
                        </v-card-actions>
                    </v-card>  
                </v-card-text>
            </v-card>            
        </v-tab-item>
    </v-tabs>
    
    <center>
        <v-btn @click="$emit('ok')" color="success" :disabled="!validation.layout">
            OK
        </v-btn>
    </center>


  </div>
</template>

<script>
import ResourceSelector from '../../ResourceManager.vue'
import AssetSelector from '@/components/Assets/Manager.vue'
import draggable from 'vuedraggable'
import ComponentGrid from './TextBlockComponents/ComponentGrid/ComponentGrid.vue'
import VideoGrid from './TextBlockComponents/VideoGrid/VideoGrid.vue'
import ImageGrid from './TextBlockComponents/ImageGrid/ImageGrid.vue'
import Stories from './Stories.vue'
import StoryViewer from '@/components/Admin/StoryEditor.vue'
import Articles from './Articles.vue'
import Resources from './Resources.vue'
import Partners from '@/components/Section/Partners/Partners'
import TinyEditor from '@tinymce/tinymce-vue'
import Browser from '@/components/Assets/Manager'
import ContactUs from './ContactUs.vue'
import PodCast from './PodCast.vue'
import FindUs from './FindUs.vue'
import Twitter from './Twitter/content.vue'
import Twitter_Config from './Twitter/config.vue'

import Example_Title from '@/components/Admin/assets/title.png'
import Example_Icon from '@/components/Admin/assets/icon.png'
import Example_Stories from '@/components/Admin/assets/stories.png'
import Example_Articles from '@/components/Admin/assets/articles.png'
import Example_Resources from '@/components/Admin/assets/resources-b.png'
import Example_Partners from '@/components/Admin/assets/partners.png'


export default {
    name: 'TextBlockEditor',
    components: {
        AssetSelector,
        ResourceSelector,
        draggable,
        ComponentGrid,
        VideoGrid,
        ImageGrid,
        Partners,
        TinyEditor,
        Browser,
        Stories,
        StoryViewer,
        Articles,
        Resources,
        ContactUs,
        PodCast,
        FindUs,
        Twitter,
        Twitter_Config
    },
    created: function(){
        this.tinyConfig.apiKey = process.env.VUE_APP_TINYMCE_API_KEY
        // if(!this.config.columns){
        //     this.config.columns = {
        //         number: null,
        //         fill: 'balance',
        //         maxHeight: '200'
        //     }
        // }
        // if(!(this.config && this.config.layout)){
        //     let template = this.template()
        //     for(let key in template){
        //         this.config[key] = template[key]
        //     }
        // }
        
        // let _config = JSON.stringify(this._config)
        // this.config = JSON.parse(_config)
        // console.log('create contentComponents',this.config)
        if(!this.config.contentComponents){
            this.config.componentType='template'
            this.$set(this.config,'contentComponents', [])
        }
        if(!this.config.layout){
            this.$set(this.config,'layout', {display: 'row', ratio: "1", paddingTop: 25, paddingBottom: 25, marginTop: 0, marginBottom: 0})
        }
        if(!this.config.section || !this.config.section.length){
            this.$set(this.config,'section', [[],[]])
        }
        if(!this.config.theme){
            this.config.theme = this._themes[0].value
        }
        for(let i=0; i<this.config.section.length; i++){
            let sectionRecords = this.config.section[i]
            for(let s=0; s<sectionRecords.length; s++){
                let section = sectionRecords[s]
                if(section.config.contentType=='title'){
                    if(!section.content.fontSize){
                        section.content.fontSize = {
                            title: null,
                            subtitle: null
                        }
                    }
                }
            }
        }

        let self = this
        setTimeout(()=>{
            self.$refs.layoutForm.validate()
        },800)

        // let checkLanguages = [ 'title', 'subtitle', 'content']
        // for(let i=0; i<checkLanguages.length; i++){
        //     let field = checkLanguages[i]
        //     for(let l=0; l<this.languages.length; l++){
        //         let language = this.languages[l]
        //         if(!this.config[field][language]){
        //             this.config[field][language] = null
        //         }
        //     }
        // }
    },
    data: function(){
        return {
            ui: {
                activeTab: 0,
                sectionTab: 0,
                type: "component",
                asset: false,
                panel: undefined,
                resourceSelector: false,
                resourceLanguage: null,
                assetSelector: false,
                assetLanguage: null,
            },
            tinyConfig: {
                init: {
                    media_use_script: true,
                    extended_valid_elements: 'script[language|type|src]',
                    selector: 'textarea',
                    convert_urls: false,
                    plugins: 'code fullscreen anchor charmap codesample emoticons image media link lists searchreplace table visualblocks wordcount',
                    toolbar: 'customExitButton | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | fullscreen | code',
                    height: "800",
                    setup: function(editor){
                        // editor.ui.registry.addButton('customExitButton', {
                        //     text: 'Exit',
                        //     onAction: function (_) {
                        //         GuideEditor.exit()
                        //     }
                        // });
                        // editor.ui.registry.addButton('customSaveButton', {
                        //     text: 'Save',
                        //     onAction: function (_) {
                        //         GuideEditor.save()
                        //     }
                        // });
                        // editor.ui.registry.addButton('customPreviewButton', {
                        //     text: 'Preview',
                        //     onAction: function (_) {  
                        //         // editor.execCommand('mceFullScreen');
                        //         GuideEditor.state.preview = true
                        //     }
                        // });
                        // editor.ui.registry.addButton('customGuideDetails', {
                        //     text: 'Guide Details',
                        //     onAction: function (_) {  
                        //         // editor.execCommand('mceFullScreen');
                        //         GuideEditor.state.guideDetails = true
                        //     }
                        // });
                        // editor.ui.registry.addButton('customSelectTopicsButton', {
                        //     text: 'Select Topic(s)',
                        //     onAction: function (_) {  
                        //         // editor.execCommand('mceFullScreen');
                        //         GuideEditor.state.selectTopics = true
                        //     }
                        // });
                        
                        editor.on('init', function(e) {
                            // editor.execCommand('mceFullScreen');
                            // if(!GuideEditor.guide.meta.id){
                            //     GuideEditor.state.guideDetails = true
                            // }
                        });                        
                    }
                },
                apiKey: null
            },
            ready: false,
            rules: {
                required: [
                    v => !(v==null || v.length==0) || 'Required'
                ],
                theme: [
                    v => !!v || 'Please select a theme',
                ],
                layout: [
                    v => !!v || 'Please select a layout'
                ],
                ratio: [
                    v => !!v || 'Please select a ratio'
                ],
                links: [
                    v => !v.includes(' ') || 'No spaces allowed'
                ]
            },
            validation: {
                layout: false,
                section_1: false,
                section_2: false
            }
        }
    },
    methods: {
        template: function(){
            return {
                layout: {
                    display: null,
                    ratio: null
                },
                theme: null,
                background: null,
                title: {},
                subtitle: {},
                content: [],
                contentComponents: [],
                button: {
                    newTab: false,
                    label: {},
                    link: {}
                }
            }
        },
        addComponent: function(definition){
            this.config.contentComponents.push(definition)
            this.$forceUpdate()
        },
        removeComponent: function(componentIndex){
            this.config.contentComponents.splice(componentIndex, 1)
            this.$forceUpdate()
        },
        selectImage: function(data){
            navigator.clipboard.writeText(`${this.$store.getters.bucket}/${data.Key}`);
            let self = this
            setTimeout(function(){
                alert('Copied to clipboard')
                self.ui.asset = false
            },200)            
        },
        addContent: function(sectionIndex){
            function template(){
                return {
                    config: {
                        contentType: null,
                        alignSelf: 'center',
                        columns: {
                            number: 1,
                            fill: 'balance',
                            maxHeight: null
                        }
                    },
                    content: {}
                }
            }
            let record = template()
            // let languages = this.languages
            // for(let i=0; i<languages.length; i++){
            //     let language = languages[i]
            //     record.content[language] = null
            // }
            this.config.section[sectionIndex].push(record)
            this.ui.panel = this.config.section[sectionIndex].length-1 
        },
        initContentType: function(sectionIndex, contentIndex){
            let languages = this.languages
            function blank(languages){
                let output = {}
                for(let i=0; i<languages.length; i++){
                    let language = languages[i]
                    output[language] = null
                }
                return output
            }

            let record = this.config.section[sectionIndex][contentIndex]
            let contentType = record.config.contentType
            if(contentType=='title'){
                record.content = {
                    title: {},
                    subtitle: {},
                    dense: false,
                    fontSize: {
                        title: null,
                        subtitle: null
                    }
                    
                }
            }else{
                record.content = {}
            }
                
                
            if(contentType=='title'){
                record.content.title = blank(languages)
                record.content.subtitle = blank(languages)
            }else if(contentType=='button'){
                record.content = blank(languages)
                for(let language in record.content){
                    record.content[language] = {
                        internal: true,
                        text: null,
                        size: 'default',
                        url: null,
                        path: null,
                        align: 'left'
                    }
                }
            }else if(contentType=='Stories'){
                record.content = null
            }else{
                record.content = blank(languages)
            }
        },
        deleteContent: function(sectionIndex, contentIndex){
            this.config.section[sectionIndex].splice(contentIndex, 1)
        },
        cleanIconSource: function(sectionRecord, contentIndex){
            let bucket = this.$store.getters.bucket
            let corrected = sectionRecord[contentIndex].content.icon.replaceAll(bucket,'')
            sectionRecord[contentIndex].content.icon = corrected

        },
        selectResource: function(data){
            let language = this.ui.resourceLanguage
            let resource = data.resource
            let path = data.base
            path = path.replace(':resourceId',resource.id)
            path = path.replace(':language',language)
            navigator.clipboard.writeText(path);
            this.ui.resourceSelector = false
            setTimeout(() => {
                alert('Copied path to resource to clipboard: '+path+'\r\n*Suggestion: set downloads or external links to open in new window') 
            }, 800);
        },
        selectAsset: function(data){
            navigator.clipboard.writeText(`${this.$store.getters.bucket}/${data.Key}`);
            let self = this
            setTimeout(function(){
                alert('Copied to clipboard')
                self.ui.assetSelector = false
            },200)            
        }
    },
    computed: {
        languages: function(){
            return this.$store.getters.languages
        },
        config: function(){
            return this.$attrs.value
        },
        _backgrounds: function(){
            let output = [
                {
                    text: 'White',
                    value: 'white'
                },
                {
                    text: 'Lightblue',
                    value: 'theme-blue'
                },
                {
                    text: 'Darkblue',
                    value: 'theme-darkblue'
                }
            ]

            return output
        },
        _themes: function(){
            let output = [
                {
                    text: 'White',
                    value: 'theme-white'
                },
                {
                    text: 'Lightblue',
                    value: 'theme-blue'
                },
                {
                    text: 'Darkblue',
                    value: 'theme-darkblue'
                },
                {
                    text: 'Red',
                    value: 'theme-red'
                }
            ]

            return output
        },
        _layoutItems: function(){
            // let output = [
            //     {
            //         text: 'title-top-center | content-bottom-center',
            //         value: 'title-top-center_content-bottom-center'
            //     },
            //     {
            //         text: 'title-left | content-right',
            //         value: 'title-left_content-right'
            //     },
            //     {
            //         text: 'title-bottom-center | content-top-center',
            //         value: 'title-bottom-center_content-top-center'
            //     },
            //     {
            //         text: 'title-right | content-left',
            //         value: 'title-right_content-left'
            //     },
            //     {
            //         text: 'text-left | button-right',
            //         value: 'text-left_button-right'
            //     }
            // ]

            let output = [
                {
                    text: 'Row',
                    value: 'row'
                },
                {
                    text: 'Column',
                    value: 'column'
                }
            ]            

            return output
        },
        componentMap: function(){
            return {
                ComponentGrid,
                // ImageGrid,
                // VideoGrid,
                // Partners
            }
        },
        _contentItems: function(){
            return [
                {text:'Title',value:'title',example: Example_Title},
                {text:'Icon',value:'icon', example: Example_Icon},
                {text:'HTML',value:'html'},
                {text:'Button',value:'button'},
                {text:'Stories Carousel',value:'Stories', example: Example_Stories},
                {text:'StoryViewer',value:'StoryViewer', example: null},
                {text:'Articles',value:'Articles', example: Example_Articles},
                {text:'Resources',value:'Resources', example: Example_Resources},
                {text:'Partners',value:'Partners', example: Example_Partners},
                {text:'Contact Us',value:'ContactUs', example: null},
                {text:'PodCast',value:'PodCast', example: null},
                {text:'Find Us',value:'FindUs', example: null},
                {text:'Twitter',value:'Twitter', example: null},
            ]
        }
    }
}
</script>

<style>

</style>