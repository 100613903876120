<template>
<div class="footer">
    <v-container>
        <v-row>
            <v-col cols="12" sm="12" md="4" lg="3">
                <div>
                    <img :src="images.logo" style="width: 200px; margin-bottom: 25px;cursor: pointer;" @click="$router.push({name:'Home'})"/>
                </div>
                <p>Thrombosis Canada is a Canadian registered non-profit organization with no commercial interests.</p>
                <p>The organization boasts a membership including the most eminent and internationally recognized thrombosis experts globally.</p>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="5">
              <strong>CONTACT</strong><div class="square"></div><br>
              <v-container style='padding-left:0;'>
                <v-row dense>
                  <v-col cols="1" align-self="start">
                    <v-btn icon @click="showOnGMap()">
                      <v-icon color="green">
                        mdi-map-marker
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col align-self="start" style='margin-left:16px;'>
                    Address:<br>
                    {{$store.getters.contact.address.street}}<br>
                    {{$store.getters.contact.address.city}}, {{$store.getters.contact.address.province}}<br>
                    {{$store.getters.contact.address.postal_code}}, {{$store.getters.contact.address.country}}
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <div style="border-bottom: 1px dotted grey; width: 100%; max-width: 200px;"></div>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="1" align-self="center">
                    <v-btn icon>
                      <v-icon color="teal">
                        mdi-cellphone
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col align-self="center" style='margin-left:16px;'>
                    Phone: {{$store.getters.contact.phone}}
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="1" align-self="center">
                    <v-btn icon link :href='`mailto:${$store.getters.contact.email}`'>
                      <v-icon color="#293272">
                        mdi-email
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col align-self="center" style='margin-left:16px;'>
                    Email: <a :href='`mailto:${$store.getters.contact.email}`'>{{$store.getters.contact.email}}</a>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4">
              <strong>SITE MAP</strong><div class="square"></div><br>
                <SiteMap :site="site"/>
            </v-col>
        </v-row>
    </v-container>

    <div class="foot">
        <v-container>
            <v-row>
                <v-col cols="12" sm="4" md="4" lg="4" style='margin-top:8px;'>© Copyright 2022 Thrombosis Canada</v-col>
                <v-col cols="12" sm="4" md="4" lg="4">
                    <v-btn text dark @click="$store.getters.Disclaimer.display()">Disclaimer</v-btn>
                    <span>|</span>
                    <!-- <span style='word-break:keep-all; padding:0 16px;'>Privacy Policy</span> -->
                    <v-btn text dark @click="$router.push({path:'/terms_of_use'})">Terms of Use</v-btn>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4">
                    <v-btn link text dark href="/about_us/contact" @click.prevent="$router.push({path:'/about_us/contact'})">Contact Us</v-btn>
                </v-col>
            </v-row>
            <v-row v-if="$store.getters.Authorizer.isAuthorized(['SuperAdmin','admin']) && !$route.params.adminModule">
                <v-col>
                    <v-btn x-small icon @click="$router.push('/admin')">
                        <v-icon>mdi-account-hard-hat</v-icon>
                    </v-btn>
                    <v-btn x-small icon :color="$store.getters.language=='en-ca' ? 'green' : 'red' " @click="$store.commit('language',$store.getters.language=='en-ca' ? 'fr-ca' : 'en-ca')">
                        <v-icon>mdi-earth</v-icon>
                    </v-btn>
                    <v-btn x-small icon :color="$store.getters.debug ? 'red' : '' " @click="$store.commit('debug',!$store.getters.debug)">
                        <v-icon>mdi-bug</v-icon>
                    </v-btn>
                    <v-btn x-small icon @click="edit">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</div>
</template>

<script>
import Logo from '@/assets/logo.png'
import SiteMap from './SiteMap/SiteMap.vue'
import FindUs from '@/components/FindUs/find_us.vue'
import Marker from '@/assets/map-marker.svg'

export default {
  props: {
    site: {
      type: Object,
      required: true
    }
  },
  components: {
    SiteMap,
    FindUs
  },
  methods: {
    showOnGMap: function(){
      window.open(this.$store.getters.contact.address.gmap, '_blank', 'noreferrer');
    },
    edit: function(){
      function makeShortCutKey(path){
        let parts = path.substring(1).split('/')
        let output = ''
        for(let i=0; i<parts.length; i++){
          let node = parts[i]
          if(node!='home'){
            output += `['_pages']['${node}']`
          }else{
            output += node
          }
        }
        return output
      }

      let path = this.$route.path
      let target = makeShortCutKey(path) //path.replaceAll('/','._pages.')
      if(path=='/'){
        this.$router.push({path:'/admin/pages',query:{edit:'home'}})
      }else{
        this.$router.push({path:'/admin/pages',query:{edit:'home'+target}})
      }
    }
  },
  computed: {
    images: function(){
      return {
        logo: Logo,
        Marker
      }
    },
  }
}
</script>

<style scoped lang="scss">

.footer{
    width: 100%;
    border-top: 3px solid $primary;
    font-size: 0.8em;
    text-align: left;
    padding-top: 25px;

    > .container {
        padding: 0 16px;
    }
}

.foot{
    text-align: center;
    font-weight: bold;
    width: 100%;
    // height: 50px;
    background-color: $primary;
    color: white;
}

.square{
    display: inline-block;
    bottom: 0px;
    right: 0px;
    // transform: translate(0,-10px);
    transform: rotate(90deg) translate(-4px,0);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid $accent;
    border-top: 8px solid transparent;
}
</style>
