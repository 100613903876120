<template>
  <div class="pageNav">
    <v-btn @click="add">New Link</v-btn>
    <v-switch v-model="config.inherit" :true-value="true" :false-value="false" label="inherit"/>
    <v-expansion-panels v-if="!config.inherit">
        <draggable v-model="config.pages" style="width: 100%;">
            <v-expansion-panel v-for="(page, pageIndex) in config.pages" :key="`_page_${pageIndex}`">
                <v-expansion-panel-header>
                    <div><b><span v-html="page.label[language]"/></b>: /{{page.path}}</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-text-field v-model="page.icon.Key"/>
                    <Browser v-model="page.icon" @setBucket="setBucket"/>
                    <!-- <v-select v-model="page.icon" :items="_navIcons" label="Icon"> -->
                    
                        <template v-slot:selection="{ item }">
                            <component :is="item.value" style="width: 30px;"/>
                        </template>


                        <template v-slot:item="{ active, item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                <v-list-item-action>

                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-row no-gutters align="center">
                                            <span>{{ item.text }}</span>
                                            <v-spacer></v-spacer>
                                            <component :is="item.value" style="width: 30px;"/>
                                        </v-row>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-select>


                    <v-text-field v-model="page.path" label="Path: /"/>
                    <v-switch v-model="page.newTab" :true-value="true" :false-value="false" label="newTab"/>
                    <div v-for="language in $store.getters.languages" :key="'panel-language-'+language">
                        <v-text-field v-model="page.label[language]" :label="language"/>
                    </div>
                    <v-switch :true-value="true" :false-value="false" v-model="page.enabled" label="Enabled"/>
                    <v-btn @click="remove(pageIndex)">Remove</v-btn>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </draggable>
    </v-expansion-panels>

    <hr>
    <div style="margin: 25px; border: 1px solid grey; padding: 12px;">
        <h4>Children</h4>
        <v-list-item v-for="(page, pageKey) in structure.page._pages" :key="'pageNavHelper_'+pageKey">
            <v-list-item-content>
                <v-list-item-title>
                    {{pageKey}}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>    
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Browser from '@/components/Assets/Manager'

// import Team from '@/components/Nav/assets/team.svg'
// import Partners from '@/components/Nav/assets/partners.svg'
// import Members from '@/components/Nav/assets/members.svg'
// import Contribute from '@/components/Nav/assets/contribute.svg'
// import Drop from '@/components/Nav/assets/drop.svg'
// import Info from '@/components/Nav/assets/info.svg'
// import Hand from '@/components/Nav/assets/hand.svg'
// import Clipboard  from '@/components/Nav/assets/clipboard.svg'
// import Checks  from '@/components/Nav/assets/checks.svg'
// import Laptop  from '@/components/Nav/assets/laptop.svg'
// import Presenter  from '@/components/Nav/assets/presenter.svg'
// import Doctor  from '@/components/Nav/assets/doctor.svg'
// import Pills  from '@/components/Nav/assets/pills.svg'
// import Shield  from '@/components/Nav/assets/shield.svg'
// import Medboard  from '@/components/Nav/assets/medboard.svg'
// import Pin  from '@/components/Nav/assets/pin.svg'
// import Profile  from '@/components/Nav/assets/profile.svg'
// import Gear  from '@/components/Nav/assets/gear.svg'

export default {
    created: function(){
        let config
        if(this.$attrs.value){
            config = JSON.stringify(this.$attrs.value)
            config = JSON.parse(config)
        }

        if(!config.pages){
            config.pages = []
            config.inherit = true
        }

        this.config = config
        this.save()
        
    },
    components: {
        draggable,
        Browser,
        // Team,
        // Partners,
        // Members,
        // Contribute,
        // Drop,
        // Info,
        // Hand,
        // Clipboard,
        // Checks,
        // Laptop,
        // Presenter,
        // Doctor,
        // Pills,
        // Shield,
        // Medboard,
        // Pin,
        // Profile,
        // Gear
    },
    props: {
        // definitionIndex: {
        //     type: Number,
        //     required: true
        // },        
        structure: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    data: function(){
        return {
            config: {
                inherit: true,
                pages: []
            },
            bucket: null
        }
    },
    methods: {
        template: function(){
            return {
                enabled: true,
                newTab: false,
                icon: {},
                label: {},
                path: null
            }
        },
        add: function(){
            this.config.pages.push(this.template())
        },
        remove: function(index){
            this.config.pages.splice(index,1)
        },
        save: function(){
            // this.$emit('save',{definitionIndex:this.definitionIndex, config:this.config})
            let config = this.config
            for(let key in config){
                this.model[key] = config[key]
            }
        },
        setBucket: function(data){
            this.bucket = data
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        },
        // _navIcons: function(){
        //     let output = [
        //         {
        //             text: 'Team',
        //             value: 'Team'
        //         },
        //         {
        //             text: 'Partners',
        //             value: 'Partners'
        //         },
        //         {
        //             text: 'Members',
        //             value: 'Members'
        //         },
        //         {
        //             text: 'Contribute',
        //             value: 'Contribute'
        //         },
        //         {
        //             text: 'Drop',
        //             value: 'Drop'
        //         },
        //         {
        //             text: 'Info',
        //             value: 'Info'
        //         },
        //         {
        //             text: 'Hand',
        //             value: 'Hand'
        //         },
        //         {
        //             text: 'Clipboard',
        //             value: 'Clipboard'
        //         },
        //         {
        //             text: 'Checks',
        //             value: 'Checks'
        //         },
        //         {
        //             text: 'Laptop',
        //             value: 'Laptop'
        //         },
        //         {
        //             text: 'Presenter',
        //             value: 'Presenter'
        //         },
        //         {
        //             text: 'Doctor',
        //             value: 'Doctor'
        //         },
        //         {
        //             text: 'Pills',
        //             value: 'Pills'
        //         },
        //         {
        //             text: 'Shield',
        //             value: 'Shield'
        //         },
        //         {
        //             text: 'Medboard',
        //             value: 'Medboard'
        //         },
        //         {
        //             text: 'Pin',
        //             value: 'Pin'
        //         },
        //         {
        //             text: 'Profile',
        //             value: 'Profile'
        //         },
        //         {
        //             text: 'Gear',
        //             value: 'Gear'
        //         }
        //     ]


        //     return output
        // },
        // icons: function(){
        //     return {
        //         Team,
        //         Partners,
        //         Members,
        //         Contribute,
        //         Drop,
        //         Info,
        //         Hand,
        //         Clipboard,                
        //     }
        // }
    },
    watch: {
        config: {
            deep: true,
            handler: function(){
                this.save()
            }
        }
    }
}
</script>

<style scoped lang="scss">
// .pageNav{
//     // border: 1px solid red;
// }
</style>